@mixin \compass\search\autocomplete-variation-klevu\apply() {
    .search-autocomplete {
        @include \compass\scroll-bar\apply();
        position: absolute;
        top: 100%;
        right: 0;
        z-index: \kindling\z-index\get('above-sibling');
        background-color: \kindling\color\get('form.search.autocomplete.background');
        width: 100%;
        max-height: calc(100vh - #{\kindling\value\rem-calc(130)});

        @include \kindling\breakpoint\media-query($\compass\search\header\desktop-breakpoint) {
            min-width: \kindling\value\rem-calc(800);
        }

        .klevu-pt-rs-hover,
        .klevu-searching-area-l2,
        .klevu-searching-area {
            position: relative !important; // sass-lint:disable-line no-important
            top: 0 !important; // sass-lint:disable-line no-important
            right: 0 !important; // sass-lint:disable-line no-important
            left: 0 !important; // sass-lint:disable-line no-important
        }

        .klevu-fluid {
            .klevu-pt-rs-hover {
                width: 100% !important; // sass-lint:disable-line no-important
            }

            .klevu-searching-area-l2,
            .klevu-searching-area {
                @include \kindling\spacing\apply('reset', $margin-sides: ('left'));
                width: 100%;
            }

            .klevu-grid-view {
                ul {
                    li {
                        vertical-align: top;

                        .klevu-result-box {
                            @include \kindling\property\apply(min-height, ('small': \kindling\value\rem-calc(260), 'medium': \kindling\value\rem-calc(245), 'large': \kindling\value\rem-calc(260), 'xlarge': \kindling\value\rem-calc(245)))
                        }
                    }
                }
            }
        }
    }
}

@mixin \compass\search\autocomplete-variation-magento\apply() {
    .search-autocomplete {
        z-index: \kindling\z-index\get('above-sibling');
        background-color: \kindling\color\get('form.search.autocomplete.background');

        ul {
            @include \kindling\typography\unstyled-list();
            @include \kindling\border\apply('form.search.autocomplete', $sides: ('right', 'bottom', 'left'));

            li {
                &.selected {
                    a {
                        background-color: \kindling\color\get('form.search.autocomplete.background-hover');
                    }
                }
            }
        }

        a {
            @include \kindling\typography\font-size('form.search.autocomplete');
            @include \kindling\spacing\apply('form.search.autocomplete', $padding-sides: ('all'));
            display: block;
            text-decoration: none;
            color: \kindling\color\get('form.search.autocomplete.color');

            &:focus,
            &:hover {
                background-color: \kindling\color\get('form.search.autocomplete.background-hover');
            }
        }
    }
}

@mixin \compass\search\autocomplete($variation: 'klevu') {
    @if $variation == 'klevu' {
        @include \compass\search\autocomplete-variation-klevu\apply();
    } @else if $variation == 'magento' {
        @include \compass\search\autocomplete-variation-magento\apply();
    } @else {
        @error 'Unknown autocomplete type `#{$variation}`';
    }
}
