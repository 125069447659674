@mixin \customer\account\form\button() {
    @include \kindling\grid\column(
        $responsive-sizes: (
            'small': \kindling\grid\column-width-calc(12),
            'medium': auto
        ),
        $flex-grow: 0
    );
    @include \kindling\spacing\apply($spacing: 'grid-gutters', $margin-sides: 'bottom');

    @include \kindling\breakpoint\media-query('medium') {
        margin-bottom: 0;
    }

    .action {
        @include \compass\button\primary();
        display: block;
    }
}

@mixin \customer\account\form() {
    .form-edit-account {
        @include \compass\layout\address-form();

        .block-title {
            align-items: flex-start;
        }

        .fieldset {
            @include \kindling\spacing\apply('global.medium', $margin-sides: ('bottom'));
        }
    }

    .form-address-edit {
        @include \compass\layout\address-form();

        .default-address-labels {
            @include \kindling\spacing\apply($spacing: 'grid-gutters', $margin-sides: ('top', 'bottom'));
        }
    }

    .actions-toolbar {
        @include \kindling\spacing\apply('base.medium', $margin-sides: ('top'));
        @include \kindling\grid\row();
        align-items: center;
        justify-content: space-between;

        .primary {
            @include \customer\account\form\button();
        }

        .secondary {
            @include \customer\account\form\button();
        }
    }
}
