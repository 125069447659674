@mixin \compass\page\order-review\apply() {
    .checkout-review-form {
        .checkout-order-review-section {
            @include \kindling\spacing\apply('box.default.container', $padding-sides: ('all'), $margin-sides: ('bottom'));
            @include \kindling\border\apply($checkout-section-border);
            background-color: \kindling\color\get('neutral-lighter');

            .legend {
                @include \kindling\spacing\apply('typography.h3', $margin-sides: ('bottom'));
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                .heading {
                    @include \kindling\typography\heading\h4();
                    flex: 0 0 auto;
                    margin-bottom: 0;
                }
            }

            .checkout-order-review-edit {
                flex: 0 0 auto;
                text-decoration: none;

                .edit-icon {
                    @include \kindling\icon\apply($icon: $icon-edit) {
                        vertical-align: 0;
                    }
                }

                span {
                    &:not(:first-child) {
                        text-decoration: underline;
                    }
                }
            }

            .sub-heading {
                @include \kindling\spacing\apply('typography.h4', $margin-sides: ('top'));
                @include \compass\typography\font\apply('primary', $weight: 'bold')
            }
        }

        .checkout-action-toolbar {
            .complete-order-button {
                @include \compass\button\icon-button($icon: $icon-checkmark-strong, $size: 'regular', $use-before: false);
                width: 100%;

                @include \kindling\breakpoint\media-query('large') {
                    width: auto;
                }
            }
        }
    }
}
