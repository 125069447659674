@mixin \compass\grid\columns\apply(
    $breakpoint: null,
    $grid-gap-spacing: 'grid-gutters',
    $grid-column-gap-spacing: null,
    $columns: 2
) {
    @supports (grid-area: auto) { // sass-lint:disable-block no-css-comments
        /* autoprefixer: off */
        @include \kindling\property\apply('grid-gap', \kindling\spacing\get($grid-gap-spacing));
        display: grid;
        grid-template-columns: 1fr;

        @if $breakpoint == null {
            $breakpoint: $\kindling\breakpoint\first;
        }

        @include \kindling\breakpoint\media-query($breakpoint) {
            grid-template-columns: repeat($columns, 1fr);

            @if $grid-column-gap-spacing != null {
                @include \kindling\property\apply('grid-column-gap', \kindling\spacing\get($grid-column-gap-spacing));
            }
        }

        > * {
            margin: 0;
        }
    }
}

@mixin \compass\grid\row(
    $column-gutters: \kindling\spacing\get('grid-gutters'),
    $vertical-gutters: true,
    $spacing-type: 'padding',
    $target: $\compass\grid\row\target
) {
    display: flex;
    flex-wrap: wrap;

    @each $breakpoint, $grid-gutter in $column-gutters {
        @include \kindling\breakpoint\media-query($breakpoint) {
            margin-left: (-1 * \kindling\value\get-side($grid-gutter, 'left'));

            #{$target} {
                #{$spacing-type}-left: \kindling\value\get-side($grid-gutter, 'left');
            }

            @if $vertical-gutters {
                margin-top: (-1 * \kindling\value\get-side($grid-gutter, 'top'));

                #{$target} {
                    #{$spacing-type}-top: \kindling\value\get-side($grid-gutter, 'top');
                }
            }
        }
    }
}
