@mixin \project\cms\homepage\bike-shop-list() {
    .bike-shop-bike-list {
        @include \kindling\typography\unstyled-list();
        @include \compass\grid\row(
            $column-gutters: (
                'small': \kindling\value\rem-calc(24),
                'medium': \kindling\value\rem-calc(30),
                'large': \kindling\value\rem-calc(20),
                'xlarge': \kindling\value\rem-calc(0)
            )
        );

        li {
            @include \kindling\grid\column(
                $responsive-sizes: (
                    'small': 100%,
                    'medium': 50%,
                    'large': \kindling\grid\column-width-calc(4),
                    'xlarge': \kindling\grid\column-width-calc(1.25)
                )
            );
            flex: 0 1 auto;
            margin: auto;

            > * {
                @include \kindling\spacing\apply('reset', $padding-sides: ('left'));
            }

            a {
                display: flex;
                position: relative;
                flex-direction: column;
                text-align: center;
                text-decoration: none;

                &:hover,
                &:focus {
                    .shop-text {
                        @include \compass\button\tertiary-hover();
                    }
                }
            }

            .bike-logo-image {
                @include \kindling\property\apply('height', \kindling\value\rem-calc(40));
                @include \kindling\spacing\apply('base.xsmall', $margin-sides: ('bottom'));
                display: flex;
                align-items: center;
                width: 100%;

                img {
                    margin: auto;
                    max-width: 70%;
                    max-height: 100%;
                }
            }

            .bike-image {
                @include \kindling\property\apply('height', \kindling\value\rem-calc(90));
                display: flex;
                align-items: end;

                img {
                    margin: auto;
                    max-height: 100%;
                }
            }

            .shop-text {
                @include \compass\button\tertiary('xsmall');
                display: inline-block;
                margin: \kindling\value\rem-calc(10) auto 0;
                text-decoration: none;
                letter-spacing: inherit;
            }
        }
    }
}
