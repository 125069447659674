@mixin \compass\base\form-legend\heading(
    $heading-type: 'h2',
    $text-color: 'form.default.legend.heading',
    $include-margin: true
) {
    @include \compass\typography\heading($heading-type);
    color: \kindling\color\get($text-color);

    @if $include-margin {
        @include \kindling\spacing\apply('form.default.heading', $margin-sides: ('bottom'));
    }
}

@mixin \compass\base\form-legend\description(
    $text-color: 'form.default.legend.description'
) {
    @include \compass\typography\copy();
    @include \kindling\typography\font-weight('body');
    color: \kindling\color\get($text-color);
}

@mixin \compass\forms\input\completed-border() {
    &:not(:disabled) {
        @include \kindling\border\apply('form.default.complete');

        &:hover,
        &:focus {
            @include \kindling\border\apply('form.default.active');
        }
    }
}

@mixin \compass\form\inline-input-button($context: 'form.default', $complete: 'complete') {
    $complete-border: \kindling\border\context-keys-calc($complete, $context, 'form.default');

    display: flex;
    align-items: flex-start;

    .field {
        flex: 1 1 auto;
        margin-bottom: 0;

        &.is-initialized {
            &:not(.is-empty) {
                input {
                    @include \compass\form\input\border($complete-border, $use-inset: true);
                }
            }
        }

        input {
            @include \compass\form\input\border($use-inset: true);
        }
    }
}

@mixin \compass\forms\apply() {
    @include \kindling\apply-form-styles();

    .field {
        @include \kindling\form\field();
        @include \kindling\magento\apply-sneaky-labels();
        @include \compass\base\input\mage-error();

        &.has-error {
            .field-error-message {
                display: block;
            }
        }

        &.is-initialized {
            &:not(.is-empty) {
                #{\kindling\inputs\get-type-selector()},
                textarea,
                select {
                    @include \compass\form\input\border('form.default.complete');
                }
            }
        }

        #{\kindling\inputs\get-type-selector()},
        textarea {
            &:disabled {
                cursor: not-allowed;

                &::placeholder {
                    color: \kindling\color\get('form.default.input.placeholder-color');
                }
            }
        }

        input[type='checkbox'],
        input[type='radio'] {
            ~ label {
                @include \kindling\typography\font-weight('form.default.input');
            }

            &:disabled {
                ~ label {
                    @include \kindling\typography\font-weight('form.default.normal');
                    cursor: not-allowed;
                    color: \kindling\color\get('form.default.input.checkbox-disabled');
                }
            }

            ~ div.mage-error {
                @include \kindling\border\apply('form.default.error', $sides: ('top'));
                @include \kindling\spacing\apply('form.default.error', $margin-sides: ('top'));
            }
        }

        select {
            &:disabled {
                cursor: not-allowed;
            }
        }
    }

    .required {
        label,
        .label {
            &::before {
                content: '* ';
            }
        }
    }

    .form-label {
        @include \kindling\apply-sneaky-labels();
    }

    .nested {
        @include \kindling\property\apply('margin-top', $form-margin);

        .field {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .note {
        @include \kindling\form\help-text();
    }

    .form-submit,
    [type='submit'] {
        @include \compass\button\primary();
    }

    .password-field {
        @include \compass\base\input\password-field();
    }

    .legend {
        @include \kindling\form\legend();
    }

    .heading {
        @include \compass\base\form-legend\heading();
    }

    .description {
        @include \compass\base\form-legend\description();
    }

    fieldset:disabled, // sass-lint:disable-line force-pseudo-nesting
    .field.disabled {
        .label {
            color: \kindling\color\get('form.default.input.text-color-disabled');
        }
    }
}

@mixin \compass\base\form-styles\small\apply() {
    .field {
        &:not(.is-empty) {
            > .control {
                select {
                    @include \compass\form\input\border('form.default.complete');
                }
            }
        }

        &.is-initialized {
            &.is-empty {
                .label {
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            &:not(.is-empty) {
                .label {
                    $top: \kindling\value\get-side(\kindling\spacing\get('form.default.input-small-sneaky.padding'), 'top');
                    @include \kindling\property\apply('top', $top * 0.7);
                }
            }

            .label {
                @include \kindling\property\apply('left', \kindling\value\get-side(\kindling\spacing\get('form.default.input-small-sneaky.padding'), 'left'));
            }
        }

        > .control {
            select {
                @include \kindling\form\sneaky\base\frame($padding: 'input-small-sneaky.padding');
                @include \kindling\form\select($padding: 'input-small-sneaky.padding');
            }
        }
    }
}

@mixin \compass\base\form-styles\apply() {
    .form {
        @include \compass\forms\apply();
    }
}

// Some Magento RMA templates use form markup where there are no inputs.
@mixin \compass\forms\negate-sneaky-label-position\apply() {
    .label {
        position: static !important; // sass-lint:disable-line no-important
    }
}

@mixin \kindling\form\select\extra(
    $context: $\kindling\form\default-context,
    $border: null,
    $border-color-hover: null,
    $border-radius: null,
    $background-color: null,
    $background-color-hover: null,
    $background-color-disabled: null,
    $color: null,
    $font-size: null,
    $include-outline: null,
    $padding: null,
    $transition-properties: null,
    $line-height: null,
    $triangle-color: null,
    $triangle-size: null,
    $triangle-shape: null,
    $background-repeat: null,
    $background-position: null,
    $width: null,
    $appearance: null,
    $height-calc: null,
    $option-color: null,
    $option-background-color: null,
    $option-hover-color: null,
    $option-hover-background-color: null,
    $option-active-color: null,
    $option-active-background-color: null,
    $extras: ()
) {
    opacity: 1;
}
