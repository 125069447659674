@import 'components/wishlist-management.scss';
@import 'components/wishlist-dropdown.scss';
@import 'components/wishlist-modals.scss';

@mixin \project\multiple-wishlist\apply() {
    @include \project\multi-wishlist\wishlist-modals\apply();
    @include \project\multi-wishlist\wishlist-dropdown();

    .page-multiple-wishlist {
        @include \project\multi-wishlist\management();
    }
}
