@mixin \compass\catalog\search-page\apply() {
    .catalogsearch-result-index {
        .search.results {
            display: flex;
            flex-wrap: wrap;

            > * {
                flex: 1 0 auto;
                width: 100%;
            }

            .search-data {
                @include \kindling\spacing\apply('search-results.padding', $padding-sides: ('all'));
                @include \kindling\spacing\apply('search-results.margin', $margin-sides: ('all'));
                order: -1;
                background-color: \kindling\color\get('neutral-lighter');

                ul {
                    @include \kindling\typography\unstyled-list();
                    @include \compass\grid\row(\kindling\spacing\get('search-results.list'));

                    .title {
                        @include \compass\typography\font\apply('primary', 'bold');

                        &::after {
                            content: ':';
                        }
                    }
                }
            }
        }
    }
}
