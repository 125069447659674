$\compass\magento-checkout\cart\responsive-sizes: (
    'small': \kindling\grid\column-width-calc($columns: 6),
    'large': \kindling\grid\column-width-calc($columns: 3)
);

@mixin \compass\magento-checkout\cart\item\image-modal() {
    .product-photo {
        position: relative;
    }

    .cart-product-photo-modal-open-button {
        @include \kindling\spacing\apply('base.xsmall', $margin-sides: ('top'));
        @include \compass\button\icon-button($icon-zoom-in, 'secondary', 'micro');
        width: 100%;

        @include \kindling\breakpoint\media-query('large') {
            @include \compass\button\reset-browser-button();
            @include \kindling\icon\selector() {
                @include \kindling\typography\font-size('large.5');
                margin: 0;
                padding: 0;
            }
            position: absolute;
            top: 0;
            left: 0;
            margin-top: 0;
            border: 0;
            box-shadow: none;
            padding: \kindling\breakpoint\get-breakpoint-value(\kindling\spacing\get('base.xxsmall'), 'large');
            width: auto;
        }

        &:not(:disabled) {
            &:hover,
            &:focus {
                box-shadow: \kindling\map\get($\compass\button\shadows, 'top-left');
                background: \kindling\color\get('neutral-white');
                color: \kindling\color\get('neutral-black');

                @include \kindling\breakpoint\media-query('large') {
                    @include \kindling\icon\selector() {
                        text-indent: 0;
                    }
                }
            }
        }
    }

    .cart-product-photo-modal-open-button-text {
        @include \kindling\breakpoint\media-query('large') {
            display: none;
        }
    }

    @at-root {
        .cart-product-photo-modal {
            @include \compass\modal\apply();
            @include \compass\modal\magento-content();
        }
    }
}

@mixin \compass\magento-checkout\cart\items\apply() {
    @include \compass\magento-checkout\cart\item\image-modal();

    .order-items-container {
        @include \kindling\typography\unstyled-list();
        @include \compass\checkout\order\item\apply($include-bottom-border: true);

        .product-photo {
            $max-width: (
                'small': 100%,
                'medium': \kindling\value\rem-calc(225),
                'large': none,
                'xlarge': \kindling\value\rem-calc(210)
            );
            @include \kindling\property\apply('max-width', $max-width);
            @include \kindling\grid\column(
                $responsive-sizes: (
                    'small': \kindling\grid\column-width-calc(4),
                    'large': 40%,
                    'xlarge': 25%
                )
            );

            .cart-product-photo-modal {
                display: none;
            }
        }
    }

    .order-items-title {
        @include \kindling\spacing\apply('base.medium', $margin-sides: ('bottom'));
    }

    .item-price-row {
        @include \compass\typography\font\apply('primary', 'normal');

        @include \kindling\breakpoint\media-query('xlarge') {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
        }

        .actions-toolbar {
            @include \kindling\spacing\apply('base.small', $margin-sides: ('top'));
            display: flex;
            flex: 0 0 auto;
            flex-wrap: wrap;
            align-items: center;

            @include \kindling\breakpoint\media-query('xlarge') {
                margin-top: 0;
            }

            .action {
                @include \kindling\icon\selector() {
                    @include \kindling\icon\base();
                    margin-right: \kindling\value\rem-calc(3);
                }
                @include \kindling\typography\font-size('typography.copy.small');
                display: flex;
                align-items: center;
                text-transform: none;
                text-decoration: none;

                &:hover,
                &:focus {
                    span {
                        text-decoration: underline;
                    }
                }

                & {
                    @include \kindling\spacing\apply('base.small', $margin-sides: ('right'));
                }

                span {
                    text-decoration: underline;
                }

                &.action-edit {
                    @include \kindling\icon\selector() {
                        @include \kindling\typography\font-size('cart.item.action.icon.edit');
                        margin-right: \kindling\value\rem-calc(3);
                        content: $icon-edit;
                    }
                }

                &.action-delete {
                    @include \kindling\icon\selector() {
                        @include \kindling\typography\font-size('cart.item.action.icon.delete');
                        margin-right: \kindling\value\rem-calc(3);
                        content: $icon-trash;
                    }
                }

                &.action-towishlist {
                    @include \kindling\icon\selector() {
                        @include \kindling\typography\font-size('cart.item.action.icon.wishlist');
                        margin-right: \kindling\value\rem-calc(3);
                        content: $icon-heart-on;
                    }
                }
            }
        }
    }

    .item-price {
        input {
            width: \kindling\value\rem-calc(70);
        }
    }

    .item-total {
        @include \kindling\typography\font-size('cart.item.total');
        @include \kindling\spacing\apply('grid-gutters.small', $margin-sides: ('bottom'));
        @include \kindling\spacing\apply('base.small', $margin-sides: ('top'));
        @include \compass\typography\font\apply('primary', 'medium');
        order: 3;
        text-transform: uppercase;
        color: \kindling\color\get('primary');
    }

    .item-individual-price {
        @include \compass\typography\font\apply('primary', 'normal');

        @include \kindling\breakpoint\media-query('medium') {
            font-size: \kindling\breakpoint\get-breakpoint-value(\kindling\typography\font-size\get('typography.copy.default'), 'small');
        }
    }

    .item-quantity {
        display: flex;
        flex: 1 0 auto;
        align-items: center;
        line-height: 1;

        .divider {
            @include \kindling\icon\apply($icon: $icon-close);
            @include \kindling\spacing\apply('base.xsmall', $margin-sides: ('right', 'left'));
            @include \kindling\typography\font-size('cart.item.quantity-divider');
        }

        .price-suffix {
            text-transform: none;
        }
    }
}

@mixin \compass\magento-checkout\cart\actions() {
    .cart {
        &.actions {
            @include \kindling\spacing\apply('base.large', $padding-sides: ('top', 'bottom'));
            @include \kindling\border\apply('checkout-bottom-border.default.default', $sides: ('bottom', 'top'));
            @include \kindling\grid\row();

            align-items: center;
            justify-content: space-between;

            .continue {
                @include \compass\button\icon-button($icon-previous, $style: 'secondary');
            }

            [type='submit'] {
                @include \compass\button\icon-button($icon-refresh, $style: 'secondary');
                @include \kindling\typography\font-size('cart.actions.submit');
            }

            .product-item-details-reviews {
                display: none;
            }

            .continue,
            [type='submit'] {
                @include \kindling\breakpoint\media-query('small' 'down') {
                    justify-content: center;
                    width: 100%;
                }

                &:not(:first-child) {
                    @include \kindling\breakpoint\media-query('small' 'down') {
                        margin-top: \kindling\value\rem-calc(10);
                    }
                }
            }
        }
    }
}

@mixin \compass\magento-checkout\cart\layout() {
    .cart-container {
        $cart-items-area: \compass\grid\area-name('cart-items');
        $cart-sidebar-area: \compass\grid\area-name('sidebar');
        $cart-additional-area: \compass\grid\area-name('additional');
        $responsive-columns: (
            'small': 100%,
            'large': 50% auto \kindling\grid\column-width-calc(5),
            'xlarge': \kindling\grid\column-width-calc(8) 1fr 31%,
            'xxlarge': \kindling\grid\column-width-calc(8) 1fr 28%
        );
        $responsive-rows: (
            'small': auto,
            'large': auto 1fr
        );
        $responsive-areas: (
            'small':
            '#{$cart-items-area}' '#{$cart-sidebar-area}' '#{$cart-additional-area}',
            'large':
            '#{$cart-items-area} . #{$cart-sidebar-area}' '#{$cart-additional-area} #{$cart-additional-area} #{$cart-additional-area}',
            'xlarge':
            '#{$cart-items-area} . #{$cart-sidebar-area}' '#{$cart-additional-area} . #{$cart-sidebar-area}'
        );
        @include \project\sidebar-trust-badges\apply();
        display: grid;

        //Grid properties must be implemented together so the Autoprefixer can properly prefix the values.
        @each $breakpoint, $value in $breakpoints {
            @include \kindling\breakpoint\media-query($breakpoint) {
                grid-template-columns: \kindling\breakpoint\get-breakpoint-value($responsive-columns, $breakpoint);
                grid-template-rows: \kindling\breakpoint\get-breakpoint-value($responsive-rows, $breakpoint);
                grid-template-areas: \kindling\breakpoint\get-breakpoint-value($responsive-areas, $breakpoint);
            }
        }

        > .cart-summary {
            grid-area: $cart-sidebar-area;
        }

        > .form-cart-container {
            grid-area: $cart-items-area;
        }

        > .cart-crosssell-products {
            grid-area: $cart-additional-area;
        }
    }

    .content-columns {
        justify-content: space-between;
    }
}

@mixin \compass\magento-checkout\cart\apply() {
    .checkout-cart-index {
        @include \compass\magento-checkout\cart\layout();
        @include \compass\magento-checkout\cart\items\apply();
        @include \compass\magento-checkout\cart\actions();
        @include \compass\magento-checkout\cart\sidebar\apply();

        .loading-mask {
            @include \compass\overlay();

            p {
                display: none;
            }
        }

        .product-items {
            @include \compass\product-grid\apply($responsive-sizes: $\compass\magento-checkout\cart\responsive-sizes);
        }

        .cart-crosssell-products {
            @include \kindling\spacing\apply('base.xlarge', $margin-sides: ('top'));

            .message.empty {
                display: none;
            }
        }

        .product-renderer-list-title {
            @include \compass\typography\heading('h2');
            @include \kindling\spacing\apply('cross-sells.title', $margin-sides: ('bottom'));
        }

        .product-renderer-container {
            @include \kindling\spacing\apply('cross-sells.default', $margin-sides: ('top'));
        }


        .page-title-wrapper {
            @include \kindling\grid\row();
            @include \kindling\spacing\apply('base.large', $margin-sides: ('bottom'));
            @include \compass\magento-checkout\cart\sidebar\accordions();
            @include \kindling\border\apply('checkout-bottom-border.default.default', $sides: ('bottom'));

            align-items: center;
            justify-content: space-between;

            @include \kindling\breakpoint\media-query('large') {
                @include \kindling\spacing\apply('grid-gutters.large', $padding-sides: ('bottom'));
            }

            .cart-mobile-accordion {
                @include \kindling\spacing\apply('base.medium', $margin-sides: ('top'));
                width: 100%;

                @include \kindling\breakpoint\media-query('large') {
                    display: none;
                }

                .checkout-sidebar-accordion-item {
                    border-width: 0;
                }

                .checkout-sidebar-accordion-body {
                    padding: 0;
                }
            }

            .action.primary {
                @include \compass\button\primary($size: 'large');
                @include \kindling\spacing\apply('base.xsmall', $margin-sides: ('top'));
                @include \compass\button\icon-button($icon-chevron-right, $style: 'primary', $use-before: false);
                @include \kindling\breakpoint\media-query('medium') {
                    margin-top: 0;
                }
                flex-grow: 0;
            }

            .checkout {
                @include \kindling\breakpoint\media-query('small' 'down') {
                    @include \kindling\spacing\apply('checkout-button-mobile.button', $margin-sides: ('top'));
                    width: 100%;
                }
            }
        }

        .cart-summary {
            .checkout-sidebar-accordion-item {
                &:not(:last-child) {
                    margin-bottom: 0;
                }
            }

            .cart-totals {
                .table-wrapper {
                    .sezzle-shopify-info-button {
                        @include \kindling\spacing\apply('base.small', $margin-sides: ('top' 'right' 'left'));
                        float: left;
                    }
                }
            }
        }
    }
}
