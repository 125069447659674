@mixin \compass\skip-link\container() {
    position: relative;
}

@mixin \kindling\skip-link\apply\extra($extras: ()) {
    @include \kindling\border\apply(
        $context: 'skip-link.default',
        $sides: ('all')
    );
    @include \kindling\typography\font-size('body');
    position: absolute;
    background-color: \kindling\color\get('skip-link.default.background');
    padding: \kindling\value\rem-calc(20);
    text-decoration: none;

    &:hover,
    &:focus {
        color: \kindling\color\get('skip-link.default.text');
    }
}

@mixin \compass\skip-link\apply() {
    .skip-link-container {
        @include \compass\skip-link\container();

        &:last-of-type {
            .skip-link {
                bottom: 0;
            }
        }
    }

    .skip-link {
        @include \kindling\skip-link\apply();
    }

    .content-main {
        .skip-link {
            z-index: \kindling\z-index\get('content-skip-link');
        }
    }
}
