@mixin \compass\magento-checkout\cart\sidebar\coupon() {
    .checkout-sidebar-promo-intro-text {
        @include \kindling\spacing\apply('form.default.input.margin', $margin-sides: ('bottom'));
    }

    .checkout-sidebar-promo-intro-text,
    .coupon-cta-text {
        @include \kindling\typography\font-size('cart.sidebar.coupon.cta-text');
    }

    .checkout-sidebar-promo-intro-text {
        @include \kindling\typography\font-size('cart.sidebar.coupon.promo-text');
        @include \kindling\spacing\apply('base.medium', $margin-sides: ('bottom'));
    }

    .coupon-cta-text {
        @include \kindling\spacing\apply('base.xsmall', $margin-sides: ('top'), $padding-sides: ('top'));
        @include \kindling\border\apply($sides: ('top'));
        border-top: 0;
    }

    .coupon-fieldset {
        @include \compass\form\inline-input-button();

        .action-apply {
            @include \compass\button\primary-dark();
        }
    }

    .applied-coupons {
        @include \kindling\typography\unstyled-list();
    }

    .discount-coupon-list {
        @include \kindling\spacing\apply('base.small', $margin-sides: ('top'));
    }

    .applied-coupon-row {
        @include \kindling\typography\font-size('cart.sidebar.coupon.applied');
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        .message {
            @include \compass\typography\font\apply('primary', 'semi-bold');
            @include \kindling\icon\apply($icon: $icon-success) {
                @include \kindling\typography\font-size('cart.sidebar.coupon.list.icon');
                @include \kindling\spacing\apply('base.xxsmall', $margin-sides: ('right'));
            }
            display: inline-flex;
            align-items: center;
            color: \kindling\color\get('success');

            .code {
                @include \kindling\typography\font-weight('typography.copy.bold');
                margin-right: \kindling\value\rem-calc(3);
            }
        }

        .action-cancel-coupon {
            @include \kindling\icon\apply($icon: $icon-trash) {
                @include \kindling\typography\font-size('cart.sidebar.coupon.cancel');
                @include \kindling\spacing\apply('base.xxsmall', $margin-sides: ('right'));
            }
            @include \compass\button\reset-browser-button();
            @include \kindling\typography\font-weight('typography.copy.bold');
            display: inline-flex;
            align-items: center;

            &:hover,
            &:focus {
                color: \kindling\color\get('primary');
            }

            span {
                text-decoration: underline;
            }
        }
    }
}

@mixin \compass\magento-checkout\cart\sidebar\accordions() {
    .checkout-sidebar-accordion {
        @include \compass\accordion\container();
    }

    .checkout-sidebar-accordion-item {
        @include \compass\accordion\item($margin: \kindling\value\rem-calc(3));

        &.is-active {
            margin-bottom: 0;
        }

        &.shipping-item {
            margin-bottom: 0;
        }
    }

    .checkout-sidebar-accordion-header {
        @include \compass\accordion\title();
        background: \kindling\color\get('neutral-lighter');
    }

    .checkout-sidebar-accordion-body {
        @include \compass\accordion\body();
        border-width: 0;
        background: \kindling\color\get('neutral-lighter');
        padding-top: 0;
    }

    .checkout-sidebar-accordion-header {
        &:hover,
        &:focus {
            background: \kindling\color\get('neutral-lighter');
        }
    }
}

@mixin \compass\magento-checkout\cart\sidebar\apply() {
    .cart-summary {
        @include \compass\magento-checkout\cart\sidebar\accordions();
        @include \compass\magento-checkout\cart\sidebar\coupon();

        .cart-summary-title {
            @include \kindling\spacing\apply('base.medium', $margin-sides: ('top', 'bottom'));

            @include \kindling\breakpoint\media-query('large') {
                margin-top: 0;
            }
        }

        .shipping-legend {
            @include \kindling\typography\font-size('cart.sidebar.shipping.legend');
            @include \kindling\spacing\apply('form.default.input.margin', $margin-sides: ('bottom'));
        }

        .cart-sidebar-estimated-shipping {
            .methods {
                .label {
                    @include \kindling\typography\font-size('cart.sidebar.shipping.label');
                    @include \kindling\typography\font-weight('body');
                    text-transform: none;

                    > .price {
                        @include \compass\typography\font\apply('primary', 'normal');

                        &::before {
                            display: inline;
                            content: '+';
                        }
                    }
                }
            }
        }

        .checkout-methods-items {
            @include \kindling\typography\unstyled-list();
            @include \kindling\spacing\apply('cart.methods.items', $margin-sides: ('top'));
            display: flex;
            align-items: center;

            .item {
                @include \kindling\grid\column(('small': 100%, 'medium': 50%));

                &:not(:first-child) {
                    margin-top: \kindling\value\rem-calc(5);

                    @include \kindling\breakpoint\media-query('medium') {
                        margin-top: 0;
                        margin-left: \kindling\value\rem-calc(5);
                    }
                }

                .primary {
                    @include \compass\button\icon-button($icon-chevron-right, $style: 'primary', $use-before: false);
                    width: 100%;
                }
            }

            @include \kindling\breakpoint\media-query('small' 'down') {
                flex-wrap: wrap;
            }
        }

        .negotiate-credit-link-wrapper {
            @include \kindling\spacing\apply('base.medium', $margin-sides: ('top'));
            @include \kindling\typography\font-size('cart.sidebar.credit.link');

            @include \kindling\breakpoint\media-query('large') {
                text-align: center;
            }
        }

        .shipping-method-form {
            .field {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .totals.table {
            @include \compass\checkout\totals();
            @include \kindling\border\apply('checkout-bottom-border.default.default', $sides: ('all'));

            .table-caption {
                display: none;
            }

            .totals.shipping {
                .mark,
                .price {
                    @include \kindling\spacing\apply('cart.totals', $margin-sides: ('bottom'));
                }
            }

            .totals.sub {
                @include \compass\typography\font\apply('primary', 'normal');

                .mark,
                .price {
                    @include \kindling\spacing\apply('cart.totals', $margin-sides: ('bottom'));
                }
            }

            .mark {
                @include \compass\typography\font\apply('primary', 'normal');
            }

            .grand {
                @include \kindling\border\apply('checkout-bottom-border.default.default', $sides: ('bottom', 'top'));
                text-transform: uppercase;
            }
        }
    }

    .sidebar-payment-icons {
        @include \kindling\spacing\apply('base.large', $margin-sides: ('top'));
    }

    @at-root {
        .cart-container {
            .page-payment-icon-item {
                @include \kindling\spacing\apply('base.xsmall', $margin-sides: ('right'));
                margin-left: 0;
            }
        }
    }
}
