@mixin \checkout\section\apply() {
    .checkout-step-container {
        @include \kindling\box\container(
            $margin-bottom: \kindling\spacing\get($checkout-section-vertical-spacing),
            $border: $checkout-section-include-borders
        );

        &:not(:first-of-type) {
            @include \compass\section();
        }
    }

    .checkout-payment-form {
        .checkout-step-container {
            &:first-of-type {
                @include \compass\section();
            }
        }
    }

    .checkout-information-form {
        @include \compass\section();
    }

    .checkout-step-section {
        $padding-ratio: $checkout-section-padding-ratio-default;

        @if $checkout-section-include-padding == false {
            $padding-ratio: 0;
        }

        @include \kindling\box\section($background-color: $checkout-section-background-color, $text-color: null, $padding-ratio: $padding-ratio);
    }

    .checkout-option-set {
        @include \kindling\box\container($border: 'not-set', $margin-bottom: null);
        @include \kindling\border\apply($context: $checkout-section-border, $sides: ('top'));
        margin-bottom: 0;

        .checkout-option-set-header {
            @include \kindling\border\apply($context: $checkout-section-border, $sides: ('right', 'left'));
            @include \kindling\spacing\apply('base.xsmall', $padding-sides: ('all'));
        }

        .checkout-option-set-body {
            @include \kindling\box\section($border-sides: ('left', 'right'), $background-color: $checkout-option-set-body-background-color, $text-color: null);
            @include \kindling\border\apply($context: $checkout-section-border, $sides: ('top', 'right', 'left'), $additive: true);
            background: \kindling\color\get('neutral-lighter');
        }

        &:last-of-type {
            @include \kindling\border\apply($context: $checkout-section-border, $sides: ('bottom'), $additive: true);
        }

        .checkout-option-set-label {
            display: inherit;
            align-items: center;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}

@mixin \northern\checkout\form\apply() {
    .checkout-shipping-address-form,
    .checkout-billing-address-form {
        @include \compass\layout\address-form($shorten: false);
    }

    .checkout-tooltip-button {
        @include \compass\tooltip\trigger\apply($apply-button-position-styles: true);
    }

    .checkout-tooltip,
    .compass-tooltip {
        @include \compass\tooltip\content\apply();
    }
}

@mixin \northern\checkout\steps\apply() {
    @include \checkout\onepage\step\customer\apply();
    @include \checkout\onepage\step\shipping\apply();
    @include \checkout\onepage\step\payment\apply();
}

@mixin \northern\checkout\actions\apply() {
    .checkout-actions {
        .checkout-action-toolbar {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
        }

        .back-tocart-button,
        .prev-step-button,
        .next-step-button,
        .complete-checkout-button {
            flex-shrink: 0;
            width: 100%;

            @include \kindling\breakpoint\media-query('medium' 'down') {
                @include \kindling\spacing\apply('grid-gutters', $margin-sides: ('bottom'));
                flex-grow: 1;
            }

            @include \kindling\breakpoint\media-query('large') {
                width: auto;
            }
        }

        .back-tocart-button {
            @include \compass\button\back-button($style: 'secondary');
        }

        .prev-step-button {
            @include \compass\button\back-button($style: 'secondary');
        }

        .next-step-button {
            @include \compass\button\next-button($style: 'primary');
        }

        .complete-checkout-button {
            @include \compass\button\icon-button($icon: $checkout-actions-complete-button-icon, $size: $checkout-actions-button-size, $use-before: false);
        }
    }
}

@mixin \northern\checkout\layout\styles() {
    .content-main {
        @include \kindling\breakpoint\media-query('medium' 'down') {
            padding-top: \kindling\value\rem-calc(2);
        }
    }

    .checkout-wrapper {
        @include \kindling\grid\row(\kindling\spacing\get('grid-gutters'));
    }

    .checkout-sidebar-container {
        @include \compass\layout\panel-right();
        order: -1;

        @include \kindling\breakpoint\media-query('large') {
            order: 2;
        }
    }

    .checkout-steps-container {
        @include \compass\layout\panel-main();
    }
}

@mixin \northern\checkout\onepage\apply() {
    @include \compass\social-login\page\checkout-index-index\apply();

    .northern-checkout {
        @include \northern\checkout\layout\styles();

        .page-title-wrapper {
            @include \kindling\visually-hidden\apply();
        }

        @include \checkout\section\apply();

        .legend {
            .subheading {
                @include \kindling\typography\heading\h3();
                @include \kindling\typography\apply-margin\h3();
            }
        }

        @include \northern\checkout\form\apply();
        @include \northern\checkout\steps\apply();
        @include \northern\checkout\actions\apply();
        @include \northern\checkout\checkout\apply();

        .sidebar-payment-icons {
            @include \kindling\spacing\apply('base.xsmall', $margin-sides: ('top'))
        }
    }
}
