@mixin \compass\slider\hero\inner-constrained-content\base(
    $columns: $\project\slider\hero\slide-columns,
    $breakpoint: $\project\banner\hero\breakpoint
) {
    @include \kindling\breakpoint\media-query($breakpoint) {
        $column-width: \kindling\value\rem-calc($global-max-width * ($columns / 12));
        $scroll-bar-offset: \kindling\value\rem-calc(15);
        padding: 0 calc((100vw - (#{$scroll-bar-offset} + #{$column-width})) / 2) !important; // sass-lint:disable-line no-important
    }
}

@mixin \compass\slider\hero\inner-constrained-content\mobile() {
    @include \compass\layout\column-padding();

    @include \kindling\breakpoint\media-query(\kindling\breakpoint\get-previous-breakpoint($\project\banner\hero\breakpoint) 'down') {
        $spacing: \kindling\grid\column-width-calc(1);
        @include \kindling\property\apply('margin-right', $spacing);
        @include \kindling\property\apply('margin-left', $spacing);
    }
}

@mixin \compass\slider\hero\inner-constrained-content\desktop() {
    @include \compass\slider\hero\inner-constrained-content\base(
        $columns: $\project\slider\hero\slide-columns,
        $breakpoint: $\project\banner\hero\breakpoint
    );

    > * {
        @include \kindling\breakpoint\media-query($\project\banner\hero\breakpoint) {
            @include \compass\layout\container-padded-corrected($column-count: ('small': 12));
            max-width: \project\grid\column-width-calc-relative-to-grandparent($\project\slider\hero\content-columns, $\project\slider\hero\slide-columns);
        }
    }
}

@mixin \compass\slider\hero\controls\apply() {
    .can-slide {
        .slide-inner {
            @include \compass\layout\section-overlap\spacing(('small': \kindling\value\rem-calc(65)));
        }
    }

    .hero-slider-controls-container {
        @include \compass\slider\hero\inner-constrained-content\mobile();
        @include \compass\slider\hero\inner-constrained-content\desktop();
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateY(-100%);
        z-index: \kindling\z-index\get('above-sibling');


        @include \kindling\breakpoint\media-query($\project\banner\hero\breakpoint) {
            transform: none;
            width: 100%;
        }

        &.static {
            all: revert !important; // sass-lint:disable-line no-important

            .hero-slider-controls-container-inner {
                position: static;
                margin-bottom: 0;
            }

            .hero-slider-controls-row {
                position: absolute;
                bottom: \kindling\value\rem-calc(20);
                left: \kindling\value\rem-calc(15);
                flex-wrap: nowrap;
                z-index: \kindling\z-index\get('above-sibling');
                max-width: 80%;

                > * {
                    flex: 0 0 auto;
                }

                .hero-slider-progress-bar-background {
                    flex-shrink: 1;
                }
            }
        }
    }

    .hero-slider-controls-container-inner {
        @include \project\banner\hero-text\shadow();
        @include \compass\typography\font\apply('primary', 'semi-bold');
        @include \kindling\typography\font-size('typography.copy.micro');
        @include \kindling\spacing\apply(
            $spacing: 'slider.hero.controls.outer',
            $margin-sides: ('bottom')
        );
        position: relative;
        z-index: \kindling\z-index\get('above-sibling');
        color: \kindling\color\get('slider.hero.progress.default');
    }

    .hero-slider-controls-row {
        @include \compass\grid\row(
            $column-gutters: ('small': \kindling\spacing\get('slider.hero.control-items.outer')),
            $spacing-type: 'margin'
        );
        align-items: center;
    }

    .slick-pause-play-button {
        $size: \kindling\value\rem-calc(18);

        @include \compass\button\reset-browser-button();
        display: flex;
        align-items: center;
        justify-content: center;
        width: $size;
        height: $size;
        text-shadow: inherit;
        line-height: 1;
        color: inherit;
    }

    .slick-pause-play-button-icon {
        @include \kindling\icon\apply($icon: $icon-pause) {
            font-size: \kindling\value\rem-calc(18);
        }
    }

    .state-paused {
        .slick-pause-play-button-icon {
            @include \kindling\icon\selector() {
                content: $icon-play;
            }
        }
    }

    .slick-pause-play-button-text {
        @include \kindling\visually-hidden\apply();
    }

    .hero-slider-progress-bar-background {
        box-shadow: #{\kindling\value\rem-calc(0 0 10 0)} $\project\banner\shadow-color;
        background-color: rgba(\kindling\color\get('slider.hero.progress.default'), 0.5);
        width: \kindling\value\rem-calc(155);
    }

    .hero-slider-progress-bar-foreground {
        background-color: \kindling\color\get('slider.hero.progress.default');
        width: 0;
        height: \kindling\value\rem-calc(2);
    }
}

@mixin \compass\slider\hero\arrow\apply() {
    $size: (
        'small': \kindling\value\rem-calc(50),
        'xxlarge': \kindling\value\rem-calc(60)
    );
    $icon-size: (
        'small': \kindling\value\rem-calc(24) / \kindling\breakpoint\get-breakpoint-value($size, 'small'),
        'xxlarge': \kindling\value\rem-calc(28) / \kindling\breakpoint\get-breakpoint-value($size, 'xxlarge')
    );
    $rotate: 45deg;
    $arrow-offset: (
        'small': 0
    );

    @include \compass\slider\base\arrow\apply(
        $size: $size,
        $icon-size: $icon-size,
        $context: 'slider.hero',
        $arrow-offset: $arrow-offset
    );
    @include \kindling\icon\selector() {
        display: block;
        transform: rotate(#{-1 * $rotate});
    }
    z-index: \kindling\z-index\get('above-next-sibling');

    &.slick-prev {
        transform: translate(-50%, -50%) rotate($rotate);
        text-align: right;
    }

    &.slick-next {
        transform: translate(50%, -50%) rotate($rotate);
        text-align: left;
    }
}

@mixin \compass\slider\hero\content\apply($constrict-title-width: true) {
    @include \compass\slider\hero\controls\apply();

    &.has-vehicle-navigation {
        @include \compass\layout\section-overlap(
            $top-section-selector: '.slide-contents',
            $bottom-section-selector: '.hero-slider-navigator-container',
            $overlap: (
                \kindling\breakpoint\get-previous-breakpoint($\project\banner\hero\breakpoint) 'down': \kindling\value\rem-calc(45)
            )
        );

        &.static {
            @include \compass\layout\section-overlap(
                $top-section-selector: null,
                $bottom-section-selector: '.hero-slider-navigator-container',
                $overlap: (
                    'small': \kindling\value\rem-calc(35)
                )
            );

            .slick-list {
                &.draggable {
                    padding: 0 !important; // sass-lint:disable-line no-important
                }
            }

            .slick-slide {
                &.slick-current {
                    padding: 0 !important; // sass-lint:disable-line no-important
                }

                a {
                    display: block !important; // sass-lint:disable-line no-important
                }
            }
        }

        .hero-slider-content-slider {
            @include \project\banner\hero-text\apply();
        }

        .slide-inner {
            @include \kindling\breakpoint\media-query($\project\banner\hero\breakpoint) {
                min-height: \kindling\value\rem-calc(395);
            }
        }
    }

    &.no-vehicle-navigation {
        .hero-slider-content-slider {
            @include \project\banner\hero-text\apply($constrict-title-width);
        }
    }

    .hero-slider-content-container {
        @include \compass\slider\base\bug-fix\flexbox();
        display: flex;
        position: relative;
        flex-direction: column;

        &:not(.static) {
            @include \kindling\breakpoint\media-query($\project\banner\hero\breakpoint) {
                flex-direction: row;
            }
        }
    }

    .slick-slider {
        &.can-slide {
            .slick-list {
                @include \compass\slider\hero\inner-constrained-content\base();
            }
        }

        &.cannot-slide {
            .slick-list {
                width: 100% !important; // sass-lint:disable-line no-important

                @include \kindling\breakpoint\media-query($\project\banner\hero\breakpoint) {
                    padding: 0 !important; // sass-lint:disable-line no-important
                }
            }

            .slick-track {
                width: inherit !important; // sass-lint:disable-line no-important
            }

            .slick-slide {
                @include \compass\slider\hero\inner-constrained-content\base();
                width: inherit !important; // sass-lint:disable-line no-important
            }
        }
    }

    .slick-list {
        position: static;
    }

    .slick-slide {
        position: static;
    }

    .static-hero-slider-content-container {
        .hero-slider-content-slider-container {
            position: relative;
            width: 100%;
            aspect-ratio: 6 / 7; // sass-lint:disable-line no-misspelled-properties

            @include \kindling\breakpoint\media-query('medium') {
                aspect-ratio: 9 / 5; // sass-lint:disable-line no-misspelled-properties
            }

            @include \kindling\breakpoint\media-query('xlarge') {
                aspect-ratio: 11 / 4; // sass-lint:disable-line no-misspelled-properties
            }

            @include \kindling\breakpoint\media-query($\project\banner\hero\breakpoint) {
                position: static;
            }
        }
    }

    .hero-slider-content-slider-container {
        @include \compass\image-section\foreground();
        flex: 1 1 auto;
        z-index: \kindling\z-index\get('above-sibling');

        @include \kindling\breakpoint\media-query($\project\banner\hero\breakpoint) {
            position: static;
        }

        .slide-inner {
            @include \compass\slider\hero\inner-constrained-content\mobile();
            position: relative;
            z-index: \kindling\z-index\get('above-sibling');
            margin: 0 auto;

            @include \kindling\breakpoint\media-query($\project\banner\hero\breakpoint) {
                width: \project\grid\column-width-calc-relative-to-grandparent($\project\slider\hero\content-columns, $\project\slider\hero\slide-columns);
            }
        }
    }

    .hero-slider-content-slider-container-inner {
        display: flex;
        position: relative;
        flex-direction: column;
        height: 100%;
    }

    .hero-slider-content-slider {
        @include \compass\slider\base\apply();
        @include \compass\slider\base\layout($align-items: center);
        @include \compass\slider\base\prevent-fouc-by-hiding-until-ready();
        display: flex !important; // sass-lint:disable-line no-important
        position: static;
        flex: 1 1 auto;
        align-items: center;
        z-index: \kindling\z-index\get('above-sibling');
        overflow: hidden;

        .static-hero-slider-banner-image {
            img {
                width: 100%;
            }
        }
    }

    .slide-contents {
        @include \kindling\spacing\apply(
            $spacing: 'slider.hero.section.inner',
            $padding-sides: ('all')
        );
        display: block !important; // sass-lint:disable-line no-important
        background: \kindling\color\get('slider.hero.section.background');
        width: 100%;

        &::before {
            @include \kindling\breakpoint\media-query($\project\banner\hero\breakpoint) {
                @include \compass\animation\fade-in\active-state($opacity: 0.5);
                position: absolute;
                top: 0;
                left: 0;
                z-index: \kindling\z-index\get('above-all-siblings');
                background-color: \kindling\color\get('neutral-black');
                width: 100%;
                height: 100%;
                content: '';
            }
        }
    }

    .slick-current {
        .slide-contents {
            &::before {
                @include \kindling\breakpoint\media-query($\project\banner\hero\breakpoint) {
                    @include \compass\animation\fade-in\hidden-state();
                }
            }
        }
    }

    .hero-slider-background {
        @include \compass\image-section\background();
    }

    .hero-slider-navigator-container {
        @include \compass\slider\hero\inner-constrained-content\base(
            $columns: $\project\slider\hero\slide-columns,
            $breakpoint: $\project\banner\hero\breakpoint
        );
        position: relative;
        z-index: \kindling\z-index\get('above-next-sibling');

        &.static { // sass-lint:disable-block no-important
            margin-top: 0 !important;
            padding: 0 !important;
        }

        &:not(.static) {
            @include \kindling\breakpoint\media-query($\project\banner\hero\breakpoint) {
                position: absolute !important; // sass-lint:disable-line no-important
                top: 50%;
                width: 100%;
                overflow: visible;
            }
        }
    }

    .hero-slider-navigator-container-inner {
        @include \compass\layout\column-padding();

        &:not(.static) {
            @include \kindling\breakpoint\media-query($\project\banner\hero\breakpoint) {
                display: flex;
                align-items: center;
                margin-right: auto;
                margin-left: auto;
                height: 0;
            }
        }

        &.static {
            margin-top: 0;
            padding-right: 0;
            padding-left: 0;

            .vehicle-navigation-container {
                margin-right: auto;
                width: 100%;
                height: \kindling\value\rem-calc(416.56);

                @include \kindling\breakpoint\media-query('medium') {
                    height: \kindling\value\rem-calc(394.56);
                }

                @include \kindling\breakpoint\media-query('large') {
                    height: \kindling\value\rem-calc(225.39);
                }

                .vehicle-navigator-form-accordion {
                    height: 100%;

                    .vehicle-navigator-form-accordion-item {
                        height: 100%;

                        .vehicle-navigator-form-accordion-body {
                            height: 100%;
                        }
                    }
                }
            }

            .vehicle-navigator-form-accordion-title-text {
                width: 100%;
            }

            .form {
                flex-direction: column;
            }

            .fields-container-row {
                display: flex;
                flex-wrap: nowrap;

                @include \kindling\breakpoint\media-query('xlarge') {
                    flex-direction: row;
                }
            }

            .field {
                @include \kindling\breakpoint\media-query('xlarge') {
                    width: 25%;
                }
            }
        }
    }

    .vehicle-navigation-container {
        width: 100%;

        @include \kindling\breakpoint\media-query($\project\banner\hero\breakpoint) {
            margin-left: auto;
            width: \kindling\grid\column-width-calc(3);
        }

        @include \kindling\breakpoint\media-query(\kindling\breakpoint\get-next-breakpoint($\project\banner\hero\breakpoint)) {
            margin-right: -1 * \project\grid\column-width-calc-relative-to-grandparent(1, $\project\slider\hero\slide-columns);
        }
    }

    .slick-arrow {
        @include \compass\slider\hero\arrow\apply();
    }
}

@mixin \compass\slider\hero\apply($constrict-title-width: true) {
    @include \compass\slider\hero\content\apply($constrict-title-width);
    @include \project\fitment\vehicle-navigator-form\featured\apply();
    position: relative;
    z-index: \kindling\z-index\get('above-sibling');
}
