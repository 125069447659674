@mixin \checkout\toolbar\arrows() {
    @include \kindling\icon\apply($icon: $checkout-toolbar-steps-arrow-icon, $use-before: false) {
        vertical-align: text-bottom;
        line-height: $checkout-toolbar-steps-arrow-line-height;
        color: \kindling\color\get($checkout-toolbar-steps-arrow-color);
        font-size: $checkout-toolbar-steps-arrow-font-size;
    }
}

@mixin \checkout\toolbar\step\states($state) {
    &.#{$state} {
        .checkout-toolbar-step-title {
            color: \kindling\color\get(\kindling\map\get($checkout-toolbar-step-title-colors, $state));
        }

        @if $checkout-toolbar-steps-show-numbers-breakpoint != null or $checkout-toolbar-steps-show-numbers {
            .checkout-toolbar-step-number {
                @include \kindling\color\apply-background-color(\kindling\map\get($checkout-toolbar-step-number-colors, $state), $foreground: $checkout-toolbar-step-number-foreground-colors);
            }
        }
    }
}

@mixin \checkout\toolbar\step\processed\icon() {
    &.processed {
        .checkout-toolbar-step-number {
            @include \kindling\icon\apply($icon: $checkout-toolbar-step-processed-state-icon) {
                font-weight: \kindling\typography\font-weight\get('normal');
            }
        }
    }
}

@mixin \checkout\toolbar\step\number() {
    @include \kindling\color\apply-background-color(\kindling\map\get($checkout-toolbar-step-number-colors, 'inactive'), $foreground: $checkout-toolbar-step-number-foreground-colors);
    display: inline-block;
    flex-shrink: 0;
    width: $checkout-toolbar-step-diameter;
    height: $checkout-toolbar-step-diameter;
    text-align: center;
    line-height: $checkout-toolbar-step-diameter;
    font-size: $checkout-toolbar-steps-number-font-size;
    counter-increment: checkout-toolbar-step;

    &::before {
        content: counter(checkout-toolbar-step);
    }
}

@mixin \checkout\toolbar\step\state\line($position) {
    position: relative;

    @each $state, $color in $checkout-toolbar-step-state-line-colors {
        &.#{$state} {
            &::after {
                @include \kindling\property\apply('height', $checkout-header-bottom-border-width);
                @include \kindling\property\apply(#{$position}, $checkout-header-bottom-border-width, $ratio: -1);
                display: block;
                position: absolute;
                right: 0;
                left: 0;
                background-color: \kindling\color\get($color);
                content: '';
            }
        }
    }
}

@mixin \checkout\toolbar\step\item() {
    @include \kindling\spacing\apply('checkout.header.toolbar.steps.between', $padding-sides: ('left'));

    .checkout-toolbar-step-item-link {
        @include \compass\typography\heading('h6');
        display: flex;
        align-items: center;
        text-decoration: none;
    }

    &:first-of-type {
        padding-left: 0;
    }

    @if $checkout-toolbar-steps-show-numbers {
        .checkout-toolbar-step-number {
            @include \checkout\toolbar\step\number();
        }

        .checkout-toolbar-step-title {
            @include \kindling\spacing\apply('grid-gutters', $padding-sides: ('left'), $ratio: 0.5);
        }

        @if $checkout-toolbar-step-processed-state-icon != null {
            @include \checkout\toolbar\step\processed\icon();
        }
    }

    @if $checkout-toolbar-steps-show-numbers-breakpoint != null {
        @include \kindling\breakpoint\media-query($checkout-toolbar-steps-show-numbers-breakpoint only) {
            .checkout-toolbar-step-number {
                @include \checkout\toolbar\step\number();
            }

            .checkout-toolbar-step-title {
                display: none;
            }

            &.active {
                @if $checkout-toolbar-steps-mobile-show-active-number == false {
                    .checkout-toolbar-step-number {
                        @include \kindling\visually-hidden\apply();
                    }
                }

                .checkout-toolbar-step-title {
                    display: initial;
                }
            }

            &.inactive {
                .checkout-toolbar-step-title {
                    @include \kindling\visually-hidden\apply();
                }
            }

            @if $checkout-toolbar-step-processed-state-icon != null {
                @include \checkout\toolbar\step\processed\icon();
            }
        }
    }

    @if $checkout-toolbar-steps-show-numbers == true or $checkout-toolbar-steps-show-numbers-breakpoint != null {
        @include \checkout\toolbar\step\states('active');
        @include \checkout\toolbar\step\states('inactive');
        @include \checkout\toolbar\step\states('processed');
    }

    @if $checkout-toolbar-steps-show-arrows {
        .checkout-toolbar-step-item-link {
            @include \checkout\toolbar\arrows();

            &::after {
                @include \kindling\spacing\apply('checkout.header.toolbar.steps.between', $margin-sides: ('left'));
            }
        }

        &:last-of-type {
            .checkout-toolbar-step-item-link {
                &::after {
                    display: none;
                    content: '';
                }
            }
        }
    }

    @if $checkout-toolbar-step-state-line-position != null {
        @include \checkout\toolbar\step\state\line($checkout-toolbar-step-state-line-position);
    }
}

@mixin \checkout\toolbar\steps\apply() {
    .checkout-toolbar-steps-list {
        @include \kindling\typography\unstyled-list();
        display: flex;
        align-items: stretch;
        counter-reset: checkout-toolbar-step;

        .checkout-toolbar-step-item {
            @include \checkout\toolbar\step\item();
            @include \kindling\spacing\apply('checkout.header.toolbar.steps.item', $padding-sides: ('top', 'bottom'));
            display: flex;
        }
    }
}

@mixin \checkout\toolbar\font-styles\base() {
    @include \kindling\typography\font-size('body');
    color: \kindling\color\get($checkout-toolbar-font-color);
    font-family: \kindling\typography\font-stack\get($checkout-toolbar-font-family);
    font-weight: \kindling\typography\font-weight\get($checkout-toolbar-font-weight);
}

@mixin \northern\checkout\toolbar\apply() {
    .checkout-toolbar {
        @include \checkout\toolbar\font-styles\base();
        @include \checkout\toolbar\steps\apply();
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
