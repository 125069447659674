$icon-font-family: 'icon';
$gp-icon-font-family: 'gp-icon';
$font-family-primary: 'barlow';
$font-family-secondary: 'bebas-neue';

$heading-font-stack: 'typography.heading';

$\compass\typography\font-faces\config: (
    $icon-font-family: (
        'normal': (
            'normal': \compass\typography\utilities\font-face-config('../font/icons/icon', ('woff2', 'woff'))
        )
    ),
    $gp-icon-font-family: (
        'normal': (
            'normal': \compass\typography\utilities\font-face-config('../font/icons/gp-icon', ('woff'))
        )
    ),
    $font-family-primary: (
        'normal': (
            'normal': \compass\typography\utilities\font-face-config('../font/barlow/barlow-latin-400', ('woff2', 'woff')),
            'italic': \compass\typography\utilities\font-face-config('../font/barlow/barlow-latin-400italic', ('woff2', 'woff'))
        ),
        'medium': (
            'normal': \compass\typography\utilities\font-face-config('../font/barlow/barlow-latin-500', ('woff2', 'woff')),
            'italic': \compass\typography\utilities\font-face-config('../font/barlow/barlow-latin-500italic', ('woff2', 'woff'))
        ),
        'semi-bold': (
            'normal': \compass\typography\utilities\font-face-config('../font/barlow/barlow-latin-600', ('woff2', 'woff')),
            'italic': \compass\typography\utilities\font-face-config('../font/barlow/barlow-latin-600italic', ('woff2', 'woff'))
        ),
        'bold': (
            'normal': \compass\typography\utilities\font-face-config('../font/barlow/barlow-latin-700', ('woff2', 'woff')),
            'italic': \compass\typography\utilities\font-face-config('../font/barlow/barlow-latin-700italic', ('woff2', 'woff'))
        )
    ),
    $font-family-secondary: (
        'bold': (
            'normal': \compass\typography\utilities\font-face-config('../font/bebas-neue/BebasNeue', ('woff2', 'woff')),
        )
    )
);

$\kindling\typography\font-stack\palette: (
    'not-set': null,
    'inherit-value': inherit,
    'serif': serif,
    'sans-serif': ($font-family-primary, sans-serif),
    'sans-serif-2': ($font-family-secondary, sans-serif),
    'monospace': monospace,
    'icon': ($icon-font-family)
);

$\kindling\typography\font-stack\contexts: (
    'primary': 'sans-serif',
    'secondary': 'sans-serif-2',
    'accordion': (
        'default': (
            'title': (
                'text': 'secondary',
                'icon': 'icon'
            )
        )
    ),
    'button': (
        'default': 'secondary'
    ),
    'typography': (
        'heading': 'secondary',
        'anchor': 'not-set',
        'copy': (
            'default': 'primary',
            'micro': 'primary',
            'small': 'primary',
            'lead': 'primary'
        )
    ),
    'product-price': (
        'default': (
            'price': 'primary',
            'out-of-stock': 'secondary'
        )
    ),
    'tooltip': (
        'default': (
            'text': 'primary'
        )
    ),
    'table': (
        'default': (
            'heading': 'secondary'
        ),
        'mobile': (
            'heading': 'primary'
        ),
        'secondary': (
            'heading': 'secondary'
        )
    ),
    'item-actions': (
        'default': (
            'actions': 'primary'
        )
    ),
    'header': (
        'shop-for-bikes': 'secondary'
    ),
    'shop-for-bikes-modal': (
        'brand-item': 'secondary'
    )
);
