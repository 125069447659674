$\kindling\typography\line-height\palette: (
    'not-set': null,
    'inherit-value': inherit,
    'hero': 0.9,
    'base': 1,
    'xsmall': 1.1,
    'small': 1.15,
    'medium': 1.5,
    'large': 1.75,
    'newsletter-signup-button': 1.4,
    'blog-list-title': 1.4,
    'blog-list-content': 1.28
);

$\kindling\typography\line-height\contexts: (
    'body': 'medium',
    'typography': (
        'hero': (
            'small': 'base',
            'large': 'hero'
        ),
        'h1': 'base',
        'h2': 'base',
        'h3': (
            'small': 'xsmall',
            'large': 'base'
        ),
        'h4': 'base',
        'h5': 'base',
        'h6': 'base',
        'anchor': 'not-set',
        'subheading': (
            'large': 'small',
            'medium': 'small',
            'small': 'small'
        ),
        'copy': (
            'default': 'medium',
            'lead': 'medium',
            'small': 'medium',
            'micro': 'medium'
        ),
        'blog': (
            'h2': 'blog-list-title',
            'title': 'small'
        )
    ),
    'button': (
        'default': 'base'
    ),
    'accordion': (
        'default': (
            'title': 'base'
        )
    ),
    'form': (
        'default': (
            'input': 'small'
        ),
        'search': (
            'input': 'medium'
        ),
        'newsletter': (
            'signup-button': 'newsletter-signup-button'
        )
    ),
    'reviews': (
        'default': (
            'short-summary': (
                'small': 'small'
            )
        )
    ),
    'badge': 'medium',
    'dropdown': (
        'default': (
            'list': (
                'anchor': 'small'
            )
        ),
        'pagination': (
            'list': (
                'anchor': 'base'
            )
        )
    ),
    'header': (
        'promo-bar': 'base',
        'icon': 'base'
    ),
    'tooltip': (
        'default': (
            'text': 'small'
        )
    ),
    'fitment': (
        'label': 'small',
        'mini-garage': (
            'reset': 'base'
        )
    ),
    'shop-for-bikes': (
        'header': 'small'
    ),
    'minicart': (
        'item': (
            'name': 'medium',
            'attributes': 'small'
        )
    ),
    'swatches': (
        'more-label': 'small'
    )
);
