@mixin \compass\mega-menu\link-featured\apply($context) {
    $color: \kindling\color\context-keys-calc('accent', $context);

    .mega-menu-featured-link {
        color: \kindling\color\get($color);
    }
}

@mixin \compass\mega-menu\link-bottom-level($context) {
    $list-spacing: \kindling\context\concat-key-parts($context, 'items');
    $item-outer-spacing: \kindling\context\concat-key-parts($context, 'item-outer');

    @include \kindling\typography\unstyled-list();
    @include \kindling\spacing\apply(
        $spacing: $list-spacing,
        $padding-sides: ('all')
    );

    li {
        @include \kindling\typography\font-size('typography.copy.small');

        &:not(:last-child) {
            @include \kindling\spacing\apply(
                $spacing: $item-outer-spacing,
                $margin-sides: ('bottom')
            );
        }
    }

    a {
        @include \compass\typography\font\apply('primary', 'medium');
        @include \kindling\typography\font-size('typography.copy.small');
        @include \kindling\typography\line-height('base');
        display: inline-block;
        text-decoration: none;

        &:not(.mega-menu-mobile-shop-all-link) {
            &:hover,
            &:focus {
                text-decoration: underline;
                color: inherit;
            }
        }
    }
}

@mixin \compass\mega-menu\apply() {
    @include \compass\mega-menu\mobile\apply();
    @include \compass\mega-menu\mobile\additional\apply();
    @include \compass\mega-menu\desktop\apply();
}
