@import 'variables.scss';
@import 'layout/customer-account.scss';
@import 'layout/customer-account-entry.scss';
@import 'components/customer-account-forms.scss';
@import 'components/customer-account-boxes.scss';
@import 'components/customer-account-sidebar.scss';
@import 'components/customer-account-titles.scss';
@import 'components/customer-address-book.scss';
@import 'components/customer-account-order-tables.scss';
@import 'components/customer-account-garage.scss';
@import 'components/header.scss';
@import 'pages/customer-account-dashboard.scss';
@import 'pages/customer-account-entry.scss';
@import 'pages/customer-account-login.scss';
@import 'pages/customer-account-create.scss';
@import 'pages/customer-account-forgot.scss';
@import 'pages/customer-account-logoutsuccess.scss';

address { //@TODO get this added to northern-match
    font-style: normal;
}

@mixin \customer\account\apply() {
    @include \customer\account\dashboard\apply();
    @include \customer\account-entry\apply();
    @include \customer\account\logout\apply();
    @include \compass\social-login\page\sociallogin-customer-connections\apply();
}
