@mixin \northern\checkout\overlay-styles() {
    .checkout-loading-overlay {
        @include \compass\overlay();
        display: none;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: $checkout-loader-overlay-spinner-size;

        &.checkout-loader-active {
            display: block;
        }
    }
}
