@mixin \project\catalog\category\featured-list() {
    .featured-category-list {
        @include \project\catalog\category\list();
    }
}

@mixin \project\catalog\category\list() {
    @include \kindling\typography\unstyled-list();
    @include \compass\grid\row(
        $column-gutters: \kindling\spacing\get('category-list.default.spacing')
    );
    @include \kindling\spacing\apply('category-list.default.list', $margin-sides: ('bottom'));
    justify-content: center;

    > li {
        @include \kindling\grid\column(
            $responsive-sizes: (
                'small': 50%,
                'medium': 25%,
                'large': 20%
            ),
            $flex-grow: 0
        );
        display: flex;

        a {
            @include \kindling\spacing\apply('category-list.default.item', $padding-sides: ('all'));
            @include \kindling\border\apply('category-list.default');
            display: flex;
            flex-direction: column;
            box-shadow: $\project\catalog\category\list\box-shadow;
            width: 100%;
            text-align: center;
            text-decoration: none;

            &:hover,
            &:focus {
                box-shadow: $\project\catalog\category\list\box-shadow-active;

                .item-name {
                    text-decoration: underline;
                    color: \kindling\color\get('category-list.default.text-active');
                }
            }

            .image-wrapper {
                display: flex;
                flex-grow: 1;
                align-items: center;
                justify-content: center;
                min-height: 1px; // sass-lint:disable-line property-units
            }

            img {
                display: inline-block;
                width: 70%;
            }
        }
    }

    .item-name {
        @include \compass\typography\heading('h6');
        @include \kindling\typography\font-size('brands-page.brand-name');
        display: block;
        margin-top: \kindling\value\rem-calc(15);
        color: \kindling\color\get('category-list.default.text');
        word-break: break-word;
    }
}
