@mixin \project\cms\no-route\apply() {
    .cms-no-route {
        .cms-no-route-wrapper {
            @include \kindling\spacing\apply('hero-container', $padding-sides: ('all'));
            position: relative;
            background-color: \kindling\color\get('banner.default-background');

            .background {
                @include \compass\image-section\background();
            }
        }

        .cms-no-route-content {
            @include \compass\layout\container-padded();
            @include \project\banner\hero-text\apply();
            position: relative;
        }

        .cms-no-route-featured-products {
            @include \compass\layout\container-padded();
            @include \kindling\spacing\apply('no-route.featured-section', $margin-sides: ('top', 'bottom'));
        }
    }
}
