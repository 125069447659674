@mixin \compass\sales\order\view\page-title\actions\apply() {
    @include \kindling\spacing\apply($spacing: 'base.xsmall', $margin-sides: ('top', 'bottom'), $ratio: 0.5);
    flex-grow: 1;

    .actions {
        @include \compass\button\grid-row();
        width: 100%;

        @include \kindling\breakpoint\media-query('large') {
            justify-content: flex-end;
        }

        .action {
            @include \compass\button\apply(
                $style: 'secondary',
                $size: 'small'
            );

            &.print {
                @include \compass\button\icon-button(
                    $icon: $icon-print,
                    $style: 'secondary',
                    $size: 'small'
                );
            }

            &.order {
                @include \compass\button\icon-button(
                    $icon: $icon-refresh,
                    $style: 'secondary',
                    $size: 'small'
                );
            }
        }
    }
}

@mixin \compass\sales\order\view\page-title\container() {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

@mixin \compass\sales\order\view\page-title\badge() {
    @include \kindling\badge\style('badge.account-sales-title.background');
    @include \kindling\typography\font-size('typography.copy.small');
    padding: \kindling\value\rem-calc(4);
    text-transform: uppercase;
}

@mixin \compass\sales\order\view\page-title\spacing() {
    @include \kindling\spacing\apply('base.xxsmall', $margin-sides: ('right'));
}

@mixin \compass\sales\order\view\page-title\layout\apply() {
    .page-title-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: flex-start;

        @include \kindling\breakpoint\media-query('large') {
            flex-wrap: nowrap;
        }

        .page-title {
            @include \compass\sales\order\view\page-title\spacing();
        }

        .order-title-left {
            @include \compass\sales\order\view\page-title\container();
            flex: 0 1 auto;
        }

        .order-actions-toolbar {
            @include \compass\sales\order\view\page-title\actions\apply();
            flex: 1 0 auto;
        }

        .order-date,
        .negotiable-quote-order-information {
            width: 100%;
        }

        .order-status {
            @include \compass\sales\order\view\page-title\badge();
        }
    }
}
