@mixin \compass\checkout\totals() {
    @include \kindling\typography\font-size('checkout.sidebar-totals.default');
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    > tbody {
        > tr {
            > * {
                @include \kindling\spacing\apply(
                    $spacing: 'checkout.sidebar-totals.row',
                    $padding-sides: ('all')
                );
            }

            > td,
            > th {
                line-height: 1;
            }

            th {
                font-weight: inherit;
            }
        }
    }

    .mark {
        @include \kindling\typography\font-weight('typography.copy.bold');
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        text-align: left;
    }

    .amount {
        text-align: right;
    }

    .totals {
        &.sub {
            @include \kindling\typography\font-weight('typography.copy.bold');

            .mark {
                font-weight: inherit;
            }
        }
    }

    .delete {
        @include \kindling\icon\apply($icon: $icon-trash) {
            margin-right: \kindling\value\rem-calc(2);
            font-size: 140%;
        }
        @include \kindling\typography\font-size('checkout.sidebar-totals.delete-action');
        display: flex;
        align-items: center;
        order: 1;
        margin-left: \kindling\value\rem-calc(3);
        text-decoration: none;

        span {
            text-decoration: underline;
        }
    }

    .totals.grand,
    .grand_total { // sass-lint:disable-line class-name-format
        > * {
            @include \kindling\spacing\apply(
                $spacing: 'checkout.sidebar-totals.total-row',
                $padding-sides: ('all')
            );
            @include \compass\typography\heading('h5');
        }

        strong {
            font-weight: inherit;
        }
    }
}
