@mixin \compass\layout\panel-main($responsive-sizes: $\compass\layout\panel-main\widths) {
    @include \kindling\grid\column($responsive-sizes);
}

@mixin \compass\layout\panel-left($responsive-sizes:  $\compass\layout\panel-left\widths) {
    @include \kindling\grid\column($responsive-sizes, $flex-grow: 0);
}

@mixin \compass\layout\panel-right($responsive-sizes:  $\compass\layout\panel-right\widths) {
    @include \kindling\grid\column($responsive-sizes, $flex-grow: 0);
}

@mixin \compass\layout\container-padded(
    $max-width: $global-max-width,
    $column-count: $\compass\layout\container\column-count
) {
    @include \compass\layout\container($max-width, $column-count);
    @include \compass\layout\column-padding();
}

@mixin \compass\layout\container-padded-corrected(
    $max-width: $global-max-width,
    $column-count: $\compass\layout\container\column-count
) {
    @include \compass\layout\container-corrected($max-width, $column-count);
    @include \compass\layout\column-padding();
}

@mixin \compass\layout\container(
    $max-width: $global-max-width,
    $column-count: $\compass\layout\container\column-count
) {
    @include \kindling\grid\max-width-container();

    @each $breakpoint, $column in $column-count {
        @include \kindling\breakpoint\media-query($breakpoint) {
            width: \kindling\grid\column-width-calc($column);
        }
    }
}

// Correctly sets max width. Added as separate mixin later
// to avoid changing the rest of the site
@mixin \compass\layout\container-corrected(
    $max-width: $global-max-width,
    $column-count: $\compass\layout\container\column-count
) {
    margin-right: auto;
    margin-left: auto;

    @each $breakpoint, $column in $column-count {
        @include \kindling\breakpoint\media-query($breakpoint) {
            width: \kindling\grid\column-width-calc($column);
            max-width: calc(#{$global-max-width} * #{$column / $grid-columns});
        }
    }
}

@mixin \compass\layout\column-padding(
    $padding-sides: ('left', 'right')
) {
    @include \kindling\spacing\apply(
        $spacing: 'constrained-content-container.default',
        $padding-sides: $padding-sides
    );
}

@mixin \compass\layout\constrained-section-layout-styles(
    $outer-container-class: '.container',
    $inner-container-class: '.container-constrained',
    $breakpoint: 'xlarge',
    $inner-desktop-spacing: 0,
    $column-count: ('small': 12, 'xlarge': 10)
) {
    #{$outer-container-class} {
        @include \compass\layout\container-padded();

        @include \kindling\breakpoint\media-query(\kindling\breakpoint\get-previous-breakpoint($breakpoint) 'down') {
            padding-right: 0;
            padding-left: 0;
        }

        #{$inner-container-class} {
            @include \compass\layout\container($column-count: $column-count);
            @include \compass\layout\column-padding();

            @include \kindling\breakpoint\media-query($breakpoint) {
                padding-right: $inner-desktop-spacing;
                padding-left: $inner-desktop-spacing;
            }
        }
    }
}

// Make space for overlapping section without using padding
@mixin \compass\layout\section-overlap\spacing($overlap) {
    &::after {
        @include \kindling\property\apply('height', $overlap);
        display: block;
        width: 1px; // sass-lint:disable-line property-units
        content: '';
    }
}

@mixin \compass\layout\section-overlap($top-section-selector, $bottom-section-selector, $overlap) {
    @if $top-section-selector {
        #{$top-section-selector} {
            @include \compass\layout\section-overlap\spacing($overlap);
        }
    }

    @if $bottom-section-selector {
        #{$bottom-section-selector} {
            @include \kindling\property\apply('margin-top', $overlap, -1);
            position: relative;
            z-index: \kindling\z-index\get('above-sibling');
        }
    }
}
