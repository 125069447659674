@mixin \compass\catalog\compare-page\item-common() {
    @include \kindling\spacing\apply(
        $spacing: 'compare-page.item.cell-inner',
        $padding-sides: ('all')
    );
    @include \kindling\border\apply('compare-page.table', $sides: ('top'));
    vertical-align: top;
    box-sizing: content-box;
}

@mixin \compass\catalog\compare-page\table-cell-width($size) {
    @include \kindling\property\apply('width', $size);
    @include \kindling\property\apply('min-width', $size); // min width must be same as width to avoid columns shrinking
}

@mixin \compass\catalog\compare-page\main() {
    .compare-product-grid-wrapper {
        @include \project\horizontal-scroll-bar\apply();

        .compare-product-table-wrapper {
            @include \kindling\spacing\apply(
                $spacing: 'compare-page.item.table-outer',
                $margin-sides: ('top')
            );
        }

        .compare-product-table {
            min-width: 100%;
            table-layout: fixed;
            border-collapse: collapse;

            tbody {
                th,
                td {
                    @include \kindling\spacing\apply('compare-page.item.table-inner', $padding-sides: ('top', 'bottom'));

                }

                &:first-of-type {
                    tr {
                        &:first-child {
                            .compare-item-cell-product,
                            .compare-item-cell-header {
                                border-top: 0;
                            }
                        }
                    }
                }
            }
        }

        .compare-item-cell-header {
            @include \compass\catalog\compare-page\item-common();
            @include \kindling\typography\heading\h5();
            @include \compass\catalog\compare-page\table-cell-width(('large': \kindling\value\rem-calc(250)));
            text-align: left;
            line-height: inherit;
        }

        .compare-item-cell-product {
            @include \compass\catalog\compare-page\item-common();
            $width: (
                'small': \kindling\value\rem-calc(179),
                'xlarge': \kindling\value\rem-calc(252)
            );
            @include \compass\catalog\compare-page\table-cell-width($width);
        }

        .compare-item-card {
            @include \compass\product-badge\badge\apply();
            @include \compass\reviews\short-summary\apply();
            position: relative;
            width: inherit;

            .product-badge-container {
                position: absolute;
                top: \kindling\value\rem-calc(0);
                left: \kindling\value\rem-calc(0);
            }

            .product-item {
                @include \compass\product-grid\card\apply();

                .tocompare.checked {
                    @include \kindling\icon\apply($icon: $icon-close);
                    @include \kindling\icon\selector() {
                        color: \kindling\color\get('button.primary-light.icon-color');
                        font-size: \kindling\typography\font-size\get('small.1');
                    }
                    background-color: \kindling\color\get('button.primary-light.background-color');

                    &:hover,
                    &:focus {
                        background-color: \kindling\color\get('button.primary-light.background-color-hover');

                        &::before {
                            color: \kindling\color\get('button.primary-light.icon-color-hover');
                        }
                    }
                }
            }

            .product-item-actions {
                .action {
                    @include \compass\button\apply();
                }
            }
        }
    }
}

@mixin \compass\catalog\compare-page\apply() {
    .catalog-product_compare-index { // sass-lint:disable-line class-name-format
        @include \compass\components\page-title();
        @include \compass\catalog\compare-page\main();

        .page-title-wrapper {
            @include \kindling\border\apply('reset', $sides: 'bottom');
            @include \compass\grid\row();
            align-items: center;

            .compare-remove-all {
                @include \compass\button\secondary($size: 'small');
                width: 100%;
            }
        }

        .page-title {
            flex: 1 1 auto;
        }

        .compare-clearall-action-wrapper {
            @include \kindling\breakpoint\media-query('medium' 'down') {
                width: 100%;
            }
        }
    }
}
