@mixin \customer\account-entry\actions\apply() {
    .actions-toolbar {
        .primary {
            @include \kindling\breakpoint\media-query('medium' 'down') {
                width: 100%;
            }
        }

        .action {
            &.primary {
                @include \compass\button\next-button();
            }
        }
    }
}

@mixin \customer\account-entry\form\apply() {
    @include \customer\account-entry\actions\apply();

    .fields {
        @include \compass\layout\form\grid-columns\apply();
        @include \kindling\spacing\apply('form.default.input.margin', $margin-sides: ('bottom'));
    }

    .note {
        @include \kindling\typography\font-size('body');
    }
}

@mixin \customer\account-entry\layout\apply() {
    @include \compass\components\page-title();

    .account-entry-container {
        @include \kindling\grid\row($vertical-gutters: true);
    }

    .content-main {
        .block {
            @include \kindling\grid\column(
                $responsive-sizes: (
                    'small': \kindling\grid\column-width-calc(12),
                    'large': \kindling\grid\column-width-calc(6)
                ),
                $flex-grow: 0
            );
        }

        .block-title {
            @include \kindling\typography\margin('typography.h3');
        }

        .form {
            @include \customer\account-entry\form\apply();
        }
    }
}
