@mixin \project\cms\home\apply() {
    .cms-home {
        @include \compass\slider\product\apply();

        .page-main-content {
            @include \compass\layout\section-overlap(
                $top-section-selector: '.visit-bike-shop-section',
                $bottom-section-selector: '.homepage-bike-shop-block',
                $overlap: \kindling\value\rem-calc(70)
            );

            .hero-slider-container {
                @include \compass\slider\hero\apply();
            }

            .product-items {
                @include \compass\product-grid\apply();
            }

            .view-our-online-showroom-block {
                padding-bottom: \kindling\value\rem-calc(25);

                @include \kindling\breakpoint\media-query('large') {
                    padding-bottom: \kindling\value\rem-calc(50);
                }

                .pagebuilder-column {
                    margin-bottom: \kindling\value\rem-calc(50);
                    padding-right: \kindling\value\rem-calc(25);
                    padding-left: \kindling\value\rem-calc(25);
                    width: 100% !important; // sass-lint:disable-line no-important

                    @include \kindling\breakpoint\media-query('large') {
                        margin-bottom: \kindling\value\rem-calc(25);
                        width: auto !important; // sass-lint:disable-line no-important
                        max-width: 33%; // arbitrary number to limit width in case client uploads images which are too large
                    }
                }

                .pagebuilder-column-group {
                    justify-content: center;
                }

                .cms-image {
                    margin-bottom: 0;

                    a {
                        @include \kindling\transition\apply(transform);
                        display: block;
                        transform: none;

                        img {
                            max-width: 100%;
                        }

                        &:hover,
                        &:focus {
                            transform: scale(120%);
                        }
                    }

                    img {
                        margin-right: auto;
                        margin-left: auto;
                    }
                }
            }

            .visit-bike-shop-section {
                [data-content-type='text'] {
                    margin: 0 auto;
                    width: 100%;
                    max-width: \kindling\value\rem-calc(500);
                }
            }

            .shop-bikes-row {
                .cms-button {
                    &.button-secondary {
                        &.button-medium {
                            background-color: \kindling\color\get('transparent');

                            &:hover,
                            &:focus {
                                background-color: \kindling\color\get('button.secondary.background-color-hover');
                            }
                        }
                    }
                }
            }

            .homepage-bike-shop-block {
                @include \kindling\spacing\apply('bike-shops-block', $padding-sides: ('all'));
                @include \kindling\property\apply(
                    $property: 'margin-right',
                    $property-value: (
                        'small': \kindling\value\rem-calc(20),
                        'medium': auto,
                        'large': 0,
                        'xlarge': auto
                    )
                );
                @include \kindling\property\apply(
                    $property: 'margin-left',
                    $property-value: (
                        'small': \kindling\value\rem-calc(20),
                        'medium': auto,
                        'large': 0,
                        'xlarge': auto
                    )
                );
                @include \kindling\property\apply(
                    $property: 'width',
                    $property-value: (
                        'small': auto,
                        'medium': \kindling\grid\column-width-calc(11),
                        'large': 100%,
                        'xlarge': \kindling\grid\column-width-calc(11)
                    )
                );
                @include \project\cms\homepage\bike-shop-list();
                box-shadow: $\project\cta\overlapping-image\box-shadow;
                background-color: \kindling\color\get('neutral-white');
            }

            .shop-motorcycle-power-gear-row {
                [data-content-type='text'] {
                    margin: 0 auto;
                    width: 100%;

                    @include \kindling\breakpoint\media-query('xxlarge') {
                        max-width: \kindling\value\rem-calc(1080);
                    }
                }
            }

            .homepage-shop-categories-block {
                @include \project\cms\homepage\shop-categories-list();
            }
        }
    }
}
