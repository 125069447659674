@mixin \project\accordion\primary\container() {
    @include \compass\accordion\container('accordion.default');
}

@mixin \project\accordion\primary\item() {
    @include \compass\accordion\item('accordion.default');

    &.is-active,
    &:last-child {
        > button {
            @include \kindling\border\apply('reset', $sides: ('bottom'));
        }
    }
}

@mixin \project\accordion\primary\title() {
    @include \compass\accordion\title('accordion.default');
    @include \kindling\border\apply('primary-thin', $sides: ('bottom'));
    @include \kindling\spacing\apply('reset', $padding-sides: ('left'));
    @include \kindling\spacing\apply('accordion.default.extra-icon-padding', $padding-sides: ('right'));
}

@mixin \project\accordion\primary\body() {
    @include \compass\accordion\body('accordion.default');
}
