$theme-modal-close-button-size: \kindling\value\rem-calc(50);

@mixin \compass\modal\shadow() {
    box-shadow: \kindling\value\rem-calc(0 10 14 0) rgba(\kindling\color\get('modal.default.content.box-shadow'), 0.1);
}

@mixin \compass\modal\prompt-modal() {
    &.prompt {
        .modal-footer {
            justify-content: space-between;

            button {
                @include \compass\button\primary('small');
            }
        }
    }
}

@mixin \compass\modal\apply(
    $width: $modal-width,
    $max-width: $modal-max-width,
    $fullscreen: false
) {
    @include \kindling\modal\apply(
        $width: $width,
        $max-width: $max-width,
        $fullscreen: $fullscreen
    );
    @include \kindling\spacing\apply('reset', $padding-sides: ('all'));
    @include \kindling\color\apply-background-color('modal.default.content.background-color', 'modal.default.content.text-color');
    @include \compass\modal\shadow();
    @include \compass\modal\prompt-modal();

    position: relative;

    .modal-title-container {
        @include \compass\modal\heading\apply();
    }

    .modal-content {
        @include \compass\modal\content\apply();
    }
}

@mixin \compass\modal\heading\apply() {
    @include \kindling\spacing\apply(
        $spacing: 'modal.header',
        $padding-sides: ('all')
    );
    @include \kindling\spacing\apply('modal.extra-icon-padding', $padding-sides: ('right'));
    @include \kindling\color\apply-background-color('modal.default.title.background-color', 'modal.default.title.text-color');
}

@mixin \compass\modal\content\apply() {
    @include \kindling\spacing\apply(
        $spacing: 'modal.content',
        $padding-sides: ('all')
    );
}

@mixin \compass\modal\overlay() {
    @include \compass\overlay();
}

@mixin \compass\modal\heading() {
    @include \compass\typography\heading(
        $heading-type: 'h3'
    );
}

@mixin \compass\modal\close-button($context: $\compass\modal\context) {
    @include \compass\button\close($context: $context);
    position: absolute;
    top: 0;
    right: 0;
    z-index: \kindling\z-index\get('above-sibling');

    span {
        @include \kindling\visually-hidden\apply();
    }
}

@mixin \compass\modal\body-modal-open() {
    @include \kindling\modal\body-modal-open();
}

@mixin \compass\modal\footer\apply() {
    @include \kindling\spacing\apply($spacing: 'modal.content', $padding-sides: ('all'));
    display: flex;
    justify-content: center;

    > * {
        &:not(:first-child) {
            @include \kindling\spacing\apply($spacing: 'modal.content', $margin-sides: ('left'));
        }
    }
}

@mixin \compass\modal\magento-content() {
    .modal-header {
        @include \compass\modal\heading\apply();
    }

    .modal-content {
        @include \compass\modal\content\apply();
    }

    .modal-footer {
        @include \compass\modal\footer\apply();
    }

    .block-title {
        @include \compass\modal\heading();

        strong {
            font-weight: inherit;
        }
    }

    .action-primary {
        @include \compass\button\apply();
    }

    .action-secondary {
        @include \compass\button\apply($style: 'secondary');
    }

    .action-close {
        @include \compass\modal\close-button();
    }
}

@mixin \compass\modal\global\apply() {
    @at-root {
        body {
            @include \compass\modal\body-modal-open();
        }

        .modal {
            @include \compass\modal\apply();
            @include \compass\modal\magento-content();
        }

        .modal-overlay {
            @include \compass\modal\overlay();
        }

        .modal-heading,
        .modal-title {
            @include \compass\modal\heading();
        }

        .modal-footer {
            @include \compass\modal\footer\apply();
        }

        .modal-close-button {
            @include \compass\modal\close-button();
        }

        .modal-backdrop {
            // sass-lint:disable-block no-important
            z-index: \kindling\z-index\get('modal-overlay') !important;
        }

        .yotpo-device-tablet,
        .yotpo-device-mobile {
            .yotpo-media-modal {
                .yotpo-media-modal-body {
                    // sass-lint:disable-block no-important
                    flex-flow: column-reverse !important;
                    justify-content: center !important;

                    @include \kindling\breakpoint\media-query('medium') {
                        flex-direction: inherit !important;
                    }
                }
            }
        }

        .yotpo-device-desktop,
        .yotpo-device-laptop,
        .yotpo-display-l,
        .yotpo-display-m {
            // sass-lint:disable-block no-important
            .yotpo-media-modal {
                .yotpo-modal {
                    @include \kindling\breakpoint\media-query('large') {
                        height: fit-content !important;
                    }

                    .yotpo-media-modal-body {
                        flex-wrap: wrap-reverse;

                        @include \kindling\breakpoint\media-query('large') {
                            flex-wrap: initial;
                        }

                        > * {
                            width: 100% !important;

                            @include \kindling\breakpoint\media-query('large') {
                                width: 50% !important;
                            }
                        }
                    }
                }
            }
        }

        .yotpo-review-form-modal {
            // sass-lint:disable-block no-important
            .yotpo-modal {
                $width: (
                    'small': 100% !important,
                    'medium': \kindling\value\rem-calc(650) !important,
                    'large': \kindling\value\rem-calc(1000) !important,
                    'xlarge': \kindling\value\rem-calc(1200) !important,
                );
                @include \kindling\property\apply('width', $width);
                height: 100%;
                overflow-x: hidden !important;

                @include \kindling\breakpoint\media-query('large') {
                    height: fit-content;
                }


                .yotpo-review-form {
                    width: 100% !important;

                    @include \kindling\breakpoint\media-query('large') {
                        flex-flow: wrap;
                        justify-content: space-between;
                    }

                    > div {
                        width: 100% !important;
                        min-height: inherit !important;

                        @include \kindling\breakpoint\media-query('large') {
                            width: 50% !important;
                        }
                    }

                    .yotpo-star-rating-wrapper {
                        order: 0;
                    }

                    .yotpo-name-email-wrapper {
                        flex-flow: wrap;
                        order: 1;
                        min-width: inherit !important;

                        .yotpo-input-wrapper {
                            width: 100% !important;

                            @include \kindling\breakpoint\media-query('medium') {
                                width: 47% !important;
                            }
                        }

                        + div {
                            order: 5;

                            @include \kindling\breakpoint\media-query('large') {
                                order: 2;
                            }
                        }
                    }

                    .yotpo-new-review-headline {
                        order: 2;

                        @include \kindling\breakpoint\media-query('large') {
                            order: 3;
                        }
                    }

                    .yotpo-custom-questions-container {
                        order: 3;

                        @include \kindling\breakpoint\media-query('large') {
                            order: 4;
                        }
                    }

                    .yotpo-new-review-content {
                        order: 4;

                        @include \kindling\breakpoint\media-query('large') {
                            order: 5;
                        }

                        textarea {
                            @include \kindling\border\apply('neutral-dark', $sides: ('all'));
                        }
                    }

                    .yotpo-uploaded-images {
                        $width: (
                            'small': 100% !important,
                            'large': 47% !important,
                        );
                        @include \kindling\property\apply('width', $width);

                        @include \kindling\breakpoint\media-query('large') {
                            position: absolute;
                        }

                        .yotpo-inner-label {
                            @include \kindling\typography\font-size('pdp.yotpo-modal.image-label');
                            @include \kindling\spacing\apply('modal.yotpo.image-label', $padding-sides: ('all'));
                            @include \kindling\property\apply('top', \kindling\value\rem-calc(10));
                            @include \kindling\property\apply('left', \kindling\value\rem-calc(4));
                        }

                        .yotpo-thumbnail {
                            @include \kindling\property\apply('width', \kindling\value\rem-calc(50));
                            @include \kindling\property\apply('height', \kindling\value\rem-calc(50));
                            min-width: inherit;
                            min-height: inherit;
                        }
                    }

                    .form-footer {
                        @include \kindling\spacing\apply('modal.yotpo.footer', $padding-sides: ('top' 'bottom'));
                        order: 6;
                    }
                }
            }
        }
    }
}
