@mixin \compass\global\header\top\nav\apply() {
    @include \kindling\typography\unstyled-list();
    @include \compass\grid\row($column-gutters: \kindling\spacing\get('top-header-nav'));
    flex-wrap: nowrap;
}

@mixin \compass\global\header\dropdown-content() {
    @include \compass\dropdown\content\apply();
    @include \compass\scroll-bar\apply($side: 'y');
    overflow-x: hidden;

    $header-height: (
        'small': \kindling\value\rem-calc(90),
        'large': \kindling\value\rem-calc(42)
    );

    @each $breakpoint, $height in $header-height {
        @include \kindling\breakpoint\media-query($breakpoint) {
            max-height: calc(100vh - #{$height});
        }
    }

    .dropdown-content {
        .menu-header {
            @include \compass\menu\header\apply('menu.toolbar');

            .menu-header-text {
                @include \compass\typography\heading('h4');
                @include \kindling\icon\selector() {
                    @include \kindling\icon\base() {
                        @include \kindling\spacing\apply('base.xsmall', $margin-sides: ('right'));
                        color: \kindling\color\get('secondary');
                    }
                }
            }
        }
    }
}

@mixin \compass\global\header\top\dropdown\apply() {
    .dropdown-button {
        @include \compass\dropdown\trigger\apply();
        @include \kindling\spacing\apply('header.button.small', $padding-sides: ('all'));
        @include \kindling\typography\font-size('header.top.text');
        color: \kindling\color\get('header.top.text');

        &:hover,
        &:focus {
            .text {
                text-decoration: underline;
            }
        }

        &::after {
            color: \kindling\color\get('header.top.dropdown-icon');
        }

        &.hover {
            background-color: \kindling\color\get('menu.toolbar.header.background');
            color: \kindling\color\get('menu.toolbar.header.text');

            .icon-image {
                color: \kindling\color\get('secondary');
            }
        }
    }

    .dropdown {
        @include \compass\global\header\dropdown-content();
    }
}

@mixin \compass\global\header\top\apply() {
    .page-header-top {
        position: relative;
        z-index: \kindling\z-index\get('above-sibling');
        background-color: \kindling\color\get('header.top.background');
        color: \kindling\color\get('header.top.text');

        .icon-image {
            color: \kindling\color\get('header.top.icon');
        }

        .my-account-dropdown-container {
            .icon-image {
                @include \kindling\icon\apply($icon: $icon-account) {
                    @include \kindling\typography\font-size('header.top.icon.account-icon');
                }
            }
        }

        .my-garage-dropdown-container {
            @include \project\fitment\header-dropdown\apply();

            .icon-image {
                @include \kindling\icon\apply($icon: $icon-garage) {
                    @include \kindling\typography\font-size('header.top.icon.garage-icon');
                }
            }
        }
    }

    .header-need-help {
        @include \kindling\spacing\apply('header.nav', $margin-sides: ('right'));

        p {
            @include \kindling\typography\font-size('header.top.text');
        }

        .icon-image {
            @include \kindling\icon\apply($icon: $icon-phone) {
                @include \kindling\typography\font-size('header.top.icon.phone-icon');
            }
        }

        a {
            &:hover,
            &:focus {
                color: \kindling\color\get('header.top.text');
            }
        }
    }

    .header-blog-link {
        @include \kindling\spacing\apply('header.nav', $margin-sides: ('right'));

        p {
            @include \kindling\typography\font-size('header.top.text');
        }

        .icon-image {
            @include \kindling\icon\apply($icon: $icon-edit) {
                @include \kindling\typography\font-size('header.top.icon.phone-icon');
            }
        }

        a {
            &:hover,
            &:focus {
                color: \kindling\color\get('header.top.text');
            }
        }
    }

    .page-header-top-content {
        @include \compass\layout\container-padded();
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .page-header-top-content-left {
        margin: 0 auto;

        @include \kindling\breakpoint\media-query($\compass\header\main\desktop-breakpoint) {
            margin: 0;
        }

        .header-top-left-message {
            @include \kindling\spacing\apply('header.top', $padding-sides: ('top', 'bottom'));
            @include \kindling\typography\font-size('header.top.text');
            @include \kindling\typography\font-weight('typography.anchor');

            @include \kindling\breakpoint\media-query($\compass\header\main\desktop-breakpoint) {
                padding: 0;
            }

            .icon-image {
                @include \kindling\icon\apply($icon: $icon-maplesleaf) {
                    vertical-align: -15%;
                }
                @include \kindling\typography\font-size('header.top.icon.free-ship-icon');
                color: \kindling\color\get('header.top.free-ship-message-icon');
            }

            strong {
                @include \kindling\typography\font-stack('typography.heading');
                @include \kindling\typography\font-size('header.top.free-ship-message');
                @include \kindling\typography\letter-spacing('typography.h5');
                vertical-align: -10%;
                text-transform: uppercase;
            }

            a {
                display: inline-block;

                &:hover,
                &:focus {
                    color: \kindling\color\get('header.top.text');
                }
            }
        }
    }

    .page-header-top-content-right {
        @include \customer\account\header\content-desktop();
        @include \compass\magento-checkout\minicart\desktop-trigger();
        display: none;

        @include \kindling\breakpoint\media-query($\compass\header\main\desktop-breakpoint) {
            display: flex;
            align-items: center;
        }

        .header-top-nav-list {
            @include \compass\global\header\top\nav\apply();
            @include \compass\global\header\top\dropdown\apply();
        }
    }
}

@mixin \compass\header\main\left\apply() {
    .page-header-main-left {
        display: flex;
        flex: 0 0 auto;
    }

    .page-header-main-left-row {
        @include \kindling\grid\row();
        flex-wrap: nowrap;
        align-items: center;
    }
}

@mixin \compass\header\main\right\apply() {
    @include \compass\magento-checkout\minicart\mobile-trigger();

    .page-header-main-center {
        display: flex;
        flex: 1 1 auto;
    }

    .page-header-main-center-row {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        height: 100%;
    }

    .page-header-main-right {
        display: flex;
        flex: 0 15 auto;
        width: auto;

        @include \kindling\breakpoint\media-query($\compass\search\header\desktop-breakpoint) {
            width: \kindling\value\rem-calc(200);
            min-width: \kindling\value\rem-calc(100);
        }

        @include \kindling\breakpoint\media-query($\compass\search\header\desktop-widescreen-breakpoint) {
            width: \kindling\value\rem-calc(339);
        }
    }

    .page-header-main-right-row {
        @include \compass\magento-checkout\minicart\mobile-trigger();
        @include \compass\search\apply();
        @include \project\fitment\main-header\dropdown\apply();
        @include \kindling\grid\row(
            $column-gutters: (
                $\compass\header\main\mobile-breakpoint: \kindling\value\rem-calc(16),
                $\compass\header\main\desktop-breakpoint: \kindling\value\rem-calc(0)
            )
        );
        flex-wrap: nowrap;
        align-items: center;
        width: 100%;
    }
}

@mixin \compass\global\header\main\apply() {
    .page-header-main-container {
        position: relative;
        z-index: \kindling\z-index\get('header-main');
    }

    .page-header-main-content {
        @include \compass\layout\container-padded();

        @include \kindling\breakpoint\media-query($\compass\header\main\desktop-breakpoint-down down) {
            @include \kindling\spacing\apply('reset', $padding-sides: ('left', 'right'));
        }
    }

    .page-header-main {
        @include \compass\header\main\left\apply();
        @include \compass\header\main\right\apply();
        position: relative;
        z-index: \kindling\z-index\get('neutral-sibling');
        box-shadow: 0 \kindling\value\rem-calc(1 5) 0 rgba(\kindling\color\get('primary'), 0.15);
    }

    .header-logo-container {
        @include \kindling\property\apply('min-height', $\compass\header\height);
        @include \kindling\property\apply('width', $\compass\header\logo-width);
        display: flex;
        align-items: center;
        background: \kindling\color\get('header.logo.background-color');
        height: 100%;
    }

    .page-header-logo-container {
        $padding: (
            'small': \kindling\spacing\get('base.xxsmall') \kindling\spacing\get('base.xsmall'),
            $\compass\header\main\desktop-breakpoint: \kindling\spacing\get('base.xxsmall') \kindling\spacing\get('base.small')
        );
        @include \kindling\property\apply('padding', $padding);
        display: flex;
        align-items: center;
        height: 100%;
    }

    .page-header-logo {
        width: 100%;
    }

    .page-header-main-content-row {
        @include \kindling\property\apply('min-height', $\compass\header\height);
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
    }
}

@mixin \compass\global\header\sticky() {
    position: sticky;
    top: 0;
}

@mixin \compass\global\header\cookies\status() {
    .cookie-status-message {
        display: none;
    }
}

@mixin \compass\global\header\apply() {
    @include \compass\global\header\main\apply();
    @include \compass\global\header\top\apply();
    @include \project\global\header\bottom\apply();
    @include \compass\mega-menu\apply();
    @include \compass\global\header\cookies\status();

    .page-header {
        @include \compass\global\header\sticky();
        @include \compass\header\promo\apply();
        z-index: \kindling\z-index\get('header');
        background-color: \kindling\color\get('header.outer.background');
    }
}
