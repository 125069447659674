@mixin \msp-recaptcha\styles\apply() {
    .hidden-recaptcha {
        .g-recaptcha {
            visibility: hidden;
            height: 0 !important; // sass-lint:disable-line no-important
        }
    }

    .field-recaptcha-text {
        @include \kindling\spacing\apply('form.default.input.margin', $margin-sides: ('bottom'));
    }
}
