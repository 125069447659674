@mixin \project\live-chat\apply() {
    .ajax-loading,
    .is-body-modal-open,
    .disable-body-scroll {
        #chat-widget-container { // sass-lint:disable-line no-ids
            z-index: \kindling\z-index\get('chat-widget-inactive') !important; //sass-lint:disable-line no-important
        }
    }

    #chat-widget-container { // sass-lint:disable-line no-ids
        @each $breakpoint, $value in \kindling\spacing\get('constrained-content-container.default') {
            @include \kindling\breakpoint\media-query($breakpoint) {
                right: \kindling\spacing\get($value) !important; // sass-lint:disable-line no-important
            }
        }
    }
}
