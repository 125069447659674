@mixin \compass\page-builder\styles\apply() {
    .cms-content-important {
        margin: 0 0 \kindling\value\rem-calc(35) \kindling\value\rem-calc(-20);
        background-color: \kindling\color\get('neutral-white');
        padding: \kindling\value\rem-calc(20);
        line-height: 1.1;
        color: \kindling\color\get('neutral-dark');
        font-size: \kindling\value\rem-calc(22);
        font-weight: 300;
        font-style: normal;
    }

    .pagebuilder-full-width {
        float: left;
        width: 100%;
    }

    .pagebuilder-content-type {
        margin-bottom: \kindling\value\rem-calc(20);
    }

    //.pagebuilder-accordion {
    // TODO: Apply kindling accordions to pagebuilder
    // lib-data-accordion();
    //}

    @include \kindling\breakpoint\media-query('medium') {
        .pagebuilder-mobile-only {
            display: none !important; // sass-lint:disable-line no-important
        }
    }

    @include \kindling\breakpoint\media-query('small' down) {
        .pagebuilder-mobile-hidden {
            display: none !important; // sass-lint:disable-line no-important
        }
    }
}
