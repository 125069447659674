$\kindling\border-radius\palette: (
    'not-set': null,
    'reset': 0,
    'circular': 50%
);

$\kindling\border-radius\contexts: (
    'button': (
        'default': 'not-set'
    ),
    'accordion': (
        'default': 'not-set'
    ),
    'form': (
        'default': (
            'input': 'reset',
            'switch': 'not-set'
        )
    ),
    'minicart': (
        'trigger': (
            'count': 'circular'
        )
    )
);
