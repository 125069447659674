@mixin \compass\sales\order\view() {
    @include \compass\sales\order\view\page-title\layout\apply();
    @include \compass\sales\order\view\navigation-tabs\apply();

    .order-details-items {
        @include \compass\sales\order\details\apply();
    }

    .block-order-details-view {
        @include \compass\sales\order\item-details\apply();
    }
}

@mixin \compass\sales\order\view\apply() {
    body {
        &[class*='sales-order-'],
        &[class*='sales-guest-'] {
            @include \compass\sales\order\view();
        }

        &[class*='sales-guest-'] {
            @include \compass\components\page-title();

            .page-main-content {
                @include \customer\account\block\apply();
            }
        }
    }
}
