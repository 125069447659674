$\kindling\button\disabled\opacity: 1;

$\compass\button\types: (
    'primary',
    'primary-light',
    'primary-dark',
    'secondary',
    'tertiary'
);

$\compass\button\sizes: (
    'micro',
    'small',
    'regular',
    'large'
);

$\compass\button\text-decoration: none;

$\compass\button\icon-only\button-size: (
    'small': \kindling\value\rem-calc(50)
);
$\compass\button\icon-only\icon-ratio: \kindling\value\rem-calc(20) / \kindling\breakpoint\get-breakpoint-value($\compass\button\icon-only\button-size, 'small');

$\compass\button\grid-row\spacing: 'button-grid.default';

$\compass\button\tertiary\icon-spacing: \kindling\value\px-to-em(2);
$\compass\button\tertiary\icon-movement: \kindling\value\px-to-em(8);

$\compass\button\shadows: (
    'top-left': \kindling\value\rem-calc(3 3 4 0) rgba(\kindling\color\get('neutral-black'), 0.1),
    'bottom-right': \kindling\value\rem-calc(-3 -3 4 0) rgba(\kindling\color\get('neutral-black'), 0.1)
);
