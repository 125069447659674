$\kindling\typography\letter-spacing\palette: (
    'not-set': null,
    'inherit-value': inherit,
    'reset': 0,
    'n-quarter-pixel': \kindling\value\rem-calc(-0.25),
    'n-half-pixel': \kindling\value\rem-calc(-0.5),
    'half-pixel': \kindling\value\rem-calc(0.5),
    'quarter-pixel': \kindling\value\rem-calc(0.25),
    'one-pixel': \kindling\value\rem-calc(1),
    'two-pixel': \kindling\value\rem-calc(2)
);

$\kindling\typography\letter-spacing\contexts: (
    'typography': (
        'anchor': 'not-set',
        'hero': 'two-pixel',
        'h1': (
            'small': 'one-pixel',
            'large': 'two-pixel'
        ),
        'h2': (
            'small': 'one-pixel',
            'large': 'two-pixel'
        ),
        'h3': 'one-pixel',
        'h4': 'one-pixel',
        'h5': 'half-pixel',
        'h6': 'half-pixel',
        'copy': (
            'default': 'reset',
            'small': 'reset',
            'lead': 'reset',
            'micro': 'reset'
        ),
        'blog': (
            'h2': 'reset',
            'title': 'reset'
        )
    ),
    'button': (
        'default': 'one-pixel'
    ),
    'badge': 'half-pixel'
);
