@mixin \customer\account-entry\create\apply() {
    @include \compass\social-login\page\customer-account-create\apply();

    .customer-account-create {
        .main-content-panel {
            display: flex;
            flex-wrap: wrap;
        }

        .form-create-account {
            @include \kindling\spacing\apply('section.default.inner', $padding-sides: ('top'));

            @include \kindling\breakpoint\media-query('large') {
                width: 50%;
            }

            .account-entry-container {
                .block {
                    @include \kindling\grid\column(
                        $responsive-sizes: (
                            'small': \kindling\grid\column-width-calc(12),
                            'large': \kindling\grid\column-width-calc(12)
                        ),
                        $flex-grow: 0
                    );
                }
            }

            .form-title {
                @include \kindling\spacing\apply('base.medium', $padding-sides: ('bottom'));
            }

            .block {
                @include \compass\section(
                    $border-sides: ('bottom'),
                    $margin-sides: ('bottom'),
                    $padding-sides: ('bottom')
                );
            }

            .newsletter {
                .label {
                    @include \kindling\typography\font-weight('body');
                    @include \kindling\typography\font-size('typography.small-copy');
                }

                a {
                    @include \kindling\typography\font-size('typography.small-copy');
                    @include \kindling\spacing\apply('global.xsmall', $margin-sides: ('right'));
                }
            }
        }

        .social-login-container {
            @include \compass\section(
                $border-sides: ('bottom'),
                $margin-sides: (),
                $padding-sides: ('bottom', 'top')
            );
            flex: 1 1 auto;

            @include \kindling\breakpoint\media-query('large') {
                @include \kindling\border\apply('reset', $sides: ('bottom'));
                padding-right: \kindling\value\rem-calc(30);
                width: 50%;
            }
        }

        .content-main {
            .block {
                width: 100%;
            }
        }
    }
}
