@mixin \project\fitment\vehicle-navigator-form\base\apply() {
    @include \compass\base\form-styles\small\apply();

    .vehicle-navigator-form-accordion {
        @include \project\accordion\vehicle-navigation-form\container();
    }

    .vehicle-navigator-form-accordion-item {
        @include \project\accordion\vehicle-navigation-form\item();
        position: relative;
    }

    .vehicle-navigator-form-accordion-title {
        @include \project\accordion\vehicle-navigation-form\title();
        position: relative;

        .accordion-title-overlay {
            @include \compass\accordion\overlay-button();
        }
    }

    .vehicle-navigator-form-accordion-title-text {
        transform: translateY(11%);

        @include \kindling\breakpoint\media-query('xlarge') {
            width: \kindling\value\rem-calc(200),;
        }
    }

    .vehicle-navigator-form-accordion-body {
        @include \project\accordion\vehicle-navigation-form\body();
        border-bottom: 0 !important; // sass-lint:disable-line no-important
    }

    .form-widget-submit-button {
        @include \compass\button\primary-dark('micro');
        width: 100%;

        @include \kindling\breakpoint\media-query('medium' 'only') {
            width: auto;
        }
    }
}
