$\kindling\typography\font-size\palette: (
    'not-set': null,
    'inherit-value': inherit,
    'tiny': \kindling\value\rem-calc(11),
    'icon': (
        '1': \kindling\value\rem-calc(9)
    ),
    'micro': \kindling\value\rem-calc(12),
    'small': (
        '1': \kindling\value\rem-calc(13),
        '2': \kindling\value\rem-calc(14),
        '3': \kindling\value\rem-calc(15)
    ),
    'normal': (
        '1': \kindling\value\rem-calc(16),
        '2': \kindling\value\rem-calc(17),
        '3': \kindling\value\rem-calc(18),
    ),
    'large': (
        '1': \kindling\value\rem-calc(20),
        '2': \kindling\value\rem-calc(22),
        '3': \kindling\value\rem-calc(24),
        '4': \kindling\value\rem-calc(26),
        '5': \kindling\value\rem-calc(30),
        '6': \kindling\value\rem-calc(36),
        '7': \kindling\value\rem-calc(38),
        '8': \kindling\value\rem-calc(40),
        '9': \kindling\value\rem-calc(48),
        '10': \kindling\value\rem-calc(68),
        '11': \kindling\value\rem-calc(90)
    ),
    'banner': (
        'hero-icon': \kindling\value\px-to-em(22)
    )
);

$\kindling\typography\font-size\contexts: (
    'base': 'normal.1',
    'body': 'base',
    'button': (
        'micro': 'small.2',
        'small': 'normal.1',
        'regular': 'normal.3',
        'large': 'large.1',
        'tertiary': (
            'micro': 'small.2',
            'xsmall': 'small.3',
            'small': 'normal.1',
            'regular': 'normal.3',
            'large': 'large.2'
        )
    ),
    'typography': (
        'hero': (
            'small': 'large.9',
            'large': 'large.10'
        ),
        'h1': (
            'small': 'large.6',
            'large': 'large.9'
        ),
        'h2': (
            'small': 'large.5',
            'large': 'large.6'
        ),
        'h3': (
            'small': 'large.3',
            'large': 'large.4'
        ),
        'h4': (
            'small': 'large.2'
        ),
        'h5': (
            'small': 'large.1'
        ),
        'h6': (
            'small': 'normal.3'
        ),
        'subheading': (
            'large': 'large.1',
            'medium': 'normal.3',
            'small': 'normal.1'
        ),
        'small-copy': 'small.2',
        'micro-copy': 'micro',
        'copy': (
            'micro': 'micro',
            'small': 'small.2',
            'default': 'normal.1',
            'lead': 'normal.3'
        ),
        'li': 'normal.1',
        'anchor': 'inherit-value',
        'cite': 'small.1',
        'small-element': 'small.2',
        'blockquote': (
            'icon': 'large.5',
            'content': 'normal.3',
            'author': 'normal.1'
        ),
        'blog': (
            'h2': (
                'small': 'large.5',
                'large': 'large.6'
            ),
            'title': (
                'small': 'large.9',
                'large': 'large.11'
            ),
            'category-bar': (
                'icon': (
                    'small': 'large.4'
                )
            )
        )
    ),
    'customer': (
        'account': (
            'sidebar': (
                'heading': 'large.1',
                'link': 'normal.1'
            )
        )
    ),
    'checkout': (
        'sidebar-totals': (
            'default': 'normal.1',
            'delete-action': 'small.1'
        ),
        'items': (
            'default': 'large.1'
        ),
        'header': (
            'icon': 'large.1',
            'text': 'small.2'
        )
    ),
    'tooltip': (
        'default': (
            'text': 'small.2'
        )
    ),
    'accordion': (
        'default': (
            'title': 'large.1'
        ),
        'table': (
            'title': 'normal.3'
        ),
        'mini-garage': (
            'title': 'normal.1'
        ),
        'vehicle-navigation-form': (
            'title': 'large.2',
            'dropdown-title': 'normal.1'
        )
    ),
    'form': (
        'default': (
            'input': 'base',
            'input-micro': 'small.2',
            'error': 'micro',
            'help': 'micro',
            'password': 'small.1',
            'icon': 'small.1',
            'checkbox': (
                'icon': 'large.1'
            ),
            'radio': (
                'icon': 'large.1'
            )
        ),
        'search': (
            'input': 'small.3',
            'icon': 'large.1',
            'mobile-toggle': 'large.4',
            'autocomplete': 'small.2'
        )
    ),
    'sneaky-label': 'tiny',
    'reviews': (
        'default': (
            'icon': 'normal.2',
            'action': 'small.1'
        ),
        'pdp': (
            'icon': 'normal.3',
            'action': 'normal.1'
        )
    ),
    'pdp': (
        'product-info': (
            'product-info-top': 'small.1',
            'add-to-cart': (
                'label': 'normal.3'
            ),
            'stock': (
                'icon': 'normal.1'
            ),
            'social-link': (
                'text': 'normal.1',
                'icon': 'normal.3'
            ),
            'fitment-label': (
                'icon': 'large.4',
                'action': 'typography.copy.micro'
            ),
            'currency': (
                'label': 'small.2',
                'icon': 'normal.2'
            ),
            'stock-wrapper': (
                'text': 'small.2',
                'shipping-policy': 'micro'
            )
        ),
        'yotpo-review': (
            'ratings': 'small.2',
            'legend': 'small.1'
        ),
        'yotpo-modal': (
            'image-label': (
                'small': 'icon.1'
            )
        )
    ),
    'product-price': (
        'default': (
            'price': 'large.1',
            'special-price': 'product-price.default.price',
            'old-price': 'normal.3',
            'out-of-stock': 'normal.1',
            'msrp': 'small.2'
        ),
        'product-list': (
            'price': 'small.3',
            'special-price': 'product-price.product-list.price',
            'old-price': 'micro',
            'msrp': 'micro'
        ),
        'checkout': (
            'price': 'body',
            'special-price': 'product-price.checkout.price',
            'old-price': 'micro',
            'msrp': 'micro'
        )
    ),
    'badge': 'small.2',
    'minicart': (
        'item': (
            'default': 'body',
            'name': 'small.2',
            'price': 'small.2',
            'attribute-label': 'micro',
            'attribute-value': 'micro',
            'action': (
                'icon': 'small.2',
                'link': 'micro'
            )
        ),
        'trigger': (
            'icon': (
                'small': 'large.4',
                'large': 'large.4'
            ),
            'count': 'micro'
        ),
        'header': (
            'count': 'small.2'
        )
    ),
    'cart': (
        'item': (
            'action': (
                'text': (
                    'small': 'small.1',
                    'medium': 'typography.copy.default'
                ),
                'icon': (
                    'edit': (
                        'small': 'normal.3'
                    ),
                    'delete': (
                        'small': 'normal.3'
                    ),
                    'wishlist': (
                        'small': 'normal.3'
                    ),
                    'primary': (
                        'small': 'normal.3',
                        'medium': 'large.2'
                    )
                )
            ),
            'total': 'typography.copy.default',
            'quantity-divider': 'large.1',
            'default': 'large.1'
        ),
        'sidebar': (
            'coupon': (
                'cta-text': 'typography.copy.small',
                'list': (
                    'text': 'small.1',
                    'icon': 'large.1'
                ),
                'applied': 'small.2',
                'promo-text': 'normal.1',
                'cancel': 'normal.3'
            ),
            'shipping': (
                'legend': 'typography.copy.small',
                'label': 'typography.copy.small'
            ),
            'credit': (
                'link': 'small.1'
            )
        ),
        'actions': (
            'submit': 'typography.copy.default'
        )
    ),
    'compare-bar': (
        'accordion-title-text': (
            'small': 'accordion.default.title'
        )
    ),
    'compare-page': (
        'item': (
            'name': 'small.3'
        )
    ),
    'contact': (
        'sidebar': (
            'content': 'small.1'
        ),
        'required-note': 'micro'
    ),
    'dropdown': (
        'default': (
            'trigger': (
                'text': 'small.1',
                'icon': 'icon.1'
            ),
            'list': (
                'anchor': 'normal.1'
            )
        )
    ),
    'header': (
        'promo-bar': 'normal.1',
        'top': (
            'text': 'micro',
            'free-ship-message': 'small.2',
            'icon': (
                'free-ship-icon': 'normal.1',
                'account-icon': 'normal.1',
                'phone-icon': 'small.2',
                'garage-icon': 'normal.2',
                'minicart-icon': 'large.1'
            )
        ),
        'shop-for-bikes-title': (
            'small': 'normal.1',
            'xlarge': 'button.large'
        ),
        'shop-for-bikes-icon': 'large.5',
        'shop-for-bikes-text': 'micro'
    ),
    'table': (
        'default': (
            'thead': 'large.1',
            'tbody': 'normal.1'
        ),
        'secondary': (
            'thead': 'normal.3',
            'tbody': 'normal.1'
        ),
        'mobile': (
            'thead': 'normal.1',
            'tbody': 'normal.1'
        )
    ),
    'item-actions': (
        'default': (
            'text': 'normal.3',
            'icon': 'large.1'
        )
    ),
    'tabs': (
        'default': (
            'title': 'normal.1'
        ),
        'pdp': (
            'title': 'normal.1'
        )
    ),
    'fitment': (
        'label': 'micro'
    ),
    'menu': (
        'mega-menu-desktop': 'normal.2',
        'mobile-mega-menu-icon': 'large.5',
        'mobile-mega-menu-level-2-link': 'normal.1'
    ),
    'my-garage': (
        'mobile-toggle': 'large.4'
    ),
    'breadcrumb': 'small.2',
    'shop-for-bikes-modal': (
        'brand-item': 'large.1',
        'header': 'small.2',
        'footer': 'micro',
        'title-icon': 'large.8'
    ),
    'footer': (
        'small-text': 'small.2',
        'heading': 'large.1',
        'nav-link': 'normal.1',
        'tiny-link': 'micro',
        'social-icon': 'large.2'
    ),
    'brands-page': (
        'desktop-nav': (
            'link': (
                'large': 'micro',
                'xlarge': 'small.2'
            ),
        ),
        'sticky-on-page-nav': (
            'link': 'micro'
        ),
        'brand-name': (
            'small': 'normal.1',
            'xlarge': 'normal.3'
        )
    ),
    'swatches': (
        'more-label': 'typography.copy.micro',
        'attribute-label': 'typography.copy.small'
    )
);
