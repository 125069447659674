@mixin \compass\global\footer\apply() {
    @include \compass\footer\container\apply();
    @include \compass\footer\main\apply();
    @include \compass\footer\additional\apply();
    @include \compass\footer\sticky\apply();
}

@mixin \compass\footer\container\apply() {
    .page-footer {
        position: relative;
        z-index: \kindling\z-index\get('neutral-sibling');
        background-color: \kindling\color\get($\compass\footer\background-color);
        color: \kindling\color\get($\compass\footer\text-color);
    }
}

@mixin \compass\footer\main\apply() {
    .page-footer-main {
        @include \project\footer\main\top\apply();
        @include \compass\footer\main\contact\apply();
        @include \compass\footer\main\information\apply();
        @include \compass\layout\section-overlap(
            $top-section-selector: '.page-footer-main-top-row',
            $bottom-section-selector: '.page-footer-contact',
            $overlap: \kindling\spacing\get('footer.newsletter-overlap')
        );
        background-color: \kindling\color\get($\compass\footer\main\background-color);
        color: \kindling\color\get($\compass\footer\main\text-color);
    }

    .page-footer-main-content {
        @include \compass\layout\container-padded();
    }

    .page-footer-main-content-row {
        @include \kindling\grid\row();

        @include \kindling\breakpoint\media-query($\compass\footer\breakpoint\desktop) {
            align-items: stretch;
        }
    }
}

@mixin \project\footer\main\top\apply() {
    .page-footer-main-top-row {
        background: \kindling\color\get('footer.pre-block.background-color');

        .page-footer-main-content {
            @include \kindling\spacing\apply('footer.top-block', $padding-sides: ('top', 'bottom'));
        }
    }

    .pre-footer-list {
        @include \kindling\typography\unstyled-list();
        @include \kindling\grid\row();

        @include \kindling\breakpoint\media-query($\compass\footer\breakpoint\desktop) {
            flex-wrap: nowrap;
            justify-content: center;
        }
    }

    .pre-footer-list-item {
        @include \kindling\spacing\apply('footer.pre-footer-icons-side', $padding-sides: ('left', 'right'));
        @include \kindling\spacing\apply('footer.pre-footer-icons', $padding-sides: ('bottom'));
        @include \kindling\grid\column(
            $responsive-sizes: (
                'small': \kindling\grid\column-width-calc(12),
                'medium': \kindling\grid\column-width-calc(6),
                'large': \kindling\grid\column-width-calc(3)
            ),
            $flex-grow: 0
        );
        display: flex;
        flex-wrap: nowrap;

        @include \kindling\breakpoint\media-query($\compass\footer\breakpoint\mobile) {
            justify-content: center;
        }

        @include \kindling\breakpoint\media-query($\compass\footer\breakpoint\desktop) {
            @include \kindling\border\apply('footer.top-icons', $sides: ('right'));
        }

        &:nth-of-type(3) {
            @include \kindling\spacing\apply('footer.pre-footer-payment-icon', $padding-sides: ('bottom'));
        }

        &:last-of-type {
            @include \kindling\spacing\apply('reset', $padding-sides: ('bottom'));
        }

        &:nth-child(odd) {
            @include \kindling\breakpoint\media-query($\compass\footer\breakpoint\mobile) {
                @include \kindling\border\apply('footer.top-icons', $sides: ('right'));
            }
        }

        &:last-of-type {
            @include \kindling\breakpoint\media-query($\compass\footer\breakpoint\desktop) {
                border: 0;
            }
        }

        a {
            @include \kindling\typography\font-size('footer.tiny-link');
        }
    }

    .pre-footer-list-title {
        @include \kindling\typography\heading\h6();
        display: block;
    }

    .pre-footer-icon {
        @include \kindling\spacing\apply('footer.links-column-gap', $padding-sides: ('right'));
        @include \kindling\property\apply(max-height, \kindling\value\rem-calc(41));
    }
}

@mixin \compass\footer\main\title($include-spacing: true) {
    @include \compass\typography\heading(
        $heading-type: 'h5',
        $color: 'footer.information.color',
        $include-spacing: $include-spacing
    );
}

@mixin \compass\footer\main\logo\apply() {
    .page-footer-logo {
        @include \kindling\spacing\apply('footer.outer-logo-gap', $padding-sides: ('right'));
        @include \kindling\grid\column(
            $responsive-sizes: (
                'small': \kindling\grid\column-width-calc(12),
                'large': \kindling\grid\column-width-calc(4)
            ),
            $flex-grow: 0
        );
        @include \kindling\grid\row();
        align-items: center;

        @include \kindling\breakpoint\media-query($\compass\footer\breakpoint\desktop) {
            flex-direction: column;
        }

        > * {
            @include \kindling\grid\column(
                $responsive-sizes: (
                    'small': \kindling\grid\column-width-calc(12),
                    'medium': \kindling\grid\column-width-calc(6),
                    'large': \kindling\grid\column-width-calc(12)
                ),
                $flex-grow: 0
            );
        }

        .logo {
            @include \kindling\spacing\apply('footer.inner-logo-gap', $padding-sides: ('right'));
        }

        img {
            @include \kindling\property\apply(max-width, \kindling\value\rem-calc(295));
            @include \kindling\spacing\apply('footer.image-gap', $padding-sides: ('bottom'));
            width: 100%;
        }
    }

    .footer-address {
        @include \kindling\typography\font-size('footer.small-text');

        @include \kindling\breakpoint\media-query($\compass\footer\breakpoint\mobile) {
            @include \kindling\typography\font-size('typography.copy.default');
        }
    }
}

@mixin \compass\footer\main\social\apply() {
    .footer-social-links-container {
        @include \kindling\spacing\apply('footer.social-links-gap', $padding-sides: ('top'));
        @include \kindling\grid\row();
        @include \kindling\grid\column(
            $responsive-sizes: (
                'small': auto,
                'xlarge': \kindling\grid\column-width-calc(2, $total-columns: 8)
            ),
            $flex-grow: 0
        );
        align-items: center;

        @include \kindling\breakpoint\media-query($\compass\footer\breakpoint\large-desktop) {
            flex-direction: column;
            align-items: flex-start;
        }

        ul {
            @include \kindling\typography\unstyled-list();
        }
    }

    .footer-social-links-title {
        @include \compass\footer\main\title($include-spacing: false);
        @include \kindling\spacing\apply('footer.links-column-gap', $padding-sides: ('right'));
        @include \kindling\grid\column(
            $responsive-sizes: (
                'small': \kindling\grid\column-width-calc(12),
                'medium': auto,
                'xlarge': \kindling\grid\column-width-calc(12)
            ),
            $flex-grow: 0
        );

        @include \kindling\breakpoint\media-query($\compass\footer\breakpoint\large-desktop) {
            @include \kindling\typography\margin('typography.h5');
        }
    }

    .social-links-text {
        @include \kindling\grid\row();
        align-items: center;
    }

    .footer-social-link {
        @include \kindling\typography\font-size('footer.social-icon');
        @include \project\anchor\special-underline($hover-color: 'footer.information.color');
    }

    .social-links-phone {
        @include \kindling\typography\font-size('footer.small-text');
        @include \kindling\spacing\apply('footer.phone-link', $margin-sides: ('bottom'));
        @include \kindling\grid\column(
            $responsive-sizes: (
                'small': auto,
                'xlarge': \kindling\grid\column-width-calc(12),
                'xxlarge': auto
            ),
            $flex-grow: 0
        );

        a {
            &:focus,
            &:hover {
                color: \kindling\color\get('footer.information.color');
            }
        }
    }

    .footer-social-links-list {
        @include \kindling\grid\column(
            $responsive-sizes: (
                'small': auto,
                'xlarge': \kindling\grid\column-width-calc(12),
                'xxlarge': auto
            ),
            $flex-grow: 0
        );
        @include \kindling\spacing\apply('footer.social-icon-container', $padding-sides: ('left'));
    }

    .footer-social-media-list {
        li {
            @include \kindling\spacing\apply('footer.small-column-gap', $padding-sides: ('right'));
            display: inline-block;
        }
    }

    .footer-social-icon-youtube {
        @include \kindling\icon\apply($icon: $icon-youtube);
    }

    .footer-social-icon-instagram {
        @include \kindling\icon\apply($icon: $icon-instagram);
    }

    .footer-social-icon-facebook {
        @include \kindling\icon\apply($icon: $icon-facebook);
    }
}

@mixin \compass\footer\main\information\apply() {
    @include \compass\footer\main\logo\apply();
    @include \compass\footer\main\social\apply();

    .page-footer-main-bottom-row {
        @include \kindling\spacing\apply('footer.content', $padding-sides: ('bottom'));
        color: \kindling\color\get('footer.information.color');

        > .page-footer-main-content {
            @include \kindling\spacing\apply('footer.bottom-block', $padding-sides: ('top'));
        }
    }

    .page-footer-information {
        @include \kindling\grid\row();
    }

    .page-footer-right-wrapper {
        @include \kindling\grid\row();
        @include \kindling\grid\column(
            $responsive-sizes: (
                'small': \kindling\grid\column-width-calc(12),
                'large': \kindling\grid\column-width-calc(8)
            ),
            $flex-grow: 0
        );
    }

    .page-footer-hours-wrapper {
        @include \kindling\grid\row();
        @include \kindling\grid\column(
            $responsive-sizes: (
                'small': \kindling\grid\column-width-calc(8, $total-columns: 8),
                'xlarge': \kindling\grid\column-width-calc(6, $total-columns: 8)
            ),
            $flex-grow: 0
        );
    }

    .page-footer-hours {
        @include \kindling\typography\font-size('footer.small-text');
        @include \kindling\spacing\apply('footer.table', $margin-sides: ('top'));
        @include \kindling\grid\column(
            $responsive-sizes: (
                'small': \kindling\grid\column-width-calc(12),
                'medium': auto
            ),
            $flex-grow: 0
        );
        @include \kindling\spacing\apply('footer.large-table-gap', $margin-sides: ('right'));

        .hours-table-title {
            @include \compass\footer\main\title();
        }

        table {
            @include \kindling\typography\line-height('base');
        }

        th {
            @include \kindling\spacing\apply('footer.newsletter-gap', $padding-sides: ('right'));
            text-align: left;
            font-weight: inherit;
        }
    }
}

@mixin \compass\footer\main\nav\apply() {
    .page-footer-nav {
        @include \kindling\grid\column(
            $responsive-sizes: (
                'small': \kindling\grid\column-width-calc(12),
                'xlarge': \kindling\grid\column-width-calc(8)
            ),
            $flex-grow: 0
        );
    }

    .footer-links-container {
        @include \compass\responsive-accordion\container($accordion-type: 'footer');
        @include \kindling\grid\row(('small': \kindling\spacing\get('footer.links-column-gap')));
        flex-wrap: nowrap;

        > * {
            @include \kindling\breakpoint\media-query(\kindling\breakpoint\get-previous-breakpoint($\compass\footer\breakpoint\desktop) 'down') {
                padding-left: 0;
            }
        }
    }

    .footer-links-column {
        @include \compass\responsive-accordion\item();
        @include \kindling\grid\column(
            $responsive-sizes: (
                'small': \kindling\grid\column-width-calc(12),
                'large': \kindling\grid\column-width-calc(3)
            ),
            $flex-grow: 0
        );
    }

    .footer-links-heading {
        @include \compass\responsive-accordion\item\heading();
        @include \kindling\typography\font-size('footer.heading');
        @include \kindling\spacing\apply('footer.nav-link-gap', $padding-sides: ('bottom'));
        color: \kindling\color\get('footer.bottom.link-headings');
    }

    .footer-links-button {
        @include \compass\responsive-accordion\item\title();
    }

    .footer-links-content {
        @include \compass\responsive-accordion\item\body();
    }

    .footer-link-list {
        @include \kindling\typography\unstyled-list();
    }

    .footer-link-item {
        @include \kindling\spacing\apply('footer.nav-link-gap', $padding-sides: ('bottom'));
    }

    .footer-link {
        @include \kindling\typography\font-size('footer.nav-link');
        @include \kindling\typography\font-weight('footer.links');
        @include \project\anchor\special-underline($hover-color: $\compass\footer\additional\text-color);
    }
}

@mixin \compass\footer\main\back-to-top\apply() {
    .footer-back-to-top-container {
        @include \kindling\spacing\apply('footer.back-to-top-gap', $margin-sides: ('right'));
    }

    .back-to-top-button {
        @include \kindling\button\link-style($context: 'button.default', $text-decoration: none, $text-transform: uppercase);
        @include \kindling\typography\font-size('button.regular');
        @include \kindling\spacing\apply('reset', $padding-sides: ('all'));
        @include \kindling\icon\apply($icon: $icon-double-arrow-left, $use-before: false) {
            display: inline-block;
            transform: rotate(90deg);
            vertical-align: 0;
        }

        &:hover,
        &:focus {
            .back-to-top-text {
                position: relative;

                &::before {
                    position: absolute;
                    bottom: 0;
                    background-color: \kindling\color\get('button.default.text-color');
                    width: 100%;
                    height: 1px; // sass-lint:disable-line property-units
                    content: '';
                }
            }
        }
    }
}

@mixin \compass\footer\additional\apply() {
    .page-footer-additional {
        background-color: \kindling\color\get($\compass\footer\additional\background-color);
        color: \kindling\color\get($\compass\footer\additional\text-color);
    }

    .page-footer-additional-content {
        @include \compass\layout\container-padded();
    }

    .page-footer-additional-content-row {
        @include \compass\footer\main\nav\apply();
        @include \project\footer\additional-right\apply();
        @include \kindling\grid\row();
        @include \kindling\spacing\apply('footer.bottom.section.inner', $padding-sides: ('all'));
    }
}

@mixin \project\footer\additional-right\apply() {
    .page-footer-additional-right {
        @include \compass\global\payment-icons\apply();
        @include \project\footer\copyright\apply();
        @include \project\footer\additional-right-bottom\apply();
        @include \kindling\spacing\apply('footer.additional-right', $margin-sides: ('top'));
        @include \kindling\grid\column(
            $responsive-sizes: (
                'small': \kindling\grid\column-width-calc(12),
                'xlarge': \kindling\grid\column-width-calc(4)
            ),
            $flex-grow: 0
        );
        position: relative;

        @include \kindling\breakpoint\media-query($\compass\footer\breakpoint\mobile) {
            display: flex;
            flex-wrap: wrap;
        }

        @include \kindling\breakpoint\media-query($\compass\footer\breakpoint\large-desktop) {
            flex-direction: column;
            text-align: right;
        }
    }
}

@mixin \project\footer\additional-right-bottom\apply() {
    .page-footer-additional-right-bottom {
        @include \compass\footer\main\back-to-top\apply();
        @include \kindling\spacing\apply(
            $spacing: 'footer.back-to-top-outer',
            $margin-sides: ('all')
        );

        @include \kindling\breakpoint\media-query($\compass\footer\breakpoint\mobile) {
            flex: 0 0 auto;
            flex-wrap: wrap;
        }
    }
}

@mixin \project\footer\copyright\apply() {
    .copyright {
        text-align: center;
        color: \kindling\color\get('footer.bottom.copyright');

        @include \kindling\breakpoint\media-query($\compass\footer\breakpoint\mobile) {
            flex: 1 1 auto;
            width: 50%;
            text-align: inherit;
        }

        @include \kindling\breakpoint\media-query($\compass\footer\breakpoint\large-desktop) {
            width: auto;
        }
    }
}

@mixin \compass\footer\sticky\apply() {
    .page-footer-sticky {
        position: sticky;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: \kindling\z-index\get('footer-sticky');
    }
}
