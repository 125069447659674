@mixin \project\cta\overlapping-image-cta(
    $image-height: $\project\cta\overlapping-image\default-image-height,
    $image-overlap: $\project\cta\overlapping-image\default-image-overlap,
    $content-spacing: \kindling\spacing\get('cta.overlapping-image-cta'),
    $heading-type: $\project\cta\overlapping-image\default-heading-type,
    $wrapper-selector: '.overlapping-cta',
    $background-selector: '.overlapping-cta-background',
    $content-selector: '.overlapping-cta-content'
) {
    #{$wrapper-selector} {
        @include \compass\layout\section-overlap(
            $top-section-selector: null,
            $bottom-section-selector: $content-selector,
            $overlap: $image-overlap
        );

        #{$background-selector} {
            @include \kindling\image-fit\apply();
            @include \kindling\property\apply('height', $image-height);
            position: relative;
            background-color: \kindling\color\get('cta.overlapping-image-cta.background');
            width: 100%;
        }

        #{$content-selector} {
            @include \kindling\property\apply('margin-right', $content-spacing);
            @include \kindling\property\apply('margin-left', $content-spacing);
            @include \kindling\spacing\apply('cta.overlapping-image-cta', $margin-sides: ('right', 'left'), $padding-sides: ('all'));
            box-shadow: $\project\cta\overlapping-image\box-shadow;
            background: \kindling\color\get('cta.overlapping-image-cta.content');
            text-align: center;
        }

        .overlapping-cta-title {
            @include \compass\typography\heading($heading-type);
        }

        .overlapping-cta-link {
            @include \compass\button\tertiary();
            margin-top: \kindling\value\rem-calc(5);
        }
    }
}

@mixin \project\cta\overlapping-image-cta\grid($responsive-sizes, $grid-gutters: ('small': \kindling\value\rem-calc(30))) {
    @include \kindling\typography\unstyled-list();
    @include \compass\grid\row($grid-gutters);

    > * {
        @include \kindling\grid\column($responsive-sizes, $flex-grow: 1, $flex-shrink: 0);
        @include \project\cta\overlapping-image-cta();
    }
}
