@mixin \compass\responsive-accordion\container(
    $breakpoint: $\compass\responsive-accordion\destroy-viewport,
    $accordion-type: 'footer'
) {
    @include \kindling\breakpoint\media-query(\kindling\breakpoint\get-previous-breakpoint($breakpoint) down) {
        @if $accordion-type == 'primary' {
            @include \project\accordion\primary\container();
        } @else if $accordion-type == 'footer' {
            @include \project\accordion\footer\container();
        } @else if $accordion-type == 'sidebar' {
            @include \project\accordion\sidebar\container();
        } @else if $accordion-type == 'account-sidebar' {
            @include \project\accordion\account-sidebar\container();
        } @else {
            @error 'Unknown accordion type `#{$accordion-type}`';
        }
    }
}

@mixin \compass\responsive-accordion\item(
    $breakpoint: $\compass\responsive-accordion\destroy-viewport,
    $accordion-type: 'footer'
) {
    @include \kindling\breakpoint\media-query(\kindling\breakpoint\get-previous-breakpoint($breakpoint) down) {
        @if $accordion-type == 'primary' {
            @include \project\accordion\primary\item();
        } @else if $accordion-type == 'footer' {
            @include \project\accordion\footer\item();
        } @else if $accordion-type == 'sidebar' {
            @include \project\accordion\sidebar\item();
        } @else if $accordion-type == 'account-sidebar' {
            @include \project\accordion\account-sidebar\item();
        } @else {
            @error 'Unknown accordion type `#{$accordion-type}`';
        }
    }
}

@mixin \compass\responsive-accordion\item\heading($breakpoint: $\compass\responsive-accordion\destroy-viewport) {
    @include \kindling\breakpoint\media-query(\kindling\breakpoint\get-previous-breakpoint($breakpoint) down) {
        display: none;
    }
}

@mixin \compass\responsive-accordion\item\title(
    $breakpoint: $\compass\responsive-accordion\destroy-viewport,
    $accordion-type: 'footer'
) {
    @include \kindling\breakpoint\media-query(\kindling\breakpoint\get-previous-breakpoint($breakpoint) down) {
        @if $accordion-type == 'primary' {
            @include \project\accordion\primary\title();
        } @else if $accordion-type == 'footer' {
            @include \project\accordion\footer\title();
        } @else if $accordion-type == 'sidebar' {
            @include \project\accordion\sidebar\title();
        } @else if $accordion-type == 'account-sidebar' {
            @include \project\accordion\account-sidebar\title();
        } @else {
            @error 'Unknown accordion type `#{$accordion-type}`';
        }
    }

    @include \kindling\breakpoint\media-query($breakpoint) {
        display: none;
    }
}

@mixin \compass\responsive-accordion\item\body(
    $breakpoint: $\compass\responsive-accordion\destroy-viewport,
    $accordion-type: 'footer'
) {
    @include \kindling\breakpoint\media-query(\kindling\breakpoint\get-previous-breakpoint($breakpoint) down) {
        @if $accordion-type == 'primary' {
            @include \project\accordion\primary\body();
        } @else if $accordion-type == 'footer' {
            @include \project\accordion\footer\body();
        } @else if $accordion-type == 'sidebar' {
            @include \project\accordion\sidebar\body();
        } @else if $accordion-type == 'account-sidebar' {
            @include \project\accordion\account-sidebar\body();
        } @else {
            @error 'Unknown accordion type `#{$accordion-type}`';
        }
    }
}
