@mixin \compass\page-builder\button-item\apply() {
    [data-content-type='button-item'] {
        max-width: 100%;

        [data-element='link'],
        [data-element='empty_link'] {
            max-width: 100%;
            word-wrap: break-word;
        }

        [data-element='empty_link'] {
            cursor: default;
        }
    }

    a,
    button,
    div {
        &.pagebuilder-button-link {
            @include \kindling\button\link-style();
        }

        &.pagebuilder-button-primary {
            @include \compass\button\apply($style: 'primary');
        }

        &.pagebuilder-button-secondary {
            @include \compass\button\apply($style: 'secondary');
        }
    }
}
