@mixin \project\trust-badges\item(
    $context,
    $icon
) {
    $default-context: 'badges.default.phone';
    $icon-color: \kindling\color\context-keys-calc('icon', $context, $default-context);
    $text-color: \kindling\color\context-keys-calc('text', $context, $default-context);
    $link-color: \kindling\color\context-keys-calc('link', $context, $default-context);
    $link-color-hover: \kindling\color\context-keys-calc('link-hover', $context, $default-context);

    & {
        margin-top: $\project\trust-badges\spacing-outer;
    }

    & {
        margin-bottom: $\project\trust-badges\spacing-outer;
    }

    > * {
        @include \kindling\icon\apply($icon: $icon) {
            margin-right: $\project\trust-badges\icon-spacing;
            line-height: 1.2;
            color: \kindling\color\get($icon-color);
            font-size: \kindling\value\rem-calc(20);
        }
        display: flex;
        color: \kindling\color\get($text-color);
    }

    .badge-text {
        @include \compass\typography\copy\small();
    }

    a {
        color: \kindling\color\get($link-color);

        &:hover,
        &:focus {
            color: \kindling\color\get($link-color-hover);
        }
    }
}

@mixin \project\trust-badges\item-phone() {
    @include \project\trust-badges\item(
        $context: 'badges.default.phone',
        $icon: $icon-phone
    );
}

@mixin \project\trust-badges\item-secure() {
    @include \project\trust-badges\item(
        $context: 'badges.default.secure',
        $icon: $icon-lock-closed
    );
}

@mixin \project\trust-badges\item-support() {
    @include \project\trust-badges\item(
        $context: 'badges.default.support',
        $icon: $icon-hours
    );
}

@mixin \project\trust-badges\item-returns() {
    @include \project\trust-badges\item(
        $context: 'badges.default.returns',
        $icon: $icon-loop
    );
}

@mixin \project\trust-badges\item-currency() {
    @include \project\trust-badges\item(
        $context: 'badges.default.currency',
        $icon: $icon-maplesleaf
    );
}

@mixin \project\sidebar-trust-badges\apply() {
    @include \compass\global\payment-icons\apply($show-secure: false, $in-footer: false);

    .badge-phone {
        @include \project\trust-badges\item-phone();
    }

    .badge-secure {
        @include \project\trust-badges\item-secure();
    }

    .badge-support {
        @include \project\trust-badges\item-support();
    }

    .badge-returns {
        @include \project\trust-badges\item-returns();
    }

    .badge-currency {
        @include \project\trust-badges\item-currency();
    }
}

@mixin \project\trust-badges\apply() {
    .trust-badges {
        @include \kindling\spacing\apply('base.large', $margin-sides: ('top'));
        @include \kindling\border\apply('checkout-bottom-border.default.default', $sides: ('top'));
        @include \kindling\spacing\apply('base.medium', $padding-sides: ('top'));
    }
}
