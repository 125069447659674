@mixin \compass\page-builder\image\apply() {
    figure[data-content-type='image'] {
        > [data-element='link'],
        > [data-element='link'] img {
            border-radius: inherit;
        }

        figcaption {
            word-wrap: break-word;
        }
    }
}
