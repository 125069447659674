@mixin \customer\account\dashboard\apply() {
    .customer-account-dashboard {
        @include \customer\account\layout\apply();
        @include \customer\account\sidebar\apply();
        @include \compass\components\page-title();
        @include \customer\account\form();
        @include \customer\account\address\default-label();
        @include \customer\account\heading();
        @include \customer\account\heading\box();
        @include \customer\account\garage();
        @include \compass\catalog\toolbar\apply();

        .content-main {
            @include \kindling\breakpoint\media-query('small' only) {
                @include \kindling\spacing\apply('reset', $padding-sides: ('top', 'right', 'left'));
                @include \kindling\spacing\apply('base.xxxsmall', $margin-sides: ('top'));
            }

            @include \kindling\breakpoint\media-query('small') {
                @include \compass\layout\container-padded();
            }
        }

        .left-content-panel {
            @include \kindling\breakpoint\media-query('small' only) {
                $offset: \kindling\spacing\get('grid-gutters.small');
                @include \kindling\property\apply('margin-left', 0);
                @include \kindling\property\apply('margin-right', $offset, -1);
                width: calc(100% + #{$offset});
            }
        }

        .main-content-panel {
            @include \kindling\breakpoint\media-query('small' only) {
                @include \kindling\spacing\apply('base.large', $margin-sides: ('top'));
            }
        }

        .main {
            .block {
                @include \kindling\spacing\apply($spacing: 'base.small', $padding-sides: ('bottom'));
            }
        }

        .page-title-wrapper {
            @include \kindling\breakpoint\media-query('xlarge') {
                @include \kindling\spacing\apply('base.large', $margin-sides: ('bottom'));
            }
        }

        .block-dashboard-orders {
            @include \customer\account\table();
        }

        .block-dashboard-info,
        .block-dashboard-addresses,
        .block-addresses-default,
        .block-addresses-list {
            .block-content {
                @include \kindling\grid\row($vertical-gutters: true);
                @include \kindling\typography\unstyled-list();
                @include \customer\account\boxes\apply();
            }

            .account-box-wrapper {
                @include \kindling\grid\column(
                    $responsive-sizes: (
                        'small': \kindling\grid\column-width-calc(12),
                        'large': \kindling\grid\column-width-calc(6)
                    )
                );
                display: flex;

                .box {
                    width: 100%;
                }

                .box-content {
                    flex-grow: 1;
                    flex-shrink: 0;
                }

                .box-actions {
                    @include \kindling\spacing\apply($spacing: 'global.small', $margin-sides: ('top'));

                    a {
                        @include \kindling\spacing\apply($spacing: 'base.xsmall', $margin-sides: ('right'));

                        &:last-of-type {
                            margin-right: 0;
                        }
                    }
                }

                .action {
                    &.edit {
                        @include \compass\button\item-action\apply($\customer\account\icon\edit-action);
                    }

                    &.change-password {
                        @include \compass\button\apply($style: 'secondary', $size: 'micro');
                    }

                    &.delete {
                        @include \compass\button\item-action\apply($\customer\account\icon\delete-action);
                    }

                    &.edit,
                    &.delete {
                        @include \kindling\icon\selector() {
                            @include \kindling\icon\base() {
                                margin-right: \kindling\value\rem-calc(5);
                                vertical-align: 0;
                            }
                        }
                        @include \kindling\typography\font-size('typography.copy.small');
                        display: flex;
                        align-items: center;
                        text-decoration: none;

                        &::before {
                            @include \kindling\typography\font-size('typography.copy.small');
                        }

                        &:hover,
                        &:focus {
                            span {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }
}
