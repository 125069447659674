@mixin \compass\layered-navigation\option-link() {
    @include \kindling\typography\font-size('normal.1');
    @include \compass\typography\font\apply('primary', 'medium');
    text-decoration: none;

    &:focus,
    &:hover {
        text-decoration: none;
        color: inherit;

        .layered-navigation-link-text {
            text-decoration: underline;
        }
    }
}

@mixin \compass\layered-navigation\base\apply() {
    .layered-section-sort-items {
        @include \kindling\typography\unstyled-list();
    }

    .layered-section-sort-item {
        @include \kindling\icon\apply($icon: $icon-radio-off) {
            display: inline-block;
            width: \kindling\value\rem-calc(25);
            font-size: \kindling\value\rem-calc(22);
        }
        @include \compass\layered-navigation\option-link();

        &::after {
            content: none;
        }

        &.current-sort {
            &::before {
                content: $icon-radio-on;
            }
        }
    }

    .layered-navigation-state-list {
        @include \kindling\typography\unstyled-list();

        a {
            @include \kindling\icon\apply($icon: $icon-trash) {
                font-size: \kindling\value\rem-calc(20);
            }
            @include \kindling\typography\font-size('typography.small-copy');
            @include \compass\typography\font\apply('primary', 'normal');
            text-decoration: none;

            &:hover,
            &:focus {
                color: inherit;

                .layered-navigation-link-text {
                    text-decoration: underline;
                }
            }
        }
    }

    .layered-navigation-link-list {
        @include \kindling\typography\unstyled-list();

        .layered-navigation-list-item {
            a {
                @include \compass\layered-navigation\option-link();

                &:hover,
                &:focus {
                    &::before {
                        @include \kindling\form\checkbox\checked();
                    }
                }

                &::before {
                    @include \kindling\form\checkbox\unchecked();
                }

                &.is-active {
                    &::before {
                        @include \kindling\form\checkbox\checked();
                    }
                }
            }

            &.hidden {
                &:not(.is-active) {
                    display: none;
                }
            }
        }

        .layered-navigation-links-view-more-toggle {
            padding-top: \kindling\value\rem-calc(10);

            button {
                @include \compass\button\reset-browser-button();
                @include \compass\typography\anchor\small();
            }
        }
    }
}

@mixin \compass\layered-navigation\apply() {
    .layered-navigation {
        display: flex;
        flex-wrap: wrap;

        @include \kindling\breakpoint\media-query($\compass\layered-nav\desktop-breakpoint) {
            flex-direction: column;
        }

        > * {
            $sizes: \kindling\breakpoint\get-values-greater-than(\kindling\spacing\get('grid-gutters'), $\compass\layered-nav\desktop-breakpoint);
            @include \kindling\property\apply('margin-top', $sizes, 0.5);

            &:first-child {
                margin-top: 0;
            }
        }

        .layered-navigation-filter-by-section,
        .layered-navigation-sort-by {
            width: 50%;

            @include \kindling\breakpoint\media-query($\compass\layered-nav\desktop-breakpoint) {
                width: auto;
            }
        }

        .layered-navigation-child-accordion {
            @include \kindling\spacing\apply(
                $spacing: 'accordion.sidebar.item',
                $margin-sides: ('top')
            );
        }

        .layered-navigation-filter-by-section {
            .layered-navigation-desktop-content {
                background-color: \kindling\color\get('transparent');
            }

            .layered-navigation-desktop-content-inner {
                @include \kindling\spacing\apply(
                    $spacing: 'accordion.sidebar.body',
                    $padding-sides: ('right', 'bottom', 'left')
                );
                background-color: \kindling\color\get('accordion.sidebar.body.background-color');
            }
        }

        .layered-navigation-accordion-item {
            @include \project\accordion\sidebar\item();
        }

        .layered-navigation-accordion-title {
            @include \project\accordion\sidebar\title();
        }

        .layered-navigation-accordion-body {
            @include \project\accordion\sidebar\body();
        }

        .mobile-layered-nav-open-button {
            @include \project\accordion\sidebar\title();

            @include \kindling\breakpoint\media-query($\compass\layered-nav\desktop-breakpoint) {
                display: none;
            }
        }

        .layered-navigation-sort-by {
            @include \kindling\breakpoint\media-query(\kindling\breakpoint\get-previous-breakpoint($\compass\layered-nav\desktop-breakpoint) 'down') {
                padding-right: \kindling\value\rem-calc(3);
            }

            + * {
                @include \kindling\breakpoint\media-query(\kindling\breakpoint\get-previous-breakpoint($\compass\layered-nav\desktop-breakpoint) 'down') {
                    padding-left: \kindling\value\rem-calc(3);
                }
            }
        }

        .layered-nav-desktop-container {
            @include \compass\layered-navigation\base\apply();

            @include \kindling\breakpoint\media-query(\kindling\breakpoint\get-previous-breakpoint($\compass\layered-nav\desktop-breakpoint) 'down') {
                display: none;
            }
        }

        .layered-navigation-desktop-header-button {
            @include \project\accordion\sidebar\title();

            @include \kindling\breakpoint\media-query(\kindling\breakpoint\get-previous-breakpoint($\compass\layered-nav\desktop-breakpoint) 'down') {
                display: none;
            }
        }

        .layered-navigation-desktop-content {
            @include \project\accordion\sidebar\body();
            padding: 0;
        }
    }

    .layered-nav-mobile-container {
        @include \compass\layered-navigation\base\apply();
        @include \compass\pullout-menu\apply($width: 100%);
        @include \compass\scroll-bar\apply();

        .layered-navigation-accordion-item {
            @include \project\accordion\sidebar-light\item();
        }

        .layered-navigation-accordion-title {
            @include \project\accordion\sidebar-light\title();
        }

        .layered-nav-mobile-content {
            @include \project\accordion\sidebar-light\container();
        }

        .layered-navigation-filter-by-list {
            .layered-navigation-accordion-body {
                @include \project\accordion\sidebar-light\body();
            }
        }

        .layered-navigation-sort-by {
            @include \project\accordion\sidebar-light\item();

            @include \kindling\breakpoint\media-query(\kindling\breakpoint\get-previous-breakpoint($\compass\layered-nav\desktop-breakpoint) 'down') {
                border: 0;
            }

            .layered-navigation-accordion-body {
                @include \project\accordion\sidebar-light\body($top-padding: true);
            }
        }

        .layered-navigation-desktop-content-inner {
            @include \kindling\spacing\apply(
                $spacing: 'accordion.sidebar.body',
                $padding-sides: ('all')
            );
        }

        .layered-navigation-state-list {
            @include \compass\grid\row();
        }

        .layered-navigation-desktop-header-button {
            display: none;
        }

        .layered-navigation-desktop-content {
            @include \project\accordion\sidebar-light\body();
            border: 0;
            padding: 0;
        }

        .layered-nav-mobile-backdrop {
            @include \compass\pullout-menu\overlay();
        }

        .mobile-layered-nav-header {
            @include \compass\menu\header('menu.toolbar');
        }

        .mobile-layered-nav-header-title {
            @include \compass\menu\header\text('menu.toolbar');
        }

        .mobile-layered-nav-close-button {
            @include \compass\menu\header\close-button('menu.toolbar');
        }
    }
}
