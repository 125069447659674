@mixin \customer\account\sidebar\contents-apply() {
    .account-nav {
        @include \kindling\typography\unstyled-list();
    }

    .account-nav-item {
        &:not(:last-of-type) {
            @include \kindling\spacing\apply($spacing: 'base.xsmall', $margin-sides: ('bottom'));
        }
    }

    .account-nav-item-current {
        .account-nav-link {
            color: \kindling\color\get('secondary');
        }
    }

    .account-nav-link {
        @include \kindling\typography\font-size('customer.account.sidebar.link');
        @include \kindling\spacing\apply(
            $spacing: 'customer.account.sidebar.link',
            $padding-sides: ('all')
        );
        display: flex;
        align-items: center;
        line-height: 1;
    }
}

@mixin \customer\account\sidebar\accordion-apply() {
    .account-nav-accordion {
        @include \compass\responsive-accordion\container($\customer\account\navigation\breakpoint, 'account-sidebar');
        background-color: \kindling\color\get('accordion.account-sidebar.body.background-color');

        @include \kindling\breakpoint\media-query('medium' 'down') {
            @include \kindling\spacing\apply(
                $spacing: 'customer.account.sidebar.mobile-accordion.section',
                $margin-sides: ('bottom')
            );
        }
    }

    .account-nav-accordion-item {
        @include \compass\responsive-accordion\item($\customer\account\navigation\breakpoint, 'account-sidebar');
    }

    .account-nav-accordion-heading {
        @include \compass\responsive-accordion\item\heading($\customer\account\navigation\breakpoint);
        @include \kindling\typography\font-size('customer.account.sidebar.heading');
        @include \kindling\spacing\apply(
            $spacing: 'customer.account.sidebar.heading',
            $padding-sides: ('all')
        );
    }

    .account-nav-accordion-title {
        @include \compass\responsive-accordion\item\title($\customer\account\navigation\breakpoint, 'account-sidebar');
    }

    .account-nav-accordion-body {
        @include \compass\responsive-accordion\item\body($\customer\account\navigation\breakpoint, 'account-sidebar');
        @include \kindling\spacing\apply(
            $spacing: 'customer.account.sidebar.body',
            $padding-sides: ('bottom')
        );
    }
}

@mixin \customer\account\sidebar\apply() {
    @include \customer\account\sidebar\accordion-apply();
    @include \customer\account\sidebar\contents-apply();
}
