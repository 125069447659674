$tooltip-background-color: 'neutral-darkest';
$tooltip-color: 'neutral-white';
$tooltip-pip-width: \kindling\value\rem-calc(7);
$tooltip-padding: \kindling\spacing\get('tooltip.default');


$\compass\tooltip\text-context: 'tooltip.default.text';
$\compass\tooltip\background-color: $tooltip-background-color;
$\compass\tooltip\button-size: \kindling\value\rem-calc(16);
$\compass\tooltip\button-side: 'right';
$\compass\tooltip\button-side-offset: \kindling\value\rem-calc(15);
$\compass\tooltip\button-icon: $icon-question-mark;
$\compass\tooltip\button-active-icon: $icon-question-mark;
$\compass\tooltip\button-icon-color: 'neutral-dark';
$\compass\tooltip\button-active-icon-color: $tooltip-background-color;
$\compass\tooltip\button-icon-font-size: \kindling\value\rem-calc(20);
$\compass\tooltip\button-active-icon-font-size: $\compass\tooltip\button-icon-font-size;
$\compass\tooltip\button-transition-speed: 'fast';
$\compass\tooltip\button-transition-timing: 'ease-out';
$\compass\tooltip\button-background: 'transparent';
$\compass\tooltip\button-active-background: 'transparent';
$\compass\tooltip\button-larger-clickable-target-psuedo-element: 'after';
$\compass\tooltip\border-color: 'transparent';
$\compass\tooltip\border-size: 1px;
$\compass\tooltip\side: 'top';
$\compass\tooltip\align: 'left';
$\compass\tooltip\button-position-top: \kindling\value\rem-calc(26);
$\compass\tooltip\apply-button-larger-clickable-target-psuedo-element-styles: true;
$\compass\tooltip\apply-button-position-styles: false;
$\compass\tooltip\button-larger-clickable-target-sizes: (
    'small': \kindling\value\rem-calc(50),
    'large': \kindling\value\rem-calc(30)
);
