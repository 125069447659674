@mixin \compass\gift-card\form\apply() {
    .page-product-giftcard {
        .giftcard.form {
            .fieldset {
                @include \compass\layout\form\grid-columns\apply();

                > * {
                    &:last-child {
                        @include \kindling\breakpoint\media-query('large') {
                            grid-column-end: span 2;
                        }
                    }
                }
            }
        }

        .form {
            .legend {
                @include \kindling\spacing\apply('form.default.heading', $margin-sides: ('bottom'));
            }
        }
    }
}
