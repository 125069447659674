@mixin \northern\checkout\footer\icons\apply() {
    .checkout-footer-trust-elements-container {
        @include \compass\global\payment-icons\apply();

        .page-payment-icon-list {
            @include \kindling\spacing\apply('checkout.footer.inner', $margin-sides: ('bottom'));
            flex-wrap: wrap;

            @include \kindling\breakpoint\media-query($\compass\footer\breakpoint\mobile) {
                flex-wrap: nowrap;
            }

            .page-payment-icon-item {
                &.icon-secure,
                &.icon-privacy {
                    @include \kindling\spacing\apply('checkout.footer.inner', $padding-sides: ('bottom'), $ratio: 0.5);
                    @include \kindling\spacing\apply('checkout.footer.icons.between', $padding-sides: ('left', 'right'));
                    @include \kindling\property\apply('width', ('small': \kindling\grid\column-width-calc(6), $\compass\footer\breakpoint\mobile: auto));

                    @include \kindling\breakpoint\media-query('small' 'only') {
                        @include \kindling\spacing\apply('reset', $margin-sides: ('all'));
                    }
                }

                &.icon-privacy {
                    text-align: right;

                    @include \kindling\breakpoint\media-query($\compass\footer\breakpoint\mobile) {
                        text-align: left;

                    }
                }

                &:nth-child(3) {
                    @include \kindling\breakpoint\media-query('small' 'only') {
                        @include \kindling\spacing\apply('reset', $margin-sides: ('left'));
                    }
                }
            }
        }
    }
}

@mixin  \northern\checkout\footer\logo\apply() {
    .checkout-footer-logo-row {
        display: flex;
        flex: 0 0 auto;
        justify-content: center;
        background-color: \kindling\color\get('checkout.footer.logo.background-color');
        width: 100%;

        @include \kindling\breakpoint\media-query($\compass\footer\breakpoint\mobile) {
            display: block;
            background-color: transparent;
            width: auto;
        }
    }

    .checkout-footer-logo-container {
        @include \kindling\property\apply('min-height', $\compass\checkout\footer\height);
        @include \kindling\property\apply('width', $\compass\checkout\footer\logo-width);
        display: flex;
        align-items: center;
        background-color: \kindling\color\get('checkout.footer.logo.background-color');
        height: 100%;

        .logo {
            @include \kindling\spacing\apply('checkout.footer.logo', $padding-sides: ('all'));
            display: flex;
            align-items: center;
            height: 100%;

            img {
                width: 100%;
            }
        }
    }
}

@mixin \northern\checkout\footer\apply() {
    .checkout-page-footer {
        @include \northern\checkout\footer\logo\apply();
        @include \northern\checkout\footer\icons\apply();
        @include \kindling\color\apply-background-color('checkout.footer.background-color');

        .checkout-footer-content {
            @include \compass\layout\container-padded();

            @include \kindling\breakpoint\media-query('small' 'only') {
                @include \kindling\spacing\apply('reset', $padding-sides: ('left', 'right'));
            }
        }

        .checkout-footer-content-row {
            @include \kindling\grid\row(\kindling\spacing\get('grid-gutters'));
            align-items: center;

            .copyright {
                flex: 1 0 auto;
                text-align: center;
            }
        }

        .checkout-footer-additional-container {
            @include \kindling\grid\row(\kindling\spacing\get('grid-gutters'));
            flex: 1 1 auto;
            flex-direction: column;
            align-items: center;

            @include \kindling\breakpoint\media-query($\compass\footer\breakpoint\mobile) {
                align-items: flex-end;
            }

            @include \kindling\breakpoint\media-query('xlarge') {
                flex-direction: row;
                align-items: center;
            }
        }

        .checkout-footer-copyright-container {
            @include \kindling\spacing\apply('checkout.footer.inner', $padding-sides: ('top', 'bottom'));
            flex: 1 0 auto;
            color: \kindling\color\get('checkout.footer.text-color');

            @include \kindling\breakpoint\media-query('small' 'only') {
                @include \kindling\spacing\apply('checkout.footer.outer', $margin-sides: ('left', 'right'));
                text-align: center;
            }
        }
    }
}
