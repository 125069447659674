@mixin \catalog\product-image\overlay\apply($z-index: 'neutral-sibling') {
    position: relative;

    &::before {
        @supports (mix-blend-mode: darken) {
            @include \kindling\transition\apply(background-color, 'slow');
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: \kindling\z-index\get($z-index);
            background-color: \kindling\color\get('neutral-lighter');
            content: '';
            mix-blend-mode: darken;
            pointer-events: none;
        }
    }
}
