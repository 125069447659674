@mixin \compass\tooltip\arrow(
    $psuedo-element,
    $side: $\compass\tooltip\side,
    $align: $\compass\tooltip\align,
    $color: $\compass\tooltip\background-color
) {
    &::#{$psuedo-element} {
        position: absolute;
    }

    @if $side == 'bottom' {
        &::#{$psuedo-element} {
            @include \kindling\shapes\css-triangle($tooltip-pip-width, $color, 'up');
            bottom: 100%;

            @if $align == 'center' {
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    @if $side == 'top' {
        &::#{$psuedo-element} {
            @include \kindling\shapes\css-triangle($tooltip-pip-width, $color, 'down');
            top: 100%;

            @if $align == 'center' {
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    @if $side == 'left' {
        &::#{$psuedo-element} {
            @include \kindling\shapes\css-triangle($tooltip-pip-width, $color, right);
            left: 100%;

            @if $align == 'center' {
                top: 50%;
                bottom: auto;
                transform: translateY(-50%);
            }
        }
    }

    @if $side == 'right' {
        &::#{$psuedo-element} {
            @include \kindling\shapes\css-triangle($tooltip-pip-width, $color, left);
            right: 100%;
            left: auto;

            @if $align == 'center' {
                top: 50%;
                bottom: auto;
                transform: translateY(-50%);
            }
        }
    }

    @if $align == 'top' {
        &::#{$psuedo-element} {
            top: 10%;
            bottom: auto;
        }
    }

    @if $align == 'bottom' {
        &::#{$psuedo-element} {
            top: auto;
            bottom: 10%;
        }
    }

    @if $align == 'left' {
        &::#{$psuedo-element} {
            right: auto;
            left: 10%;
        }
    }

    @if $align == 'right' {
        &::#{$psuedo-element} {
            right: 10%;
            left: auto;
        }
    }
}

@mixin \compass\tooltip\content\apply(
    $side: $\compass\tooltip\side,
    $align: $\compass\tooltip\align,
    $border-size: $\compass\tooltip\border-size,
    $border-color: $\compass\tooltip\border-color
) {
    @include \kindling\tooltip\apply(
        $side: $side,
        $align: $align
    );

    &.align-left {
        @include \compass\tooltip\arrow(
            $psuedo-element: 'before',
            $side: $side,
            $align: 'left'
        );
    }

    &.align-center {
        @include \compass\tooltip\arrow(
            $psuedo-element: 'before',
            $side: $side,
            $align: 'center'
        );
    }

    &.align-right {
        @include \compass\tooltip\arrow(
            $psuedo-element: 'before',
            $side: $side,
            $align: 'right'
        );
    }

    @if $border-size {
        // partial fake border on side of the arrow using another pseudo element.
        @include \compass\tooltip\arrow(
            $psuedo-element: 'after',
            $side: $side,
            $align: $align,
            $color: $border-color
        );
        // the border around the message
        box-shadow: 0 0 0 $border-size \kindling\color\get($border-color);

        &::after {
            z-index: -1;
            margin-#{$side}: $border-size;
        }
    }

    @include \compass\typography\font\apply($\compass\tooltip\text-context, 'normal', \kindling\typography\font-style\get($\compass\tooltip\text-context));
    @include \kindling\typography\font-size($\compass\tooltip\text-context);

    a {
        text-decoration: underline;
        color: \kindling\color\get($tooltip-color);

        &:hover,
        &:focus {
            color: \kindling\color\get($tooltip-color);
        }
    }
}

@mixin \compass\tooltip\position(
    $side: $\compass\tooltip\button-side,
    $side-offset: $\compass\tooltip\button-side-offset,
    $top: $\compass\tooltip\button-position-top
) {
    position: absolute;
    #{$side}: $side-offset;
    top: $top;
    transform: translateY(-50%);
    z-index: 10;
}

@mixin \compass\tooltip\larger-clickable-area(
    $psuedo-element: $\compass\tooltip\button-larger-clickable-target-psuedo-element,
    $sizes: $\compass\tooltip\button-larger-clickable-target-sizes
) {
    &::#{$psuedo-element} {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        content: '';

        @each $breakpoint, $size in $sizes {
            @include \kindling\breakpoint\media-query($breakpoint) {
                width: $size;
                height: $size;
            }
        }
    }
}

@mixin \compass\tooltip\trigger\base(
    $size: $\compass\tooltip\button-size,
    $icon: $\compass\tooltip\button-icon,
    $active-icon: $\compass\tooltip\button-active-icon,
    $icon-color: $\compass\tooltip\button-icon-color,
    $active-icon-color: $\compass\tooltip\button-active-icon-color,
    $icon-font-size: $\compass\tooltip\button-icon-font-size,
    $active-icon-font-size: $\compass\tooltip\button-active-icon-font-size,
    $background: $\compass\tooltip\button-background,
    $active-background: $\compass\tooltip\button-active-background,
    $transition-speed: $\compass\tooltip\button-transition-speed,
    $transition-timing: $\compass\tooltip\button-transition-timing
) {
    @include \kindling\tooltip\has-tooltip();
    @include \kindling\icon\apply($icon: $icon) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: \kindling\color\get($icon-color);
        font-size: $icon-font-size;
    }
    @include \kindling\typography\line-height('tooltip.default.text');

    transition: \kindling\transition\get-duration($transition-speed) \kindling\transition\get-timing-function($transition-timing);
    border-bottom: 0;
    border-radius: 50%;
    background-color: \kindling\color\get($background);
    padding: 0;
    width: $size;
    height: $size;

    &.active {
        @include \kindling\icon\apply($icon: $active-icon) {
            color: \kindling\color\get($active-icon-color);
            font-size: $active-icon-font-size;
        }
        background-color: \kindling\color\get($active-background);
    }
}

@mixin \compass\tooltip\trigger\apply(
    $size: $\compass\tooltip\button-size,
    $icon: $\compass\tooltip\button-icon,
    $active-icon: $\compass\tooltip\button-active-icon,
    $icon-color: $\compass\tooltip\button-icon-color,
    $active-icon-color: $\compass\tooltip\button-active-icon-color,
    $icon-font-size: $\compass\tooltip\button-icon-font-size,
    $active-icon-font-size: $\compass\tooltip\button-active-icon-font-size,
    $background: $\compass\tooltip\button-background,
    $active-background: $\compass\tooltip\button-active-background,
    $transition-speed: $\compass\tooltip\button-transition-speed,
    $transition-timing: $\compass\tooltip\button-transition-timing,
    $side: $\compass\tooltip\button-side,
    $side-offset: $\compass\tooltip\button-side-offset,
    $psuedo-element: $\compass\tooltip\button-larger-clickable-target-psuedo-element,
    $sizes: $\compass\tooltip\button-larger-clickable-target-sizes,
    $button-position-top: $\compass\tooltip\button-position-top,
    $apply-button-larger-clickable-target-psuedo-element-styles: $\compass\tooltip\apply-button-larger-clickable-target-psuedo-element-styles,
    $apply-button-position-styles: $\compass\tooltip\apply-button-position-styles
) {
    @include \compass\tooltip\trigger\base(
        $size: $size,
        $icon: $icon,
        $active-icon: $active-icon,
        $icon-color: $icon-color,
        $active-icon-color: $active-icon-color,
        $icon-font-size: $icon-font-size,
        $active-icon-font-size: $active-icon-font-size,
        $background: $background,
        $active-background: $active-background,
        $transition-speed: $transition-speed,
        $transition-timing: $transition-timing
    );
    @if $apply-button-position-styles {
        @include \compass\tooltip\position($side, $side-offset, $button-position-top);
    }
    @if $apply-button-larger-clickable-target-psuedo-element-styles {
        @include \compass\tooltip\larger-clickable-area($psuedo-element, $sizes);
    }
}
