@mixin \compass\catalog\product\page-products\layout() {
    .left-content-panel {
        $widths: (
            'large': \kindling\grid\column-width-calc(4),
            'xlarge': \kindling\grid\column-width-calc(3)
        );
        @include \compass\layout\panel-left($widths);

        ~ .main-content-panel {
            $spacing: (
                'xlarge': \kindling\breakpoint\get-breakpoint-value(\kindling\spacing\get('grid-gutters'), 'xlarge') * 2
            );
            @include \kindling\property\apply('margin-left', $spacing);
        }
    }
}

@mixin \compass\catalog\product\page-products\apply() {
    .page-products {
        @include \compass\catalog\product\page-products\layout();
        @include \compass\layered-navigation\apply();
        @include \compass\catalog\toolbar\apply();

        .product-items {
            @include \compass\product-grid\apply();
        }

        .left-content-panel {
            @include \project\fitment\vehicle-navigator-form\sidebar\apply();

            .vehicle-navigation-container {
                @include \kindling\breakpoint\media-query(\kindling\breakpoint\get-previous-breakpoint($\compass\layered-nav\desktop-breakpoint) 'down') {
                    @include \kindling\property\apply('margin-bottom', \kindling\breakpoint\get-breakpoint-value(\kindling\spacing\get('grid-gutters'), 'xlarge'));
                    width: 100%;
                }
            }
        }

        .main-content-panel {
            .message {
                &.empty {
                    @include \compass\section($border-sides: ());
                }
            }
        }
    }
}
