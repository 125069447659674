@mixin \compass\catalog\product\pdp\layout() {
    .product-info-container {
        @include \kindling\grid\row(('large': \kindling\value\rem-calc(30)));
        align-items: flex-start;
        justify-content: space-between;

        .product-info-main {
            @include \kindling\grid\column($\compass\catalog\product\pdp\info\width, 0);
        }

        .product.media {
            @include \kindling\grid\column($\compass\catalog\product\pdp\gallery\width, 0);
            order: -1;
        }
    }
}

@mixin \compass\catalog\product\pdp\sliders() {
    @include \compass\slider\product\apply();

    .block-title {
        margin-bottom: \kindling\value\rem-calc(16);
        text-align: center;
    }

    .associated {
        &.related {
            margin-bottom: \kindling\value\rem-calc(60);
        }

        .product-item-details-reviews .empty {
            display: none;
        }
    }
}

@mixin \compass\catalog\product\pdp\apply() {
    .catalog-product-view {
        @include \compass\catalog\product\pdp\layout();
        @include \compass\catalog\gallery\apply();
        @include \compass\catalog\product\info\apply();
        @include \compass\catalog\product\details\apply();
        @include \compass\catalog\product\pdp\sliders();
    }
}
