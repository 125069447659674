@mixin \compass\page-builder\video\apply() {
    div[data-content-type='video'] {
        .pagebuilder-video-inner {
            display: inline-block;
            width: 100%;
        }

        .pagebuilder-video-container {
            position: relative;
            border-radius: inherit;
            padding-top: 56.25%;
            overflow: hidden;
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
