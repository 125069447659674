@mixin \project\global\modal\brands\list\apply() {
    ul {
        @include \kindling\typography\unstyled-list();

        li {
            &:not(:last-of-type) {
                @include \kindling\spacing\apply('shop-for-bikes-modal.between', $margin-sides: ('bottom'));
            }
        }
    }

    .brand-item {
        @include \kindling\spacing\apply('shop-for-bikes-modal.between', $padding-sides: ('all'));
        position: relative;
    }

    .background-image-container {
        @include \compass\image-section\background();
        background-color: \kindling\color\get('shop-for-bikes-modal.brand-item.background-color');
    }

    .brand-item-link {
        @include \kindling\icon\apply($icon: $icon-double-arrow-right) {
            @include \kindling\typography\font-size('shop-for-bikes-modal.brand-item');
            position: absolute;
            top: 50%;
            right: \kindling\value\rem-calc(16);
            transform: translateY(-50%);
            z-index: \kindling\z-index\get('above-sibling');
            color: \kindling\color\get('shop-for-bikes-modal.brand-item.text-color');
        }
        display: flex;
        align-items: center;
        text-decoration: none;

        &:hover,
        &:focus {
            .brand-item-title {
                text-decoration: underline;
            }
        }
    }

    .brand-item-image-wrapper {
        z-index: \kindling\z-index\get('above-sibling');
        background-color: \kindling\color\get('shop-for-bikes-modal.brand-icon.background-color');
        width: \kindling\value\rem-calc(45);
    }

    .brand-item-image {
        @include \kindling\transition\apply(transform);
        width: 100%;
        height: \kindling\value\rem-calc(45);

        &.header-image {
            display: none;
        }
    }

    .brand-item-title {
        @include \kindling\spacing\apply('shop-for-bikes-modal.between', $margin-sides: ('left'));
        @include \kindling\typography\font-stack('shop-for-bikes-modal.brand-item');
        @include \kindling\typography\font-size('shop-for-bikes-modal.brand-item');
        z-index: \kindling\z-index\get('above-sibling');
        color: \kindling\color\get('shop-for-bikes-modal.brand-item.text-color');
    }

    .shop-all-item {
        @include \kindling\spacing\apply('reset', $padding-sides: ('all'));

        a {
            @include \compass\button\primary($size: 'large');
            width: 100%;
        }
    }
}

@mixin \project\global\modal\brands\apply() {
    .modal-shop-for-bike {
        @include \compass\modal\apply($fullscreen: true);

        .modal-title-container {
            display: flex;
            position: relative;

            .icon {
                @include \kindling\breakpoint\media-query('small' 'down') {
                    display: none;
                }
            }

            .icon-motorcycle {
                @include \kindling\icon\apply($icon: $icon-motorcycle) {
                    @include \kindling\typography\font-size('shop-for-bikes-modal.title-icon');
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    color: \kindling\color\get('shop-for-bikes-modal.title-icon');
                }
            }

            .icon-atv {
                @include \kindling\icon\apply($icon: $icon-atv) {
                    @include \kindling\typography\font-size('shop-for-bikes-modal.title-icon');
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    color: \kindling\color\get('shop-for-bikes-modal.title-icon');
                }
                margin-left: \kindling\value\rem-calc(10);
            }
        }

        .modal-title {
            @include \kindling\spacing\apply('shop-for-bikes-modal.title-icon', $padding-sides: ('left'));
        }

        .modal-content {
            @include \kindling\spacing\apply('reset', $padding-sides: ('all'));

            .modal-header {
                @include \kindling\spacing\apply('shop-for-bikes-modal.header', $padding-sides: ('all'));
                @include \kindling\border\apply('shop-for-bikes-modal', $sides: ('top'));
                text-align: center;

                p {
                    @include \kindling\typography\font-size('shop-for-bikes-modal.header');
                    @include \kindling\typography\line-height('shop-for-bikes.header');
                }
            }

            .modal-footer {
                button {
                    @include \kindling\button\link-style();
                    @include \kindling\spacing\apply('reset', $margin-sides: ('left'));
                }

                p,
                button {
                    @include \kindling\typography\font-size('shop-for-bikes-modal.footer');
                }
            }
        }

        .modal-body {
            @include \kindling\spacing\apply('shop-for-bikes-modal.inner', $padding-sides: ('all'));
        }

        .modal-footer {
            display: block;
            text-align: center;
        }
    }
}

@mixin \project\global\header\shop-for-bike\cta\apply() {
    > a {
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: none;
            color: \kindling\color\get('header.shop-for-bikes.text-color-hover');

            .title {
                text-decoration: none;
                color: \kindling\color\get('header.shop-for-bikes.text-color-hover');
            }

            .text {
                text-decoration: none;
            }
        }
    }

    .title {
        @include \compass\typography\heading('h6', $color: 'header.top.text');
        @include \kindling\typography\font-size('header.shop-for-bikes-title');
        letter-spacing: \kindling\value\rem-calc(2);

        @include \kindling\breakpoint\media-query('medium' down) {
            padding-top: \kindling\value\rem-calc(3);
        }
    }

    .text {
        @include \kindling\spacing\apply('header.shop-for-bikes.icon', $margin-sides: ('bottom'));
        @include \kindling\typography\font-size('header.shop-for-bikes-text');
        @include \kindling\typography\line-height('base');
        margin-bottom: 0;
    }

    .icon {
        @include \kindling\typography\font-size('header.shop-for-bikes-icon');
        @include \kindling\typography\line-height('base');
        text-decoration: none;

        @include \kindling\breakpoint\media-query('medium' down) {
            @include \kindling\spacing\apply('header.shop-for-bikes.icon', $padding-sides: ('top' 'bottom'));
        }
    }

    .icon-motorcycle {
        @include \kindling\spacing\apply('header.shop-for-bikes.icon', $margin-sides: ('right'));
        @include \kindling\icon\apply($icon: $icon-motorcycle) {
            color: \kindling\color\get('header.shop-for-bikes.text-color-hover');
        }
    }

    .icon-atv {
        @include \kindling\spacing\apply('header.shop-for-bikes.icon', $margin-sides: ('left'));
        @include \kindling\icon\apply($icon: $icon-atv) {
            color: \kindling\color\get('header.shop-for-bikes.text-color-hover');
        }
    }
}

@mixin \project\global\header\bottom\mobile\apply() {
    @include \project\global\modal\brands\apply();

    .page-header-bottom-mobile {
        flex: 1 1 auto;

        @include \kindling\breakpoint\media-query($\compass\header\main\desktop-breakpoint) {
            display: none;
        }

        .shop-for-bikes-mobile-button {
            @include \project\global\header\shop-for-bike\cta\apply();
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            ul {
                margin-right: \kindling\value\rem-calc(10);
                margin-left: \kindling\value\rem-calc(3);
                list-style: none;

                li {
                    @include \kindling\spacing\apply('header.shop-for-bikes.mobile-logo-between', $margin-sides: ('left', 'right'));
                    display: inline-flex;
                }
            }

            a {
                @include \compass\button\reset-browser-button();
                height: 100%;

                &:hover {
                    .content {
                        &::before {
                            color: \kindling\color\get('header.shop-for-bikes.text-color-hover');
                        }
                    }
                }
            }

            .background-image-container,
            .brand-item-title,
            .shop-all-item {
                display: none;
            }

            .brand-item-image-wrapper {
                .brand-item-image {
                    &.header-image {
                        display: none;
                    }

                    &.modal-image {
                        width: \kindling\value\rem-calc(17);

                        @include \kindling\breakpoint\media-query('medium') {
                            width: \kindling\value\rem-calc(30);
                        }
                    }
                }
            }

            .shop-for-bikes-container {
                @include \kindling\spacing\apply('header.shop-for-bikes.mobile.logo', $padding-sides: ('all'));
                flex: 1 1 auto;

                > a {
                    @include \compass\button\primary('regular');
                    @include \kindling\border\apply('shop-for-bikes-mobile');
                    @include \kindling\spacing\apply('reset', $padding-sides: ('top' 'bottom'));
                    @include \kindling\spacing\apply('header.shop-for-bikes.mobile-button-sides', $padding-sides: ('right' 'left'));
                    background: \kindling\color\get('header.shop-for-bikes.tablet');
                    width: 100%;
                    text-decoration: none;
                    color: \kindling\color\get('white');

                    &:hover,
                    &:focus {
                        &:not(:disabled) {
                            &::before {
                                display: none;
                            }

                            &::after {
                                transform: none;
                                color: \kindling\color\get('black');
                            }
                        }
                    }

                    .icon {
                        font-size: \kindling\value\rem-calc(24);

                        @include \kindling\breakpoint\media-query('medium') {
                            font-size: \kindling\value\rem-calc(30);
                        }

                        @include \kindling\breakpoint\media-query('medium' 'down') {
                            @include \kindling\spacing\apply('reset', $padding-sides: ('top', 'bottom'));
                        }
                    }

                    .title {
                        letter-spacing: 0;
                        font-size: \kindling\value\rem-calc(18);

                        @include \kindling\breakpoint\media-query('medium') {
                            letter-spacing: \kindling\value\rem-calc(2);
                            font-size: \kindling\value\rem-calc(16);
                        }
                    }
                }
            }

            .visit-link {
                width: 100%;
            }

            .content {
                @include \kindling\spacing\apply('header.shop-for-bikes.inner', $padding-sides: ('left'));
                display: flex;
                position: relative;
                flex-wrap: wrap;
                align-items: center;
                background: \kindling\color\get('header.shop-for-bikes.tablet');
                width: 100%;
                height: \kindling\value\rem-calc(30);
                text-align: left;
                color: \kindling\color\get('white');

                .text {
                    @include \kindling\icon\apply($icon: $icon-next, $use-before: false) {
                        vertical-align: -25%;
                        font-size: \kindling\value\rem-calc(20);
                    }
                    margin: auto;
                }
            }
        }
    }

    .page-header-bottom-mobile-row {
        height: 100%;
    }
}

@mixin \project\global\header\bottom\left\apply() {
    .header-bottom-logo-container {
        @include \kindling\property\apply('width', $\compass\header\logo-width);
        display: none;
        background-color: \kindling\color\get('header.logo.background-color');

        @include \kindling\breakpoint\media-query($\compass\header\main\desktop-breakpoint) {
            display: flex;
            align-items: center;
        }
    }

    .page-header-bottom-logo-link {
        @include \kindling\spacing\apply('header.bottom-logo', $padding-sides: ('all'));
        display: flex;
        align-items: center;
        height: \kindling\value\rem-calc(66); // clip wordmark which has too much built-in whitespace
        overflow: hidden;
    }

    .page-header-bottom-logo {
        width: 100%;
    }
}

@mixin \compass\global\shop-for-bike\brands\list\apply($image-dimension: \kindling\value\rem-calc(45), $list-spacing: 'header.shop-for-bikes.logo-between') {
    ul {
        @include \kindling\typography\unstyled-list();

        li {
            @include \kindling\spacing\apply($list-spacing, $margin-sides: ('left'));
            display: inline-block;
        }
    }

    .background-image-container {
        display: none;
    }

    .brand-item-link {
        &:not(:hover) {
            .brand-item-image {
                transform: scale(0.82);
            }
        }

        &:focus {
            .brand-item-image {
                transform: scale(1);
            }
        }
    }

    .brand-item-title {
        display: none;
    }

    .brand-item-image-wrapper {
        width: $image-dimension;
    }

    .brand-item-image {
        @include \kindling\transition\apply(transform);
        width: 100%;
        height: $image-dimension;

        &.modal-image {
            display: none;
        }
    }

    .shop-all-item {
        display: none;
    }
}

@mixin \project\global\header\bottom\right\apply() {
    .page-header-bottom-right {
        flex: 1 1 auto;

        @include \kindling\breakpoint\media-query($\compass\header\main\desktop-breakpoint-down down) {
            display: none;
        }

        .shop-for-bikes-brands {
            @include \compass\global\shop-for-bike\brands\list\apply();
            @include \kindling\spacing\apply('header.shop-for-bikes.betweem', $padding-sides: ('left'));
            position: relative;

            &::before {
                @include \kindling\border\apply('header', $sides: ('left'));
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                height: \kindling\value\rem-calc(30);
                content: '';
            }
        }

        .shop-for-bikes-block {
            display: flex;
            align-items: center;
        }

        .shop-for-bikes-container {
            @include \project\global\header\shop-for-bike\cta\apply();
            flex: 1 1 auto;
            text-align: right;

            > a {
                @include \compass\button\apply($style: 'primary', $size: 'regular');
                @include \kindling\border\apply('primary-thick');
                @include \kindling\spacing\apply('reset', $padding-sides: ('top' 'bottom'));
                background: \kindling\color\get('minicart.trigger.background');
                text-decoration: none;
                color: \kindling\color\get('white');

                &:hover,
                &:focus {
                    &:not(:disabled) {
                        &::before {
                            display: none;
                        }

                        &::after {
                            transform: none;
                            color: \kindling\color\get('black');
                        }
                    }
                }
            }
        }
    }
}

@mixin \project\global\header\bottom\apply() {
    @include \project\global\header\bottom\right\apply();
    @include \project\global\header\bottom\left\apply();
    @include \project\global\header\bottom\mobile\apply();

    .page-header-bottom {
        @include \kindling\color\apply-background-color('header.bottom.background-color');
    }

    .page-header-bottom-content {
        @include \compass\layout\container-padded();

        @include \kindling\breakpoint\media-query($\compass\header\main\desktop-breakpoint-down down) {
            @include \kindling\spacing\apply('reset', $padding-sides: ('left', 'right'));
        }
    }

    .page-header-bottom-content-row {
        @include \kindling\grid\row();
        flex-wrap: nowrap;
        align-items: center;
    }
}
