$icon-account: '\e900';
$icon-quote: '\e901';
$icon-triangle-down: '\e902';
$icon-triangle-left: '\e903';
$icon-triangle-right: '\e904';
$icon-triangle-up: '\e905';
$icon-alert: '\e906';
$icon-bookmark: '\e907';
$icon-calendar: '\e908';
$icon-cart: '\e909';
$icon-checkbox-off: '\e90a';
$icon-checkbox-on: '\e90b';
$icon-checkmark-strong: '\e90c';
$icon-chevron-down: '\e90d';
$icon-chevron-left: '\e90e';
$icon-chevron-right: '\e90f';
$icon-chevron-up: '\e910';
$icon-clock: '\e911';
$icon-close: '\e912';
$icon-edit: '\e913';
$icon-email: '\e914';
$icon-expand: '\e915';
$icon-facebook: '\e916';
$icon-gift: '\e917';
$icon-google: '\e918';
$icon-heart-off: '\e919';
$icon-heart-on: '\e91a';
$icon-hours: '\e91b';
$icon-in-stock: '\e91c';
$icon-info: '\e91d';
$icon-instagram: '\e91e';
$icon-linked-in: '\e91f';
$icon-location-pin: '\e920';
$icon-lock-closed: '\e921';
$icon-loop: '\e922';
$icon-menu: '\e923';
$icon-minus: '\e924';
$icon-next: '\e925';
$icon-out-of-stock: '\e926';
$icon-pause: '\e927';
$icon-phone: '\e928';
$icon-pinterest: '\e929';
$icon-play: '\e92a';
$icon-plus: '\e92b';
$icon-previous: '\e92c';
$icon-print: '\e92d';
$icon-question-mark: '\e92e';
$icon-quote1: '\e92f';
$icon-radio-off: '\e930';
$icon-radio-on: '\e931';
$icon-refresh: '\e932';
$icon-return: '\e933';
$icon-search: '\e934';
$icon-share: '\e935';
$icon-star-off: '\e936';
$icon-star-on: '\e937';
$icon-stop: '\e938';
$icon-success: '\e939';
$icon-tag: '\e93a';
$icon-trash: '\e93b';
$icon-triangle-down1: '\e93c';
$icon-triangle-left1: '\e93d';
$icon-triangle-right1: '\e93e';
$icon-triangle-up1: '\e93f';
$icon-truck: '\e940';
$icon-tumblr: '\e941';
$icon-twitter: '\e942';
$icon-vimeo: '\e943';
$icon-error: '\e944';
$icon-lock-open: '\e945';
$icon-download: '\e946';
$icon-youtube: '\e947';
$icon-zoom-in: '\e948';
$icon-zoom-out: '\e949';
$icon-home: '\e94a';
$icon-arrow-down: '\e94b';
$icon-arrow-left: '\e94c';
$icon-arrow-right: '\e94d';
$icon-arrow-up: '\e94e';
$icon-atv: '\e94f';
$icon-dirtbike: '\e950';
$icon-double-arrow-left: '\e951';
$icon-double-arrow-right: '\e952';
$icon-garage: '\e953';
$icon-maplesleaf: '\e954';
$icon-motorcycle: '\e955';
$icon-parts: '\e956';
$icon-snowmobile: '\e957';
$icon-watersports: '\e958';
$icon-ebikes: '\e960';

$icon-selectors: (
    'icon-account': $icon-account,
    'icon-quote': $icon-quote,
    'icon-triangle-down': $icon-triangle-down,
    'icon-triangle-left': $icon-triangle-left,
    'icon-triangle-right': $icon-triangle-right,
    'icon-triangle-up': $icon-triangle-up,
    'icon-alert': $icon-alert,
    'icon-bookmark': $icon-bookmark,
    'icon-calendar': $icon-calendar,
    'icon-cart': $icon-cart,
    'icon-checkbox-off': $icon-checkbox-off,
    'icon-checkbox-on': $icon-checkbox-on,
    'icon-checkmark-strong': $icon-checkmark-strong,
    'icon-chevron-down': $icon-chevron-down,
    'icon-chevron-left': $icon-chevron-left,
    'icon-chevron-right': $icon-chevron-right,
    'icon-chevron-up': $icon-chevron-up,
    'icon-clock': $icon-clock,
    'icon-close': $icon-close,
    'icon-edit': $icon-edit,
    'icon-email': $icon-email,
    'icon-expand': $icon-expand,
    'icon-facebook': $icon-facebook,
    'icon-gift': $icon-gift,
    'icon-google': $icon-google,
    'icon-heart-off': $icon-heart-off,
    'icon-heart-on': $icon-heart-on,
    'icon-hours': $icon-hours,
    'icon-in-stock': $icon-in-stock,
    'icon-info': $icon-info,
    'icon-instagram': $icon-instagram,
    'icon-linked-in': $icon-linked-in,
    'icon-location-pin': $icon-location-pin,
    'icon-lock-closed': $icon-lock-closed,
    'icon-loop': $icon-loop,
    'icon-menu': $icon-menu,
    'icon-minus': $icon-minus,
    'icon-next': $icon-next,
    'icon-out-of-stock': $icon-out-of-stock,
    'icon-pause': $icon-pause,
    'icon-phone': $icon-phone,
    'icon-pinterest': $icon-pinterest,
    'icon-play': $icon-play,
    'icon-plus': $icon-plus,
    'icon-previous': $icon-previous,
    'icon-print': $icon-print,
    'icon-question-mark': $icon-question-mark,
    'icon-quote1': $icon-quote1,
    'icon-radio-off': $icon-radio-off,
    'icon-radio-on': $icon-radio-on,
    'icon-refresh': $icon-refresh,
    'icon-return': $icon-return,
    'icon-search': $icon-search,
    'icon-share': $icon-share,
    'icon-star-off': $icon-star-off,
    'icon-star-on': $icon-star-on,
    'icon-stop': $icon-stop,
    'icon-success': $icon-success,
    'icon-tag': $icon-tag,
    'icon-trash': $icon-trash,
    'icon-triangle-down1': $icon-triangle-down1,
    'icon-triangle-left1': $icon-triangle-left1,
    'icon-triangle-right1': $icon-triangle-right1,
    'icon-triangle-up1': $icon-triangle-up1,
    'icon-truck': $icon-truck,
    'icon-tumblr': $icon-tumblr,
    'icon-twitter': $icon-twitter,
    'icon-vimeo': $icon-vimeo,
    'icon-error': $icon-error,
    'icon-lock-open': $icon-lock-open,
    'icon-download': $icon-download,
    'icon-youtube': $icon-youtube,
    'icon-zoom-in': $icon-zoom-in,
    'icon-zoom-out': $icon-zoom-out,
    'icon-home': $icon-home,
    'icon-arrow-down': $icon-arrow-down,
    'icon-arrow-left': $icon-arrow-left,
    'icon-arrow-right': $icon-arrow-right,
    'icon-arrow-up': $icon-arrow-up,
    'icon-atv': $icon-atv,
    'icon-dirtbike': $icon-dirtbike,
    'icon-double-arrow-left': $icon-double-arrow-left,
    'icon-double-arrow-right': $icon-double-arrow-right,
    'icon-garage': $icon-garage,
    'icon-maplesleaf': $icon-maplesleaf,
    'icon-motorcycle': $icon-motorcycle,
    'icon-parts': $icon-parts,
    'icon-snowmobile': $icon-snowmobile,
    'icon-watersports': $icon-watersports
);
