@mixin \project\cms\browse-our-inventory\apply() {
    .cms-browse-our-inventory { // sass-lint:disable-block class-name-format
        background: \kindling\color\get('thunder');

        .browse-background {
            @include \kindling\spacing\apply('browse-inventory', $padding-sides: ('left' 'right'));
            position: absolute;
            background: url('/media/wysiwyg/cms/5/0/503-hero-desktop.jpg') no-repeat center center fixed;
            width: 100%;
            height: 100%;

            h2 {
                @include \kindling\spacing\apply('base.xsmall', $padding-sides: ('all'));
                background: \kindling\color\get('thunder');
                color: \kindling\color\get('white');
            }
        }

        .cms-grid {
            @include \kindling\grid\row(
                $column-gutters: \kindling\spacing\get('grid-gutters'),
                $vertical-gutters: true
            );

            .cms-grid-item {
                img {
                    @include \kindling\border\apply('secondary-thick');

                    width: 100%;
                }

                .cms-grid-item-inner {
                    height: 100%;
                }
            }
        }

        [data-grid-items='cms-grid-container-2-item'] {
            .cms-grid-item {
                @include \kindling\grid\column(
                    $responsive-sizes: (
                        'small': 100%,
                        'large': \kindling\grid\column-width-calc(6)
                    ),
                    $flex-grow: 0
                );
            }
        }

        [data-grid-items='cms-grid-container-3-item'] {
            .cms-grid-item {
                @include \kindling\grid\column(
                    $responsive-sizes: (
                        'small': 100%,
                        'large': \kindling\grid\column-width-calc(4)
                    ),
                    $flex-grow: 0
                );
            }
        }

        [data-grid-items='cms-grid-container-4-item'] {
            .cms-grid-item {
                @include \kindling\grid\column(
                    $responsive-sizes: (
                        'small': 100%,
                        'large': \kindling\grid\column-width-calc(3)
                    ),
                    $flex-grow: 0
                );
            }
        }

        [data-grid-items='cms-grid-container-5-item'] {
            .cms-grid-item {
                @include \kindling\grid\column(
                    $responsive-sizes: (
                        'small': 100%,
                        'large': 20%
                    ),
                    $flex-grow: 0
                );
            }
        }

        #chat-widget-container, // sass-lint:disable-block no-ids
        #livechat-eye-catcher {
            display: none;
        }
    }
}
