@mixin \project\accordion\mini-garage\container() {
    @include \compass\accordion\container('accordion.mini-garage');
}

@mixin \project\accordion\mini-garage\item() {
    @include \compass\accordion\item('accordion.mini-garage');
}

@mixin \project\accordion\mini-garage\title() {
    @include \compass\accordion\title('accordion.mini-garage');
    display: flex; // IE 11 bug fix - unwanted white space
}

@mixin \project\accordion\mini-garage\body() {
    @include \compass\accordion\body('accordion.mini-garage', ('right', 'bottom', 'left'));
}
