@mixin \compass\dropdown\shadow() {
    box-shadow: \kindling\value\rem-calc(0 4 6 0) rgba(\kindling\color\get('dropdown.default.content.box-shadow'), 0.35);
}

@mixin \compass\dropdown\content\apply($max-width: $min-content-width) {
    @include \kindling\dropdown\apply($include-triangle: false, $include-padding: false);
    @include \compass\dropdown\shadow();
    // Can have inline relative styles occasionally, so we force important
    position: absolute !important; // sass-lint:disable-line no-important
    align-items: center;
    z-index: \kindling\z-index\get('above-sibling');
    background-color: \kindling\color\get('dropdown.default.content.background');
    max-width: $min-content-width;
    color: \kindling\color\get('dropdown.default.content.text');

    .dropdown-content {
        min-width: 100%;
    }
}

@mixin \compass\dropdown\trigger\apply($font-size: 'dropdown.default.trigger.icon', $color: 'inherit') {
    @include \compass\button\reset-browser-button();
    @include \kindling\icon\apply($icon: $icon-triangle-down, $use-before: false) {
        @include \kindling\typography\font-size($font-size);
        vertical-align: 0;
        color: \kindling\color\get($color);
    }

    &.hover {
        @include \kindling\icon\selector($use-before: false) {
            content: $icon-triangle-up;
        }
    }
}

@mixin \compass\dropdown\content\padding($context: $\compass\dropdown\default\context) {
    $padding: \kindling\spacing\context-keys-calc('content.inner', $context, $\compass\dropdown\default\context);

    @include \kindling\spacing\apply($padding, $padding-sides: ('all'));
}

@mixin \compass\dropdown\list\styles(
    $icon: null,
    $max-height: null
) {
    @include \compass\menu\link-list\apply($icon: $icon);

    @if $max-height != null {
        @include \compass\scroll-bar\apply();
        max-height: $max-height;
    }
}

@mixin \compass\dropdown\actions-dropdown($action-selector: 'button', $dropdown-selector: 'ul', $actions-spacing: ('small': \kindling\value\rem-calc(5 10))) {
    position: relative;

    > #{$action-selector} {
        @include \compass\dropdown\trigger\apply($font-size: 'inherit', $color: 'dropdown.action-dropdown.icon');
    }

    > #{$dropdown-selector} {
        @include \kindling\typography\unstyled-list();
        @include \compass\dropdown\content\apply();
        @include \kindling\border\apply('dropdown.action-dropdown.content');
        width: 100%;
        min-width: auto;

        a {
            @include \kindling\property\apply('padding', $actions-spacing);
            display: block;
        }
    }
}
