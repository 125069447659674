@mixin \compass\toolbar\container($breakpoint: $\compass\toolbar\breakpoint) {
    @include \compass\section(
        $context: 'section.medium',
        $border-sides: ('top', 'bottom')
    );
    @include \compass\typography\font\apply('typography.copy.small');
    display: flex;
    position: relative;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    @include \kindling\breakpoint\media-query($breakpoint) {
        flex-wrap: nowrap;
    }
}

@mixin \compass\toolbar\page-count($breakpoint: $\compass\toolbar\breakpoint) {
    @include \kindling\spacing\apply(
        $spacing: 'toolbar.default.page-count',
        $margin-sides: ('right')
    );
    display: none;

    @include \kindling\breakpoint\media-query($breakpoint) {
        display: block;
        margin-left: auto;
    }

    &.only-child {
        @include \kindling\breakpoint\media-query($breakpoint) {
            margin-left: 0;
        }
    }
}

@mixin \compass\toolbar\pagination() {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;

    .button-previous,
    .button-next {
        @include \compass\button\secondary('micro');
        flex: 0 0 auto;
    }

    .button-next {
        @include \kindling\spacing\apply(
            $spacing: $\compass\button\grid-row\spacing,
            $margin-sides: ('left')
        );
    }
}

@mixin \compass\toolbar\dropdown\apply() {
    @include \compass\toolbar\dropdown\apply();
    flex: 0 0 auto;
}

@mixin \compass\toolbar\dropdown\apply() {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    .page-nav-text {
        @include \compass\dropdown-select\trigger-label();
    }

    .dropdown-button {
        @include \compass\dropdown-select\trigger\apply('micro');
        @include \kindling\spacing\apply(
            $spacing: 'button-grid.default',
            $margin-sides: ('left')
        );
    }

    .pagination-dropdown {
        @include \compass\dropdown-select\content\apply();
        min-width: \kindling\value\rem-calc(100);
    }

    .page-link-list {
        @include \compass\dropdown-select\list\apply($max-height: \kindling\value\rem-calc(250));
    }
}

@mixin \compass\toolbar\apply($breakpoint: $\compass\toolbar\breakpoint) {
    @include \compass\toolbar\container($breakpoint);

    .page-nav {
        @include \compass\toolbar\dropdown\apply();
    }

    .page-count {
        @include \compass\toolbar\page-count($breakpoint);
    }

    .toolbar-pagination {
        @include \compass\toolbar\pagination();
    }
}
