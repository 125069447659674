@mixin \project\fitment\mini-garage\accordion-featured\apply() {
    @include \project\fitment\mini-garage\accordion\apply();

    .mini-garage-accordion-item {
        overflow: visible;
    }

    .mini-garage-accordion-body {
        @include \compass\dropdown\shadow();
        position: absolute;
        top: 100%;
        left: 0;
        z-index: \kindling\z-index\get('above-sibling');
        width: 100%;
    }
}

@mixin \project\fitment\mini-garage\empty-message-featured\apply() {
    @include \project\fitment\mini-garage\empty-message\apply();

    .garage-empty-list-title {
        display: none;
    }
}
