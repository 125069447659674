@mixin \customer\account\table() {
    .table-wrapper {
        @include \compass\responsive-table\apply();
        @include \kindling\spacing\apply($spacing: 'global.xsmall', $padding-sides: ('top', 'bottom'), $ratio: 0.5);

        .table-caption {
            @include \kindling\visually-hidden\apply();
        }

        .action {
            display: inline-flex;
            text-decoration: none;

            &:hover,
            &:focus {
                span {
                    text-decoration: underline;
                }
            }

            &:not(:first-child) {
                margin-left: \kindling\value\rem-calc(10);
            }

            &.view {
                @include \kindling\icon\apply($icon: $icon-cart) {
                    margin-right: \kindling\value\rem-calc(5);
                }
            }

            &.order {
                @include \kindling\icon\apply($icon: $icon-refresh) {
                    margin-right: \kindling\value\rem-calc(2);
                }
            }
        }
    }

    .order-products-toolbar {
        border: 0;
    }
}
