@mixin \customer\account\header\list() {
    @include \kindling\typography\unstyled-list();

    li {
        @include \kindling\typography\font-size('typography.copy.small');

        &:not(:last-child) {
            @include \kindling\spacing\apply('base.xsmall', $margin-sides: ('bottom'));
        }
    }
}

@mixin \customer\account\header\content-desktop() {
    .my-account-dropdown-container {
        .dropdown-content {
            .menu-header {
                .menu-header-text {
                    @include \kindling\icon\selector() {
                        content: $icon-account;
                    }
                }
            }

            .myaccount-authorization-desktop-link-container {
                @include \kindling\spacing\apply('menu.account.top-bar', $padding-sides: ('all'));
                margin-top: 1px; // sass-lint:disable-line property-units
                background-color: \kindling\color\get('menu.toolbar.header.background');
                text-align: center;

                .authorization-link-top-bar {
                    @include \compass\button\primary('small');
                    width: 100%;
                }

                .authorization-desktop-register {
                    @include \kindling\spacing\apply('base.small', $margin-sides: ('top'));
                    @include \kindling\typography\font-size('typography.copy.micro');
                }

                .authorization-listlink {
                    display: none;
                }
            }

            .myaccount-dropdown-links {
                @include \customer\account\header\list();
                @include \kindling\spacing\apply('menu.toolbar.items', $padding-sides: ('all'));

                .auth-link {
                    display: none;
                }
            }
        }
    }
}

@mixin \customer\account\header\content-mobile() {
    .myaccount-authorization-desktop-link-container {
        display: none;
    }

    .myaccount-dropdown-links {
        @include \customer\account\header\list();
        @include \kindling\spacing\apply(
            $spacing: 'menu.mobile-mega-menu.items',
            $padding-sides: ('all')
        );

        .authorization-link-top-bar,
        .authorization-desktop-register {
            display: none;
        }
    }
}
