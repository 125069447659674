@mixin \customer\account\logout\apply() {
    body {
        &.customer-account-logoutsuccess {
            @include \compass\components\page-title();

            .page-main-content {
                .action {
                    @include \compass\button\next-button();
                }
            }
        }
    }
}
