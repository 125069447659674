@mixin \project\wishlist\page\apply() {
    .wishlist-index-index {
        @include \project\wishlist\item-list();

        .wishlist-toolbar {
            ~ .wishlist-toolbar {
                display: none;
            }
        }

        .form-wishlist-items {
            .actions-toolbar {
                .primary {
                    @include \compass\grid\row(('small': \kindling\value\rem-calc(20)), $spacing-type: 'margin');
                    flex: 1 1 auto;
                }

                .secondary {
                    display: none;
                }
            }
        }
    }
}
