$\compass\layout\panel-main\widths: (
    'small': 100%,
    'large': 40%
);

$\compass\layout\panel-left\widths: (
    'small': 100%,
    'large': 33.3%,
);

$\compass\layout\panel-right\widths: (
    'small': 100%,
    'large': 33.3%,
);

$\compass\layout\container\column-count: (
    'small': 12
);
