$\compass\mega-menu\mobile\side: 'left';
$\project\mega-menu\mobile\category-icon-size: \kindling\value\rem-calc(25);

$\compass\mega-menu\desktop\breakpoint: $\compass\header\main\desktop-breakpoint;
$\compass\mega-menu\desktop\breakpoint-down: $\compass\mega-menu\desktop\breakpoint - \kindling\value\rem-calc(1);
$\compass\mega-menu\desktop\level-1-width: 100%;
$\compass\mega-menu\desktop\level-1-spacing: \kindling\value\rem-calc(20 30);
$\compass\mega-menu\desktop\level-2-image-width: \kindling\value\rem-calc(30);
$\compass\mega-menu\desktop\level-2-image-spacing: \kindling\value\rem-calc(10);
$\compass\mega-menu\desktop\level-2-image-total-space: $\compass\mega-menu\desktop\level-2-image-width + $\compass\mega-menu\desktop\level-2-image-spacing;
