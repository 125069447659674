@mixin \compass\sales\order\details\apply() {
    @include \kindling\spacing\apply($spacing: 'global.medium', $padding-sides: ('all'));
    @include \kindling\border\apply('light-thick');

    .order-title {
        @include \kindling\typography\margin('typography.h4');
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        justify-content: space-between;

        strong {
            @include \kindling\typography\heading\h4();
            flex-grow: 1;
            width: 100%;

            @include \kindling\breakpoint\media-query('large') {
                width: auto;
            }
        }

        .action.print {
            @include \compass\button\icon-button(
                $icon: $icon-print,
                $style: 'secondary',
                $size: 'micro'
            );
        }

        .action.track {
            @include \compass\button\icon-button(
                $icon: $icon-truck,
                $style: 'secondary',
                $size: 'micro'
            );

            @include \kindling\breakpoint\media-query('large') {
                @include \kindling\spacing\apply($spacing: 'grid-gutters', $margin-sides: ('left'), $ratio: 0.5);
            }
        }
    }

    .table-wrapper {
        overflow-x: auto;
    }

    .table-order-items {
        @include \kindling\typography\font-size('typography.copy.small');
        width: 100%;
        border-collapse: collapse;

        .table-caption {
            @include \kindling\visually-hidden\apply();
        }

        tfoot {
            display: table;
            background-color: \kindling\color\get('neutral-lighter');
            width: 100%;
            text-align: left;

            tr {
                &:first-of-type {
                    th {
                        @include \kindling\spacing\apply($spacing: 'grid-gutters', $padding-sides: ('top'), $ratio: 0.5);
                    }
                }

                &:not(:last-of-type) {
                    th {
                        @include \compass\typography\font\apply('primary', 'normal');
                    }
                }

                &:last-of-type {
                    th {
                        @include \kindling\spacing\apply($spacing: 'grid-gutters', $padding-sides: ('bottom'), $ratio: 0.5);
                    }
                }
            }

            th {
                @include \kindling\spacing\apply($spacing: 'global.medium', $padding-sides: ('left'));
                @include \kindling\spacing\apply($spacing: 'grid-gutters', $padding-sides: ('top', 'bottom'), $ratio: 0.25);
                text-align: left;
                white-space: nowrap;
            }

            td {
                &.amount {
                    @include \kindling\spacing\apply($spacing: 'global.medium', $padding-sides: ('right'));
                    text-align: right;
                }
            }
        }
    }

    .item-renderer {
        @include \customer\account\box\container();
        @include \kindling\border\apply('light-thick');

        table {
            @include \kindling\typography\font-size('typography.copy.small');

            tr {
                td {
                    &:first-child {
                        padding-right: \kindling\value\rem-calc(5);
                    }
                }
            }
        }

        .cart-price {
            @include \catalog\price\apply();
            @include \kindling\spacing\apply('base.xxsmall', $margin-sides: ('bottom'));
            display: block;
        }
    }

    .item-renderer-inner {
        @include \customer\account\box\body();

        @each $breakpoint, $value in \kindling\spacing\get('box.account.section') {
            @include \kindling\breakpoint\media-query($breakpoint) {
                padding-top: 0;
            }
        }
    }

    .product-item-name {
        @include \customer\account\box\header();

        @include \kindling\breakpoint\media-query('large') {
            padding-bottom: \kindling\breakpoint\get-breakpoint-value(\kindling\spacing\get('base.xxsmall'), 'small');
        }
    }

    .actions-toolbar {
        display: none;
    }
}

@mixin \compass\sales\order\item-details\apply() {
    @include \kindling\spacing\apply($spacing: 'base.small', $padding-sides: ('top'));

    .block-content {
        @include \customer\account\boxes\apply();
        @include \kindling\grid\row(
            $vertical-gutters: true
        );

        .box {
            @include \kindling\grid\column(
                $responsive-sizes: (
                    'small': \kindling\grid\column-width-calc(12),
                    'large': \kindling\grid\column-width-calc(6),
                    'xlarge': \kindling\grid\column-width-calc(3)
                )
            );

            .box-content {
                flex-grow: 1;
            }
        }
    }
}
