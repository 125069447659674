@mixin \project\multi-wishlist\wishlist-modals\apply() { // sass-lint:disable-block no-ids
    #edit-wishlist-form {
        @include \compass\forms\apply();
    }

    #edit-wishlist-form,
    #create-wishlist-form {
        .actions-toolbar {
            @include \kindling\spacing\apply('wishlist.modal.toolbar', $margin-sides: ('top'));
            @include \kindling\grid\row();
            align-items: center;
            justify-content: space-between;

            .primary {
                @include \customer\account\form\button();
            }

            .secondary {
                @include \customer\account\form\button();
            }
        }
    }
}
