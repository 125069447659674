@mixin \compass\rma\sales\guest\form\apply() {
    body {
        &.sales-guest-form {
            @include \compass\components\page-title();

            #oar-widget-orders-and-returns-form { // sass-lint:disable-line no-ids
                > .fieldset {
                    @include \compass\grid\fieldset();
                }

                .actions-toolbar {
                    @include \kindling\spacing\apply($spacing: 'base.medium', $margin-sides: ('top'));
                }
            }
        }
    }
}
