@import 'components/autocomplete.scss';

@mixin \compass\search\apply() {
    .block-search {
        @include \compass\search\autocomplete();
        flex: 0 1 auto;
        width: 100%;

        .block-content {
            z-index: \kindling\z-index\get('neutral-sibling');

            @include \kindling\breakpoint\media-query($\compass\search\header\desktop-breakpoint) {
                position: relative;
            }
        }

        .minisearch {
            $width: (
                'small': 100%,
                $\compass\header\main\desktop-breakpoint: calc(100% - #{\project\simple-map-get($\compass\header\logo-width, $\compass\header\main\desktop-breakpoint)}),
                $\compass\header\main\max-logo-breakpoint: calc(100% - #{\project\simple-map-get($\compass\header\logo-width, $\compass\header\main\max-logo-breakpoint)}),
                $\compass\search\header\desktop-breakpoint: 100%
            );
            @include \kindling\property\apply('width', $width);
            position: relative;
            right: 0;

            @include \kindling\breakpoint\media-query($\compass\search\header\desktop-breakpoint-down 'down') {
                @include \kindling\spacing\apply('form.search.mobile-container.small', $padding-sides: ('top', 'bottom', 'left'));
                @include \kindling\color\apply-background-color('white');
                display: flex;
                position: absolute;
                top: 0;
                flex-direction: column;
                justify-content: center;
                z-index: \kindling\z-index\get('above-sibling');
                height: 100%;

                &:not(.is-active) {
                    display: none;
                }
            }

            @include \kindling\breakpoint\media-query($\compass\search\header\tablet-breakpoint 'down') {
                @include \kindling\spacing\apply('form.search.mobile-container.small', $padding-sides: ('all'));
                @include \kindling\color\apply-background-color('form.search.mobile-container');
                left: 0;
                box-shadow: \kindling\value\rem-calc(0 4 6 0) rgba(\kindling\color\get('form.search.mobile-container-shadow'), 0.35);
            }

            .search-input {
                @include \kindling\spacing\apply('form.search.input.padding', $padding-sides: ('top', 'bottom', 'right'));
                @include \kindling\typography\font-size('form.search.input');
                @include \kindling\typography\line-height('form.search.input');
                @include \kindling\button\border('form.search.default');
                border: 0;
                border-radius: 0;
                background-color: \kindling\color\get('form.search.input.background-color');
                padding-left: calc(#{$\compass\search\header\button-width} + #{\kindling\value\rem-calc(2)});
                width: 100%;
                color: \kindling\color\get('form.search.input.text-color');
                appearance: none;

                &:focus,
                &:hover {
                    @include \kindling\button\border('form.search.active');
                    outline: 0;
                }

                &::placeholder {
                    @include \kindling\typography\font-weight('form.search.placeholder');
                    color: \kindling\color\get('form.search.input.placeholder-color');
                }
            }

            .search-action {
                $border-width: \kindling\border\get-property(\kindling\border\get('form.search.active'), 'width');

                @include \compass\button\reset-browser-button();
                @include \kindling\color\apply-background-color('form.search.input.background-color', 'form.search.input.text-color');

                display: flex;
                position: absolute;
                top: $border-width;
                left: $border-width;
                align-items: center;
                justify-content: center;
                z-index: \kindling\z-index\get('above-sibling');
                width: $\compass\search\header\button-width;
                height: calc(100% - #{$border-width * 2});
                text-align: center;

                @include \kindling\breakpoint\media-query($\compass\search\header\desktop-breakpoint-down 'down') {
                    top: 50%;
                    left: \kindling\value\rem-calc(40);
                    transform: translateY(-50%);
                    background-color: \kindling\color\get('transparent');
                    height: auto;
                }

                &:hover,
                &:focus {
                    .search-icon {
                        color: \kindling\color\get('form.search.mobile.submit-icon-color-hover');
                    }
                }

                &:disabled {
                    pointer-events: none;
                }

                .search-icon {
                    @include \kindling\icon\apply($icon: $icon-search) {
                        @include \kindling\typography\font-size('form.search.icon');
                    }
                    width: 100%;
                    color: \kindling\color\get('form.search.mobile.submit-icon-color');

                    &::before {
                        vertical-align: 0;
                    }
                }
            }
        }
    }

    .mobile-search-toggle {
        @include \compass\button\reset-browser-button();
        @include \kindling\icon\apply($icon: $icon-search) {
            @include \kindling\typography\font-size('form.search.mobile-toggle');
            line-height: 1;

            @include \kindling\breakpoint\media-query($\compass\header\main\desktop-breakpoint) {
                @include \kindling\breakpoint\media-query($\compass\header\main\max-logo-breakpoint-down 'down') {
                    font-size: \kindling\value\rem-calc(20);
                }
            }
        }

        @include \kindling\breakpoint\media-query($\compass\search\header\desktop-breakpoint) {
            display: none;
        }
    }

    .mobile-search-close {
        @include \compass\button\reset-browser-button();
        @include \kindling\icon\apply($icon: $icon-error) {
            @include \kindling\typography\font-size('form.search.mobile-toggle');
        }
        position: absolute;
        top: 50%;
        right: \kindling\value\rem-calc(7);
        transform: translateY(-50%);
        z-index: \kindling\z-index\get('above-next-sibling');
        line-height: 1;
        color: \kindling\color\get('form.search.mobile.close-icon-color');

        @include \kindling\breakpoint\media-query($\compass\search\header\desktop-breakpoint) {
            display: none;
        }

        @include \kindling\breakpoint\media-query($\compass\search\header\tablet-breakpoint 'down') {
            right: calc(#{\kindling\value\rem-calc(7)} + #{\kindling\value\get-side(\kindling\spacing\get('form.search.mobile-container.small'), 'right')});
        }

        &:hover,
        &:focus {
            color: \kindling\color\get('form.search.mobile.close-icon-color-hover');
        }
    }
}
