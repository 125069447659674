@mixin \project\delivery-timelines\table\apply() {
    border-collapse: collapse;

    > tbody {
        > tr {
            &:not(.product-info-option-group-delivery-timeline) {
                @include \kindling\border\apply('delivery-timeline.row-spacing', $sides: ('top', 'bottom'));
            }
        }
    }

    .product-info-option-group-delivery-timeline {
        &:not(:first-child) {
            @include \kindling\border\apply('delivery-timeline.extra-spacing', $sides: ('top'));
        }

        > * {
            @include \kindling\spacing\apply('delivery-timeline.inner', $padding-sides: ('all'));
            background-color: \kindling\color\get('delivery-timeline.background');

            &:first-child {
                @include \compass\typography\font\apply($font-stack: 'primary', $weight: 'semi-bold');
            }
        }
    }
}
