@mixin \project\wishlist\item() {
    .product-item-info {
        $image-area: \compass\grid\area-name('image');
        $name-area: \compass\grid\area-name('name');
        $price-area: \compass\grid\area-name('price');
        $options-area: \compass\grid\area-name('options');
        $content-area: \compass\grid\area-name('content');

        $responsive-columns: (
            'small': \kindling\value\rem-calc(80) auto,
            'medium': \kindling\value\rem-calc(100) auto,
            'large': \kindling\value\rem-calc(155) auto
        );
        $responsive-areas: (
            'small': '#{$image-area} #{$name-area}' '#{$image-area} #{$price-area}' '#{$image-area} #{$options-area}' '#{$image-area} #{$content-area}',
            'medium': '#{$image-area} #{$name-area}' '#{$image-area} #{$price-area}' '#{$image-area} #{$options-area}' '#{$image-area} #{$content-area}',
            'large': '#{$image-area} #{$name-area}' '#{$image-area} #{$price-area}' '#{$image-area} #{$options-area}' '#{$image-area} #{$content-area}'
        );
        $grid-gap: (
            'small': \kindling\value\rem-calc(20),
            'medium': \kindling\value\rem-calc(20),
            'large': \kindling\value\rem-calc(30),
        );

        display: grid;
        position: relative;

        //Grid properties must be implemented together so the Autoprefixer can properly prefix the values.
        @each $breakpoint, $value in $breakpoints {
            @include \kindling\breakpoint\media-query($breakpoint) {
                grid-template-columns: \kindling\breakpoint\get-breakpoint-value($responsive-columns, $breakpoint);
                grid-template-areas: \kindling\breakpoint\get-breakpoint-value($responsive-areas, $breakpoint);
                grid-column-gap: \kindling\breakpoint\get-breakpoint-value($grid-gap, $breakpoint);
            }
        }

        > * {
            &:not(:last-child) {
                @include \kindling\spacing\apply('wishlist.list.item.inner', $margin-sides: ('bottom'));
            }
        }

        .product-item-photo {
            grid-area: $image-area;
        }

        .product-item-name {
            @include \compass\typography\heading('h5');
            padding-right: \kindling\value\rem-calc(20);
            grid-area: $name-area;

            a {
                text-decoration: none;
            }
        }

        .price-box {
            @include \catalog\price\apply('price', 'product-price.product-list');
            grid-area: $price-area;
        }

        .item-options {
            grid-area: $options-area;

            .product-info-option-group {
                @include \kindling\typography\font-size('typography.copy.small')
            }

            .product-info-option-label {
                @include \compass\typography\font\apply('primary', 'normal');
                @include \kindling\spacing\apply('wishlist.list.item.options', $padding-sides: ('right'));
                width: \kindling\value\rem-calc(130);
                text-align: left;
            }

            .product-info-option-value {
                vertical-align: bottom;
            }
        }

        .product-item-inner {
            @include \compass\forms\apply();
            grid-area: $content-area;

            > .product-item-actions {
                @include \compass\grid\row(('small': \kindling\value\rem-calc(10)));
                @include \kindling\spacing\apply('wishlist.list.item.actions', $padding-sides: ('top'));
                align-items: center;

                .action {
                    @include \kindling\typography\font-size('typography.copy.small');
                    order: -1;
                    text-decoration: none;

                    &.edit {
                        @include \kindling\icon\apply($icon: $\customer\account\icon\edit-action);
                    }

                    &.delete {
                        @include \kindling\icon\apply($icon: $\customer\account\icon\delete-action);
                    }

                    > span {
                        text-decoration: underline;
                    }
                }

                .wishlist-item-management {
                    @include \compass\grid\row(('small': \kindling\value\rem-calc(10)), $spacing-type: 'margin');
                    width: 100%;
                }
            }
        }

        .box-tocart {
            .fieldset {
                display: flex;
                align-items: center;
            }

            .field {
                margin-right: \kindling\value\rem-calc(10);
                margin-bottom: 0;

                &.is-initialized {
                    &:not(.is-empty) {
                        [type='number'] {
                            @include \compass\form\input\border('form.default.complete', $use-inset: true);
                        }
                    }
                }

                [type='number'] {
                    @include \compass\form\input\border($use-inset: true);
                    width: \kindling\value\rem-calc(60);
                }
            }

            .tocart {
                @include \compass\button\primary();
            }
        }

        .product-item-checkbox {
            position: absolute;
            top: 0;
            right: 0;

            input {
                @include \kindling\form\checkbox();

                &:not(:last-child) {
                    + * {
                        @include \kindling\icon\selector() {
                            margin: 0;
                        }
                    }
                }

                + label {
                    line-height: 1;
                }
            }
        }
    }
}

@mixin \project\wishlist\item-list() {
    .product-items {
        @include \kindling\typography\unstyled-list();
        @include \project\wishlist\item();

        .product-item {
            @include \compass\section($border-sides: ('bottom'));
        }
    }
}
