@mixin \compass\dropdown-select\trigger-label() {
    flex: 0 0 auto;
}

@mixin \compass\dropdown-select\trigger\apply($size: 'default') {
    @include \compass\button\reset-browser-button();
    @include \kindling\form\select(
        $padding: 'input#{if($size == 'default', '', '-#{$size}')}.padding',
        $font-size: 'input#{if($size == 'default', '', '-#{$size}')}',
        $width: null,
        $height-calc: false,
        $option-color: 'not-set',
        $option-background-color: 'not-set',
        $option-hover-color: 'not-set',
        $option-hover-background-color: 'not-set',
        $option-active-color: 'not-set',
        $option-active-background-color: 'not-set'
    );
}

@mixin \compass\dropdown-select\content\apply($max-width: $min-content-width) {
    @include \compass\dropdown\content\apply();
}

@mixin \compass\dropdown-select\list\apply(
    $icon: null,
    $max-height: null
) {
    @include \compass\dropdown\list\styles($icon, $max-height);
}
