@mixin \compass\accordion\container($context: $\kindling\accordion\default-context) {
    @include \kindling\accordion\container($context);
}

@mixin \compass\accordion\item(
    $context: $\kindling\accordion\default-context,
    $margin: 'item',
    $border: 'item',
    $border-sides: ('all')
) {
    $margin: \kindling\spacing\context-keys-calc($margin, $context, $\kindling\accordion\default-context);
    $border: \kindling\border\context-keys-calc($border, $context, $\kindling\accordion\default-context);

    @include \kindling\accordion\item($context);
    @include \kindling\border\apply($border, $sides: $border-sides);

    &:not(:first-child) {
        border-top: 0;
    }

    &:not(:last-child) {
        @include \kindling\spacing\apply($margin, $margin-sides: ('bottom'));
    }
}

@mixin \compass\accordion\title($context: $\kindling\accordion\default-context) {
    @include \kindling\accordion\title(
        $context: $context,
        $text-align: left,
        $justify-content: left,
        $expand-icon: $icon-plus,
        $collapse-icon: $icon-minus
    );
    @include \kindling\spacing\apply('accordion.default.extra-icon-padding', $padding-sides: ('right'));
}

@mixin \compass\accordion\body(
    $context: $\kindling\accordion\default-context,
    $border-sides: ('all')
) {
    @include \kindling\accordion\body(
        $context: $context,
        $border-sides: $border-sides
    );
}

@mixin \compass\accordion\overlay-button() {
    @include \compass\button\reset-browser-button();
    position: absolute;
    top: 0;
    left: 0;
    z-index: \kindling\z-index\get('neutral-sibling');
    width: 100%;
    height: 100%;
}

@mixin \compass\accordion\above-overlay-button() {
    position: relative;
    z-index: \kindling\z-index\get('above-sibling');
}

// Disable accordion button
//
// For this to work properly the expand/collapse button must be a child element
// of a div which has title styles applied. This is because of the fact that
// elements are still focusable with pointer-events: none.
@mixin \compass\accordion\disable-button() {
    pointer-events: none;

    &::before {
        content: none !important; // sass-lint:disable-line no-important
    }

    .accordion-title-overlay {
        display: none;
    }
}
