@mixin \compass\reviews\short-summary\rating\apply(
    $filled-icon: $\compass\reviews\rating\icon-filled,
    $filled-color: $\compass\reviews\rating\icon-filled\color,
    $unfilled-icon: $\compass\reviews\rating\icon-unfilled,
    $unfilled-color: $\compass\reviews\rating\icon-unfilled\color
) {
    @include \kindling\typography\line-height('reviews.default.short-summary');

    .rating-summary {
        @include \kindling\typography\font-size('reviews.default.icon');
        display: inline-block;
        position: relative;

        .rating-result {
            display: inline-flex;

            &.filled {
                position: absolute;
                top: 0;
                left: 0;
                overflow: hidden;

                .star {
                    @include \kindling\icon\apply($icon: $filled-icon) {
                        color: \kindling\color\get($filled-color);
                    }
                }
            }

            &.unfilled {
                .star {
                    @include \kindling\icon\apply($icon: $unfilled-icon) {
                        color: \kindling\color\get($unfilled-color);
                    }
                }
            }
        }
    }
}

@mixin \compass\reviews\short-summary\action\apply() {
    @include \compass\typography\font\apply('primary', 'medium');
    @include \kindling\typography\font-size('reviews.default.action');
    color: \kindling\color\get('reviews.default.action');

    &:focus,
    &:hover {
        color: \kindling\color\get('reviews.default.action-active');
    }
}

@mixin \compass\reviews\short-summary\apply() {
    .product-reviews-summary {
        @include \compass\reviews\short-summary\rating\apply();
    }

    .reviews-actions {
        display: inline-block;

        .action {
            @include \compass\reviews\short-summary\action\apply();
        }
    }
}
