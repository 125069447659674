@mixin \project\message\item(
    $context,
    $icon,
    $outer-spacing: true
) {
    $default-context: 'messages.default.success';
    $background-color: \kindling\color\context-keys-calc('background', $context, $default-context);
    $icon-color: \kindling\color\context-keys-calc('icon', $context, $default-context);
    $text-color: \kindling\color\context-keys-calc('text', $context, $default-context);
    $link-color: \kindling\color\context-keys-calc('link', $context, $default-context);
    $link-color-hover: \kindling\color\context-keys-calc('link-hover', $context, $default-context);

    @if $outer-spacing {
        margin-bottom: $\project\message\spacing-outer;
    }
    background-color: \kindling\color\get($background-color);

    @if $outer-spacing {
        &:first-of-type {
            margin-top: $\project\message\spacing-outer;
        }

        &:last-of-type {
            margin-bottom: $\project\message\spacing-outer;
        }
    }

    > * {
        @include \kindling\icon\apply($icon: $icon) {
            margin-right: $\project\message\icon-spacing;
            line-height: 1.2;
            color: \kindling\color\get($icon-color);
            font-size: \kindling\value\rem-calc(20);
        }
        display: flex;
        padding: $\project\message\spacing-inner;
        color: \kindling\color\get($text-color);
    }

    .message-text {
        @include \compass\typography\copy\small();
    }

    a {
        color: \kindling\color\get($link-color);

        &:hover,
        &:focus {
            color: \kindling\color\get($link-color-hover);
        }
    }
}

@mixin \project\message\item-success($outer-spacing: true) {
    @include \project\message\item(
        $context: 'messages.default.success',
        $icon: $icon-success,
        $outer-spacing: $outer-spacing
    );
}

@mixin \project\message\item-error($outer-spacing: true) {
    @include \project\message\item(
        $context: 'messages.default.error',
        $icon: $icon-error,
        $outer-spacing: $outer-spacing
    );
}

@mixin \project\message\item-notice($outer-spacing: true) {
    @include \project\message\item(
        $context: 'messages.default.information',
        $icon: $icon-info,
        $outer-spacing: $outer-spacing
    );
}

@mixin \project\message\item-warning($outer-spacing: true) {
    @include \project\message\item(
        $context: 'messages.default.warning',
        $icon: $icon-alert,
        $outer-spacing: $outer-spacing
    );
}

@mixin \project\message\apply() {
    @at-root {
        .message-success {
            @include \project\message\item-success();
        }

        .message-error {
            @include \project\message\item-error();
        }

        .message-notice {
            @include \project\message\item-notice();
        }

        .message-warning {
            @include \project\message\item-warning();
        }
    }
}

@mixin \project\message\full-width-styles() {
    .message {
        margin-bottom: \kindling\value\rem-calc(5);

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .message-inner {
        @include \compass\layout\container-padded();
    }
}
