@mixin \compass\catalog\product\catagory-landing\apply() {
    .page-layout-category-full-width {
        .category-cms {
            @include \compass\cms\styles\apply();
            @include \compass\slider\product\apply();

            .hero-slider-container {
                @include \compass\slider\hero\apply($constrict-title-width: false);
            }

            .category-featured-tabs {
                display: none;
            }

            .category-featured-tabs-panel {
                .featured-category-tabs-actions {
                    .featured-categories-shop-all {
                        display: inline-block;
                    }
                }
            }
        }

        .category-description {
            @include \compass\cms\styles\apply();
            @include \kindling\spacing\apply('section.large.outer', $margin-sides: ('top', 'bottom'));

            [data-content-type='row'] > * {
                @include \kindling\spacing\apply('section.large.inner', $padding-sides: ('top'));
                @include \kindling\border\apply('section.default', $sides: ('top'));
            }

            [data-content-type='text'] {
                @include \kindling\breakpoint\media-query('xxlarge') {
                    @include \kindling\grid\max-width-container(\kindling\grid\column-width-calc(10));
                }
            }
        }
    }
}

@mixin \compass\catalog\product\catagory-grid-view\apply() {
    .page-layout-category-default {
        .content-main {
            @include \compass\page-content-container('main-content-container.category');
        }

        .category-description {
            [data-content-type='row'] {
                padding-right: 0 !important; // sass-lint:disable-line no-important
                padding-left: 0 !important; // sass-lint:disable-line no-important
            }
        }
    }
}

@mixin \compass\catalog\product\catagory\apply() {
    @include \compass\catalog\product\catagory-grid-view\apply();
    @include \compass\catalog\product\catagory-landing\apply();

    .catalog-category-view {
        .category-description {
            @include \compass\cms\styles\apply();
        }
    }
}
