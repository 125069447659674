@mixin \compass\contact\form\apply() {
    .form {
        &.contact {
            @include \compass\section($margin-sides: ('top'));

            .legend {
                h2 {
                    @include \compass\base\form-legend\heading();
                }
            }

            .fieldset {
                @include \compass\layout\form\grid-columns\apply(
                    $breakpoint: 'large'
                );

                > * {
                    &:last-child {
                        @include \kindling\breakpoint\media-query('large') {
                            grid-column-end: span 2;
                        }
                    }
                }
            }

            .field {
                &.comment {
                    @include \compass\base\form\textarea\label();
                }
            }

            .actions-toolbar {
                @include \kindling\spacing\apply('contact.form.actions', $margin-sides: ('top'));
            }
        }
    }
}
