@mixin \project\pseudo-underline\apply(
    $use-before: true,
    $bottom: 0.1em,
    $color: null,
    $height: 1px
) {
    &#{if($use-before, '::before', '::after')} {
        position: absolute;
        bottom: $bottom;
        left: 0;
        background-color: \kindling\color\get($color);
        width: 100%;
        height: $height;
        content: '';
        @content;
    }
}

@mixin \project\anchor\special-underline(
    $hover-color: null,
    $underline-color-hover: 'current-color',
    $underline-size: 1px,
    $underline-offset: 0.3em
) {
    position: relative;
    text-decoration: none;

    &:hover,
    &:focus {
        @include \project\pseudo-underline\apply(
            $use-before: false,
            $bottom: -1 * $underline-offset,
            $color: $underline-color-hover,
            $height: $underline-size
        );
        color: \kindling\color\get($hover-color);
    }
}
