@mixin \compass\scroll-bar\apply(
    $context: $\compass\scroll-bar\context,
    $overflow: $\compass\scroll-bar\overflow,
    $side: $\compass\scroll-bar\side
) {
    $thickness: $\compass\scroll-bar\thumb-thickness + (2 * $\compass\scroll-bar\thumb-spacing);
    $main-background-color: \kindling\color\context-keys-calc('main', $context, $\compass\scroll-bar\context);
    $main-background-border: \kindling\color\context-keys-calc('main', $context, $\compass\scroll-bar\context);
    $thumb-background-color: \kindling\color\context-keys-calc('thumb', $context, $\compass\scroll-bar\context);

    @if $side == 'both' {
        overflow: unquote($overflow);
    } @else {
        overflow-#{$side}: unquote($overflow);
    }

    &::-webkit-scrollbar {
        border: $\compass\scroll-bar\thumb-spacing solid \kindling\color\get('transparent');
        background-clip: content-box;
        background-color: \kindling\color\get($main-background-color);
        width: $thickness;
        height: $thickness;
    }

    &::-webkit-scrollbar-thumb {
        border: $\compass\scroll-bar\thumb-spacing solid \kindling\color\get('transparent');
        background-clip: content-box;
        background-color: \kindling\color\get($thumb-background-color);
    }
}

@mixin \compass\scroll-bar\reset() {
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
        display: none;
    }
}

@mixin \compass\scroll-bar\invisible($side: $\compass\scroll-bar\side) {
    @include \compass\scroll-bar\reset();
    @if $side == 'both' {
        overflow: auto;
    } @else {
        overflow-#{$side}: auto;
    }
}
