$page-title-border-color: 'brand-light';

@mixin \compass\components\page-title() {
    .page-title-wrapper {
        @include \kindling\spacing\apply('typography.h1', $margin-sides: ('bottom'), $padding-sides: ('bottom'));
        @include \kindling\border\apply('section.default', $sides: 'bottom');

        .page-title {
            @include \kindling\typography\heading\h1();
        }
    }
}
