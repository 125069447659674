@mixin \compass\animation\slide-in\hidden-state(
    $duration: $\compass\animation\slide-in\display-duration,
    $slide-from: $\compass\animation\slide-in\from-direction,
    $fade: $\compass\animation\slide-in\fades
) {
    $transform-value: 100% * if($slide-from == 'right' or $slide-from == 'bottom', 1, -1);
    transform: translate#{if($slide-from == 'right' or $slide-from == 'left', 'X', 'Y')}#{unquote('(#{$transform-value})')};
    transition: transform \kindling\transition\get-duration($duration), opacity \kindling\transition\get-duration($duration), visibility \kindling\transition\get-duration($duration) step-end;
    visibility: hidden;
    pointer-events: none;
    @if $fade {
        opacity: 0;
    }
}

@mixin \compass\animation\slide-in\active-state(
    $duration: $\compass\animation\slide-in\display-duration,
    $fade: $\compass\animation\slide-in\fades
) {
    transform: none;
    transition: transform \kindling\transition\get-duration($duration), opacity \kindling\transition\get-duration($duration), visibility \kindling\transition\get-duration($duration) step-start;
    visibility: visible;
    pointer-events: auto;
    @if $fade {
        opacity: 1;
    }
}

@mixin \compass\animation\fade-in\hidden-state(
    $duration: $\compass\animation\fade-in\display-duration
) {
    transition: opacity \kindling\transition\get-duration($duration), visibility \kindling\transition\get-duration($duration) step-end;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
}

@mixin \compass\animation\fade-in\active-state(
    $duration: $\compass\animation\fade-in\display-duration,
    $opacity: 1
) {
    transition: opacity \kindling\transition\get-duration($duration), visibility \kindling\transition\get-duration($duration) step-start;
    visibility: visible;
    opacity: $opacity;
    pointer-events: auto;
}
