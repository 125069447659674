@mixin \project\catalog\brand\featured-list() {
    text-align: center;

    .featured-brands-list {
        @include \kindling\typography\unstyled-list();
        @include \compass\grid\row(
            $column-gutters: (
                'small': \kindling\value\rem-calc(20),
                'large': \kindling\value\rem-calc(30)
            )
        );
        align-items: center;
        justify-content: center;
        margin-bottom: 0;

        > li {
            @include \kindling\grid\column(
                $responsive-sizes: (
                    'small': 50%,
                    'medium': \kindling\grid\column-width-calc(4),
                    'large': \kindling\grid\column-width-calc(2)
                ),
                $flex-grow: 0
            );

            a {
                display: block;
                position: relative;
                width: 100%;
                text-align: center;

                &:hover,
                &:focus {
                    img {
                        opacity: 0.5;
                    }
                }
            }

            img {
                @include \kindling\property\apply(
                    $property: 'max-height',
                    $property-value: (
                        'small': \kindling\value\rem-calc(60),
                        'medium': \kindling\value\rem-calc(72),
                        'large': \kindling\value\rem-calc(50),
                        'xlarge': \kindling\value\rem-calc(70),
                        'xxlarge': \kindling\value\rem-calc(78)
                    )
                );
                @include \kindling\transition\apply(opacity);
                display: inline-block;
                opacity: 1;
                max-width: 100%;
            }
        }
    }

    .featured-category-tabs-actions {
        @include \compass\button\grid-row($column-gutters: 'button-grid.large');
        @include \kindling\spacing\apply('base.xlarge', $padding-sides: ('top'));
        justify-content: center;

        a {
            @include \compass\button\tertiary('large');
            display: inline-block;
        }

        .featured-categories-shop-all {
            display: none;
        }
    }
}
