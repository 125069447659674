// Header
$checkout-header-main-background-color: 'white';
$checkout-header-main-foreground-colors: ('primary', 'white');
$checkout-header-bottom-background-color: 'neutral-lighter';
$checkout-header-main-icon-font-size: 'checkout.header.icon';
$checkout-header-logo-width: (
    'small': \kindling\value\rem-calc(80),
    'large': \kindling\value\rem-calc(103),
    'xlarge': \kindling\value\rem-calc(126)
);
$checkout-footer-logo-width: $checkout-header-logo-width;

$checkout-header-main-border-color: $global-border-color;
$checkout-header-bottom-border-color: $checkout-header-main-border-color;

$checkout-footer-main-border-color: $checkout-header-main-border-color;

$checkout-header-main-border-width: $global-border-width;
$checkout-header-bottom-border-width: \kindling\value\rem-calc(2);

$checkout-header-contact-icon: $icon-phone;
$checkout-header-trust-element-secure-icon: $icon-lock-closed;

$checkout-toolbar-font-family: 'primary';
$checkout-toolbar-font-weight: 'bold';
$checkout-toolbar-font-color: 'primary';

$checkout-toolbar-steps-show-numbers: true;
$checkout-toolbar-steps-show-numbers-breakpoint: small;
$checkout-toolbar-steps-mobile-show-active-number: true;
$checkout-toolbar-step-number-foreground-colors: ('white', 'primary');
$checkout-toolbar-steps-number-font-size: \kindling\value\rem-calc(13);

$checkout-toolbar-steps-show-arrows: true;
$checkout-toolbar-steps-arrow-icon: '/';
$checkout-toolbar-steps-arrow-color: 'neutral-light';
$checkout-toolbar-steps-arrow-font-size: \kindling\value\rem-calc(26);
$checkout-toolbar-steps-arrow-line-height: \kindling\value\rem-calc(20);

$checkout-toolbar-step-diameter: \kindling\value\rem-calc(20);
$checkout-toolbar-step-font-size: \kindling\value\rem-calc(10);

$checkout-toolbar-step-title-colors: (
    'active': 'primary',
    'inactive': 'primary',
    'processed': 'primary'
);

$checkout-toolbar-step-number-colors: (
    'active': 'secondary',
    'inactive': 'primary',
    'processed': 'neutral-darker'
);

$checkout-toolbar-step-state-line-position: 'bottom';

$checkout-toolbar-step-state-line-colors: (
    'active': 'secondary',
    'processed': 'secondary'
);

$checkout-toolbar-step-processed-state-icon: $icon-checkmark-strong;

// Steps/Main Body
$checkout-section-vertical-spacing: 'base.large';
$checkout-section-background-color: 'transparent';
$checkout-section-border: 'checkout.section';
$checkout-section-include-borders: 'not-set';
$checkout-section-include-padding: false;
$checkout-section-padding-ratio-default: 1;
$checkout-section-legend-subheading-type: 'h3';

$checkout-section-login-button-size: 'micro';
$checkout-section-login-button-type: 'primary';

$checkout-actions-button-size: 'regular';
$checkout-actions-back-cart-button-type: 'primary';
$checkout-actions-back-cart-button-icon: $icon-cart;
$checkout-actions-back-button-type: 'primary';
$checkout-actions-back-button-icon: $icon-chevron-left;
$checkout-actions-next-button-type: 'primary';
$checkout-actions-next-button-icon: $icon-chevron-right;
$checkout-actions-complete-button-type: 'secondary';
$checkout-actions-complete-button-icon: $icon-success;

$checkout-option-set-background-color: transparent;
$checkout-option-set-body-background-color: 'white';
$checkout-step-edit-icon: $icon-edit;
$checkout-customer-info-email-error-icon: $icon-alert;
$checkout-customer-info-email-info-icon: $icon-info;
$checkout-shipping-method-option-price-font-weight: 'bold';

$checkout-loader-overlay-spinner-size: \kindling\value\rem-calc(40);

// Success Page
$checkout-success-sidebar-title-spacing: 'grid-gutters';
//$checkout-success-sidebar-title-color: 'body';  @TODO remove - don't need with contexts
$checkout-success-sidebar-body-color: 'primary';
$checkout-success-sidebar-accordion-title-padding: 'accordion.checkout.title';
//$checkout-success-sidebar-accordion-body-padding: (
//    'small': \kindling\value\rem-calc(0 30 20)
//);
$checkout-success-sidebar-body-font-size: 'typography.small-copy';
$checkout-success-content-vertical-spacing: 'base.small';
$checkout-success-content-vertical-margin: 'base.xxsmall';
$checkout-success-content-vertical-padding: 'base.xlarge';
$checkout-success-top-details-vertical-spacing: 'grid-gutters';
$checkout-success-continue-shopping-button-icon: $icon-chevron-left;
$checkout-success-create-account-button-icon: $icon-account;
$checkout-success-actions-toolbar-mobile-breakpoint: 'medium';
$checkout-content-sidebar-heading-spacing: 'base.medium';

// Sales Order Items
$checkout-sales-order-item-background-color: 'white';
$checkout-sales-order-item-border-color: 'gallery-gray';
$checkout-sales-order-item-outer-spacing: 'grid-gutters';
$checkout-sales-order-item-inner-spacing: 'grid-gutters';
$checkout-sales-order-item-image-sizes: (
    'small': \kindling\grid\column-width-calc(4)
);
$checkout-sales-order-item-content-sizes: (
    'small': \kindling\grid\column-width-calc(8)
);
$checkout-sales-order-item-info-spacing: 'base.xxsmall';
