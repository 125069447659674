@mixin \project\breadcrumbs\apply() {
    .breadcrumbs {
        @include \kindling\spacing\apply('breadcrumbs', $margin-sides: ('bottom'));

        .items {
            @include \kindling\typography\unstyled-list();
        }

        .item {
            @include \kindling\typography\font-size('breadcrumb');
            display: inline-block;

            &::after {
                margin: 0 \kindling\value\rem-calc(8);
                vertical-align: bottom;
                content: '/';
            }

            &:last-child {
                &::after {
                    content: normal;
                }
            }

            strong,
            a {
                @include \compass\typography\font\apply('primary', 'semi-bold');
                text-decoration: underline;
            }
        }
    }
}
