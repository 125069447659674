@mixin \compass\cms\clearfix() {
    &::after {
        display: table;
        clear: both;
        content: '';
    }
}

@mixin \compass\cms\typography\apply() {
    @include \kindling\typography\apply-margin($exclude: ('dl'));

    ul {
        @include \kindling\typography\unordered-list();
    }

    ol {
        @include \compass\typography\styled-ordered-list();
    }

    p {
        @include \compass\typography\copy();

        @include \kindling\breakpoint\media-query($\compass\cms\breakpoint) {
            margin-bottom: \kindling\value\rem-calc(40);
        }
    }

    hr { // sass-lint:disable-block no-important
        @include \kindling\spacing\apply('typography.horizontal-rule', $margin-sides: ('top', 'bottom'));
        border-top-width: 0 !important;
        border-right-width: 0 !important;
        border-bottom-width: \kindling\border\get-property(\kindling\border\get('typography.horizontal-rule'), 'width') !important;
        border-left-width: 0 !important;
        border-style: \kindling\border\get-property(\kindling\border\get('typography.horizontal-rule'), 'style') !important;
        border-color: \kindling\color\get(\kindling\border\get-property(\kindling\border\get('typography.horizontal-rule'), 'color')) !important;
    }

    blockquote {
        @include \project\typography\blockquote();
    }
}

@mixin \compass\cms\layout\content\apply() {
    .section {
        &:not(:last-child) {
            @include \kindling\spacing\apply('base.xlarge', $margin-sides: ('bottom'));
        }
    }

    img {
        display: block;
    }

    figure {
        @include \kindling\spacing\apply(
            $spacing: 'typography.figcaption.outer',
            $margin-sides: ('bottom')
        );
    }

    figcaption {
        @include \kindling\spacing\apply(
            $spacing: 'typography.figcaption.inner',
            $padding-sides: ('all')
        );
        display: block;
        background-color: \kindling\color\get('typography.figcaption.background');
        color: \kindling\color\get('typography.figcaption.color');
    }
}

@mixin \compass\cms\responsive-video() {
    @include \kindling\embed\apply('widescreen');
}

@mixin \compass\cms\tooltip() {
    .cms-tooltip-button {
        &.tooltip-button {
            @include \compass\tooltip\trigger\apply();
        }

        &.tooltip-text {
            @include \compass\button\reset-browser-button();
            @include \compass\typography\font\apply('primary', 'bold');
            display: inline-block;
        }
    }

    @at-root {
        .cms-tooltip {
            @include \compass\tooltip\content\apply(
                $side: 'top',
                $align: 'center'
            );
        }
    }
}

@mixin \compass\cms\accordion() {
    .cms-accordion {
        @include \project\accordion\primary\container();
    }

    .cms-accordion-item {
        @include \project\accordion\primary\item();
    }

    .cms-accordion-title {
        @include \project\accordion\primary\title();
    }

    .cms-accordion-body {
        @include \project\accordion\primary\body();
    }
}

@mixin \compass\cms\tabs() {
    .cms-tabs {
        @include \compass\tabs\list\apply();
    }

    .cms-tab-title {
        @include \compass\tabs\title\apply();
    }

    .cms-tabs-body {
        @include \compass\tabs\body\apply();
    }

    .cms-tabs-panel {
        @include \compass\tabs\panel\apply();
    }
}

@mixin \project\cms\cta() {
    .cms-overlapping-cta-wrapper {
        @include \project\cta\overlapping-image-cta(
            $image-height: ('small': \kindling\value\rem-calc(170), 'large': \kindling\value\rem-calc(240)),
            $image-overlap: \kindling\value\rem-calc(50),
            $wrapper-selector: '.cms-overlapping-cta',
            $background-selector: '.cms-overlapping-cta-background',
            $content-selector: '.cms-overlapping-cta-content'
        );

        .cms-overlapping-cta-background {
            img {
                height: 100% !important; // sass-lint:disable-line no-important
            }
        }
    }
}

@mixin \project\cms\button() {
    .cms-button {
        &.button-primary {
            &.button-small {
                @include \compass\button\primary('small');
            }

            &.button-medium {
                @include \compass\button\primary('regular');
            }

            &.button-large {
                @include \compass\button\primary('large');
            }
        }

        &.button-secondary {
            &.button-small {
                @include \compass\button\secondary('small');
            }

            &.button-medium {
                @include \compass\button\secondary('regular');
            }

            &.button-large {
                @include \compass\button\secondary('large');
            }
        }

        &.button-tertiary {
            &.button-small {
                @include \compass\button\tertiary('small');
            }

            &.button-medium {
                @include \compass\button\tertiary('regular');
            }

            &.button-large {
                @include \compass\button\tertiary('large');
            }
        }

        &.button-tertiary-light {
            &.button-small {
                @include \compass\button\tertiary\light($size: 'small');
            }

            &.button-medium {
                @include \compass\button\tertiary\light($size: 'regular');
            }

            &.button-large {
                @include \compass\button\tertiary\light($size: 'large');
            }
        }

        &.button-tertiary-dark {
            &.button-small {
                @include \compass\button\tertiary\dark($size: 'small');
            }

            &.button-medium {
                @include \compass\button\tertiary\dark($size: 'regular');
            }

            &.button-large {
                @include \compass\button\tertiary\dark($size: 'large');
            }
        }

        &.button-icon-left {
            @each $icon-selector, $icon in $icon-selectors {
                &.#{$icon-selector} {
                    @include \kindling\icon\apply($icon: $icon) {
                        @include \kindling\spacing\apply($spacing: 'button-icon', $margin-sides: ('right'));
                    }
                }
            }
        }

        &.button-icon-right {
            @each $icon-selector, $icon in $icon-selectors {
                &.#{$icon-selector} {
                    @include \kindling\icon\apply($icon: $icon, $use-before: false) {
                        @include \kindling\spacing\apply($spacing: 'button-icon', $margin-sides: ('left'));
                    }
                }
            }
        }
    }
}

@mixin \project\cms\layout-helpers\apply() {
    .cms-image-center {
        > img {
            margin-right: auto;
            margin-left: auto;
        }
    }
}

@mixin \compass\cms\styles\apply() {
    @include \compass\cms\layout\content\apply();
    @include \compass\cms\typography\apply();
    @include \compass\cms\accordion();
    @include \compass\cms\tooltip();
    @include \compass\cms\tabs();
    @include \project\cms\cta();
    @include \project\cms\button();
    @include \project\catalog\category\featured\apply();
    @include \compass\table\apply();
    @include \project\cms\layout-helpers\apply();

    table {
        tbody {
            > tr {
                @include \kindling\border\apply('table.default.tbody', $sides: ('top'), $additive: true);
            }
        }
    }

    .cms-table-wrapper {
        @include \compass\responsive-table\apply();
    }

    .cms-specs-wrapper {
        @include \compass\table-secondary\apply();
    }

    .cms-responsive-video {
        @include \compass\cms\responsive-video();
    }

    .product-items {
        @include \compass\product-grid\apply();

        .product-item {
            &::before {
                content: normal;
            }
        }
    }

    .hero-slider-container {
        @include \compass\slider\hero\apply($constrict-title-width: false);
    }
}

@mixin \compass\cms\global\apply() {
    .apply-cms-styles {
        @include \compass\cms\styles\apply();
    }
}
