@mixin \compass\tabs\list\separator() {
    padding-left: $\compass\tabs\separator\spacing;

    &::before {
        display: inline-block;
        position: absolute;
        top: $\compass\tabs\separator\top;
        left: $\compass\tabs\separator\left;
        transform: $\compass\tabs\separator\transform;
        background-color: $\compass\tabs\separator\color;
        width: $\compass\tabs\separator\width;
        height: $\compass\tabs\separator\height;
        content: '';
    }
}

@mixin \compass\tabs\list\apply($justify-content: center) {
    @include \kindling\typography\unstyled-list();
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: $justify-content;

    li {
        position: relative;
        flex: 0 0 auto;

        &:not(:first-child) {
            @include \compass\tabs\list\separator();

            > button {
                border-left: 0;
            }
        }
    }
}

@mixin \compass\tabs\title\apply($context: 'tabs.default.title') {
    @include \compass\button\reset-browser-button();
    @include \kindling\spacing\apply($context, $padding-sides: ('all'));
    @include \compass\typography\font\apply('primary', 'medium');
    @include \kindling\typography\font-size($context);

    $text-color: \kindling\color\context-keys-calc('text-color', $context, 'tabs.default.title');
    $text-color-hover: \kindling\color\context-keys-calc('text-color-hover', $context, 'tabs.default.title');
    $border: \kindling\border\context-keys-calc('default', $context, 'tabs.default.title');
    $border-active: \kindling\border\context-keys-calc('active', $context, 'tabs.default.title');
    $background-color-hover: \kindling\color\context-keys-calc('background-color-hover', $context, 'tabs.default.title');

    @include \kindling\border\apply($border, $sides: ('bottom'));
    box-shadow: none;
    text-align: center;
    color: \kindling\color\get($text-color);

    &.is-active {
        @include \kindling\border\apply($border-active, $sides: ('bottom'));
        @include \compass\typography\font\apply('primary', 'semi-bold');
    }

    &:hover,
    &:focus {
        @include \kindling\border\apply($border-active, $sides: ('bottom'));
        @include \compass\typography\font\apply('primary', 'semi-bold');
        background-color: \kindling\color\get($background-color-hover);
        color: \kindling\color\get($text-color-hover);
    }
}

@mixin \compass\tabs\body\apply($context: 'tabs.default.body') {
    @include \kindling\border\apply($context);
    @include \kindling\spacing\apply($context, $margin-sides: ('top'));
    width: 100%;
}

@mixin \compass\tabs\panel\apply($context: 'tabs.default.panel', $include-padding: true) {
    @include \kindling\spacing\apply($context, $padding-sides: ('all'));
    background-color: \kindling\color\get($context);

    &:not(.is-active) {
        display: none !important; // sass-lint:disable-line no-important
    }
}
