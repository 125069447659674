@mixin \compass\layout\global\apply() {
    @include \compass\layout\page-banner-overlap\apply();

    body {
        &:not(.page-layout-empty-full-width) {
            &:not(.page-layout-category-full-width) {
                .content-main {
                    @include \compass\layout\container-padded();
                }
            }
        }

        &.page-layout-cms-full-width,
        &.page-layout-empty-full-width {
            .breadcrumbs {
                @include \compass\layout\container-padded();
            }
        }
    }

    .content-columns {
        @include \compass\grid\row($vertical-gutters: false, $spacing-type: 'margin');
    }

    .main-content-panel {
        @include \compass\layout\panel-main();
    }

    .left-content-panel {
        @include \compass\layout\panel-left();
    }

    .right-content-panel {
        @include \compass\layout\panel-right();
    }
}

@mixin \compass\layout\page-banner-overlap\apply() {
    body {
        &.page-layout-page_banner_layout { // sass-lint:disable-line class-name-format
            @include \compass\layout\section-overlap(
                $top-section-selector: '.page-banner-image',
                $bottom-section-selector: '.main-content-panel',
                $overlap: ('small': 0, 'large': \kindling\value\rem-calc(60))
            );

            .page {
                &.messages {
                    $spacing: \kindling\breakpoint\get-values-greater-than(
                        $map: \kindling\spacing\get('grid-gutters'),
                        $size: 'large'
                    );
                    @include \kindling\property\apply('padding-left', $spacing);
                    @include \kindling\property\apply('padding-right', $spacing);

                    .message {
                        &:first-of-type {
                            margin-top: 0;

                            $spacing: \kindling\breakpoint\get-values-greater-than(
                                $map: \kindling\spacing\get('main-content-container.default'),
                                $size: 'large'
                            );

                            @each $breakpoint, $padding in $spacing {
                                @include \kindling\breakpoint\media-query($breakpoint) {
                                    $offset: \kindling\breakpoint\get-breakpoint-value(\kindling\spacing\get('grid-gutters'), $breakpoint);
                                    margin-top: -1 * ($padding - \kindling\value\get-side($offset, 'left'));
                                }
                            }
                        }

                        &:last-of-type {
                            @include \kindling\spacing\apply(
                                $spacing: 'main-content-container.default',
                                $margin-sides: ('bottom')
                            );
                        }
                    }
                }
            }

            .page-main-content {
                @include \kindling\property\apply(
                    $property: 'background-color',
                    $property-value: (
                        'small': \kindling\color\get('neutral-white'),
                        'large': \kindling\color\get('neutral-lighter')
                    )
                );
            }

            .content-main {
                @include \compass\page-content-container\reset();
            }

            .main-content-panel {
                @include \compass\page-content-container();
                background-color: \kindling\color\get('neutral-white');
            }
        }
    }
}
