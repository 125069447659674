@mixin \compass\catalog\compare\footer\accordion() {
    .footer-compare-bar-item {
        @include \compass\accordion\item();

        &.is-active {
            .compare-count-label {
                &::before {
                    @include \kindling\accordion\title\icon-collapse();
                }
            }
        }
    }

    .footer-compare-title-container {
        position: relative;
        background-color: \kindling\color\get('neutral-lighter');
    }

    .footer-compare-title-wrapper {
        @include \compass\layout\container-padded();
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
    }

    .footer-compare-title {
        @include \compass\button\reset-browser-button();
        position: absolute;
        top: 0;
        left: 0;
        z-index: \kindling\z-index\get('above-sibling');
        width: 100%;
        height: 100%;
    }

    .compare-count-label {
        @include \compass\accordion\title('accordion.compare-bar');
        @include \kindling\typography\font-size('compare-bar.accordion-title-text');
        padding-right: \kindling\value\rem-calc(0);
        padding-left: \kindling\value\rem-calc(25);

        &::before {
            right: auto;
            left: 0;
        }
    }

    .footer-compare-buttons-container {
        @include \kindling\spacing\apply(
            $spacing: 'compare-bar.action-outer',
            $margin-sides: ('left')
        );
        position: relative;
        z-index: \kindling\z-index\get('above-sibling');

        @include \kindling\breakpoint\media-query('small' 'down') {
            display: none;
        }
    }

    .footer-compare-actions {
        @include \kindling\grid\row(('small': \kindling\spacing\get('compare-bar.action-outer')));
        flex-wrap: nowrap;

        > * {
            flex: 0 0 auto;
        }
    }

    .footer-compare-visit-link {
        @include \compass\button\primary('small');
        width: 100%;
        white-space: nowrap;
    }

    .footer-compare-remove-all {
        @include \compass\button\secondary('small');
        width: 100%;
        white-space: nowrap;
    }

    .footer-compare-body {
        @include \compass\accordion\body('accordion.compare-bar');
        @include \compass\scroll-bar\apply();
        padding-right: 0;
        padding-left: 0;
    }

    .footer-compare-mobile-buttons {
        @include \compass\button\grid-row();

        @include \kindling\breakpoint\media-query('medium') {
            display: none;
        }
    }

    .footer-compare-action-wrapper {
        flex: 1 1 auto;
    }
}

@mixin \compass\catalog\compare\footer\apply() {
    .footer-compare-bar {
        @include \compass\catalog\compare\footer\accordion();

        .footer-compare-items-container {
            @include \compass\layout\container-padded();
        }

        .footer-compare-items-container-inner {
            overflow: hidden;
        }

        .footer-compare-items {
            @include \kindling\typography\unstyled-list();
            @include \kindling\grid\row(
                $column-gutters: \kindling\spacing\get('grid-gutters'),
                $vertical-gutters: true
            );
            display: flex;
            flex-wrap: wrap;
        }

        .footer-compare-item {
            $responsive-sizes: (
                'small': 100%,
                'large': \kindling\grid\column-width-calc(4),
                'xlarge': \kindling\grid\column-width-calc(3)
            );
            @include \kindling\grid\column(
                $responsive-sizes: $responsive-sizes,
                $flex-grow: 0,
                $flex-shrink: 0
            );
            @include \kindling\spacing\apply(
                $spacing: 'grid-gutters',
                $padding-sides: ('right', 'bottom')
            );
            position: relative;

            @include \kindling\breakpoint\media-query('medium' 'down') {
                @include \kindling\border\apply('light', $sides: ('top'));
            }

            @include \kindling\breakpoint\media-query('large') {
                padding-bottom: 0;
            }

            &::before,
            &::after {
                @include \kindling\breakpoint\media-query('large') {
                    @include \kindling\border\apply('light', $sides: ('left'));
                    position: absolute;
                    top: 0;
                    height: 100%;
                    content: '';
                }
            }

            &::before {
                @include \kindling\breakpoint\media-query('large') {
                    left: 0;
                }
            }

            &::after {
                @include \kindling\breakpoint\media-query('large') {
                    left: 100%;
                }
            }
        }

        .compare-item-name {
            @include \compass\typography\font\apply('secondary', 'bold');
            @include \kindling\typography\font-size('typography.copy.lead');
            @include \kindling\spacing\apply('base.xsmall', $padding-sides: 'bottom');
            display: block;
        }

        .remove-compare-action {
            @include \compass\typography\font\apply('primary', 'bold');
            @include \kindling\typography\font-size('typography.copy.small');
            @include \kindling\icon\apply($icon: $icon-trash) {
                @include \kindling\typography\font-size('typography.copy.default');
                margin-right: \kindling\value\rem-calc(5);
            }
            display: inline-flex;
            text-decoration: none;

            span {
                text-decoration: underline;
            }
        }
    }
}

@mixin \compass\catalog\compare\checkbox() {
    @include \compass\button\reset-browser-button();
    @include \compass\typography\copy\micro();
    @include \compass\product-grid\card\compare\icon($icon: $icon-checkbox-off);
    display: flex;

    &.checked {
        @include \compass\product-grid\card\compare\icon($icon: $icon-checkbox-on);
    }
}

@mixin \compass\catalog\compare\icon-action() {
    @include \compass\button\icon-only(
        $icon: $icon-plus,
        $size: ('small': \kindling\value\rem-calc(30)),
        $icon-size: 0.6,
        $context: 'button.primary-light'
    );
    box-shadow: \kindling\map\get($\compass\button\shadows, 'bottom-right');

    &.checked {
        @include \kindling\icon\selector() {
            content: $icon-checkmark-strong;
        }
        background-color: \kindling\color\get('button.primary-light.background-color-hover');
        color: \kindling\color\get('button.primary-light.text-color-hover');
    }

    .label {
        @include \kindling\visually-hidden\apply();
    }
}
