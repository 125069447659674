@mixin \project\cms\forms() {
    .apply-cms-styles {
        .form {
            .form-required-label {
                @include \kindling\spacing\apply('form.default.input.margin', $margin-sides: ('bottom'));
                @include \compass\typography\copy\small();
                text-align: right;
            }

            .fields {
                @include \compass\layout\form\grid-columns\apply();
            }

            .field {
                &.textarea {
                    @include \compass\base\form\textarea\label();
                }
            }

            .actions-toolbar,
            .field-recaptcha {
                @include \kindling\spacing\apply('form.default.input.margin', $margin-sides: ('top'));
            }
        }
    }
}
