@mixin \compass\image-overlay\apply($background-color: $\compass\image-overlay\background-color) {
    position: relative;
    background-color: \kindling\color\get($background-color);

    img,
    .image-fit-polyfill {
        @include \compass\image-overlay\effect();
    }
}

@mixin \compass\image-overlay\effect() {
    opacity: $\compass\image-overlay\image-opacity;
}
