@mixin \project\wishlist\shared() {
    .wishlist-shared-index {
        .form.shared {
            @include \project\wishlist\item-list();

            .product-items {
                @include \compass\section($border-sides: ('top'));

                .product-item {
                    &:first-child {
                        margin-top: 0;
                    }
                }
            }

            .towishlist {
                @include \kindling\icon\apply($icon: $icon-heart-on);
            }

            .wishlist-comment {
                @include \kindling\spacing\apply('wishlist.list.item.inner', $margin-sides: ('bottom'));
            }

            .actions-toolbar {
                @include \kindling\grid\row();
                align-items: center;
                justify-content: space-between;

                .primary {
                    @include \customer\account\form\button();
                }

                .secondary {
                    @include \customer\account\form\button();
                }
            }
        }
    }
}

@mixin \project\wishlist\share\apply() {
    @include \project\wishlist\shared();

    .form.wishlist.share {
        .field {
            &.emails,
            &.text {
                @include \compass\base\form\textarea\label();
            }
        }
    }
}
