@mixin \checkout\success\sidebar\apply() {
    .order-success-sidebar-heading {
        @include \kindling\spacing\apply($checkout-content-sidebar-heading-spacing, $margin-sides: ('top', 'bottom'));
        @include \kindling\typography\heading\h3();
    }

    .order-success-sidebar-content {
        @include \project\accordion\sidebar\container();

        .order-success-sidebar-item {
            @include \project\accordion\sidebar\item();
        }

        button {
            @include \project\accordion\sidebar\title();
        }

        .order-success-sidebar-body {
            @include \project\accordion\sidebar\body();

            .payment-method {
                margin-left: 0;

                .title {
                    @include \kindling\typography\font-weight('checkout.success.method');
                }
            }
        }
    }

    .order-success-total-line {
        display: block;
        color: \kindling\color\get($checkout-success-sidebar-body-color);
    }

    .order-success-sidebar-totals {
        .order-success-total-entry {
            display: flex;
            margin-left: 0;
        }

        .label {
            flex: 1;
        }
    }

    .totals-table {
        @include \compass\checkout\totals();
        @include \kindling\border\apply('checkout.success.totals', $sides: ('all'));

        .table-caption {
            display: none;
        }

        .mark {
            @include \kindling\typography\font-weight('checkout.success.price');
        }

        .grand_total { // sass-lint:disable-line class-name-format
            @include \kindling\border\apply('checkout.success.totals', $sides: ('all'));
            @include \kindling\typography\font-weight('checkout.success.total-price');
            text-transform: uppercase;
        }
    }
}
