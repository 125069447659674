@mixin \compass\slider\product\progress-bar\apply() {
    @include \kindling\typography\unstyled-list();
    @include \kindling\spacing\apply(
        $spacing: 'slider.product.dot.outer',
        $margin-sides: ('top')
    );
    display: flex !important; // sass-lint:disable-line no-important
    position: relative;
    background-color: \kindling\color\get('slider.product.dot.background');

    @each $breakpoint, $value in \kindling\spacing\get('slider.product.inner') {
        @include \kindling\breakpoint\media-query($breakpoint) {
            $slide-gutter-offset: \kindling\breakpoint\get-breakpoint-value($\compass\product-grid\gutters, $breakpoint) / 2;
            margin-right: \kindling\value\get-side($value, 'right') + $slide-gutter-offset;
            margin-left: \kindling\value\get-side($value, 'left') + $slide-gutter-offset;
        }
    }

    &::before {
        @include \kindling\transition\apply(left, 'product-slider', 'ease-in-out');
        display: block;
        position: absolute;
        top: 0;
        background-color: \kindling\color\get('slider.product.dot.foreground');
        height: 100%;
        content: '';
        pointer-events: none;
    }

    li {
        flex: 1 1 auto;
        margin: 0;
    }

    button {
        @include \compass\slider\reset-button-styles();
        display: block;
        width: 100%;
        height: \kindling\value\rem-calc(2);
    }
}

@mixin \compass\slider\product\arrow\apply() {
    $half-visible-breakpoint: 'large';
    $size: (
        'small': \kindling\value\rem-calc(35),
        'medium': \kindling\value\rem-calc(45),
        $half-visible-breakpoint: \kindling\value\rem-calc(40)
    );
    $icon-size: (
        'small': \kindling\value\rem-calc(18) / \kindling\breakpoint\get-breakpoint-value($size, 'small'),
        'medium': \kindling\value\rem-calc(20) / \kindling\breakpoint\get-breakpoint-value($size, 'medium'),
        $half-visible-breakpoint: \kindling\value\rem-calc(25) / \kindling\breakpoint\get-breakpoint-value($size, 'large')
    );
    $rotate: 45deg;
    $arrow-offset: (
        'small': 0,
        $half-visible-breakpoint: \kindling\value\rem-calc(10)
    );

    @include \compass\slider\base\arrow\apply(
        $size: $size,
        $icon-size: $icon-size,
        $context: 'slider.product',
        $arrow-offset: $arrow-offset
    );
    @include \kindling\icon\selector() {
        display: block;
        transform: rotate(#{-1 * $rotate});
    }

    &.slick-prev {
        transform: translate(-50%, -50%) rotate($rotate);

        @include \kindling\breakpoint\media-query(\kindling\breakpoint\get-previous-breakpoint($half-visible-breakpoint) 'down') {
            text-align: right;
        }

        @include \kindling\breakpoint\media-query($half-visible-breakpoint) {
            transform: translateY(-50%) rotate($rotate);
        }
    }

    &.slick-next {
        transform: translate(50%, -50%) rotate($rotate);

        @include \kindling\breakpoint\media-query(\kindling\breakpoint\get-previous-breakpoint($half-visible-breakpoint) 'down') {
            text-align: left;
        }

        @include \kindling\breakpoint\media-query($half-visible-breakpoint) {
            transform: translateY(-50%) rotate($rotate);
        }
    }
}

@mixin \compass\slider\product\apply() {
    .product-slider-tabs {
        @include \compass\tabs\list\apply();
        margin-bottom: 0;
    }

    .product-slider-tabs-title {
        @include \compass\tabs\title\apply();
    }

    .product-slider-tabs-body {
        @include \compass\tabs\body\apply();
    }

    .product-slider-tabs-panel {
        @include \compass\tabs\panel\apply();
    }

    .product-slider-container {
        @include \compass\slider\base\bug-fix\flexbox();
    }

    .product-slider {
        @include \compass\product-grid\card\apply($responsive-sizes: ());
        @include \compass\slider\base\apply();
        @include \compass\slider\base\layout();
        @include \compass\slider\base\prevent-fouc-by-hiding-until-ready();
        overflow: hidden;
        text-align: left;

        &.slick-dotted {
            .slick-list {
                @each $breakpoint, $value in \kindling\spacing\get('slider.product.inner') {
                    @include \kindling\breakpoint\media-query($breakpoint) {
                        $gradient-value: \kindling\value\get-side($value, 'left');
                        padding: $value !important; // sass-lint:disable-line no-important
                        mask-image: linear-gradient(to right, #{\kindling\color\get('slider.product.fade.mask-invisible')}, #{\kindling\color\get('slider.product.fade.mask-visible')} #{$gradient-value}, #{\kindling\color\get('slider.product.fade.mask-visible')} calc(100% - #{$gradient-value}), #{\kindling\color\get('slider.product.fade.mask-invisible')});
                    }
                }
            }
        }

        .slide-contents {
            @include \kindling\property\apply('padding-left', $\compass\product-grid\gutters, 0.5);
            @include \kindling\property\apply('padding-right', $\compass\product-grid\gutters, 0.5);
        }

        .slick-arrow {
            @include \compass\slider\product\arrow\apply();
            background: \kindling\color\get('transparent') !important; // sass-lint:disable-line no-important
        }

        .slick-dots {
            @include \compass\slider\product\progress-bar\apply();

            @each $breakpoint, $value in \kindling\spacing\get('slider.product.inner') {
                @include \kindling\breakpoint\media-query($breakpoint) {
                    @include \kindling\property\apply('margin-left', 0);
                    @include \kindling\property\apply('margin-right', 0);
                }
            }

            li {
                button {
                    &::before {
                        content: none;
                    }
                }
            }
        }
    }

    .product-slider-actions {
        @include \kindling\spacing\apply('slider.product.actions', $margin-sides: ('top'));
        text-align: center;

        a {
            @include \compass\button\secondary();
        }
    }
}
