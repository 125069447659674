@mixin \compass\layout\form\grid-columns\apply(
    $breakpoint: 'large',
    $grid-gap-spacing: 'form.default.input.margin',
    $grid-column-gap-spacing: null,
    $columns: 2
) {
    @include \compass\grid\columns\apply(
        $breakpoint: $breakpoint,
        $grid-gap-spacing: $grid-gap-spacing,
        $grid-column-gap-spacing: $grid-column-gap-spacing,
        $columns: $columns
    );
}

@mixin \compass\layout\form\grid-gap\apply() {
    @supports (grid-area: auto) {
        @include \kindling\property\apply('grid-gap', \kindling\spacing\get('form.default.input.margin'));
    }
}

@mixin \compass\layout\address-form($shorten: true, $stretch-final: true) {
    @if $shorten == true {
        @include \kindling\grid\column(
            $responsive-sizes: (
                'small': \kindling\grid\column-width-calc(12)
            )
        );
    }

    .fieldset {
        @include \compass\layout\form\grid-columns\apply();

        @if $stretch-final {
            > * {
                &:last-child {
                    @include \kindling\breakpoint\media-query('large') {
                        grid-column-end: span 2;
                    }
                }
            }
        }
    }

    .inline-fields {
        @include \compass\layout\form\grid-columns\apply($columns: 3);

        @include \kindling\breakpoint\media-query('large') {
            grid-column-end: span 2;
        }
    }
}
