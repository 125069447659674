@mixin \compass\page-builder\slide\collage\apply() {
    [data-appearance='collage-centered'] {
        .pagebuilder-slide-wrapper {
            .pagebuilder-overlay {
                margin-right: auto;
                margin-left: auto;
            }
        }
    }

    [data-appearance='collage-left'] {
        .pagebuilder-slide-wrapper {
            .pagebuilder-overlay {
                margin-right: auto;
            }
        }
    }

    [data-appearance='collage-right'] {
        .pagebuilder-slide-wrapper {
            .pagebuilder-overlay {
                margin-left: auto;
            }
        }
    }
}

@mixin \compass\page-builder\slide\poster\apply() {
    .pagebuilder-slide-wrapper {
        .pagebuilder-poster-content {
            width: 100%;
        }
    }
}

@mixin \compass\page-builder\slide\apply() {
    @include \compass\page-builder\slide\collage\apply();
    @include \compass\page-builder\slide\poster\apply();

    [data-content-type='slide'] {
        min-height: inherit;
        overflow: hidden;

        > [data-element='link'],
        > [data-element='empty_link'] {
            min-height: inherit;
            text-decoration: inherit;
            color: inherit;

            &:hover,
            &:focus  {
                text-decoration: inherit;
                color: inherit;
            }
        }
    }

    .pagebuilder-slide-wrapper {
        border-radius: inherit;
        min-height: inherit;
        overflow-wrap: break-word;
        word-wrap: break-word;

        .pagebuilder-overlay {
            border-radius: inherit;
            padding: \kindling\value\rem-calc(30);

            &.pagebuilder-poster-overlay {
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: inherit;
            }

            &:not(.pagebuilder-poster-overlay) {
                max-width: \kindling\value\rem-calc(540);
            }
        }

        [data-element='content'] {
            min-height: \kindling\value\rem-calc(50);
        }

        .pagebuilder-slide-button {
            margin: \kindling\value\rem-calc(20) 0 0;
            max-width: 100%;
            text-align: inherit;
            word-break: break-word;
        }
    }

    @include \kindling\breakpoint\media-query('medium' down) {
        .pagebuilder-slide-wrapper {
            .pagebuilder-overlay {
                &:not(.pagebuilder-poster-overlay) {
                    max-width: none;
                }
            }
        }
    }
}
