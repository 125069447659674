// @TODO Upstream to Kindling
@function \compass\typography\utilities\font-face-config($file-path, $file-extensions) {
    $extension-to-format: (
        'woff2': 'woff2',
        'woff': 'woff',
        'otf': 'opentype',
        'ttf': 'truetype'
    );
    $output: ();

    @each $extension in $file-extensions {
        $value: (
            'file-path': '#{$file-path}.#{$extension}',
            'format': \kindling\map\get($extension-to-format, $extension)
        );
        $output: append($output, $value);
    }

    @return $output;
}

// @TODO Upstream to Kindling
@mixin \compass\typography\utilities\font-faces\at-font-face($font-family, $weights-styles-map) {
    @each $font-weight, $styles-map in $weights-styles-map {
        @each $font-style, $font-file-data in $styles-map {
            @font-face {
                text-rendering: optimizeLegibility;
                font-family: $font-family;
                font-weight: \kindling\typography\font-weight\get($font-weight);
                font-style: unquote($font-style);
                $src: ();
                @each $file-data in $font-file-data {
                    $src: append($src, url('#{\kindling\map\get($file-data, 'file-path')}') format('#{\kindling\map\get($file-data, 'format')}'), comma);
                }
                src: $src;
            }
        }
    }
}

// @TODO Upstream to Kindling
@mixin \compass\typography\utilities\font-faces\apply() {
    @each $font-family, $weights-map in $\compass\typography\font-faces\config {
        @include \compass\typography\utilities\font-faces\at-font-face($font-family, $weights-map);
    }
}
