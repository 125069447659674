$\kindling\typography\font-style\palette: (
    'not-set': null,
    'inherit-value': inherit,
    'normal': normal,
    'italic': italic
);

$\kindling\typography\font-style\contexts: (
    'button': (
        'default': 'not-set'
    ),
    'typography': (
        'anchor': 'not-set',
        'copy': (
            'default': 'not-set',
            'micro': 'not-set',
            'lead': 'not-set',
            'small': 'not-set'
        ),
    ),
    'accordion': (
        'default': (
            'title': 'not-set'
        )
    ),
    'form': (
        'default': (
            'input': 'not-set',
            'help': 'not-set',
            'error': 'not-set'
        )
    ),
    'tooltip': (
        'default': (
            'text': 'normal'
        )
    ),
    'header': (
        'shop-for-bikes': 'normal'
    )
);
