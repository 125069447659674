@mixin \project\accordion\table\container() {
    @include \compass\accordion\container('accordion.table');
}

@mixin \project\accordion\table\item() {
    @include \compass\accordion\item('accordion.table');

    &.is-active,
    &:last-child {
        > button {
            @include \kindling\border\apply('reset', $sides: ('bottom'));
        }
    }
}

@mixin \project\accordion\table\title() {
    @include \compass\accordion\title('accordion.table');
    @include \kindling\border\apply('light-thin', $sides: ('bottom'));
}

@mixin \project\accordion\table\body() {
    @include \compass\accordion\body('accordion.table');
}
