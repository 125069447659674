@mixin \compass\catalog\toolbar\limiter\apply() {
    .limiter-row {
        @include \compass\grid\row($column-gutters: \kindling\spacing\get($\compass\button\grid-row\spacing));
        align-items: center;
    }

    .limiter-options {
        @include \kindling\typography\unstyled-list();
        display: flex;

        > li {
            &:first-child {
                ~ * {
                    margin-left: -1 * \kindling\map\get(\kindling\border\get('button.secondary'), 'width');
                }
            }
        }
    }

    .limiter-button {
        @include \compass\button\secondary('micro');

        &.selected {
            background-color: \kindling\color\get(\kindling\map\get(\kindling\border\get('button.secondary'), 'color'));
            color: \kindling\color\get('button.secondary.text-color-hover');
        }
    }
}

@mixin \compass\catalog\toolbar\apply() {
    .toolbar {
        @include \compass\toolbar\apply();
        @include \compass\catalog\toolbar\limiter\apply();

        .modes {
            display: none;
        }

        .toolbar-amount {
            @include \compass\toolbar\page-count($\compass\toolbar\breakpoint);
        }
    }

    .toolbar-products-top {
        @include \kindling\breakpoint\media-query($\compass\toolbar\breakpoint) {
            margin-top: 0;
            border-top: 0;
            padding-top: 0;
        }

        .page-nav,
        .page-count {
            display: none;
        }

        .limiter {
            @include \compass\section(
                $context: 'section.medium',
                $border-sides: ('bottom'),
                $margin-sides: ('bottom')
            );
            width: 100%;

            @include \kindling\breakpoint\media-query($\compass\toolbar\breakpoint) {
                margin-bottom: 0;
                border: 0;
                padding-bottom: 0;
                width: auto;
            }
        }

        .toolbar-amount {
            display: block;
        }

        .limiter-row {
            display: flex;
            justify-content: space-between;
        }
    }

    .toolbar-products-bottom {
        .page-count,
        .limiter {
            display: none;
        }

        .page-nav {
            order: 0;
        }

        .toolbar-amount {
            order: 1;
        }

        .toolbar-pagination {
            order: 3;
        }
    }
}
