@mixin \compass\swatches\disabled-indicator() {
    $transparent-color: \kindling\color\get('transparent');
    $white-color: \kindling\color\get('neutral-white');
    $error-color: \kindling\color\get('error');

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(to left top, $transparent-color 0%, $transparent-color 42%, $white-color 43%, $white-color 46%, $error-color 47%, $error-color 53%, $white-color 54%, $white-color 57%, $transparent-color 58%, $transparent-color 100%);
        content: '';
    }
}

@mixin \compass\swatches\apply($dropdown-swatches: ('.size')) {
    .swatch-attribute {
        &[option-selected] {
            .swatch-attribute-label {
                &::after {
                    content: ':';
                }
            }
        }

        @each $attribute in $dropdown-swatches {
            &#{$attribute} {
                .swatch-attribute-label {
                    display: none;
                }

                .swatch-attribute-selected-option {
                    display: none;
                }

                .swatch-attribute-options {
                    margin-top: 0;
                }
            }
        }
    }

    .swatch-attribute-label {
        @include \compass\typography\font\apply('primary', 'bold');
        @include \kindling\typography\font-size('swatches.attribute-label');
        position: relative;
        color: \kindling\color\get('swatches.attribute-label');

        &.required {
            padding-right: \kindling\value\rem-calc(10);
        }

        &[data-required='1'] {
            &::after {
                content: '*';
            }
        }
    }

    .swatch-attribute-selected-option {
        @include \kindling\typography\font-size('swatches.attribute-label');
        padding-left: \kindling\value\rem-calc(5);
        color: \kindling\color\get('swatches.attribute-label');
    }

    .swatch-attribute-options {
        margin-top: \kindling\value\rem-calc(10);
    }

    .layered-navigation-filter-by {
        .swatch-attribute-options {
            @include \compass\grid\row(('small': \kindling\value\rem-calc(5)), $vertical-gutters: false);
            margin-top: 0;
        }

        .swatch-option-link-layered {
            flex: 0 0 auto;
        }
    }

    .product-items {
        .swatch-opt {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            margin: \kindling\value\rem-calc(12 0);
        }

        .swatch-attribute {
            display: inline-block;
            order: 0;
        }

        .swatch-attribute-options {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 0;
        }

        .swatch-option {
            margin-right: \kindling\value\rem-calc(7);
            width: \kindling\value\rem-calc(16);
            height: \kindling\value\rem-calc(16);
        }
    }

    .swatch-option {
        @include \kindling\border\apply('swatches.default');
        display: inline-block;
        position: relative;
        margin-right: \kindling\value\rem-calc(15);
        cursor: pointer;
        width: \kindling\value\rem-calc(25);
        height: \kindling\value\rem-calc(25);
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;

        &:not(.disabled) {
            &:hover {
                @include \kindling\border\apply('swatches.active');
                outline: \kindling\border\get-property(\kindling\border\get('swatches.default'), 'width') \kindling\border\get-property(\kindling\border\get('swatches.default'), 'style') \kindling\color\get(\kindling\border\get-property(\kindling\border\get('swatches.default'), 'color'));
                color: \kindling\color\get('swatches.swatch.text-color');
            }
        }

        &.selected {
            @include \kindling\border\apply('swatches.active');
            outline: \kindling\border\get-property(\kindling\border\get('swatches.default'), 'width') \kindling\border\get-property(\kindling\border\get('swatches.default'), 'style') \kindling\color\get(\kindling\border\get-property(\kindling\border\get('swatches.default'), 'color'));
            color: \kindling\color\get('swatches.swatch.text-color');
        }

        &.disabled {
            @include \compass\swatches\disabled-indicator();
            cursor: default;
        }
    }

    .swatch-more {
        @include \kindling\typography\font-size('swatches.more-label');
        @include \kindling\typography\line-height('swatches.more-label');
        display: inline-block;
        order: 1;
        text-decoration: underline;
        color: \kindling\color\get('swatches.swatch.text-color');
        pointer-events: none;
    }

    .swatch-option-link-layered {
        display: inline-block;
        position: relative;
        margin: 0;
        padding: 0;

        &:focus {
            > div {
                box-shadow: \kindling\value\rem-calc(0 0 3 1) \kindling\color\get('swatches.swatch.highlight');
            }
        }

        &:hover {
            > .swatch-option-tooltip-layered {
                display: block;
            }
        }

        .swatch-option {
            margin: 0;
        }
    }

    .swatch-opt-listing {
        margin-bottom: \kindling\value\rem-calc(10);
    }

    .swatch-option-loading {
        content: url('../images/loader-2.gif');
    }

    .swatch-input {
        position: absolute;
        left: \kindling\value\rem-calc(-1000);
        visibility: hidden;
    }
}
