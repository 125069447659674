// Grid intended exclusively for usage on <fieldset> elements which don't support display flex or grid.
// This grid system is limited, so it should only be used on fieldset elements.
@mixin \compass\grid\fieldset\row(
    $spacing: $\compass\grid\fieldset\row-spacing,
    $spacing-sides: $\compass\grid\fieldset\row-spacing-sides,
    $child-selector: '> *'
) {
    @each $side in $spacing-sides {
        @include \kindling\property\apply('margin-#{$side}', \kindling\value\get-side($spacing, $side), -1);
    }
    font-size: 0; // removes inline spacing

    #{$child-selector} {
        @include \kindling\typography\font-size($body-font-size);
        display: inline-block;
        vertical-align: top;

        @each $side in $spacing-sides {
            @include \kindling\property\apply('margin-#{$side}', \kindling\value\get-side($spacing, $side));
        }
    }
}

@mixin \compass\grid\fieldset\column(
    $responsive-sizes: $\compass\grid\fieldset\column-sizes,
    $spacing: $\compass\grid\fieldset\row-spacing
) {
    @each $breakpoint, $count in $responsive-sizes {
        @include \kindling\breakpoint\media-query($breakpoint) {
            width: calc((#{\kindling\grid\column-width-calc($count)}) - #{\kindling\value\get-side($spacing, 'left')});
        }
    }
}

@mixin \compass\grid\fieldset(
    $responsive-width: $\compass\grid\fieldset\column-sizes,
    $spacing: $\compass\grid\fieldset\row-spacing,
    $spacing-sides: $\compass\grid\fieldset\row-spacing-sides,
    $child-selector: '> *',
    $column-selector: '> *'
) {
    @include \compass\grid\fieldset\row(
        $spacing: $spacing,
        $spacing-sides: $spacing-sides,
        $child-selector: $child-selector
    );

    #{$column-selector} {
        @include \compass\grid\fieldset\column($responsive-width, $spacing);
    }
}
