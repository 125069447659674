$\compass\table\default-context: 'table.default';

@mixin \compass\table\heading(
    $context: $\compass\table\default-context,
    $padding: 'th',
    $font-stack: 'heading',
    $font-size: 'thead'
) {
    $font-stack: \kindling\typography\font-stack\context-keys-calc($font-stack, $context, $\compass\table\default-context);
    $font-size: \kindling\typography\font-size\context-keys-calc($font-size, $context, $\compass\table\default-context);
    $padding: \kindling\spacing\context-keys-calc($padding, $context, $\compass\table\default-context);

    tr {
        > th {
            @include \kindling\typography\font-stack($font-stack);
            @include \kindling\typography\font-size($font-size);
            @include \kindling\spacing\apply($spacing: $padding, $padding-sides: ('all'));

            text-transform: uppercase;
        }
    }
}

@mixin \compass\table\body(
    $context: $\compass\table\default-context,
    $font-size: 'tbody'
) {
    $font-size: \kindling\typography\font-size\context-keys-calc($font-size, $context, $\compass\table\default-context);

    @include \kindling\typography\font-size($font-size);
}

@mixin \compass\table\body\row(
    $context: $\compass\table\default-context,
    $padding: 'td',
    $border: 'tbody',
    $background-color: 'tbody.tr.background-color'
) {
    $border: \kindling\border\context-keys-calc($border, $context, $\compass\table\default-context);
    $background-color: \kindling\color\context-keys-calc($background-color, $context, $\compass\table\default-context);
    $padding: \kindling\spacing\context-keys-calc($padding, $context, $\compass\table\default-context);

    @include \kindling\border\apply($border, $sides: ('left', 'right', 'bottom'));
    @include \kindling\color\apply-background-color($background-color);

    > td {
        @include \kindling\spacing\apply($spacing: $padding, $padding-sides: ('all'));
    }
}

@mixin \compass\table\frame($context: $\compass\table\default-context) {
    @include \kindling\table\apply(
        $is-hover: false,
        $head-cell-font-weight: null,
        $head-background-color: \kindling\color\get('primary'),
        $head-font-color: \kindling\color\get('white'),
        $border-color: $table-border-color,
        $is-striped: true,
        $stripe: even,
        $striped-background-color: \kindling\color\get('neutral-lighter')
    );

    > .table-caption {
        @include \kindling\visually-hidden\apply();
    }

    > thead {
        @include \compass\table\heading($context: $context);
    }

    > tbody {
        @include \compass\table\body($context: $context);

        > tr {
            @include \compass\table\body\row($context: $context);
        }
    }
}

@mixin \compass\table\apply($context: $\compass\table\default-context) {
    table {
        @include \compass\table\frame($context: $context);
    }
}

@mixin \compass\table-secondary\heading($context: $\compass\table\default-context) {
    @include \compass\table\heading($context: $context);
}

@mixin \compass\table-secondary\body($context: $\compass\table\default-context) {
    @include \compass\table\body($context: $context);
}

@mixin \compass\table-secondary\body\row($context: $\compass\table\default-context) {
    @include \compass\table\body\row($context: $context);
}

@mixin \compass\table-secondary\frame($context: $\compass\table\default-context) {
    $background-color: \kindling\color\context-keys-calc('tbody.tr.background-color', $context, $\compass\table\default-context);
    $font-stack: \kindling\typography\font-stack\context-keys-calc('heading', $context, $\compass\table\default-context);
    $table-spacing: \kindling\spacing\context-keys-calc('outer', $context, $\compass\table\default-context);

    @include \kindling\table\apply(
        $is-hover: false,
        $background-color: $background-color,
        $is-striped: false
    );

    > tbody {
        @include \compass\table-secondary\body($context: $context);
        @include \compass\table-secondary\heading($context: $context);

        > tr {
            @include \compass\table-secondary\body\row($context: $context);

            > th {
                width: 30%;
                text-align: left;
            }

            &:first-child {
                > th,
                > td {
                    @include \kindling\spacing\apply($table-spacing, $padding-sides: ('top'));
                }
            }

            &:last-child {
                > th,
                > td {
                    @include \kindling\spacing\apply($table-spacing, $padding-sides: ('bottom'));
                }
            }
        }
    }
}

@mixin \compass\table-secondary\apply($context: 'table.secondary') {
    table {
        @include \compass\table-secondary\frame($context: $context);
    }
}
