@mixin \northern\checkout\success\apply() {
    .checkout-success {
        @include \kindling\spacing\apply($checkout-success-content-vertical-margin, $margin-sides: ('bottom'));
        @include \kindling\border\apply($color: $checkout-sales-order-item-border-color, $sides: ('bottom'));
        @include \kindling\spacing\apply($checkout-success-content-vertical-padding, $padding-sides: ('bottom'));
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        .checkout-success-details {
            @include \kindling\spacing\apply($checkout-success-top-details-vertical-spacing, $margin-sides: ('bottom'));
            flex-grow: 1;
        }

        p {
            &:first-child {
                @include \kindling\spacing\apply($checkout-success-content-vertical-spacing, $margin-sides: ('top', 'bottom'));
                @include \kindling\typography\heading\h4();
            }
        }

        .actions-toolbar {
            display: flex;
            flex-shrink: 0;
            flex-wrap: wrap;
            width: 100%;

            @include \kindling\breakpoint\media-query($checkout-success-actions-toolbar-mobile-breakpoint) {
                width: auto;
            }

            .primary,
            .create-account-link {
                @include \kindling\spacing\apply('grid-gutters', $margin-sides: ('bottom'), $ratio: 0.5);
                width: 100%;

                @include \kindling\breakpoint\media-query($checkout-success-actions-toolbar-mobile-breakpoint) {
                    width: auto;
                }
            }

            .primary {
                @include \compass\button\back-button($style: 'secondary');
            }

            .create-account-link {
                @include \compass\button\apply();

                @include \kindling\breakpoint\media-query($checkout-success-actions-toolbar-mobile-breakpoint) {
                    margin-left: \kindling\value\rem-calc(12);
                }
            }
        }
    }

    .checkout-success-content {
        @include \kindling\spacing\apply($checkout-success-content-vertical-padding, $margin-sides: ('bottom', 'right'));

        @include \kindling\breakpoint\media-query('medium' 'down') {
            @include \kindling\spacing\apply('reset', $margin-sides: ('right'));
        }

        .order-items-container {
            @include \compass\checkout\order\item\apply();
            @include \catalog\price\styles\apply($context: 'product-price.checkout', $wrapper-selector: '.item-price', $normal-price-selector: '.price');

            .item-price {
                @include \kindling\typography\font-weight('checkout.success.item-price');
            }

            h4 {
                @include \kindling\spacing\apply($checkout-content-sidebar-heading-spacing, $margin-sides: ('top', 'bottom'));
                @include \kindling\typography\heading\h3();
            }
        }
    }

    .order-success-sidebar {
        @include \checkout\success\sidebar\apply();
    }
}
