@mixin \checkout\onestep\payment\method\cc\apply() {
    .inline-cc-fields {
        @include \kindling\spacing\apply('checkout.steps.cc-forms.inline-fields', $margin-sides: ('top'));

        @include \kindling\breakpoint\media-query('large') {
            display: flex;
        }

        .cc-fields-label {
            @include \kindling\spacing\apply('checkout.steps.cc-forms.labels', $margin-sides: ('top', 'bottom'));
            flex: 0 0 auto;
            align-self: center;
        }

        .field {
            @include \kindling\spacing\apply('checkout.steps.cc-forms.inline-fields', $margin-sides: ('left', 'bottom'));
            flex: 1 1 auto;
            min-width: 25%;

            &.cvv {
                flex: 0 1 auto;

                @include \kindling\breakpoint\media-query('large') {
                    min-width: 1px; // sass-lint:disable-line property-units
                    max-width: 20%;
                }
            }
        }
    }

    .cc-icons {
        display: flex;
        flex-grow: 0;
        flex-shrink: 1;
        align-items: center;
        justify-content: flex-end;

        img {
            max-width: \kindling\value\rem-calc(38);
            max-height: \kindling\value\rem-calc(25);

            &:not(:first-child) {
                margin-left: \kindling\value\rem-calc(5);
            }
        }
    }
}

@mixin \northern\checkout\sidebar\summary() {
    .checkout-sidebar-order-items {
        @include \kindling\typography\unstyled-list();
        @include \kindling\spacing\apply('accordion.sidebar-checkout.body', $padding-sides: ('all'));

        li {
            &:not(:first-child) {
                @include \kindling\border\apply($context: 'minicart.item', $sides: ('top'));
                @include \kindling\spacing\apply($spacing: 'minicart.item-wrapper', $padding-sides: ('top'));
            }

            &:not(:last-child) {
                @include \kindling\spacing\apply($spacing: 'minicart.item-wrapper', $padding-sides: ('bottom'));
            }
        }

        .order-item-row {
            display: flex;
            width: 100%;
        }

        .product-photo {
            @include \compass\magento-checkout\minicart\item\image-container();
        }

        .item-product-info {
            @include \compass\magento-checkout\minicart\item\info-container();
        }

        .item-options {
            @include \compass\checkout\order\item\options();
        }
    }
}

@mixin \northern\checkout\sidebar\totals() {
    .totals-table-wrapper,
    .totals-table-grand-wrapper {
        @include \kindling\spacing\apply('checkout.summary', $padding-sides: ('all'));

        .totals-table {
            width: 100%;

            .mark {
                @include \compass\typography\font\apply('primary', $weight: ('normal'));
                text-align: left;
            }

            .amount {
                text-align: right;
            }
        }
    }

    .totals-table-grand-wrapper {
        @include \kindling\border\apply('accordion.sidebar-checkout.item', $sides: ('top'));

        .totals-table {
            .mark {
                @include \compass\typography\font\apply('secondary', $weight: ('bold'));
                @include \kindling\typography\font-size('accordion.table.title');
                text-transform: uppercase;
            }
        }
    }
}

@mixin \northern\checkout\sidebar\apply() {
    @include \northern\checkout\sidebar\summary();
    @include \northern\checkout\sidebar\totals();
    @include \catalog\price\styles\apply($context: 'product-price.checkout', $wrapper-selector: '.cart-price', $normal-price-selector: '.price');

    .checkout-sidebar-container {
        @include \compass\magento-checkout\cart\sidebar\coupon();
        @include \project\sidebar-trust-badges\apply();

        @include \kindling\breakpoint\media-query('medium' 'down') {
            @include \kindling\spacing\apply($checkout-section-vertical-spacing, $margin-sides: ('bottom'));
        }
    }

    .checkout-sidebar-accordion-container {
        @include \project\accordion\sidebar-checkout\container();
    }

    .checkout-sidebar-accordion-header {
        @include \project\accordion\sidebar-checkout\title();
    }

    .checkout-sidebar-accordion-item {
        @include \project\accordion\sidebar-checkout\item();
    }

    .checkout-sidebar-accordion-container-top-level {
        $offset: \kindling\spacing\get('constrained-content-container.default.small');
        @include \kindling\property\apply('margin-right', $offset, -1);
        @include \kindling\property\apply('margin-left', $offset, -1);
        width: calc(100% + (#{$offset} * 2));

        @include \kindling\breakpoint\media-query('medium') {
            $offset: \kindling\spacing\get('constrained-content-container.default.medium');
            @include \kindling\property\apply('margin-right', $offset, -1);
            @include \kindling\property\apply('margin-left', $offset, -1);
            width: calc(100% + (#{$offset} * 2));
        }

        @include \kindling\breakpoint\media-query('large') {
            margin-right: 0;
            margin-left: 0;
            width: auto;
        }

        > .checkout-sidebar-accordion-item {
            > .checkout-sidebar-accordion-body {
                @include \kindling\spacing\apply('reset', $padding-sides: ('all'));
            }

            > .checkout-sidebar-accordion-header {
                @include \kindling\breakpoint\media-query('large') {
                    @include \compass\accordion\disable-button();
                }

                + .checkout-sidebar-accordion-body {
                    @include \kindling\breakpoint\media-query('large') {
                        display: block;
                    }
                }
            }
        }
    }

    .promo-container {
        .checkout-sidebar-accordion-item {
            @include \kindling\border\apply('reset', $sides: ('left', 'right'), $additive: true);
        }
    }

    .checkout-sidebar-accordion-body {
        @include \project\accordion\sidebar-checkout\body();
    }

    .checkout-sidebar-footer,
    .checkout-content-trust-mobile {
        .sidebar-trust-badges {
            .trust-badges {
                border-top: 0;
                padding-top: 0;
            }
        }
    }

    .checkout-sidebar-footer {
        display: none;

        @include \kindling\breakpoint\media-query('large') {
            display: block;
        }
    }

    .checkout-content-trust-mobile {
        @include \project\sidebar-trust-badges\apply();
        @include \compass\section();
        @include \kindling\spacing\apply('reset', $margin-sides: ('top'));
        display: block;

        @include \kindling\breakpoint\media-query('large') {
            display: none;
        }

        .trust-badges {
            margin-top: 0;
        }
    }
}

@mixin \northern\checkout\gift-card\apply() {
    .checkout-giftcard-check-balance {
        order: 1;

        button {
            @include \compass\button\base($style: 'secondary', $size: 'micro')
        }
    }

    .checkout-giftcard-action {
        @include \kindling\spacing\apply('checkout.steps.actions.between', $margin-sides: ('right'));
        order: 0;

        button {
            @include \compass\button\base($size: 'micro')
        }
    }
}

@mixin \northern\checkout\checkout\apply() {
    .checkout-main {
        @include \northern\checkout\sidebar\apply();
        @include \northern\checkout\gift-card\apply();

        .checkout-payment {
            @include \checkout\onestep\payment\method\cc\apply();

            .paypal {
                div {
                    @include \kindling\icon\apply($icon: $icon-lock-closed) {
                        @include \kindling\spacing\apply('base.xsmall', $margin-sides: ('right'));
                        vertical-align: -15%;
                        font-size: \kindling\value\rem-calc(25);
                    }
                }
            }
        }

        .checkout-giftcard-section {
            .checkout-giftcard-actions {
                display: flex;
            }
        }

        .checkout-option-set-header {
            @include \kindling\spacing\apply('checkout.steps.option-sets.header', $padding-sides: ('all'));
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;

            @include \kindling\breakpoint\media-query('small' 'only') {
                flex-wrap: wrap;
            }

            &.sezzlepay {
                align-items: center;

                .installment-message {
                    @include \kindling\spacing\apply('base.xsmall', $margin-sides: ('left'));
                    flex-grow: 1;

                    p {
                        font-size: \kindling\value\rem-calc(14);
                    }
                }

                label {
                    flex-grow: 0;
                    width: auto;
                }
            }

            label {
                flex-grow: 1;
                flex-shrink: 1;
            }
        }

        .checkout-option-set-body {
            @include \kindling\spacing\apply('checkout.steps.option-sets.body', $padding-sides: ('all'));
        }

        .guest-checkout {
            @include \kindling\spacing\apply('base.large', $margin-sides: ('top'));

            .action {
                @include \kindling\spacing\apply('base.xsmall', $margin-sides: ('left'));
                @include \compass\button\secondary('micro');
            }
        }

        .checkout-actions {
            @include \kindling\breakpoint\media-query('medium' 'down') {
                @include \compass\section();
            }
        }
    }
}
