@mixin \customer\account\garage() {
    .account-my-garage-container {
        @include \project\fitment\vehicle-navigator-form\base\apply();
        @include \project\fitment\mini-garage\list\apply();
        @include \project\fitment\vehicle-navigator-form\featured\form\apply($breakpoint: 'large', $include-shadow: false);

        .garage-vehicles {
            @include \kindling\border\apply(
                $context: 'light'
            );
            @include \kindling\spacing\apply('form.default.input.margin', $margin-sides: ('bottom'));
        }

        .garage-vehicle-info-label {
            @include \compass\typography\copy();
        }

        .garage-empty-message-container {
            display: none;
        }
    }
}
