@mixin \checkout\onepage\step\customer\apply() {
    .checkout-step-customer-info {
        .actions {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            > :first-child {
                @include \kindling\spacing\apply('base.xsmall', $margin-sides: ('right'));
            }

            .login-text {
                @include \kindling\typography\font-size('typography.small-copy');
            }

            .login-button {
                @include \compass\button\secondary($size: $checkout-section-login-button-size);
            }

            .login-action {
                @include \compass\button\primary('small');
                @include \kindling\spacing\apply('base.small', $margin-sides: ('right'));

                width: \kindling\value\rem-calc(100);
            }
        }

        .checkout-customer-info-welcome {
            .legend {
                @include \kindling\form\legend();
            }

            .heading {
                @include \kindling\typography\heading\h2(); //@TODO verify this is correct
            }
        }

        .inline-fields {
            @include \kindling\grid\row();

            @include \kindling\breakpoint\media-query('medium') {
                flex-wrap: nowrap;
            }

            .field {
                @include \kindling\grid\column(
                    $responsive-sizes: (
                        'small': \kindling\grid\column-width-calc(12),
                        'medium': \kindling\grid\column-width-calc(6)
                    )
                );

                &:not(:first-child) {
                    @include \kindling\spacing\apply('grid-gutters', $margin-sides: ('left'));

                    @include \kindling\breakpoint\media-query('small' 'only') {
                        margin-left: 0;
                    }
                }
            }
        }
    }

    .pickup-message {
        margin-top: \kindling\value\rem-calc(5);
        margin-bottom: \kindling\value\rem-calc(5);
        background-color: \kindling\color\get('neutral-lighter');
        padding: \kindling\value\rem-calc(10);
        padding-top: \kindling\value\rem-calc(6);
    }

    .checkout-login-error,
    .checkout-login-info {
        @include \kindling\typography\small-element();
    }

    .checkout-login-error {
        .checkout-login-error-message {
            @include \kindling\icon\apply($icon: $checkout-customer-info-email-error-icon) {
                margin-right: \kindling\value\rem-calc(5);
                font-size: \kindling\value\rem-calc(18);
            }

            color: \kindling\color\get('error');
        }

        .checkout-login-error-action {
            @include \kindling\typography\anchor();
            display: inline;
            outline: none;
            border: 0;
            background: none;
        }
    }

    .checkout-login-info {
        .checkout-login-info-message {
            @include \kindling\icon\apply($icon: $checkout-customer-info-email-info-icon) {
                margin-right: \kindling\value\rem-calc(5);
                font-size: \kindling\value\rem-calc(18);
            }

            color: \kindling\color\get('information');
        }
    }
}

@mixin \checkout\onepage\step\shipping\apply() {
    .checkout-shipping-address-snapshot {
        @include \compass\box\container();
        @include \kindling\spacing\apply($checkout-section-vertical-spacing, $margin-sides: ('bottom'));

        .checkout-shipping-address-snapshot-header {
            @include \compass\box\header();
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            background-color: \kindling\color\get('neutral-lighter');

            .checkout-shipping-address-step-edit { // sass-lint:disable-line no-empty-rulesets
                //@include link-with-icon($checkout-step-edit-icon); @TODO Implement me
            }

            + .checkout-shipping-address-snapshot-box-body {
                padding-top: 0;
            }

            p {
                @include \kindling\typography\heading\h4();
            }
        }

        .checkout-shipping-address-snapshot-box-body {
            @include \compass\box\body();
            background-color: \kindling\color\get('neutral-lighter');
        }
    }

    .checkout-shipping-field-use {
        .shipping-method-rate-name {
            flex-grow: 1;
        }

        .price {
            font-weight: \kindling\typography\font-weight\get($checkout-shipping-method-option-price-font-weight);
        }
    }
}

@mixin \checkout\onepage\step\payment\apply() {
    .checkout-billing-fieldset {
        @include \compass\layout\address-form();
    }
}
