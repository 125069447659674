@mixin \compass\base\form\textarea\label(
    $context: $\kindling\form\default-context,
    $padding: 'input.padding'
) {
    .label,
    .form-label-text {
        &:first-child {
            $padding: \kindling\spacing\context-keys-calc($padding, $context, $\kindling\form\default-context);
            $padding: \kindling\value\to-responsive-map(\kindling\spacing\get($padding));

            $breakpoints: \kindling\breakpoint\get-breakpoints-in-maps($padding);

            @each $breakpoint in $breakpoints {
                @include \kindling\breakpoint\media-query($breakpoint) {
                    $padding-value: \kindling\breakpoint\get-breakpoint-value($padding, $breakpoint);

                    $padding-top: \kindling\value\get-side($padding-value, 'top');
                    $padding-left: \kindling\value\get-side($padding-value, 'left');
                    top: 0;
                    left: 0;
                    transform: none;
                    padding-top: $padding-top * 2 / 3;
                    padding-left: $padding-left;

                }
            }
            background-color: \kindling\color\get('form.default.input.background-color');
        }
    }
}
