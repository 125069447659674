@mixin \project\cms\homepage\shop-categories-list() {
    ul {
        $top-left-area: \compass\grid\area-name('top-left');
        $bottom-left-area: \compass\grid\area-name('bottom-left');
        $center-area: \compass\grid\area-name('center');
        $top-right-area: \compass\grid\area-name('top-right');
        $bottom-right-area: \compass\grid\area-name('bottom-right');

        $responsive-columns: (
            'small': 100%,
            'medium': 1fr 1fr,
            'large': 1fr 1fr 1fr,
            'xlarge': 1fr 1fr 1fr,
            'xxlarge': 1fr 1fr 1fr
        );

        $responsive-rows: (
            'small': repeat(5, #{\kindling\value\rem-calc(120)}),
            'medium': repeat(3, #{\kindling\value\rem-calc(160)}),
            'large': \kindling\value\rem-calc(160) \kindling\value\rem-calc(160),
            'xlarge': \kindling\value\rem-calc(160) \kindling\value\rem-calc(160),
            'xxlarge': \kindling\value\rem-calc(210) \kindling\value\rem-calc(210)
        );

        $responsive-areas: (
            'small':
            '#{$top-left-area}' '#{$bottom-left-area}' '#{$center-area}' '#{$top-right-area}' '#{$bottom-right-area}',
            'medium':
            '#{$top-left-area} #{$center-area}' '#{$bottom-left-area} #{$center-area}' '#{$top-right-area} #{$bottom-right-area}',
            'large':
            '#{$top-left-area} #{$center-area} #{$top-right-area}' '#{$bottom-left-area} #{$center-area} #{$bottom-right-area}',
            'xlarge':
            '#{$top-left-area} #{$center-area} #{$top-right-area}' '#{$bottom-left-area} #{$center-area} #{$bottom-right-area}',
            'xxlarge':
            '#{$top-left-area} #{$center-area} #{$top-right-area}' '#{$bottom-left-area} #{$center-area} #{$bottom-right-area}'
        );

        $grid-gap: (
            'small': \kindling\value\rem-calc(20),
            'medium': \kindling\value\rem-calc(20),
            'large': \kindling\value\rem-calc(30),
            'xlarge': \kindling\value\rem-calc(30),
            'xxlarge': \kindling\value\rem-calc(30)
        );

        @include \kindling\typography\unstyled-list();
        display: grid;

        //Grid properties must be implemented together so the Autoprefixer can properly prefix the values.
        @each $breakpoint, $value in $breakpoints {
            @include \kindling\breakpoint\media-query($breakpoint) {
                grid-template-columns: \kindling\breakpoint\get-breakpoint-value($responsive-columns, $breakpoint);
                grid-template-rows: \kindling\breakpoint\get-breakpoint-value($responsive-rows, $breakpoint);
                grid-template-areas: \kindling\breakpoint\get-breakpoint-value($responsive-areas, $breakpoint);
                grid-gap: \kindling\breakpoint\get-breakpoint-value($grid-gap, $breakpoint);
            }
        }

        li {
            @include \kindling\image-fit\apply();
            position: relative;

            &::before {
                $overlay-color: \kindling\color\get('shop-category-list.default.overlay');
                position: absolute;
                top: 0;
                left: 0;
                background: linear-gradient(180deg, rgba($overlay-color, 0) 0%, rgba($overlay-color, 0.6) 100%);
                width: 100%;
                height: 100%;
                content: '';
                pointer-events: none;
            }

            &:nth-child(1) {
                grid-area: $top-left-area;
            }

            &:nth-child(2) {
                grid-area: $bottom-left-area;
            }

            &:nth-child(3) {
                grid-area: $center-area;
            }

            &:nth-child(4) {
                grid-area: $top-right-area;
            }

            &:nth-child(5) {
                grid-area: $bottom-right-area;
            }

            a {
                &:hover,
                &:focus {
                    .category-text {
                        @include \compass\button\tertiary-hover($text-color-hover: 'shop-category-list.default.text');
                    }
                }
            }

            .category-text {
                @include \compass\button\tertiary('large');
                position: absolute;
                bottom: \kindling\value\rem-calc(15);
                left: \kindling\value\rem-calc(25);
                color: \kindling\color\get('shop-category-list.default.text');
                pointer-events: none;
            }
        }
    }
}
