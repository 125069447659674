$\compass\spacing\padding: (
    'nano': \kindling\value\rem-calc(12),
    'micro': \kindling\value\rem-calc(18),
    'small': \kindling\value\rem-calc(20),
    'regular': \kindling\value\rem-calc(30),
    'large': \kindling\value\rem-calc(36)
);

$\compass\spacing\padding\transform-ratio: 0.5;

$\compass\spacing\padding\transformed: (
    'micro-transformed': \kindling\map\get($\compass\spacing\padding, 'micro') * $\compass\spacing\padding\transform-ratio,
    'small-transformed': \kindling\map\get($\compass\spacing\padding, 'small') * $\compass\spacing\padding\transform-ratio,
    'regular-transformed': \kindling\map\get($\compass\spacing\padding, 'regular') * $\compass\spacing\padding\transform-ratio,
    'large-transformed': \kindling\map\get($\compass\spacing\padding, 'large') * $\compass\spacing\padding\transform-ratio
);

$\compass\spacing\padding: map-merge($\compass\spacing\padding, $\compass\spacing\padding\transformed);

$\kindling\spacing\palette: (
    'not-set': null,
    'em-thirty-percent': 0.3em,
    'reset': 0,
    'mimic-whitespace-character': \kindling\value\rem-calc(5),
    'padding': $\compass\spacing\padding,
    'button': (
        'micro': \kindling\value\rem-calc(11),
        'small': \kindling\value\rem-calc(15),
        'regular': \kindling\value\rem-calc(17),
        'large': \kindling\value\rem-calc(22)
    ),
    'base': (
        'xxxsmall': \kindling\value\rem-calc(2),
        'xxsmall': \kindling\value\rem-calc(5),
        'xsmall': \kindling\value\rem-calc(8),
        'small': \kindling\value\rem-calc(16),
        'medium': \kindling\value\rem-calc(24),
        'large': \kindling\value\rem-calc(32),
        'xlarge': \kindling\value\rem-calc(40),
        'xxlarge': \kindling\value\rem-calc(48),
        'xxxlarge': \kindling\value\rem-calc(64),
        'xxxxlarge': \kindling\value\rem-calc(72)
    ),
    'grid-gutters': (
        'small': \kindling\value\rem-calc(15),
        'large': \kindling\value\rem-calc(25),
        'xlarge': \kindling\value\rem-calc(30)
    ),
    'menu': (
        'default': (
            'header': (
                'text': \kindling\value\rem-calc(0 20)
            ),
            'items': \kindling\value\rem-calc(15 20)
        ),
        'toolbar': (
            'header': (
                'text': \kindling\value\rem-calc(0 24)
            ),
            'items': \kindling\value\rem-calc(16 24)
        ),
        'account': (
            'top-bar': \kindling\value\rem-calc(24 24 20),
        )
    ),
    'badge': \kindling\value\rem-calc(1 5),
    'page-banner': (
        'small': \kindling\value\rem-calc(60 0),
        'large': \kindling\value\rem-calc(100 0)
    ),
    'checkout': (
        'sidebar-totals': (
            'row': \kindling\value\rem-calc(12 28),
            'total-row': \kindling\value\rem-calc(20 28)
        ),
        'button': (
            'mobile': \kindling\value\rem-calc(24)
        ),
        'summary': \kindling\value\rem-calc(20)
    ),
    'modal': (
        'header': \kindling\value\rem-calc(13 20 10),
        'content': \kindling\value\rem-calc(16 20),
        'yotpo': (
            'container': (
                'small': \kindling\value\rem-calc(40),
                'large': 0
            ),
            'image-label': \kindling\value\rem-calc(1 3),
            'footer': (
                'small': \kindling\value\rem-calc(16),
                'large': \kindling\value\rem-calc(70 20)
            )
        )
    ),
    'slider': (
        'product': (
            'inner': (
                'small': \kindling\value\rem-calc(0 30),
                'medium': \kindling\value\rem-calc(0 40),
                'large': \kindling\value\rem-calc(0 100)
            )
        ),
        'hero': (
            'content': (
                'outer': (
                    'small': \kindling\value\rem-calc(45 0),
                    'large': \kindling\value\rem-calc(90 0),
                    'xxlarge': \kindling\value\rem-calc(190 0)
                )
            )
        )
    ),
    'bottom-header-logo': \kindling\value\rem-calc(0 16),
    'form': (
        'default': (
            'input-small-sneaky': (
                'padding': \kindling\value\rem-calc(16 12 4)
            ),
            'input-micro': (
                'padding': \kindling\value\rem-calc(7 11)
            )
        )
    ),
    'fitment': (
        'mini-garage': (
            'garage-items': (
                'item-inner': \kindling\value\rem-calc(20)
            )
        )
    ),
    'back-to-top': \kindling\value\rem-calc(85),
    'footer': (
        'back-to-top': \kindling\value\rem-calc(85),
        'newsletter-overlap': (
            'small': \kindling\value\rem-calc(141),
            'medium': \kindling\value\rem-calc(39),
            'large': \kindling\value\rem-calc(91),
            'xlarge': \kindling\value\rem-calc(79)
        )
    ),
    'hero-container': (
        'small': \kindling\value\rem-calc(25 0 40 0),
        'large': \kindling\value\rem-calc(112 0 123 0)
    ),
    'bike-shops-block': (
        'small': \kindling\value\rem-calc(20),
        'xlarge': \kindling\value\rem-calc(27 50)
    ),
    'category-list': (
        'default': (
            'spacing': (
                'small': \kindling\value\rem-calc(20),
                'large': \kindling\value\rem-calc(25 30)
            ),
            'item': (
                'small': \kindling\value\rem-calc(12),
                'medium': \kindling\value\rem-calc(15),
                'xlarge': \kindling\value\rem-calc(20)
            )
        )
    ),
    'brands-page': (
        'desktop-nav': (
            'item-outer': (
                'small': \kindling\value\rem-calc(2),
                'xlarge': \kindling\value\rem-calc(7)
            )
        )
    ),
    'cross-sells': (
        'container': \kindling\value\rem-calc(88),
        'title': \kindling\value\rem-calc(32)
    ),
    'cart': (
        'methods': (
            'items': \kindling\value\rem-calc(35)
        ),
        'totals': \kindling\value\rem-calc(10)
    ),
    'customer': (
        'account': (
            'sidebar': (
                'link': (
                    'small': \kindling\value\rem-calc(8 0),
                    'medium': \kindling\value\rem-calc(8 20)
                ),
                'heading': \kindling\value\rem-calc(15 20),
                'body': \kindling\value\rem-calc(8),
            )
        )
    ),
    'blog': (
        'wrapper': (
            'post': (
                'info': (
                    'title': \kindling\value\rem-calc(70 0 0),
                    'subtitle': (
                        'margin': \kindling\value\rem-calc(30 0),
                        'padding': \kindling\value\rem-calc(20 80 20 40)
                    ),
                    'cms-image': \kindling\value\rem-calc(20),
                    'p': \kindling\value\rem-calc(20),
                    'h2': \kindling\value\rem-calc(20),
                    'banner-full-width': \kindling\value\rem-calc(80 20 40),
                    'text-side-picture': (
                        'padding': \kindling\value\rem-calc(30),
                        'left-img': \kindling\value\rem-calc(50),
                        'right-img': \kindling\value\rem-calc(0 -20 0 50),
                    ),
                    'block-highlighted-primary': \kindling\value\rem-calc(40),
                    'block-highlighted-secondary': (
                        'padding': \kindling\value\rem-calc(0 50 60),
                        'margin': \kindling\value\rem-calc(40)
                    ),
                    'social-container': \kindling\value\rem-calc(20),
                    'tags': (
                        'small': \kindling\value\rem-calc(20 0 10),
                        'medium': \kindling\value\rem-calc(20 0 0),
                    ),
                    'features': (
                        'small': \kindling\value\rem-calc(0 15 20),
                        'medium': \kindling\value\rem-calc(15 15 20),
                    ),
                    'date': \kindling\value\rem-calc(15)
                ),
                'title': \kindling\value\rem-calc(16),
                'comments': (
                    'padding': \kindling\value\rem-calc(65),
                    'title': \kindling\value\rem-calc(20),
                    'form': \kindling\value\rem-calc(0)
                )
            ),
            'list': (
                'margin': (
                    'small': \kindling\value\rem-calc(40 10),
                    'large': \kindling\value\rem-calc(60 50)
                ),
                'post': (
                    'spacing': (
                        'small': \kindling\value\rem-calc(50)
                    ),
                    'content': (
                        'padding': (
                            'small': \kindling\value\rem-calc(10 10 0),
                            'large': \kindling\value\rem-calc(0 15 0 40)
                        )
                    ),
                    'tags': (
                        'margin': (
                            'small': \kindling\value\rem-calc(20 0)
                        ),
                        'padding': (
                            'small': \kindling\value\rem-calc(0)
                        ),
                        'tag': (
                            'margin': (
                                'small': \kindling\value\rem-calc(0 10)
                            ),
                            'padding': (
                                'small': \kindling\value\rem-calc(5 10)
                            )
                        )
                    ),
                    'titles': \kindling\value\rem-calc(0),
                    'read-more': \kindling\value\rem-calc(11 38)
                ),
                'title': (
                    'small': \kindling\value\rem-calc(60 16),
                    'large': \kindling\value\rem-calc(75 40)
                ),
                'cms-block': (
                    'spacing': (
                        'small': \kindling\value\rem-calc(20),
                        'large': \kindling\value\rem-calc(74 79 74 132)
                    ),
                    'highlight-spacing': \kindling\value\rem-calc(15 0 30),
                    'button': \kindling\value\rem-calc(11 38)
                ),
                'top-menu': (
                    'padding': (
                        'small': \kindling\value\rem-calc(0),
                        'large': \kindling\value\rem-calc(35 40 35 50)
                    ),
                    'item': (
                        'spacing': (
                            'small': \kindling\value\rem-calc(0),
                            'large': \kindling\value\rem-calc(0 10),
                            'xlarge': \kindling\value\rem-calc(0 20)
                        ),
                        'category-name': (
                            'small': \kindling\value\rem-calc(0),
                            'large': \kindling\value\rem-calc(30)
                        ),
                        'icon': (
                            'margin': (
                                'small': \kindling\value\rem-calc(8),
                                'large': \kindling\value\rem-calc(0)
                            )
                        )
                    )
                )
            )
        )
    ),
    'browse-inventory': (
        'small': \kindling\value\rem-calc(0 20),
        'large': \kindling\value\rem-calc(0 100)
    )
);

$\kindling\spacing\contexts: (
    'global': (
        'xsmall': (
            'small': 'base.xsmall',
        ),
        'small': (
            'small': 'base.small'
        ),
        'medium': (
            'small': 'base.medium'
        ),
        'large': (
            'small': 'base.large'
        ),
        'xlarge': (
            'small': 'base.xlarge'
        ),
        'xxlarge': (
            'small': 'base.xxlarge'
        ),
        'xxxlarge': (
            'small': 'base.xxlarge',
            'large': 'base.xxxlarge'
        ),
        'xxxxlarge': (
            'small': 'base.xxlarge',
            'large': 'base.xxxxlarge'
        )
    ),
    'grid-gutters': 'grid-gutters',
    'main-content-container': (
        'default': (
            'small': 'base.xlarge',
            'large': 'base.xxxlarge'
        ),
        'category': (
            'small': 'base.xlarge',
            'large': 'base.medium' 'reset' 'base.xxxlarge'
        ),
        'reset': (
            'small': 'reset',
            'large': 'reset'
        )
    ),
    'constrained-content-container': (
        'default': (
            'small': 'base.small',
            'medium': 'base.large',
            'large': 'base.xlarge'
        )
    ),
    'box': (
        'default': (
            'container': 'base.small',
            'section': 'base.small'
        ),
        'account': (
            'container': 'base.small',
            'section': 'base.medium'
        )
    ),
    'section': (
        'default': (
            'inner': 'base.xlarge',
            'outer': 'base.xlarge'
        ),
        'small': (
            'inner': 'base.small',
            'outer': 'base.small'
        ),
        'medium': (
            'inner': 'base.medium',
            'outer': 'base.medium'
        ),
        'large': (
            'inner': (
                'small': 'base.xxlarge',
                'large': 'base.xxxxlarge'
            ),
            'outer': (
                'small': 'base.xxlarge',
                'large': 'base.xxxxlarge'
            )
        ),
        'rma-forms': (
            'inner': 'section.default.inner',
            'outer': 'section.default.outer'
        )
    ),
    'typography': (
        'hero': 'base.small',
        'h1': 'base.small',
        'h2': 'base.small',
        'h3': 'base.xsmall',
        'h4': 'base.xsmall',
        'h5': 'base.xsmall',
        'h6': 'base.xsmall',
        'micro-copy': 'base.small',
        'horizontal-rule': 'base.xlarge',
        'p': 'base.small',
        'paragraph': 'typography.p', //@TODO remove once Kindling keys are consistent
        'list': 'base.small',
        'blockquote': 'base.small',
        'cite': 'base.xsmall',
        'ol': (
            'list': 'typography.list',
            'item': 'reset',
            'icon': (
                'right': 'mimic-whitespace-character'
            )
        ),
        'ul': (
            'list': 'typography.list',
            'item': 'reset'
        ),
        'figcaption': (
            'inner': 'base.xsmall',
            'outer': 'grid-gutters'
        )
    ),
    'button': (
        'micro': 'button.micro',
        'small': 'button.small',
        'default': 'button.regular',
        'large': 'button.large'
    ),
    'button-icon': 'mimic-whitespace-character',
    'button-grid': (
        'default': ('small': 'base.xsmall'),
        'large': ('small': 'base.small')
    ),
    'messages': 'base.xsmall',
    'accordion': (
        'default': (
            'container': 'not-set',
            'title': 'base.medium',
            'body': 'base.medium',
            'item': 'base.xxxsmall',
            'extra-icon-padding': 'base.xxlarge'
        ),
        'sidebar': (
            'container': 'not-set',
            'title': 'padding.small',
            'body': 'padding.small',
            'item': 'base.xxxsmall',
            'button': 'base.xsmall' 'not-set' 'base.xxxsmall'
        ),
        'sidebar-light': (
            'container': 'not-set',
            'title': 'padding.small',
            'body': 'padding.small',
            'item': 'reset'
        ),
        'account-sidebar': (
            'container': 'padding.small',
            'title': 'padding.small',
            'body': 'padding.small',
            'item': 'base.xxxsmall'
        ),
        'sidebar-checkout': (
            'container': 'not-set',
            'title': 'padding.small',
            'body': 'padding.small',
            'item': 'base.xxxsmall'
        ),
        'footer': (
            'container': 'not-set',
            'title': 'padding.small',
            'body': 'padding.small',
            'item': 'reset'
        ),
        'table': (
            'container': 'not-set',
            'title': 'button.small' 'base.medium',
            'body': 'reset',
            'item': 'reset'
        ),
        'mini-garage': (
            'container': 'not-set',
            'title': 'padding.regular-transformed' 'padding.small',
            'body': 'not-set',
            'item': 'not-set'
        ),
        'vehicle-navigation-form': (
            'container': 'not-set',
            'title': 'padding.small',
            'body': 'reset' 'padding.small' 'padding.small',
            'item': 'not-set'
        )
    ),
    'modal': (
        'content': 'modal.content',
        'header': 'modal.header',
        'extra-icon-padding': 'base.xxlarge'
    ),
    'form': (
        'default': (
            'legend': 'base.large',
            'heading': 'base.xsmall',
            'input': (
                'margin': (
                    'small': 'base.small',
                    'large': 'base.small'
                ),
                'icon': 'em-thirty-percent',
                'padding': 'base.small'
            ),
            'input-small': (
                'margin': (
                    'small': 'base.small',
                    'large': 'base.small'
                ),
            ),
            'help': 'not-set',
            'error': 'base.xsmall',
            'checkbox': 'base.xsmall'
        ),
        'search': (
            'input': (
                'padding': (
                    'small': 'base.xsmall' 'base.xsmall',
                    'xlarge': 'base.xsmall' 'base.xsmall'
                ),
                'submit': 'base.xlarge'
            ),
            'autocomplete': 'base.xxxsmall' 'base.xxsmall',
            'mobile-container': (
                'small': 'base.xsmall' 'base.xlarge',
                'xlarge': 'reset'
            )
        )
    ),
    'header': (
        'top': 'base.xxsmall',
        'promo-bar': 'base.xsmall',
        'nav': 'base.large',
        'main': (
            'small': 'base.xxsmall',
            'large': 'base.xsmall'
        ),
        'button': (
            'small': 'base.xsmall',
            'large': 'base.small'
        ),
        'checkout-logo': (
            'small': 'base.xxsmall' 'base.xsmall',
            'large': 'base.xxsmall' 'base.small'
        ),
        'bottom-logo': 'bottom-header-logo',
        'shop-for-bikes': (
            'logo-between': (
                'small': 'not-set',
                'xlarge': 'padding.nano'
            ),
            'mobile-logo-between': 'base.xxxsmall',
            'inner': 'padding.nano',
            'icon': 'mimic-whitespace-character',
            'betweem': 'base.xsmall',
            'button': 'padding.small',
            'button-sides': (
                'small': 'base.xsmall',
                'large': 'base.xxsmall',
                'xlarge': 'base.xsmall'
            ),
            'mobile-button-sides': 'base.xxxsmall',
            'mobile': (
                'logo': 'base.xsmall'
            )
        )
    ),
    'menu': (
        'mobile-mega-menu': (
            'default': 'menu.default.header.text',
            'items': 'menu.default.items',
            'item-outer': 'base.small',
            'icon': 'base.xsmall'
        ),
        'desktop-mega-menu': (
            'items': 'base.xsmall' 'reset' 'reset',
            'item-outer': 'base.xsmall'
        )
    ),
    'tabs': (
        'default': (
            'title': (
                'small': 'base.xxxsmall' 'not-set'
            ),
            'body': 'base.xlarge',
            'panel': 'reset'
        ),
        'pdp': (
            'title': (
                'small': 'base.xxxsmall' 'not-set'
            ),
            'body': 'base.xlarge',
            'panel': 'base.large'
        )
    ),
    'minicart': (
        'default': 'base.xsmall',
        'header': 'minicart.default',
        'footer': 'base.medium',
        'item': 'minicart.default',
        'item-wrapper': 'base.small'
    ),
    'pdp': (
        'product-info': (
            'product-info-top': 'base.small',
            'price': 'base.xsmall',
            'options': 'base.medium',
            'stock-wrapper': 'base.small',
            'add-to-cart': (
                'padding': 'reset',
                'margin': 'base.medium'
            ),
            'bottom-additional': (
                'margin': 'base.medium',
                'padding': 'base.medium'
            ),
            'trust': 'base.small'
        ),
        'product-details': (
            'margin': 'base.xxlarge',
            'padding': 'base.xxxlarge',
            'description': (
                'padding': 'base.medium'
            )
        )
    ),
    'badge': 'badge',
    'compare-bar': (
        'action-outer': 'base.xxsmall'
    ),
    'compare-page': (
        'item': (
            'cell-inner': (
                'small': 'base.xxsmall' 'base.xsmall',
                'large': 'base.medium' 'base.xsmall'
            ),
            'table-outer': 'base.medium',
            'table-inner': 'base.large'
        )
    ),
    'contact': (
        'title': 'base.medium',
        'map': 'base.xlarge',
        'info': (
            'small': 'base.large'
        ),
        'form': (
            'actions': (
                'small': 'base.xlarge',
                'large': 'base.xxlarge'
            )
        )
    ),
    'wishlist': (
        'management': (
            'list': (
                'outer': (
                    'small': 'base.medium'
                ),
                'actions': 'global.xsmall'
            ),
            'title': (
                'small': 'base.medium'
            )
        ),
        'modal': (
            'toolbar': (
                'small': 'base.medium'
            )
        ),
        'list': (
            'item': (
                'inner': (
                    'small': 'base.small'
                ),
                'options': 'global.xsmall',
                'actions': (
                    'small': 'base.small'
                )
            )
        )
    ),
    'site-map': (
        'default': 'base.small',
        'button': (
            'small': 'base.small' 'reset',
            'medium': 'reset' 'base.small'
        )
    ),
    'dropdown': (
        'default': (
            'content': (
                'inner': (
                    'small': 'base.xsmall' 'base.small'
                )
            )
        )
    ),
    'toolbar': (
        'default': (
            'page-count': (
                'small': 'base.xsmall',
                'large': 'reset' 'base.xsmall'
            )
        )
    ),
    'table': (
        'default': (
            'th': 'base.small' 'base.medium' 'padding.nano',
            'td': 'base.small' 'base.medium' 'padding.nano',
        ),
        'responsive': (
            'th': 'button.small' 'base.medium',
            'td': 'button.micro' 'base.medium'
        ),
        'secondary': (
            'th': 'base.xsmall' 'base.medium',
            'td': 'base.xsmall' 'base.medium',
            'outer': 'base.medium'
        ),
        'mobile': (
            'th': 'button.micro' 'base.medium',
            'td': 'button.micro' 'base.medium'
        )
    ),
    'tooltip': (
        'default': 'padding.micro'
    ),
    'item-actions': (
        'default': (
            'icon': 'mimic-whitespace-character',
            'between': 'base.small'
        )
    ),
    'cta': (
        'overlapping-image-cta': (
            'small': 'base.medium',
            'large': 'base.large'
        )
    ),
    'product-grid': (
        'item': (
            'name': 'base.small',
            'price': 'base.small',
            'reviews': 'base.small',
            'fitment': 'base.small'
        )
    ),
    'fitment': (
        'label': 'base.xxxsmall' 'base.xxsmall',
        'mini-garage': (
            'empty-message': (
                'title': 'base.xsmall',
                'item-spacing': 'base.small'
            ),
            'accordion-link-outer': 'base.xsmall',
            'accordion-icon': 'base.xxsmall',
            'garage-items': (
                'action-outer': 'base.xsmall',
                'icon-outer': 'base.xsmall'
            )
        )
    ),
    'slider': (
        'product': (
            'dot': (
                'outer': (
                    'small': 'base.xlarge',
                    'large': 'base.xxlarge',
                    'xxlarge': 'base.xxxxlarge'
                )
            ),
            'actions': 'base.xlarge'
        ),
        'hero': (
            'controls': (
                'outer': 'base.large'
            ),
            'control-items': (
                'outer': 'base.small'
            ),
            'section': (
                'inner': (
                    'small': 'base.large' 'reset',
                    'xxlarge': 'base.xxlarge' 'reset'
                )
            )
        )
    ),
    'shop-for-bikes-modal': (
        'inner': 'base.small',
        'between': 'base.xsmall',
        'header': 'padding.small',
        'title-icon': (
            'small': 'reset',
            'medium': 'base.xxlarge'
        )
    ),
    'footer': (
        'back-to-top-gap': 'footer.back-to-top',
        'back-to-top-outer': (
            'small': 'base.xlarge' 'reset' 'reset',
            'medium': 'reset' 'reset' 'reset' 'base.small',
            'xlarge': 'reset'
        ),
        'small-column-gap': 'base.xsmall',
        'links-column-gap': 'base.small',
        'newsletter-gap': 'base.large',
        'content': 'base.xlarge',
        'phone-link': (
            'small': 'reset',
            'xlarge': 'base.small'
        ),
        'large-table-gap': (
            'small': 'reset',
            'medium': 'base.large'
        ),
        'inner-logo-gap': (
            'small': 'reset',
            'medium': 'base.large',
            'large': 'reset'
        ),
        'outer-logo-gap': (
            'small': 'reset',
            'large': 'base.large'
        ),
        'social-icon-container': (
            'small': 'base.small',
            'xlarge': 'reset',
            'xxlarge': 'base.small'
        ),
        'newsletter': 'footer.newsletter',
        'pre-footer-icons-side': 'base.small',
        'social-links-gap': (
            'small': 'base.large',
            'xlarge': 'reset'
        ),
        'table': (
            'small': 'base.medium',
            'medium': 'base.xxxlarge',
            'large': 'reset'
        ),
        'image-gap': (
            'small': 'base.small',
            'medium': 'reset',
            'large': 'base.small'
        ),
        'nav-link-gap': (
            'small': 'base.xsmall',
            'large': 'base.small'
        ),
        'bottom': (
            'section': (
                'inner': (
                    'small': 'reset' 'reset' 'base.xlarge',
                    'medium': 'base.xsmall' 'reset' 'base.xlarge',
                    'large': 'base.xlarge' 'reset' 'base.xlarge'
                )
            )
        ),
        'payment-list-gap': (
            'small': 'base.small',
            'xlarge': 'base.medium'
        ),
        'additional-right': (
            'small': 'base.large',
            'large': 'base.xlarge',
            'xlarge': 'reset'
        ),
        'top-block': (
            'small': 'base.medium',
            'medium': 'base.large',
            'large': 'base.xlarge'
        ),
        'bottom-block': (
            'small': 'base.large',
            'xlarge': 'base.xxlarge'
        ),
        'pre-footer-icons': (
            'small': 'base.medium',
            'large': 'reset'
        ),
        'pre-footer-payment-icon': (
            'small': 'base.medium',
            'medium': 'reset'
        )
    ),
    'maintenance-page': (
        'logo-container': 'base.xsmall' 'base.small' 'base.medium' 'base.small',
        'logo-image': 'base.small',
        'links': 'base.xsmall',
        'offset': (
            'small': 'not-set',
            'large': 'base.xxxxlarge'
        )
    ),
    'top-header-nav': (
        'small': 'base.xsmall'
    ),
    'category-list': (
        'default': (
            'list': 'base.xxlarge'
        )
    ),
    'no-route': (
        'featured-section': (
            'small': 'base.xxlarge',
            'large': 'base.xxxxlarge'
        )
    ),
    'brands-page': (
        'desktop-nav': (
            'section-inner': 'base.medium'
        ),
        'brand-content': (
            'section-outer': 'global.xlarge'
        ),
        'section': (
            'small': 'base.medium',
            'medium': 'base.xlarge'
        ),
        'section-title': 'base.small',
        'brand-group': (
            'inner': 'base.xxsmall'
        )
    ),
    'customer': (
        'account': (
            'action': 'base.xsmall',
            'sidebar': (
                'mobile-accordion': (
                    'section': 'grid-gutters.small'
                )
            ),
            'address-labels': 'reset' 'base.xsmall'
        )
    ),
    'cross-sells': (
        'default': 'cross-sells.container',
        'title': 'cross-sells.title'
    ),
    'checkout-button-mobile': (
        'button': 'checkout.button.mobile',
    ),
    'checkout': (
        'header': (
            'toolbar': (
                'steps': (
                    'item': 'padding.small',
                    'between': 'padding.small'
                )
            ),
            'trust': (
                'between': 'base.medium'
            )
        ),
        'footer': (
            'logo': (
                'small': 'base.xxsmall' 'base.xsmall',
                'large': 'base.xxsmall' 'base.small'
            ),
            'inner': (
                'small': 'padding.small',
                'medium': 'reset'
            ),
            'icons': (
                'between': (
                    'small': 'base.xxsmall',
                    'medium': 'reset'
                )
            ),
            'outer': 'base.xxxxlarge'
        ),
        'steps': (
            'actions': (
                'between': (
                    'small': 'base.xsmall'
                )
            ),
            'option-sets': (
                'header': (
                    'small': 'base.xsmall' 'padding.small'
                ),
                'body': (
                    'small': 'padding.small' 'base.medium'
                )
            ),
            'cc-forms': (
                'inline-fields': (
                    'small': 'not-set',
                    'large': 'base.small' 'base.small' 'reset'
                ),
                'labels': (
                    'small': 'base.small' 'not-set' 'base.xsmall',
                    'large': 'reset'
                ),
                'inner': (
                    'small': 'base.small'
                )
            )
        )
    ),
    'gallery': (
        'nav-image': 'base.xsmall'
    ),
    'breadcrumbs': 'base.medium',
    'delivery-timeline': (
        'inner': (
            'small': 'base.xxsmall'
        )
    ),
    'search-results': (
        'padding': 'base.small',
        'margin': (
            'small': 'base.medium' 'reset' 'reset',
            'large': 'reset' 'reset' 'base.medium'
        ),
        'list': (
            'small': 'base.small'
        )
    )
);
