@mixin \northern\checkout\header\apply() {
    .checkout-page-header {
        .checkout-header-main {
            @include \kindling\color\apply-background-color($checkout-header-main-background-color, $checkout-header-main-foreground-colors);

            .checkout-header-main-inner {
                @include \compass\layout\container-padded();
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        .checkout-header-main-right {
            display: flex;
            flex: 1 1 auto;
            align-items: center;
            justify-content: flex-end;

            > div {
                &:not(:first-child) {
                    @include \kindling\spacing\apply('checkout.header.trust.between', $margin-sides: ('left'));

                    @include \kindling\breakpoint\media-query('small' 'down') {
                        @include \kindling\spacing\apply('reset', $margin-sides: ('left'));
                    }
                }
            }
        }

        .checkout-header-logo-container {
            @include \kindling\property\apply('min-height', $\compass\checkout\header\height);
            @include \kindling\property\apply('width', $\compass\checkout\header\logo-width);
            display: flex;
            align-items: center;
            background: \kindling\color\get('header.logo.background-color');
            height: 100%;

            .logo {
                @include \kindling\spacing\apply('header.checkout-logo', $padding-sides: ('all'));
                display: flex;
                align-items: center;
                height: 100%;

                img {
                    width: 100%;
                }
            }
        }

        .checkout-header-bottom {
            background-color: \kindling\color\get($checkout-header-bottom-background-color);

            .checkout-header-bottom-inner {
                @include \compass\layout\container-padded();
            }
        }

        .checkout-header-contact {
            .contact-icon {
                @include \kindling\icon\apply($icon: $checkout-header-contact-icon) {
                    @include \kindling\typography\font-size($checkout-header-main-icon-font-size);
                }
            }

            .contact-text-extra {
                display: none;

                @include \kindling\breakpoint\media-query('medium') {
                    display: initial;
                }
            }
        }

        .checkout-header-trust-elements {
            display: none;

            @include \kindling\breakpoint\media-query('medium') {
                display: initial;
            }

            .secure-icon {
                @include \kindling\icon\apply($icon: $checkout-header-trust-element-secure-icon) {
                    @include \kindling\typography\font-size($checkout-header-main-icon-font-size);
                }
            }
        }

        .checkout-header-contact,
        .checkout-header-trust-elements {
            p {
                @include \kindling\typography\font-size('checkout.header.text')
            }

            > * {
                display: inline-block;
            }
        }
    }
}
