// Buttons should conform to brand guidelines:
// - google: https://developers.google.com/identity/branding-guidelines
// - facebook: https://developers.facebook.com/docs/facebook-login/userexperience/#buttondesign and https://en.facebookbrand.com/#brand-guidelines-assets
@mixin \compass\social-login\button(
    $type,
    $size: $\compass\social-login\button\default-size,
    $use-before: true
) {
    $style: \kindling\context\concat-key-parts('social-login', $type);
    $icon-box-width: $\compass\social-login\button\icon-size + \kindling\value\get-side($\compass\social-login\button\icon-spacing-inner-size, 'left') + \kindling\value\get-side($\compass\social-login\button\icon-spacing-inner-size, 'right');

    @include \compass\button\base(
        $style: $style,
        $size: $size
    );
    @include \kindling\icon\selector($use-before) {
        position: absolute;
        top: \kindling\value\get-side($\compass\social-login\button\icon-spacing-outer-size, 'top');
        left: \kindling\value\get-side($\compass\social-login\button\icon-spacing-outer-size, 'left');
        background: url('#{\kindling\map\get($\compass\social-login\button\icon-icon, $type)}') center center / $\compass\social-login\button\icon-size no-repeat;
        width: $icon-box-width;
        height: 100%;
        content: '';
    }
    position: relative;
    padding-left: $icon-box-width + \kindling\value\get-side($\compass\social-login\button\icon-spacing-outer-size, 'left') + $\compass\social-login\button\icon-box-to-text-spacing;
}

@mixin \compass\social-login\button\apply-all(
    $selector-prefix: '.social-login-link-',
    $size: $\compass\social-login\button\default-size
) {
    @each $type in $\compass\social-login\button\enabled-types {
        #{$selector-prefix}#{$type} {
            @include \compass\social-login\button($type, $size);
        }
    }
}

@mixin \compass\social-login\buttons\apply() {
    @include \compass\social-login\button\apply-all();

    .social-login-link-list {
        @include \kindling\typography\unstyled-list();
        @include \compass\button\grid-row($column-gutters: 'button-grid.large');
        flex-direction: column;

        @include \kindling\breakpoint\media-query($\compass\social-login\breakpoint) {
            flex-direction: row;
        }
    }

    .social-login-link {
        border-radius: \kindling\value\rem-calc(5);
    }
}

@mixin \compass\social-login\buttons\login-page\apply() {
    @include \compass\social-login\buttons\apply();

    .social-login-legend {
        @include \compass\typography\heading(
            $heading-type: 'h2',
            $include-spacing: true
        );
    }
}
