@mixin \compass\responsive-table\table\mobile() {
    @include \kindling\table\apply(
        $is-hover: false,
        $head-cell-font-weight: null,
        $head-background-color: \kindling\color\get('primary'),
        $head-font-color: \kindling\color\get('white'),
        $border-color: $table-border-color,
        $is-striped: true,
        $stripe: even,
        $striped-background-color: \kindling\color\get('neutral-lighter')
    );

    > thead {
        @include \compass\table\heading($context: 'table.responsive');
    }

    > tbody {
        @include \compass\table\body($context: 'table.responsive');
        @include \compass\table\heading($context: 'table.mobile');

        > tr {
            @include \compass\table\body\row($context: 'table.responsive');

            > th {
                text-align: left;
                text-transform: none;
            }
        }
    }
}

@mixin \compass\responsive-table\table() {
    @include \compass\table\apply();
}

@mixin \compass\responsive-table\accordion() {
    .responsive-table-accordion {
        @include \project\accordion\table\container();
    }

    .responsive-table-accordion-item {
        @include \project\accordion\table\item();
    }

    .responsive-table-accordion-title {
        @include \project\accordion\table\title();
    }

    .responsive-table-accordion-body {
        @include \project\accordion\table\body();

        .responsive-table-mobile-table {
            @include \compass\responsive-table\table\mobile();
        }
    }
}

@mixin \compass\responsive-table\apply() {
    @include \compass\responsive-table\accordion();
    @include \compass\responsive-table\table();
}
