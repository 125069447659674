@mixin \compass\catalog\product\pdp\configurable-options\apply() {
    .catalog-product-view {
        .product-options-wrapper {
            @include \kindling\spacing\apply('pdp.product-info.options', $margin-sides: ('bottom'));
            @include \project\fitment\mini-garage\list\apply();
            @include \compass\forms\apply();

            .swatch-attribute {
                &.color {
                    @include \kindling\spacing\apply('form.default.input.margin', $margin-sides: ('bottom'));
                }
            }

            .fitment-message {
                > * {
                    margin-top: 0;
                }
            }

            .product-fitment-options {
                @include \kindling\spacing\apply('form.default.input.margin', $margin-sides: ('bottom'));
            }

            .product-label {
                display: inline-block;
                margin-right: \kindling\value\rem-calc(5);
            }

            .product-label-icon {
                @include \kindling\icon\apply($icon: $icon-garage) {
                    @include \kindling\typography\font-size('pdp.product-info.fitment-label.icon');
                }

                @each $name, $icon in $\project\fitment\fitment-icons {
                    &.icon-#{$name} {
                        @include \kindling\icon\selector() {
                            content: $icon;
                        }
                    }
                }
            }

            .product-label-text {
                @include \compass\typography\heading('h6');
            }

            .fitment-accordion {
                @include \compass\accordion\container();
            }

            .fitment-accordion-item {
                &.is-active {
                    .fitment-accordion-title {
                        @include \kindling\icon\selector($use-before: false) {
                            content: $icon-triangle-up;
                        }
                    }
                }
            }

            .fitment-accordion-title {
                @include \compass\button\reset-browser-button();
                @include \kindling\icon\apply($icon: $icon-triangle-down, $use-before: false) {
                    color: \kindling\color\get('dropdown.action-dropdown.icon');
                }
                @include \compass\typography\font\apply('primary', 'semi-bold');
                @include \kindling\typography\font-size('pdp.product-info.fitment-label.action');
            }

            .fitment-accordion-body {
                @include \kindling\spacing\apply('form.default.input.margin', $padding-sides: ('top'));
                display: none;

                .garage-vehicles {
                    @include \kindling\spacing\apply('form.default.input.margin', $margin-sides: ('top'), $ratio: -1);
                }
            }
        }
    }
}
