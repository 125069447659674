@mixin \project\fitment\vehicle-navigator-form\sidebar\form\apply() {
    @include \project\fitment\vehicle-navigator-form\base\apply();
    position: relative;
    z-index: \kindling\z-index\get('above-sibling');

    .vehicle-navigator-form-accordion-body {
        $text-color: 'white';

        .vehicle-label-text {
            @include \compass\typography\heading('h6');

            color: \kindling\color\get($text-color);
        }

        .vehicle-label-icon {
            @include \kindling\icon\apply($icon: $icon-garage) {
                @include \kindling\typography\font-size('pdp.product-info.fitment-label.icon');
            }

            @each $name, $icon in $\project\fitment\fitment-icons {
                &.icon-#{$name} {
                    @include \kindling\icon\selector() {
                        content: $icon;
                    }
                }
            }
        }

        .vehicle-clear-button {
            @include \compass\button\reset-browser-button();
            @include \compass\typography\font\apply('primary', 'semi-bold');
            @include \kindling\typography\font-size('pdp.product-info.fitment-label.action');
            @include \kindling\spacing\apply('accordion.sidebar.button', $margin-sides: ('top' 'bottom'));

            display: block;
            transform: translateY(-50%);
            text-decoration: underline;
            color: \kindling\color\get($text-color);
        }

        .search-input {
            @include \kindling\spacing\apply('form.search.input.padding', $padding-sides: ('top', 'bottom', 'right'));
            @include \kindling\typography\font-size('form.search.input');
            @include \kindling\typography\line-height('form.search.input');
            @include \kindling\button\border('form.search.default');
            border: 0;
            border-radius: 0;
            background-color: \kindling\color\get('form.search.input.background-color');
            padding-left: calc(#{$\compass\search\header\button-width} + #{\kindling\value\rem-calc(2)});
            width: 100%;
            color: \kindling\color\get('form.search.input.text-color');
            appearance: none;

            &:focus,
            &:hover {
                @include \kindling\button\border('form.search.active');
                outline: 0;
            }

            &::placeholder {
                @include \kindling\property\apply('left', \kindling\value\get-side(\kindling\spacing\get('form.default.input-small-sneaky.padding'), 'left'));
                padding-left: calc(#{$\compass\search\header\button-width} - #{\kindling\value\rem-calc(10)});
            }
        }

        .search-action {
            $border-width: \kindling\border\get-property(\kindling\border\get('form.search.active'), 'width');

            @include \compass\button\reset-browser-button();
            @include \kindling\color\apply-background-color('form.search.input.background-color', 'form.search.input.text-color');

            display: flex;
            position: absolute;
            top: $border-width;
            left: $border-width;
            align-items: center;
            justify-content: center;
            z-index: \kindling\z-index\get('above-sibling');
            width: $\compass\search\header\button-width;
            height: calc(100% - #{$border-width * 2});
            text-align: center;

            &:hover,
            &:focus {
                .search-icon {
                    color: \kindling\color\get('form.search.mobile.submit-icon-color-hover');
                }
            }

            &:disabled {
                pointer-events: none;
            }

            .search-icon {
                @include \kindling\icon\apply($icon: $icon-search) {
                    @include \kindling\typography\font-size('form.search.icon');
                }
                width: 100%;
                color: \kindling\color\get('form.search.mobile.submit-icon-color');

                &::before {
                    vertical-align: 0;
                }
            }
        }

        .label-search {
            padding-left: calc(#{$\compass\search\header\button-width} - #{\kindling\value\rem-calc(10)});
        }
    }
}

@mixin \project\fitment\vehicle-navigator-form\sidebar\apply() {
    @include \compass\layout\section-overlap(
        $top-section-selector: false,
        $bottom-section-selector: '.vehicle-navigation-container',
        $overlap: (
            'small': \kindling\value\get-side(\kindling\spacing\get('main-content-container.category.small'), 'top') + \kindling\value\rem-calc(20),
            'large': \kindling\value\get-side(\kindling\spacing\get('main-content-container.category.large'), 'top') + \kindling\value\rem-calc(62)
        )
    );

    .vehicle-navigation-container {
        @include \project\fitment\vehicle-navigator-form\sidebar\form\apply();
        @include \project\fitment\mini-garage\accordion-featured\apply();
        @include \project\fitment\mini-garage\empty-message-featured\apply();
        @include \project\fitment\mini-garage\list\apply();

        .mini-garage-login-link {
            display: none;
        }
    }
}
