@mixin \compass\catalog\gallery\slider-base() {
    @include \compass\slider\base\apply();
    @include \compass\slider\base\layout();
    @include \compass\slider\base\prevent-fouc-by-hiding-until-ready();
}

@mixin \compass\catalog\gallery\slide-overlay() {
    @include \catalog\product-image\overlay\apply($z-index: 'above-sibling');
}


@mixin \compass\catalog\gallery\fullscreen-modal() {
    @include \compass\modal\apply(
        $width: 100%,
        $max-width: $global-max-width * (\kindling\value\strip-unit(\kindling\grid\column-width-calc(8)) / 100)
    );
    height: 100vh;


    @media screen and (min-height: $\compass\catalog\gallery\vertical-breakpoint) {
        height: auto;
    }

    &.is-open {
        display: flex;
    }

    .gallery-modal-close-button {
        @include \compass\modal\close-button();
    }
}

@mixin \compass\catalog\gallery\main-slider-layout() {
    $grid-area-name: \compass\grid\area-name('gallery-main');
    // This usage of grid seems redundant, but is purely for proper centering
    // and constraining images in all browsers.
    //
    // Browsers this fix is for: IE 11, safari 12.1.2+ (mac os)
    display: grid !important; // sass-lint:disable-line no-important
    height: 100%;
    grid-template-areas: '#{$grid-area-name}';
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    > * {
        align-self: center;
        max-width: 100%;
        max-height: 100%;
        grid-area: $grid-area-name;
        justify-self: center;
    }
}

@mixin \compass\catalog\gallery\slider-nav-button($image-sizes) {
    @include \compass\button\reset-browser-button();
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    img {
        @include \kindling\property\apply('width', $image-sizes);
    }
}

@mixin \compass\catalog\gallery\loader() {
    position: relative;

    &.main-slider-loading {
        &::before {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: \kindling\z-index\get('above-all-siblings');
            background: \kindling\color\get('gallery.default.loader.background') url('../images/loader-1.gif') no-repeat center center;
            width: 100%;
            height: 100%;
            content: '';
        }

        > * {
            visibility: hidden;
        }

        .gallery-main-slider {
            $sizes: (
                'small': \kindling\value\rem-calc(250) !important, // sass-lint:disable-line no-important
                $\compass\catalog\gallery\breakpoint: \kindling\value\rem-calc(250) !important // sass-lint:disable-line no-important
            );
            @include \kindling\property\apply('min-height', $sizes);
        }
    }
}

@mixin \compass\catalog\gallery\main-slider() {
    position: relative;
    flex: 0 0 auto;
    width: 100%;

    @include \kindling\breakpoint\media-query($\compass\catalog\gallery\breakpoint) {
        flex: 1 1 auto;
        width: calc(100% - #{$\compass\catalog\gallery\thumbnail\width + \kindling\value\rem-calc(20)});
    }

    .gallery-main-slider-modal-button {
        @include \compass\button\icon-only(
            $icon: $icon-zoom-in,
            $icon-size: 0.6,
            $context: $\compass\catalog\gallery\context,
            $icon-color: 'zoom.icon-color',
            $icon-color-hover: 'zoom.icon-color',
            $background-color: 'zoom.background-color',
            $background-color-hover: 'zoom.background-color'
        );
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        box-shadow: \kindling\map\get($\compass\button\shadows, 'top-left');
    }

    .gallery-main-slider {
        @include \compass\catalog\gallery\slider-base();
        @include \compass\catalog\gallery\main-video-slide();

        @include \kindling\breakpoint\media-query($\compass\catalog\gallery\breakpoint) {
            height: 100%;
        }
    }

    .slick-list {
        @include \kindling\breakpoint\media-query($\compass\catalog\gallery\breakpoint) {
            height: 100%;
        }
    }

    .slick-track {
        @include \kindling\breakpoint\media-query($\compass\catalog\gallery\breakpoint) {
            height: 100%;
        }
    }

    .slider-item {
        @include \compass\catalog\gallery\main-slider-layout();
        @include \compass\catalog\gallery\slide-overlay();
    }

    .gallery-main-slider-image-button {
        @include \compass\button\reset-browser-button();
    }
}

@mixin \compass\catalog\gallery\nav-slider() {
    @include \compass\slider\base\bug-fix\flexbox();
    @include \kindling\spacing\apply('global.large', $margin-sides: ('top'));
    display: grid;
    position: relative;
    flex: 0 0 auto;
    margin-left: -#{\kindling\spacing\get('gallery.nav-image')};
    width: 100%;

    $grid-area-start: \compass\grid\area-name('start');
    $grid-area-end: \compass\grid\area-name('end');

    $grid-template-areas: (
        'small': '#{$grid-area-start} #{$grid-area-end}',
        $\compass\catalog\gallery\breakpoint: '#{$grid-area-start}' '#{$grid-area-end}'
    );
    $grid-template-columns: (
        'small': 1fr,
        $\compass\catalog\gallery\breakpoint: 1fr auto
    );
    $grid-template-rows: (
        'small': 1fr auto,
        $\compass\catalog\gallery\breakpoint: 1fr
    );

    // grouped together so autoprefixer can generate correct styles for IE 11
    @each $breakpoint, $grid-template in $grid-template-areas {
        @include \kindling\breakpoint\media-query($breakpoint) {
            grid-template-areas: $grid-template;
            grid-template-columns: \kindling\breakpoint\get-breakpoint-value($grid-template-columns, $breakpoint);
            grid-template-rows: \kindling\breakpoint\get-breakpoint-value($grid-template-rows, $breakpoint);
            grid-gap: \kindling\value\rem-calc(10);
        }
    }

    @include \kindling\breakpoint\media-query($\compass\catalog\gallery\breakpoint) {
        @include \kindling\spacing\apply('global.small', $margin-sides: ('right'));
        @include \kindling\spacing\apply('reset', $margin-sides: ('top'));
        order: -1;
        margin-top: -#{\kindling\spacing\get('gallery.nav-image')};
        margin-left: 0;
        width: \kindling\value\rem-calc(80);
    }

    .gallery-nav-slider {
        @include \compass\catalog\gallery\slider-base();
        grid-area: $grid-area-start;

        &.slick-slider {
            @include \kindling\spacing\apply('reset', $padding-sides: ('left' 'right'));
        }
    }

    .slick-slide {
        @include \kindling\spacing\apply('gallery.nav-image', $padding-sides: ('left'));

        @include \kindling\breakpoint\media-query($\compass\catalog\gallery\breakpoint) {
            @include \kindling\spacing\apply('gallery.nav-image', $padding-sides: ('top'));
            @include \kindling\spacing\apply('reset', $padding-sides: ('left'));
        }

        &.slick-current {
            .slider-item {
                @include \kindling\border\apply('gallery.active-item');
            }
        }

        .slider-item {
            @include \kindling\border\apply('gallery.default-item');
            @include \compass\catalog\gallery\slide-overlay();
            display: flex !important; // sass-lint:disable-line no-important
            align-items: center;
            justify-content: center;
        }
    }

    .gallery-nav-slider-button {
        $image-sizes: (
            'small': \kindling\value\rem-calc(55),
            'large': \kindling\value\rem-calc(60)
        );
        @include \compass\catalog\gallery\slider-nav-button($image-sizes);
    }

    .gallery-nav-slider-button-video {
        @include \compass\catalog\gallery\video-play-button($size: \kindling\value\rem-calc(20));
    }

    .gallery-nav-slider-modal-button {
        @include \compass\button\icon-only(
            $icon: $icon-plus,
            $icon-size: 0.6,
            $context: $\compass\catalog\gallery\context,
            $icon-color: 'zoom.icon-color',
            $icon-color-hover: 'zoom.icon-color',
            $background-color: 'zoom.background-color',
            $background-color-hover: 'zoom.background-color'
        );
        align-self: center;
        justify-self: center;
        grid-area: $grid-area-end;
    }
}

@mixin \compass\catalog\gallery\fullscreen-main-slider() {
    @include \compass\catalog\gallery\slider-base();
    @include \compass\catalog\gallery\fullscreen-video-slide();
    flex: 1 1 auto;
    height: 100%;
    overflow: hidden;

    .slick-list,
    .slick-track {
        height: 100%;
    }

    .slick-slide {
        @media screen and (min-height: $\compass\catalog\gallery\vertical-breakpoint) {
            max-height: $\compass\catalog\gallery\max-image-height;
        }

        > * {
            height: 100%;
        }
    }

    .slider-item {
        @include \compass\catalog\gallery\main-slider-layout();
    }
}

@mixin \compass\catalog\gallery\fullscreen-nav-slider() {
    @include \kindling\spacing\apply(
        $spacing: 'grid-gutters',
        $padding-sides: ('top', 'bottom')
    );
    flex: 0 0 auto;

    .gallery-modal-nav-slider-container {
        @include \compass\layout\container($column-count: ('small': 8, 'medium': 10, $\compass\catalog\gallery\breakpoint: 8));
    }

    .gallery-modal-nav-slider {
        @include \compass\catalog\gallery\slider-base();
        @include \kindling\spacing\apply('gallery.nav-image', $padding-sides: ('left', 'right'));

        .slick-slide {
            @include \kindling\spacing\apply('gallery.nav-image', $padding-sides: ('right'));

            &.slick-current {
                .slider-item {
                    @include \kindling\border\apply('gallery.active-item');
                }
            }
        }

        .slider-item {
            @include \kindling\border\apply('gallery.default-item');
            @include \compass\catalog\gallery\slide-overlay();
        }
    }

    .gallery-modal-nav-slider-button {
        @include \compass\catalog\gallery\slider-nav-button(('small': \kindling\value\rem-calc(60)));
    }

    .gallery-modal-nav-slider-button-video {
        @include \compass\catalog\gallery\video-play-button($size: \kindling\value\rem-calc(20));
    }

    .slick-arrow {
        @include \compass\slider\base\arrow\apply($context: $\compass\catalog\gallery\context);

        &.slick-prev {
            @include \kindling\property\apply('left', $\compass\slider\arrow\button-size, $ratio: -1);
        }

        &.slick-next {
            @include \kindling\property\apply('right', $\compass\slider\arrow\button-size, $ratio: -1);
        }

        &::before {
            display: block;
            margin: 0 auto;
        }
    }

    .slick-slide {
        align-items: center;
        text-align: center;
        font-size: 0; // remove inline-block spacing
    }
}

@mixin \compass\catalog\gallery\apply() {
    .gallery-main-wrapper {
        @include \compass\catalog\gallery\loader();
        @include \compass\slider\base\bug-fix\flexbox();
        display: flex;
        flex-direction: column;
        width: 100%;

        @include \kindling\breakpoint\media-query($\compass\catalog\gallery\breakpoint) {
            flex-direction: row;
            align-items: stretch;
        }
    }

    .gallery-main-slider-container {
        @include \compass\catalog\gallery\main-slider();
    }

    .gallery-nav-slider-container {
        @include \compass\catalog\gallery\nav-slider();
    }

    @at-root {
        .gallery-modal {
            @include \compass\catalog\gallery\fullscreen-modal();
        }

        .gallery-modal-main-slider {
            @include \compass\catalog\gallery\fullscreen-main-slider();
        }

        .gallery-modal-nav-slider-row {
            @include \compass\catalog\gallery\fullscreen-nav-slider();
        }
    }
}
