@mixin \compass\pullout-menu\apply(
    $context: $\compass\pullout-menu\context,
    $side: $\compass\pullout-menu\side,
    $top: $\compass\pullout-menu\top,
    $width: $\compass\pullout-menu\width,
    $transition-duration: $\compass\pullout-menu\transition-duration,
    $z-index: $\compass\pullout-menu\z-index
) {
    $background-color: \kindling\color\context-keys-calc('background', $context, $\compass\pullout-menu\context);

    @include \compass\animation\slide-in\hidden-state(
        $duration: $transition-duration,
        $slide-from: $side
    );
    position: fixed;
    top: $top;
    #{$side}: 0;
    bottom: 0;
    z-index: \kindling\z-index\get($z-index);
    background-color: \kindling\color\get($background-color);
    width: $width;

    &.pullout-menu-is-open {
        @include \compass\animation\slide-in\active-state();
    }
}

@mixin \compass\pullout-menu\overlay(
    $top: $\compass\pullout-menu\overlay\top,
    $transition-duration: $\compass\pullout-menu\overlay\display-duration,
    $z-index: $\compass\pullout-menu\overlay\z-index
) {
    @include \compass\overlay\button();
    @include \compass\animation\fade-in\hidden-state($duration: $transition-duration);
    top: $top;
    z-index: \kindling\z-index\get($z-index);

    &.pullout-menu-is-open {
        @include \compass\animation\fade-in\active-state($duration: $transition-duration);
    }
}
