@mixin \compass\slider\reset-button-styles() {
    @include \compass\button\reset-browser-button();
    font-size: 0; // make text take up no space while leaving it readable for screen readers
}

@mixin \compass\slider\base\arrow\apply(
    $size: $\compass\slider\arrow\button-size,
    $icon-size: $\compass\slider\arrow\icon-ratio,
    $context: $\compass\slider\context,
    $arrow-offset: 0
) {
    @include \compass\slider\reset-button-styles();
    @include \compass\button\icon-only-base(
        $size: $size,
        $icon-size: $icon-size,
        $context: $context,
        $icon-color: 'arrow.icon-color',
        $icon-color-hover: 'arrow.icon-color-hover',
        $background-color: 'arrow.background-color',
        $background-color-hover: 'arrow.background-color-hover',
        $border: 'arrow.default',
        $border-hover: 'arrow.hover'
    );
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: \kindling\z-index\get('above-sibling');

    &.slick-prev {
        @include \kindling\icon\apply($icon: $icon-arrow-left);
        @include \kindling\property\apply('left', $arrow-offset);
    }

    &.slick-next {
        @include \kindling\icon\apply($icon: $icon-arrow-right);
        @include \kindling\property\apply('right', $arrow-offset);
    }
}

@mixin \compass\slider\base\prevent-fouc-by-hiding-until-ready() {
    &:not(.slick-initialized) {
        &,
        * {
            visibility: hidden;
        }

        > * {
            &:not(:first-child) {
                display: none;
            }
        }
    }
}

// Fix flexbox bug where slick slider grows to millions of pixels wide.
// The cause of this problem is display flex on one of it's parent elements.
//
// Apply this mixin to the parent of the slider.
// https://github.com/kenwheeler/slick/issues/982#issuecomment-237399735
@mixin \compass\slider\base\bug-fix\flexbox() {
    &,
    & > * {
        min-width: 0;
        min-height: 0;
    }
}

// Alternate fix for flexbox bug where slick slider grows to millions of pixels wide.
// The cause of this problem is display flex on one of it's parent elements.
// Use this is when \compass\slider\base\bug-fix\flexbox doesn't work
//
// Apply this to the slider parent.
@mixin \compass\slider\base\bug-fix\flexbox-alternate($slider-selector) {
    min-width: 0;
    min-height: 0;

    // from https://github.com/kenwheeler/slick/issues/982#issuecomment-408044585
    #{$slider-selector} {
        width: 0;
        min-width: 100%;
    }
}

// Styles ported from slick repo base styles. Try to avoid modifying these since some styles are essential for slick to work.
@mixin \compass\slider\base\slick-default\apply() {
    &.slick-slider {
        display: block;
        position: relative;
        box-sizing: border-box;
        user-select: none;
        touch-action: pan-y;
    }

    &.slick-initialized {
        .slick-slide {
            display: block;
        }
    }

    &.slick-loading {
        .slick-slide {
            visibility: hidden;
        }

        .slick-track {
            visibility: hidden;
        }

        img {
            display: none;
        }
    }

    &.slick-vertical {
        .slick-slide {
            display: block;
            height: auto;
        }
    }

    .slick-list {
        display: block;
        position: relative;
        margin: 0;
        padding: 0;
        overflow: hidden;

        &:focus {
            outline: none;
        }

        &.dragging {
            cursor: pointer;
        }
    }

    .slick-track {
        display: block;
        position: relative;
        top: 0;
        left: 0;

        &::before,
        &::after {
            display: table;
            content: '';
        }

        &::after {
            clear: both;
        }
    }

    .slick-track,
    .slick-list {
        transform: translate3d(0, 0, 0);
    }

    .slick-slide {
        display: none;
        float: left;
        height: 100%;
        min-height: \kindling\value\rem-calc(1);

        img {
            display: block;
        }

        &.dragging {
            img {
                pointer-events: none;
            }
        }
    }

    .slick-arrow {
        &.slick-hidden {
            display: none;
        }
    }
}

@mixin \compass\slider\base\layout($align-items: null) {
    &.slick-initialized {
        .slick-slide {
            display: flex;
        }
    }

    &.slick-vertical {
        .slick-track {
            flex-direction: column;
        }
    }

    .slick-track {
        display: flex;
    }

    .slick-slide {
        display: flex;
        flex: 0 0 auto;
        align-items: $align-items;
        height: auto;

        > * {
            width: 100%; // fix IE 11 image stretching bug
        }
    }
}

@mixin \compass\slider\base\container\apply() {
    position: relative;
    z-index: \kindling\z-index\get('neutral-sibling'); // create stacking context to prevent child item z-index from interacting with foreign elements
}

@mixin \compass\slider\base\apply() {
    @include \compass\slider\base\slick-default\apply();
    @include \compass\slider\base\container\apply();

    .slick-slide {
        position: relative;
    }
}

@mixin \compass\slider\base\dot\active(
    $active-color: 'dot.background-color-active',
    $context: $\compass\slider\context
) {
    $active-color: \kindling\color\context-keys-calc($active-color, $context, $\compass\slider\context);
    background-color: \kindling\color\get($active-color);
}

@mixin \compass\slider\base\dot\apply(
    $size: $\compass\slider\dot\size,
    $spacing: $\compass\slider\dot\spacing,
    $context: $\compass\slider\context,
    $background-color: 'dot.background-color',
    $background-color-active: 'dot.background-color-active'
) {
    $background-color: \kindling\color\context-keys-calc($background-color, $context, $\compass\slider\context);
    $background-color-active: \kindling\color\context-keys-calc($background-color-active, $context, $\compass\slider\context);
    @include \kindling\typography\unstyled-list();
    display: flex !important; // sass-lint:disable-line no-important

    li {
        margin-right: $spacing;

        &:last-child {
            margin-right: 0;
        }

        &.slick-active {
            button {
                @include \compass\slider\base\dot\active($background-color-active);
            }
        }
    }

    button {
        @include \compass\slider\reset-button-styles();
        background-color: \kindling\color\get($background-color);
        width: $size;
        height: $size;

        &:hover,
        &:focus {
            @include \compass\slider\base\dot\active($background-color-active);
        }
    }
}
