@mixin \compass\icon\psuedo-button(
    $icon,
    $size,
    $icon-size-ratio,
    $context,
    $icon-color: 'icon-color',
    $icon-color-hover: 'icon-color-hover',
    $background-color: 'background-color',
    $background-color-hover: 'background-color-hover',
    $use-before: true,
    $hover: true
) {
    $size: \kindling\value\to-responsive-map($size);
    $icon-color: \kindling\color\context-keys-calc($icon-color, $context);
    $background-color: \kindling\color\context-keys-calc($background-color, $context);

    @include \kindling\icon\apply($icon: $icon, $use-before: $use-before) {
        @include \kindling\property\apply('width', $size);
        @include \kindling\property\apply('height', $size);
        @each $breakpoint, $value in $size {
            @include \kindling\breakpoint\media-query($breakpoint) {
                font-size: calc(#{$value} * #{$icon-size-ratio});
            }
        }
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: \kindling\color\get($background-color);
        line-height: 1;
        color: \kindling\color\get($icon-color);
        @content;
    }

    @if $hover {
        $icon-color-hover: \kindling\color\context-keys-calc($icon-color-hover, $context);
        $background-color-hover: \kindling\color\context-keys-calc($background-color-hover, $context);

        &:hover,
        &:focus {
            @include \kindling\icon\selector($use-before: $use-before) {
                background-color: \kindling\color\get($background-color-hover);
                color: \kindling\color\get($icon-color-hover);
            }
        }
    }
}
