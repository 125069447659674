@mixin \project\multi-wishlist\wishlist-list() {
    .wishlist-list {
        @include \kindling\typography\unstyled-list();
        @include \kindling\border\apply($color: \kindling\color\get('neutral-lighter'), $sides: ('bottom'));
        @include \kindling\spacing\apply('wishlist.management.list.outer', $margin-sides: ('bottom'));
        display: flex;
        flex-wrap: wrap;

        .item {
            @include \kindling\border\apply($color: \kindling\color\get('neutral-lighter'), $sides: ('top', 'left', 'right'));
            flex-grow: 1;
            width: 100%;

            @include \kindling\breakpoint\media-query('medium') {
                flex-grow: 0;
                width: auto;
            }

            &:not(:first-child) {
                @include \kindling\breakpoint\media-query('medium') {
                    border-left: 0;
                }
            }

            &.current {
                background: \kindling\color\get('neutral-lightest');
            }

            &.wishlist-add {
                > a {
                    @include \kindling\icon\apply($icon: $icon-plus);
                }
            }

            > span,
            > a {
                @include \kindling\spacing\apply($spacing: 'wishlist.management.list.actions', $padding-sides: ('all'));
                @include \compass\typography\font\apply('primary', 'semi-bold');
                @include \kindling\typography\font-size('typography.copy.small');
                display: block;
                text-decoration: none;
            }
        }
    }
}

@mixin \project\multi-wishlist\wishlist-toolbar() {
    .wishlist-toolbar {
        @include \compass\section(
            $context: 'section.medium',
            $border-sides: ('top', 'bottom')
        );
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .wishlist-info {
        display: flex;
        flex: 1 1 auto;
        align-items: center;
        line-height: 2;

        .wishlist-notice {
            @include \compass\typography\font\apply('primary', 'semi-bold');
            @include \kindling\typography\font-size('typography.copy.small');
            @include \kindling\icon\selector() {
                @include \kindling\icon\base();
                margin-right: \kindling\value\rem-calc(5);
            }
            display: flex;

            &.private {
                @include \kindling\icon\selector() {
                    content: $icon-lock-closed;
                }
            }

            &.public {
                @include \kindling\icon\selector() {
                    content: $icon-lock-open;
                }
            }
        }

        .counter {
            @include \kindling\typography\font-size('typography.copy.small');
            margin-left: \kindling\value\rem-calc(15);
        }
    }

    .wishlist-control {
        @include \compass\grid\row(('small': \kindling\value\rem-calc(10)));
        flex: 0 1 auto;
        align-items: center;

        .wishlist-toolbar-actions {
            @include \compass\grid\row(('small': \kindling\value\rem-calc(5)), $spacing-type: 'margin');
            align-items: center;

            > * {
                flex: 0 0 auto;
            }
        }

        .wishlist-toolbar-select {
            flex: 0 0 auto;

            input {
                @include \kindling\form\checkbox();

                &:not(:last-child) {
                    + * {
                        @include \kindling\icon\selector() {
                            @include \kindling\typography\font-size('typography.copy.default');
                        }
                    }
                }

                + label {
                    @include \compass\typography\font\apply('primary', 'semi-bold');
                    @include \kindling\typography\font-size('typography.copy.small');
                }
            }
        }
    }
}

@mixin \project\multi-wishlist\management() {
    .block-wishlist-management {
        @include \project\multi-wishlist\wishlist-list();
        @include \project\multi-wishlist\wishlist-toolbar();

        .wishlist-title {
            @include \kindling\spacing\apply('wishlist.management.title', $margin-sides: ('bottom'));

            > strong {
                @include \compass\typography\heading('h2');
            }

            .action.edit {
                @include \compass\button\item-action\apply(
                    $icon: $\customer\account\icon\edit-action,
                    $icon-font-size: 'typography.copy.small',
                    $font-size: 'typography.copy.small'
                );
            }
        }
    }
}
