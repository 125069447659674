@mixin \compass\footer\main\contact\apply() {
    .footer-contact-container {
        position: relative;
    }

    .page-footer-contact {
        @include \kindling\grid\row();
        @include \compass\typography\utility\set-section-color('footer.contact.color');
        background-color: \kindling\color\get('footer.contact.background-color');
        color: \kindling\color\get('footer.contact.color');

        @include \kindling\breakpoint\media-query($\compass\footer\breakpoint\desktop) {
            flex-wrap: nowrap;
        }

        > div {
            @include \kindling\spacing\apply('footer.newsletter-gap', $padding-sides: ('all'));
            @include \kindling\grid\column(
                $responsive-sizes: (
                    'small': \kindling\grid\column-width-calc(12),
                    'large': \kindling\grid\column-width-calc(6)
                ),
                $flex-grow: 0
            );
        }

        .newsletter-content-title {
            @include \compass\typography\heading(
                $heading-type: 'h3',
                $color: 'footer.contact.color',
                $include-spacing: true
            );
        }

        .subscribe-form-container {
            background-color: \kindling\color\get('footer.contact.form-background-color');
        }

        .newsletter-form-field-container {
            @include \kindling\grid\row();
            @include \kindling\spacing\apply('footer.small-column-gap', $padding-sides: ('bottom'));
            flex-wrap: nowrap;
        }

        .newsletter-form-control {
            @include \kindling\spacing\apply('reset', $margin-sides: ('bottom'));
            flex: 1 1 auto;

            &.is-initialized {
                &:not(.is-empty) {
                    input {
                        @include \compass\form\input\border('form.default.complete', $use-inset: true);
                    }
                }
            }

            input {
                @include \compass\form\input\border($use-inset: true);
            }

            div {
                &.mage-error {
                    color: inherit;
                }
            }
        }

        .newsletter-form-subscribe-container {
            flex: 0 0 auto;
        }

        .newsletter-form-subscribe-button {
            @include \compass\button\primary-dark($size: 'small');
            @include \kindling\typography\line-height('form.newsletter.signup-button');
        }

        .newsletter-legal-text,
        .field-recaptcha {
            @include \kindling\typography\font-size('footer.tiny-link');
            text-align: center;

            @include \kindling\breakpoint\media-query($\compass\footer\breakpoint\mobile) {
                text-align: left;
            }
        }

        .field-recaptcha {
            @include \kindling\spacing\apply('footer.small-column-gap', $padding-sides: ('top'));

            a {
                font-weight: inherit;
            }
        }

        .newsletter-legal-text-links {
            @include \kindling\spacing\apply('footer.small-column-gap', $padding-sides: ('top'));
            display: block;

            @include \kindling\breakpoint\media-query($\compass\footer\breakpoint\mobile) {
                display: inline;
            }

            a {
                display: inline-block;
                font-weight: inherit;

                &:not(:last-child) {
                    @include \kindling\spacing\apply('footer.small-column-gap', $margin-sides: ('right'));
                }

                &:focus,
                &:hover {
                    color: inherit;
                }
            }
        }
    }
}
