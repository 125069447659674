@mixin \compass\image-section\foreground() {
    position: relative;
    z-index: \kindling\z-index\get('above-sibling');
}

@mixin \compass\image-section\background(
    $z-index: 'neutral-sibling'
) {
    @include \kindling\image-fit\apply();
    position: absolute;
    top: 0;
    left: 0;
    z-index: \kindling\z-index\get($z-index);
    width: 100%;
    height: 100%;
}
