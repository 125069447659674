// Sourced from vendor/amasty/base/view/base/web/vendor/slick/amslick.min.css
// Do not customize. Only scope existing styles so they only apply to Amasty sliders.
// These styles used to be applied to all sliders, and caused visual bugs.
@mixin \amasty\slider\apply() {
    // sass-lint:disable-all
    .amslick-slider-container,
    .amblog-list {
        .slick-dots li button:focus,
        .slick-dots li button:hover,
        .slick-list:focus {
            outline: 0
        }

        .slick-list,
        .slick-slider,
        .slick-track {
            display: block;
            position: relative
        }

        .slick-loading .slick-slide,
        .slick-loading .slick-track {
            visibility: hidden
        }

        .slick-list {
            overflow: hidden;
            margin: 0;
            padding: 0
        }

        .slick-list.dragging {
            cursor: pointer;
            cursor: hand
        }

        .slick-slider .slick-list,
        .slick-slider .slick-track {
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);
            -ms-transform: translate3d(0, 0, 0);
            -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0)
        }

        .slick-track {
            top: 0;
            left: 0;
            margin: 0 auto
        }

        .slick-track:after,
        .slick-track:before {
            display: table;
            content: ''
        }

        .slick-slide,
        .slick-slide.slick-loading img {
            display: none
        }

        .slick-track:after {
            clear: both
        }

        .slick-slide {
            float: left;
            height: 100%;
            min-height: 1px;
            vertical-align: middle
        }

        [dir=rtl] & .slick-slide {
            float: right
        }

        .slick-slide.dragging img {
            pointer-events: none
        }

        .slick-vertical .slick-slide {
            display: block;
            height: auto;
            border: 1px solid transparent
        }

        .slick-arrow.slick-hidden {
            display: none
        }

        .slick-loading .slick-list {
            position: relative;
            background: 0 0
        }

        .slick-loading .slick-list:before {
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            content: '';
            border-radius: 100%;
            border-style: solid;
            border-width: 3px;
            height: 25px;
            width: 25px;
            border-color: rgba(0, 0, 0, .2);
            border-top-color: #5c5c5c;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            animation: loading 1s infinite linear
        }

        .slick-next.slick-disabled,
        .slick-prev.slick-disabled {
            opacity: .25
        }

        .slick-dotted.slick-slider {
            margin-bottom: 30px
        }

        .slick-dots {
            position: absolute;
            left: 0;
            display: block;
            width: 100%;
            padding: 0;
            margin: 0;
            list-style: none;
            text-align: center
        }

        .slick-dots li {
            position: relative;
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0 5px;
            padding: 0;
            cursor: pointer
        }

        .slick-dots li button,
        .slick-slider .slick-arrow {
            display: block;
            cursor: pointer;
            outline: 0;
            box-shadow: none
        }

        .slick-dots li button {
            font-size: 0;
            line-height: 0;
            width: 20px;
            height: 20px;
            padding: 5px;
            color: transparent;
            border: 0;
            background: 0 0
        }

        .slick-dots li button:focus:before,
        .slick-dots li button:hover:before {
            opacity: 1
        }

        .slick-dots li button:before {
            font-size: 16px;
            line-height: 20px;
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            content: '•';
            text-align: center;
            opacity: .25;
            color: #000;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale
        }

        .slick-dots li.slick-active button:before {
            opacity: .75;
            color: #000
        }

        .slick-slider {
            box-sizing: border-box;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            -webkit-touch-callout: none;
            -khtml-user-select: none;
            -ms-touch-action: pan-y;
            touch-action: pan-y;
            -webkit-tap-highlight-color: transparent;
            padding: 0 20px
        }

        .slick-slider .slick-arrow {
            position: absolute;
            top: 50%;
            padding: 0;
            width: 15px;
            height: 22px;
            font-size: 0;
            line-height: 0;
            -webkit-transform: translate(0, -50%);
            -moz-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            transform: translate(0, -50%);
            color: transparent;
            border: none;
            background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMjIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0uMi40QTEgMSAwIDAgMSAxLjYuMmwxMiAxMGExIDEgMCAwIDEgMCAxLjZsLTEyIDEwYTEgMSAwIDAgMS0xLjItMS42TDExLjMzMyAxMSAuNCAxLjhBMSAxIDAgMCAxIC4yLjR6IiBmaWxsPSIjQzRDNEM0Ii8+PC9zdmc+) center no-repeat !important;
            -webkit-transition: background .3s ease;
            -moz-transition: background .3s ease;
            -ms-transition: background .3s ease;
            transition: background .3s ease
        }

        .slick-slider .slick-arrow:after,
        .slick-slider .slick-arrow:before {
            content: ''
        }

        .slick-slider .slick-arrow:hover {
            background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMjIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0uMi40QTEgMSAwIDAgMSAxLjYuMmwxMiAxMGExIDEgMCAwIDEgMCAxLjZsLTEyIDEwYTEgMSAwIDAgMS0xLjItMS42TDExLjMzMyAxMSAuNCAxLjhBMSAxIDAgMCAxIC4yLjR6IiBmaWxsPSIjMTYxNjE2Ii8+PC9zdmc+) center no-repeat
        }

        .slick-slider .slick-arrow.slick-next {
            right: 0
        }

        .slick-slider .slick-arrow.slick-prev {
            left: 0;
            -webkit-transform: rotate(180deg) translate(0, 50%);
            -moz-transform: rotate(180deg) translate(0, 50%);
            -ms-transform: rotate(180deg) translate(0, 50%);
            transform: rotate(180deg) translate(0, 50%)
        }

        .slick-initialized .slick-slide {
            float: none !important;
            display: inline-block !important;
            height: auto;
        }
    }

    @keyframes loading {
        0% {
            -webkit-transform: translate(-50%, -50%) rotate(0);
            transform: translate(-50%, -50%) rotate(0)
        }

        100% {
            -webkit-transform: translate(-50%, -50%) rotate(359.9deg);
            transform: translate(-50%, -50%) rotate(359.9deg)
        }

    }

    .product-items.slick-slider > .slick-list {
        padding: 15px 0
    }

    .product-items.slick-slider .slick-slide .product-item {
        display: block !important;
        margin: auto !important;
        max-width: 205px
    }

    .sidebar.sidebar-additional {
        width: 100%
    }
    // sass-lint:enable-all
}
