@mixin \compass\typography\styled-ordered-list(
    $style-position: $list-style-position,
    $line-height: $list-line-height,
    $side-margin: $list-side-margin
) {
    @include \kindling\typography\ordered-list(
        $style-position: $style-position,
        $line-height: $line-height,
        $side-margin: $side-margin
    );
    list-style: none;
    counter-reset: li;

    > li {
        counter-increment: li;

        &::before {
            @include \kindling\typography\font-stack('secondary');
            @include \kindling\spacing\apply('typography.ol.icon.right', $margin-sides: ('right'));
            margin-left: -#{\kindling\value\get-side($side-margin, 'left')};
            color: \kindling\color\get('typography.ol.icon');
            font-weight: \kindling\typography\font-weight\get('typography.ol.icon');
            content: counter(li) '.';
        }
    }
}
