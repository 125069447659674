@mixin \compass\page-builder\products\apply() {
    .pagebuilder-column {
        .widget-product-grid {
            .product-item {
                min-width: \kindling\value\rem-calc(50);
                max-width: 100%;

                .price-box {
                    word-wrap: break-word;

                    .price {
                        white-space: normal;
                    }
                }

                .product-item-actions {
                    .actions-primary {
                        display: inline-block;
                        max-width: 100%;
                    }

                    .actions-secondary {
                        display: inline-block;
                        width: auto;
                    }

                    .tocart {
                        max-width: 100%;
                        white-space: normal;
                    }
                }
            }
        }

        [data-content-type='products'] {
            .block.widget {
                .products-grid {
                    .widget-product-grid.product-items {
                        .product-item {
                            width: \kindling\value\rem-calc(200);
                        }
                    }
                }
            }
        }
    }
}
