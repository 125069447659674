@mixin \compass\sales\order\view\navigation-tabs\apply() {
    .order-links {
        @include \kindling\typography\unstyled-list();
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        .nav.item {
            @include \kindling\border\apply($color: \kindling\color\get('neutral-lighter'), $sides: ('top', 'left', 'right'));
            flex-grow: 1;

            @include \kindling\breakpoint\media-query('medium') {
                flex-grow: 0;
            }

            &.current,
            &.active {
                background: \kindling\color\get('neutral-lightest');

                @include \kindling\breakpoint\media-query(\kindling\breakpoint\get-previous-breakpoint('medium') 'down') {
                    order: 10;
                }
            }

            a,
            strong {
                @include \kindling\spacing\apply($spacing: 'global.xsmall', $padding-sides: ('all'));
                @include \kindling\typography\font-size('typography.copy.small');
                display: block;
                text-decoration: none;
            }
        }
    }
}

@mixin \compass\sales\order\view\navigation-tabs\alternate\apply() {
    @include \compass\sales\order\view\navigation-tabs\apply();

    .order-links {
        @include \kindling\border\apply($color: \kindling\color\get('neutral-lighter'), $sides: ('bottom'));
        @include \kindling\spacing\apply($spacing: 'global.medium', $margin-sides: ('bottom'));
    }
}
