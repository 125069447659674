@mixin \project\banner\hero-text\shadow() {
    text-shadow: #{\kindling\value\rem-calc(0 2 4)} $\project\banner\shadow-color;
}

@mixin \project\banner\hero-text\apply($constrict-title-width: true) {
    @if $constrict-title-width {
        .hero-container {
            $responsive-sizes: (
                'small': \kindling\grid\column-width-calc(11),
                'large': \kindling\grid\column-width-calc(6, $total-columns: 11),
                'xlarge': \kindling\grid\column-width-calc(5)
            );
            @include \kindling\property\apply('width', $responsive-sizes);
        }
    }

    .hero-subtitle {
        $responsive-sizes: (
            'small': \kindling\grid\column-width-calc(7),
            'large': \kindling\grid\column-width-calc(12)
        );
        @include \kindling\property\apply('width', $responsive-sizes);
        @include \compass\typography\heading('h4', $include-spacing: true);
        @include \project\banner\hero-text\shadow();
        color: \kindling\color\get('page-banner.hero.text-color');
    }

    .hero-title {
        @include \compass\typography\heading('hero', $include-spacing: true);
        @include \project\banner\hero-text\shadow();
        color: \kindling\color\get('page-banner.hero.text-color');
    }

    .hero-description {
        @include \compass\typography\copy\small();
        @include \kindling\typography\margin('typography.paragraph');
        @include \compass\typography\font\apply('primary', 'semi-bold');
        @include \project\banner\hero-text\shadow();
        color: \kindling\color\get('page-banner.hero.text-color');
    }

    .hero-button-container {
        @include \compass\button\grid-row();
    }

    .hero-button {
        @include \compass\button\apply($style: 'primary-light', $size: 'small');
    }

    .hero-cta-icon {
        @include \kindling\icon\apply($icon: $icon-double-arrow-right) {
            @include \kindling\typography\font-size('banner.hero-icon');
            position: absolute;
            top: 0; // force position to prevent icon from being jumpy in firefox
            left: 0;
            transform: translate(-10%, -8%);
            color: \kindling\color\get('page-banner.hero.icon');
        }
        position: relative;
    }
}
