@mixin \compass\page-builder\row\contained\apply($layout: 'default') {
    [data-content-type='row'][data-appearance='contained'] {
        @if $layout == 'default' {
            @include \kindling\property\apply('padding-left', ('small': 0, 'large': \kindling\grid\column-width-calc(1)));
            @include \kindling\property\apply('padding-right', ('small': 0, 'large': \kindling\grid\column-width-calc(1)));
            margin-right: auto !important; // sass-lint:disable-line no-important
            margin-left: auto !important; // sass-lint:disable-line no-important
        }

        @if $layout == 'full-width' {
            @include \compass\layout\container-padded();

            > .xlarge-full-bleed {
                @include \kindling\breakpoint\media-query('xlarge' 'only') {
                    @include \kindling\spacing\apply(
                        $spacing: 'constrained-content-container.default.large',
                        $margin-sides: ('left', 'right'),
                        $ratio: -1
                    );
                }
            }

            > .large-full-bleed {
                @include \kindling\breakpoint\media-query('large' 'only') {
                    @include \kindling\spacing\apply(
                        $spacing: 'constrained-content-container.default.large',
                        $margin-sides: ('left', 'right'),
                        $ratio: -1
                    );
                }
            }

            > .tablet-full-bleed {
                @include \kindling\breakpoint\media-query('medium' 'only') {
                    @include \kindling\spacing\apply(
                        $spacing: 'constrained-content-container.default.medium',
                        $margin-sides: ('left', 'right'),
                        $ratio: -1
                    );
                }
            }

            > .mobile-full-bleed {
                @include \kindling\breakpoint\media-query('small' 'only') {
                    @include \kindling\spacing\apply(
                        $spacing: 'constrained-content-container.default.small',
                        $margin-sides: ('left', 'right'),
                        $ratio: -1
                    );
                }
            }
        }

        [data-element='inner'] {
            @include \kindling\breakpoint\media-query('medium' 'down') {
                background-attachment: scroll !important; // sass-lint:disable-line no-important
            }
        }
    }
}

@mixin \compass\page-builder\row\full-bleed\apply() {
    @include \kindling\breakpoint\media-query('medium' 'down') {
        [data-content-type='row'][data-appearance='full-bleed'] {
            background-attachment: scroll !important; // sass-lint:disable-line no-important
        }
    }
}

@mixin \compass\page-builder\row\full-width\apply($layout: 'default') {
    [data-content-type='row'][data-appearance='full-width'] {
        @include \kindling\breakpoint\media-query('medium' 'down') {
            background-attachment: scroll !important; // sass-lint:disable-line no-important
        }

        > .row-full-width-inner {
            @if $layout == 'default' {
                @include \compass\layout\container();
            }

            @if $layout == 'full-width' {
                @include \compass\layout\container-padded();
            }
        }
    }
}

@mixin \compass\page-builder\row\apply() {
    @include \compass\page-builder\row\full-bleed\apply();

    body {
        &:not(.page-layout-empty-full-width) {
            &:not(.page-layout-category-full-width) {
                @include \compass\page-builder\row\contained\apply();
                @include \compass\page-builder\row\full-width\apply();
            }
        }

        &.page-layout-empty-full-width,
        &.page-layout-category-full-width {
            @include \compass\page-builder\row\contained\apply($layout: 'full-width');
            @include \compass\page-builder\row\full-width\apply($layout: 'full-width');
        }
    }
}
