@mixin \compass\mega-menu\slide-out-animation(
    $side: $\compass\mega-menu\mobile\side,
    $active-class: '.mega-menu-mobile-list-container-active'
) {
    @include \compass\animation\slide-in\hidden-state($slide-from: $side);

    &#{$active-class} {
        @include \compass\animation\slide-in\active-state();
    }
}

@mixin \compass\mega-menu\scrollable-section() {
    @include \compass\scroll-bar\apply();
}

@mixin \compass\mega-menu\slide-out-styles($side: $\compass\mega-menu\mobile\side) {
    @include \compass\mega-menu\slide-out-animation($side);
    position: absolute;
    top: 0;
    z-index: \kindling\z-index\get('above-sibling');
    background-color: \kindling\color\get('menu.mobile-mega-menu.content.background.default');
    width: 100%;
    height: 100%;
}

@mixin \compass\mega-menu\top-level-icon-base() {
    @include \kindling\spacing\apply('base.xsmall', $margin-sides: ('right'));
    @include \kindling\spacing\apply('base.xxsmall', $margin-sides: ('left'));
    @include \kindling\typography\font-size('large.1');
    width: $\project\mega-menu\mobile\category-icon-size;
}

@mixin \compass\mega-menu\mobile\additional\apply() {
    .mega-menu-mobile-item-additional {
        .mega-menu-mobile-level-0-link {
            @include \kindling\icon\apply($icon: $icon-plus, $use-before: false) {
                color: \kindling\color\get('menu.mobile-mega-menu.content.link-list.icon');
                font-size: 110%;
            }

            &:hover,
            &:focus {
                background-color: \kindling\color\get('menu.mobile-mega-menu.content.link-list.background-hover');
            }
        }

        .mega-menu-mobile-list {
            > * {
                border-bottom: 0;
            }
        }
    }

    .mega-menu-mobile-item-additional-active {
        .mega-menu-mobile-level-0-link {
            @include \kindling\icon\apply($icon: $icon-minus, $use-before: false);
            background-color: \kindling\color\get('menu.mobile-mega-menu.content.link-list.background-hover');
        }
    }

    .mega-menu-mobile-list-additional-container {
        display: none;
        background-color: \kindling\color\get('menu.mobile-mega-menu.content.link-list.background');
    }

    .mega-menu-mobile-list-additional-container-active {
        display: block;
    }
}

@mixin \compass\mega-menu\mobile\apply() {
    .mega-menu-mobile-container {
        @include \kindling\breakpoint\media-query($\compass\mega-menu\desktop\breakpoint) {
            display: none;
        }
    }

    .mega-menu-mobile-open-button {
        @include \compass\button\icon-only($icon: $icon-menu, $icon-size: 0.6, $context: 'button.mobile-mega-menu.open-button');
    }

    .mega-menu-mobile {
        @include \compass\pullout-menu\apply($context: 'pullout-menu.default');
    }

    .mega-menu-mobile-inner {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .mega-menu-mobile-header {
        @include \compass\menu\header\apply('menu.mobile-mega-menu');

        .menu-header-text {
            .icon-image {
                @include \kindling\icon\apply($icon: $icon-phone) {
                    @include \compass\mega-menu\top-level-icon-base();
                    @include \kindling\spacing\apply('base.xxsmall', $margin-sides: ('right'));
                    @include \kindling\typography\font-size('normal.2');
                    color: \kindling\color\get('neutral');
                }
            }

            .header-need-help {
                p {
                    @include \compass\typography\font\apply('primary', 'semi-bold');
                    @include \kindling\typography\font-size('typography.copy.small');
                }
            }
        }
    }

    .mega-menu-mobile-content {
        // position required for slide menu to work
        position: relative !important; // sass-lint:disable-line no-important
        flex: 1 1 auto;
        height: 100%;
        overflow: hidden;

        .shop-for-bikes-brands {
            @include \compass\global\shop-for-bike\brands\list\apply($image-dimension: \kindling\value\rem-calc(25), $list-spacing: 'base.xxsmall');
            position: relative;

            ul {
                @include \kindling\spacing\apply('base.xxsmall', $margin-sides: ('left'));
                text-align: center;

                li {
                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }

        .shop-for-bikes-block {
            @include \kindling\spacing\apply(
                $spacing: 'menu.default.items',
                $padding-sides: ('all')
            );
            display: block;
            align-items: center;
            justify-content: space-between;
            background-color: \kindling\color\get('menu.mobile-mega-menu.content.link-list.background-hover');
        }

        .shop-for-bikes-container {
            @include \project\global\header\shop-for-bike\cta\apply();
            flex: 0 1 auto;
            text-align: right;

            > a {
                @include \kindling\button\link-style($context: 'header.shop-for-bikes');
                text-decoration: none;
            }

            .title {
                margin-right: 0;
                color: \kindling\color\get('header.shop-for-bikes.mobile');
            }

            .icon-motorcycle {
                padding-bottom: 0;
            }
        }
    }

    .mega-menu-mobile-content-scrollable {
        @include \compass\mega-menu\scrollable-section();
        height: 100%;
    }

    .mega-menu-mobile-back-button {
        @include \compass\button\reset-browser-button();
        @include \kindling\spacing\apply(
            $spacing: 'menu.mobile-mega-menu.default',
            $padding-sides: ('left', 'right')
        );
        @include \compass\typography\font\apply('secondary', 'bold');
        @include \kindling\typography\font-size('body');
        @include \kindling\icon\apply($icon: $icon-double-arrow-left) {
            @include \kindling\typography\font-size('large.1');
            margin: \kindling\value\rem-calc(0 10 0 3);
        }
        flex: 0 0 auto;
        background-color: \kindling\color\get('button.mobile-mega-menu.back-button.background-color');
        height: \kindling\value\rem-calc(40);
        text-align: left;
        line-height: 1;
        color: \kindling\color\get('button.mobile-mega-menu.back-button.content');

        &:hover,
        &:focus {
            background-color: \kindling\color\get('button.mobile-mega-menu.back-button.background-color-hover');
            color: \kindling\color\get('button.mobile-mega-menu.back-button.content-hover');
        }
    }

    .mega-menu-mobile-nav {
        @include \customer\account\header\content-mobile();
    }

    .mega-menu-mobile-list {
        @include \kindling\typography\unstyled-list();
        @include \compass\mega-menu\link-featured\apply('menu.mobile-mega-menu');
    }

    .mega-menu-mobile-level-0-list,
    .mega-menu-mobile-level-1-list {
        @include \compass\menu\link-list\apply('menu.mobile-mega-menu');

        > li {
            > a {
                &:not(.mega-menu-mobile-link-parent) {
                    @include \kindling\icon\selector($use-before: false) {
                        content: normal;
                    }
                }
            }

            &.view-more-link {
                display: none;
            }
        }
    }

    .mega-menu-mobile-level-0-list {
        .mega-menu-mobile-list-container-inner {
            @include \compass\mega-menu\scrollable-section(); // applied to each menu to prevent double scroll bar on child menus
            flex: 1 1 auto;
        }

        .mega-menu-mobile-list-container { // this container prevents horizontal scroll bars. Do not remove.
            @include \compass\mega-menu\slide-out-styles();
            display: flex;
            flex-direction: column;
        }
    }

    .mega-menu-mobile-level-2-list {
        @include \compass\mega-menu\link-bottom-level('menu.mobile-mega-menu');

        .mega-menu-mobile-category-image {
            display: none;
        }

        .mega-menu-mobile-shop-all-link {
            @include \compass\typography\font\apply('secondary', 'bold');
            @include \kindling\typography\font-size('body');
        }
    }

    .mega-menu-mobile-level-2-more-link {
        color: \kindling\color\get('menu.mobile-mega-menu.accent');
    }

    .mega-menu-mobile-list-cms {
        @include \compass\mega-menu\link-bottom-level('menu.mobile-mega-menu');

        [data-content-type='text'] {
            > ul {
                @include \kindling\typography\unstyled-list();
            }
        }
    }

    .mega-menu-mobile-list-active {
        display: block;
    }

    .mega-menu-mobile-overlay {
        @include \compass\pullout-menu\overlay();
    }

    .mega-menu-mobile-category-image {
        @include \kindling\spacing\apply(
            $spacing: 'menu.mobile-mega-menu.icon',
            $margin-sides: ('right')
        );
        width: $\project\mega-menu\mobile\category-icon-size;
    }

    .mega-menu-mobile-item-account {
        .mega-menu-mobile-text {
            @include \kindling\icon\apply($icon: $icon-account) {
                @include \compass\mega-menu\top-level-icon-base();
            }
        }
    }
}
