@mixin \customer\account-entry\apply() {
    @include \customer\account-entry\create\apply();
    @include \customer\account-entry\forgot\apply();
    @include \customer\account-entry\login\apply();

    .customer-account-create,
    .customer-account-login,
    .customer-account-forgotpassword,
    .customer-account-createpassword {
        @include \customer\account-entry\layout\apply();
        @include \customer\page-note\apply();

        .block-content {
            @include \kindling\grid\column(
                $responsive-sizes: (
                    'small': \kindling\grid\column-width-calc(12),
                    'large': \kindling\grid\column-width-calc(10)
                )
            );
        }
    }
}

@mixin \customer\page-note\apply() {
    .page-title-wrapper {
        @include \kindling\border\apply('reset');
        @include \kindling\spacing\apply('reset', $padding-sides: ('bottom'));
    }

    .required-fields-page-note {
        @include \compass\section(
            $border-sides: ('bottom'),
            $margin-sides: (),
            $padding-sides: ('bottom')
        );
        width: 100%;
    }
}
