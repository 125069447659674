@mixin \compass\typography\utility\set-section-color($color) {
    color: \kindling\color\get($color);

    a,
    button,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: inherit;
    }
}

@mixin \project\typography\blockquote() {
    @include \kindling\property\apply('flex-wrap', ('small': wrap, 'medium': nowrap));
    display: flex;
    justify-content: center;

    .icon {
        @include \kindling\icon\apply($icon: $icon-quote) {
            @include \kindling\typography\font-size('typography.blockquote.icon');
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
        }
        @include \kindling\border\apply('typography.blockquote.icon');
        display: inline-block;
        position: relative;
        flex: 0 0 auto;
        transform: rotate(45deg);
        margin: \kindling\value\rem-calc(10);
        width: \kindling\value\rem-calc(45);
        height: \kindling\value\rem-calc(45);
    }

    .content {
        @include \kindling\property\apply('padding', ('small': \kindling\value\rem-calc(30 0 0), 'medium': \kindling\value\rem-calc(0 0 0 30)));
        @include \kindling\property\apply('width', ('small': 100%, 'medium': auto));
        @include \kindling\property\apply('text-align', ('small': center, 'medium': left));
        flex: 1 1 auto;
    }

    .text {
        @include \compass\typography\font\apply('primary', 'medium', italic);
        @include \kindling\typography\font-size('typography.blockquote.content');
    }

    cite {
        @include \kindling\spacing\apply('typography.cite', $margin-sides: ('top'));
        @include \kindling\typography\font-size('typography.blockquote.author');
        font-style: normal;

        &::before {
            content: '— ';
        }
    }
}
