@mixin \compass\page-builder\block\apply() {
    [data-content-type$='block'] {
        .block {
            p {
                &:last-child {
                    margin-top: 0;
                    margin-bottom: 1rem;
                }
            }
        }
    }
}
