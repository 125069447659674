$\kindling\color\palette: (
    'not-set': null,
    'inherit': inherit,
    'transparent': transparent,
    'current-color': currentColor,
    'thunder': #231f20,
    'mine-shaft': #393939,
    'cod-gray': #1a1818,
    'scorpion-gray': #5b5b5b,
    'storm-gray': #747578,
    'oslo-gray': #939598,
    'alto-gray': #cecece,
    'gallery-gray': #f0f0f0,
    'light-gray': #f7f7f7,
    'white': #ffffff,
    'black': #000000,
    'alizarin-crimson': #e91c23,
    'red-orange': #ff343a,
    'crimson': #d7171e,
    'la-palma': #399812,
    'peppermint': #ecf7e8,
    'bilbao': #296a0e,
    'denim': #195cc3,
    'zumthor': #eaf2ff,
    'tory-blue': #13458f,
    'selective-yellow': #ffb509,
    'off-yellow': #fef8e5,
    'corn-harvest': #946a06,
    'chablis': #fff0f1,
    'thunderbird': #bb161c,
    'facebook-blue': #4267b2, // Do not change. #4267b2 is required by facebook brand guidelines: https://en.facebookbrand.com/#brand-guidelines-assets,
    'google-gray': #939598
);

$\kindling\color\contexts: (
    'white': 'white',
    'black': 'black',
    'primary': 'thunder',
    'primary-light': 'mine-shaft',
    'primary-dark': 'cod-gray',
    'secondary': 'alizarin-crimson',
    'secondary-light': 'red-orange',
    'secondary-dark': 'crimson',
    'neutral-white': 'white',
    'neutral-lightest': 'light-gray',
    'neutral-lighter': 'gallery-gray',
    'neutral-light': 'alto-gray',
    'neutral': 'oslo-gray',
    'neutral-dark': 'storm-gray',
    'neutral-darker': 'scorpion-gray',
    'neutral-darkest': 'thunder',
    'neutral-black': 'black',
    'success-light': 'peppermint',
    'success': 'la-palma',
    'success-dark': 'bilbao',
    'information': 'denim',
    'information-light': 'zumthor',
    'information-dark': 'tory-blue',
    'warning': 'selective-yellow',
    'warning-light': 'off-yellow',
    'warning-dark': 'corn-harvest',
    'error': 'alizarin-crimson',
    'error-light': 'chablis',
    'error-dark': 'thunderbird',
    'sale': 'secondary',
    'best-seller': 'primary',
    'new': 'bilbao',
    'facebook': 'facebook-blue',
    'google': 'google-gray',
    'body': 'neutral-darkest',
    'canada': 'alizarin-crimson',
    'button': (
        'default': (
            'background-color': 'secondary',
            'background-color-hover': 'secondary-dark',
            'text-color': 'white',
            'text-color-hover': 'white',
            'icon-color': 'white',
            'icon-color-hover': 'white'
        ),
        'primary-light': (
            'background-color': 'white',
            'background-color-hover': 'primary',
            'text-color': 'primary',
            'text-color-hover': 'white',
            'icon-color': 'primary',
            'icon-color-hover': 'white'
        ),
        'primary-dark': (
            'background-color': 'primary',
            'background-color-hover': 'primary-light',
            'text-color': 'white',
            'text-color-hover': 'white',
            'icon-color': 'white',
            'icon-color-hover': 'white'
        ),
        'secondary': (
            'background-color': 'white',
            'background-color-hover': 'secondary',
            'text-color': 'primary',
            'text-color-hover': 'white',
            'icon-color': 'primary',
            'icon-color-hover': 'white'
        ),
        'tertiary': (
            'background-color': 'transparent',
            'background-color-hover': 'transparent',
            'text-color': 'secondary',
            'text-color-hover': 'secondary'
        ),
        'tertiary-light': (
            'background-color': 'transparent',
            'background-color-hover': 'transparent',
            'text-color': 'white',
            'text-color-hover': 'white'
        ),
        'tertiary-dark': (
            'background-color': 'transparent',
            'background-color-hover': 'transparent',
            'text-color': 'neutral-darkest',
            'text-color-hover': 'neutral-darkest'
        ),
        'disabled': (// @TODO Nate why contexts no work for disabled?
            'background-color': 'neutral-light',
            'text-color': 'neutral-darker',
            'tertiary': (
                'background-color': 'transparent',
                'text-color': 'neutral-dark',
            )
        ),
        'social-login': (
            'facebook': (
                'background-color': 'facebook-blue', // Do not change. Facebook branding guidelines require this blue: https://en.facebookbrand.com/#brand-guidelines-assets
                'background-color-hover': 'facebook-blue', // Do not change. Facebook branding guidelines require this blue: https://en.facebookbrand.com/#brand-guidelines-assets
                'icon-background-color': 'facebook-blue', // Do not change. Facebook branding guidelines require this blue: https://en.facebookbrand.com/#brand-guidelines-assets
                'icon-background-color-hover': 'facebook-blue' // Do not change. Facebook branding guidelines require this blue: https://en.facebookbrand.com/#brand-guidelines-assets
            ),
            'google': (
                'text-color': 'neutral-black',
                'text-color-hover': 'neutral-black',
                'background-color': 'white',
                'background-color-hover': 'white',
                'icon-background-color': 'white', // Do not change. Google branding guidelines require white: https://developers.google.com/identity/branding-guidelines
                'icon-background-color-hover': 'white' // Do not change. Google branding guidelines require white: https://developers.google.com/identity/branding-guidelines
            )
        ),
        'mobile-mega-menu': (
            'open-button': (
                'icon-color': 'neutral-white',
                'icon-color-hover': 'neutral-white',
                'background-color': 'secondary',
                'background-color-hover': 'secondary'
            ),
            'back-button': (
                'content': 'neutral-white',
                'content-hover': 'neutral-white',
                'background-color': 'secondary',
                'background-color-hover': 'secondary-dark'
            )
        )
    ),
    'dropdown': (
        'default': (
            'content': (
                'box-shadow': 'neutral-darkest',
                'background': 'neutral-white',
                'text': 'body'
            )
        ),
        'action-dropdown': (
            'icon': 'neutral'
        )
    ),
    'typography': (
        'hero': (
            'text-shadow': 'neutral-black'
        ),
        'heading': (
            'default': 'neutral-black'
        ),
        'anchor': (
            'text-color': 'inherit',
            'text-color-hover': 'neutral-dark'
        ),
        'copy': (
            'default': 'not-set',
            'lead': 'not-set',
            'small': 'not-set',
            'micro': 'not-set'
        ),
        'ol': (
            'icon': 'black'
        ),
        'figcaption': (
            'background': 'not-set',
            'color': 'not-set'
        )
    ),
    'box': (
        'default': (
            'container': (
                'background-color': 'not-set',
                'text-color': 'not-set'
            ),
            'section': (
                'background-color': 'not-set',
                'text-color': $\kindling\color\foreground-colors
            )
        ),
        'account': (
            'container': (
                'background-color': 'not-set',
                'text-color': $\kindling\color\foreground-colors
            ),
            'section': (
                'background-color': 'neutral-white',
                'text-color': $\kindling\color\foreground-colors
            )
        )
    ),
    'modal': (
        'default': (
            'title': (
                'background-color': 'neutral-lighter',
                'text-color': 'primary'
            ),
            'content': (
                'background-color': 'white',
                'text-color': 'primary',
                'box-shadow': 'neutral-darkest'
            ),
            'close-button': (
                'background-color': 'transparent',
                'background-color-hover': 'transparent',
                'icon-color': 'primary',
                'icon-color-hover': 'secondary'
            )
        )
    ),
    'accordion': (
        'default': (
            'title': (
                'background-color': 'white',
                'background-color-hover': 'white',
                'text-color': ('white', 'black'),
                'text-color-hover': ('white', 'black')
            ),
            'body': (
                'background-color': 'white',
                'text-color': 'primary'
            )
        ),
        'sidebar': (
            'title': (
                'background-color': 'neutral-lighter',
                'background-color-hover': 'neutral-lighter',
                'text-color': ('white', 'black'),
                'text-color-hover': ('white', 'black')
            ),
            'body': (
                'background-color': 'neutral-lighter',
                'text-color': 'primary-dark'
            )
        ),
        'sidebar-light': (
            'title': (
                'background-color': 'transparent',
                'background-color-hover': 'transparent',
                'text-color': 'primary-dark',
                'text-color-hover': 'primary-dark'
            ),
            'body': (
                'background-color': 'transparent',
                'text-color': 'primary-dark'
            )
        ),
        'account-sidebar': (
            'title': (
                'background-color': 'neutral-lighter',
                'background-color-hover': 'neutral-lighter',
                'text-color': ('white', 'black'),
                'text-color-hover': ('white', 'black')
            ),
            'body': (
                'background-color': 'neutral-lighter',
                'text-color': 'primary-dark'
            )
        ),
        'sidebar-checkout': (
            'title': (
                'background-color': 'neutral-lighter',
                'background-color-hover': 'neutral-lighter',
                'text-color': ('white', 'black'),
                'text-color-hover': ('white', 'black')
            ),
            'body': (
                'background-color': 'white',
                'text-color': 'primary-dark'
            )
        ),
        'footer': (
            'title': (
                'background-color': 'primary-light',
                'background-color-hover': 'primary-light',
                'text-color': ('neutral-lighter', 'black'),
                'text-color-hover': ('neutral-lighter', 'black'),
                'icon-color': 'white'
            ),
            'body': (
                'background-color': 'primary-light',
                'text-color': ('white', 'black')
            )
        ),
        'table': (
            'title': (
                'background-color': 'primary',
                'background-color-hover': 'primary',
                'text-color': ('white', 'primary'),
                'text-color-hover': ('white', 'primary')
            ),
            'body': (
                'background-color': 'white',
                'stripe-background-color': 'neutral-lighter',
                'text-color': ('primary', 'white')
            )
        ),
        'mini-garage': (
            'title': (
                'background-color': 'secondary-dark',
                'background-color-hover': 'secondary-dark',
                'text-color': ('white', 'primary-dark'),
                'text-color-hover': ('white', 'primary')
            ),
            'body': (
                'background-color': 'white',
                'text-color': ('primary', 'white')
            )
        ),
        'vehicle-navigation-form': (
            'title': (
                'background-color': 'secondary',
                'background-color-hover': 'secondary-dark',
                'text-color': ('white', 'primary-dark'),
                'text-color-hover': ('white', 'primary')
            ),
            'body': (
                'background-color': 'secondary',
                'text-color': ('primary', 'white')
            )
        ),
        'compare-bar': (
            'title': (
                'background-color': 'neutral-lighter',
                'background-color-hover': 'neutral-lighter',
                'text-color': ('white', 'black'),
                'text-color-hover': ('white', 'black')
            ),
            'body': (
                'background-color': 'white',
                'text-color': 'primary'
            )
        )
    ),
    'form': (
        'default': (
            'input': (
                'border-color-disabled': 'neutral-lighter',
                'border-color-hover': 'neutral-dark',
                'background-color': 'white',
                'background-color-hover': 'white',
                'background-color-disabled': 'neutral-lighter',
                'text-color-disabled': 'neutral',
                'text-color-active': 'black',
                'text-color': 'black',
                'placeholder-color': 'neutral',
                'select-shape-color': 'black',
                'checkbox-disabled': 'neutral-dark',
                'select-option': (
                    'text-color': 'black',
                    'text-color-hover': 'white',
                    'text-color-active': 'white',
                    'background-color': 'white',
                    'background-color-hover': 'information',
                    'background-color-active': 'information'
                ),
            ),
            'switch': (//@TODO remove when kindling docs issue is fixed, or implement
                'control': 'white',
                'background-color': 'neutral-light',
                'background-color-checked': 'neutral-dark'
            ),
            'password': 'neutral',
            'password-meter-background': 'neutral-black',
            'help': 'not-set',
            'error': 'error',
            'legend': (
                'heading': 'primary',
                'description': 'primary'
            )
        ),
        'search': (
            'input': (
                'background-color': 'neutral-white',
                'text-color': 'primary',
                'placeholder-color': 'neutral-black'
            ),
            'autocomplete': (
                'color': 'neutral-black',
                'background': 'neutral-white',
                'background-hover': 'neutral-lighter'
            ),
            'mobile-container': 'neutral-lighter',
            'mobile-container-shadow': 'neutral-lighter',
            'mobile': (
                'submit-icon-color': 'primary',
                'submit-icon-color-hover': 'primary-dark',
                'close-icon-color': 'secondary',
                'close-icon-color-hover': 'secondary-dark'
            )
        )
    ),
    'slider': (
        'base': (
            'arrow': (
                'background-color': 'white',
                'background-color-hover': 'primary',
                'icon-color': 'primary',
                'icon-color-hover': 'white',
                'icon-disabled': 'button.disabled.background-color'
            ),
            'dot': (
                'background-color': 'button.default.background-color',
                'background-color-active': 'button.default.background-color-hover'
            )
        ),
        'product': (
            'arrow': (
                'background-color': 'white',
                'background-color-hover': 'primary',
                'icon-color': 'primary',
                'icon-color-hover': 'white',
                'icon-disabled': 'button.disabled.background-color'
            ),
            'fade': (
                'mask-invisible': 'transparent',
                'mask-visible': 'black'
            ),
            'dot': (
                'foreground': 'primary',
                'background': 'neutral-light'
            )
        ),
        'hero': (
            'section': (
                'background': 'neutral-darkest'
            ),
            'progress': (
                'default': 'white'
            ),
            'arrow': (
                'background-color': 'primary',
                'background-color-hover': 'white',
                'icon-color': 'white',
                'icon-color-hover': 'primary',
                'icon-disabled': 'button.disabled.background-color'
            )
        )
    ),
    'gallery': (
        'default': (
            'arrow': (
                'background-color': 'button.secondary.background-color',
                'background-color-hover': 'button.secondary.background-color',
                'icon-color': 'button.secondary.icon-color',
                'icon-color-hover': 'button.secondary.icon-color',
            ),
            'zoom': (
                'background-color': 'button.secondary.background-color',
                'background-color-hover': 'button.secondary.background-color-hover',
                'icon-color': 'button.secondary.icon-color',
                'icon-color-hover': 'button.secondary.icon-color-hover'
            ),
            'dot': (
                'background-color': 'button.secondary.background-color',
                'background-color-active': 'button.secondary.background-color-hover'
            ),
            'loader': (
                'background': 'transparent'
            ),
            'play-icon': (
                'icon-color': 'button.secondary.icon-color',
                'icon-color-hover': 'button.secondary.icon-color-hover',
                'background-color': 'button.secondary.background-color',
                'background-color-hover': 'button.secondary.background-color-hover'
            )
        )
    ),
    'header': (
        'outer': (
            'background': 'white'
        ),
        'top': (
            'background': 'primary-light',
            'text': 'neutral-white',
            'dropdown-icon': 'neutral-light',
            'icon': 'neutral',
            'free-ship-message-icon': 'secondary'
        ),
        'promo-bar': (
            'background-color': 'primary',
            'text-color': ('neutral-white', 'neutral-black')
        ),
        'logo': (
            'background-color': 'primary'
        ),
        'bottom': (
            'background-color': 'neutral-lightest'
        ),
        'shop-for-bikes': (
            'text-color': 'white',
            'text-color-hover': 'primary',
            'mobile': 'primary',
            'tablet': 'secondary'
        ),
    ),
    'scroll-bar': (
        'thumb': 'primary',
        'main': 'neutral-lighter'
    ),
    'menu': (
        'default': (
            'header': (
                'close-button': (
                    'icon-color': 'white',
                    'icon-color-hover': 'white',
                    'background-color': 'neutral-black',
                    'background-color-hover': 'neutral-black'
                ),
                'text': 'not-set',
                'background': 'not-set'
            ),
            'content': (
                'link-list': (
                    'text': 'neutral-black',
                    'text-hover': 'primary',
                    'icon': 'not-set',
                    'icon-hover': 'not-set',
                    'background': 'not-set',
                    'background-hover': 'not-set'
                )
            )
        ),
        'toolbar': (
            'header': (
                'close-button': (
                    'icon-color': 'neutral-black',
                    'icon-color-hover': 'neutral-black',
                    'background-color': 'neutral-lighter',
                    'background-color-hover': 'neutral-light'
                ),
                'text': 'neutral-black',
                'background': 'neutral-lighter'
            ),
            'content': (
                'link-list': (
                    'text': 'neutral-black',
                    'text-hover': 'primary',
                    'icon': 'not-set',
                    'icon-hover': 'not-set',
                    'background': 'not-set',
                    'background-hover': 'not-set'
                )
            )
        ),
        'mobile-mega-menu': (
            'accent': 'secondary',
            'header': (
                'close-button': (
                    'icon-color': 'white',
                    'icon-color-hover': 'neutral-light',
                    'background-color': 'primary',
                    'background-color-hover': 'primary'
                ),
                'text': 'white',
                'background': 'primary'
            ),
            'content': (
                'link-list': (
                    'text': 'primary',
                    'text-hover': 'primary',
                    'icon': 'neutral',
                    'icon-hover': 'neutral-dark',
                    'background': 'white',
                    'background-hover': 'neutral-lighter'
                ),
                'background': (
                    'default': 'neutral-white'
                )
            )
        ),
        'desktop-mega-menu': (
            'accent': 'secondary',
            'neutral': 'neutral',
            'content': (
                'background': 'neutral-white'
            )
        )
    ),
    'pullout-menu': (
        'default': (
            'background': 'neutral-white'
        )
    ),
    'tabs': (
        'default': (
            'title': (
                'text-color': 'body',
                'text-color-hover': 'secondary',
                'background-color-hover': 'not-set',
            ),
            'panel': 'not-set'
        ),
        'pdp': (
            'title': (
                'text-color': 'body',
                'text-color-hover': 'secondary',
                'background-color-hover': 'not-set',
            ),
            'panel': 'neutral-lighter'
        )
    ),
    'reviews': (
        'default': (
            'rating': 'primary',
            'action': 'primary',
            'action-active': 'typography.anchor.text-color-hover'
        )
    ),
    'pdp': (
        'product-info': (
            'add-to-cart': (
                'background': 'neutral-white',
                'quantity': 'neutral-lightest'
            )
        )
    ),
    'product-price': (
        'default': (
            'price': 'primary',
            'special-price': 'secondary',
            'old-price': 'primary'
        )
    ),
    'checkout': (
        'sidebar-totals': (
            'row-alternate': 'neutral-lighter'
        ),
        'footer': (
            'background-color': 'primary-light',
            'text-color': 'neutral-light',
            'logo': (
                'background-color': 'neutral-darkest'
            )
        )
    ),
    'minicart': (
        'background-color': 'neutral-white',
        'actions': (
            'background-color': 'neutral-white'
        ),
        'totals': (
            'background-color': 'neutral-lighter'
        ),
        'header': (
            'background': 'neutral-lighter',
            'close-button': (
                'background-color': 'transparent',
                'background-color-hover': 'transparent',
                'icon-color': 'neutral-black',
                'icon-color-hover': 'neutral-black'
            ),
            'icon': 'secondary'
        ),
        'trigger': (
            'icon': 'black',
            'icon-mobile': 'secondary',
            'background': 'secondary',
            'background-mobile': 'white',
            'count': (
                'background-color': 'not-set',
                'color': 'white',
                'color-mobile': 'black'
            )
        )
    ),
    'page-banner': (
        'foreground': 'neutral-white',
        'hero': (
            'text-color': 'neutral-white',
            'icon': 'secondary'
        )
    ),
    'overlay': (
        'default': 'neutral-darkest'
    ),
    'contact': (
        'sidebar': (
            'background': 'neutral-white'
        )
    ),
    'skip-link': (
        'default': (
            'background': 'neutral-black',
            'text': 'neutral-white'
        )
    ),
    'badge': (
        'account-sales-title': (
            'background': 'secondary'
        )
    ),
    'table': (
        'default': (
            'thead': (
                'color': 'white',
                'background-color': 'primary'
            ),
            'tbody': (
                'tr': (
                    'background-color': 'white',
                )
            )
        ),
        'secondary': (
            'tbody': (
                'tr': (
                    'background-color': 'neutral-lighter',
                )
            )
        )
    ),
    'item-actions': (
        'default': (
            'text-color': 'primary-dark',
            'text-color-hover': 'secondary'
        )
    ),
    'cta': (
        'overlapping-image-cta': (
            'background': 'neutral-black',
            'content': 'neutral-white'
        )
    ),
    'fitment': (
        'label': (
            'universal': (
                'text-color': 'primary',
                'background': 'neutral-light'
            ),
            'vehicle-specific': (
                'text-color': 'neutral-white',
                'background': 'primary'
            )
        ),
        'mini-garage': (
            'garage-icon': 'neutral-darkest'
        )
    ),
    'shop-for-bikes-modal': (
        'title-icon': 'secondary',
        'brand-icon': (
            'background-color': 'neutral-white'
        ),
        'brand-item': (
            'text-color': 'neutral-white',
            'background-color': 'primary'
        )
    ),
    'footer': (
        'bottom': (
            'copyright': 'neutral-light',
            'link-headings': 'neutral-light'
        ),
        'contact': (
            'background-color': 'secondary',
            'form-background-color': 'secondary-dark',
            'color': 'neutral-white'
        ),
        'pre-block': (
            'background-color': 'neutral-lighter'
        ),
        'information': (
            'background-color': 'primary',
            'color': 'neutral-light'
        )
    ),
    'banner': (
        'default-background': 'primary'
    ),
    'maintenance-page': (
        'background-color': 'primary',
        'logo-box-shadow': 'neutral-black'
    ),
    'shop-category-list': (
        'default': (
            'text': 'neutral-white',
            'overlay': 'neutral-black'
        )
    ),
    'messages': (
        'default': (
            'success': (
                'icon': 'success',
                'text': 'success-dark',
                'background': 'success-light',
                'link': 'inherit',
                'link-hover': 'neutral-black',
            ),
            'error': (
                'icon': 'error',
                'text': 'error-dark',
                'background': 'error-light',
                'link': 'inherit',
                'link-hover': 'neutral-black',
            ),
            'information': (
                'icon': 'information',
                'text': 'information-dark',
                'background': 'information-light',
                'link': 'inherit',
                'link-hover': 'neutral-black',
            ),
            'warning': (
                'icon': 'warning',
                'text': 'warning-dark',
                'background': 'warning-light',
                'link': 'inherit',
                'link-hover': 'neutral-black',
            )
        )
    ),
    'category-list': (
        'default': (
            'text': 'primary',
            'text-active': 'secondary'
        )
    ),
    'featured-brands-list': (
        'default': (
            'overlay': 'neutral-white'
        )
    ),
    'brands-page': (
        'desktop-nav': (
            'section-background': 'neutral-white',
            'link': (
                'text': 'not-set',
                'text-hover': 'neutral-white',
                'background': 'not-set',
                'background-hover': 'neutral-black',
            )
        ),
        'sticky-on-page-nav': (
            'active-link': 'neutral-white',
            'active-link-background': 'neutral-black'
        )
    ),
    'badges': (
        'default': (
            'phone': (
                'icon': 'primary-dark',
                'text': 'primary-dark',
                'link': 'inherit',
                'link-hover': 'neutral-black',
            ),
            'secure': (
                'icon': 'primary-dark',
                'text': 'primary-dark',
                'link': 'inherit',
                'link-hover': 'neutral-black',
            ),
            'support': (
                'icon': 'primary-dark',
                'text': 'primary-dark',
                'link': 'inherit',
                'link-hover': 'neutral-black',
            ),
            'returns': (
                'icon': 'primary-dark',
                'text': 'primary-dark',
                'link': 'inherit',
                'link-hover': 'neutral-black',
            ),
            'currency': (
                'icon': 'primary-dark',
                'text': 'primary-dark',
                'link': 'inherit',
                'link-hover': 'neutral-black',
            )
        )
    ),
    'swatches': (
        'attribute-label': 'neutral-black',
        'swatch': (
            'text-color': 'primary',
            'highlight': 'primary'
        )
    ),
    'delivery-timeline': (
        'background': 'neutral-lighter'
    )
);
