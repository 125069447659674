@mixin \project\multi-wishlist\wishlist-dropdown() {
    .wishlist-dropdown {
        @include \compass\dropdown\actions-dropdown();

        .toggle {
            @include \compass\typography\font\apply('primary', 'semi-bold');
            @include \kindling\typography\font-size('typography.copy.small');
        }

        .item {
            > button {
                @include \compass\button\reset-browser-button();
                @include \compass\typography\font\apply('primary', 'semi-bold');
                @include \kindling\typography\font-size('typography.copy.small');

                &,
                span {
                    display: block;
                    width: 100%;
                }

                span {
                    padding: \kindling\value\rem-calc(5 10);
                }
            }
        }
    }
}
