@mixin \compass\product-grid\card\item($responsive-sizes: $responsive-sizes) {
    @include \kindling\grid\column(
        $responsive-sizes: $responsive-sizes,
        $flex-grow: 0,
        $flex-shrink: 0
    );

    > * {
        position: relative;
        height: 100%;
    }
}

@mixin \compass\product-grid\card\above-link-overlay($position: relative) {
    position: $position;
    z-index: \kindling\z-index\get('product-card-action');
}

@mixin \compass\product-grid\card\link-overlay() {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: \kindling\z-index\get('above-sibling');
    width: 100%;
    height: 100%;

    &:hover,
    &:focus {
        ~ .product-item-top {
            &::before {
                background-color: \kindling\color\get('transparent');
            }
        }

        ~ .product-item-details {
            .product-item-name {
                text-decoration: underline;
                color: \kindling\color\get('secondary');
            }
        }
    }
}

@mixin \compass\product-grid\card\product-name() {
    @include \kindling\typography\heading\h6();
}

@mixin \compass\product-grid\card\compare\icon($icon: $icon-checkbox-off) {
    @include \kindling\icon\apply($icon: $icon);
}

@mixin \compass\product-grid\card\apply($responsive-sizes: $\compass\product-grid\responsive-sizes) {
    .product-item {
        @include \compass\product-grid\card\item($responsive-sizes: $responsive-sizes);
    }

    .product-item-link-overlay {
        @include \compass\product-grid\card\link-overlay();
    }

    .product-item-top {
        @include \kindling\border\apply('product-grid.item.top');
        @include \catalog\product-image\overlay\apply();
    }

    .actions-secondary {
        position: absolute;
        right: -#{\kindling\map\get(\kindling\border\get('product-grid.item.top'), 'width')};
        bottom: -#{\kindling\map\get(\kindling\border\get('product-grid.item.top'), 'width')};
    }

    .product-item-details {
        @include \catalog\price\styles\apply('product-price.product-list');
        @include \project\fitment\fitment-label();
        @include \compass\product-badge\badge\apply();

        .product-badge-container {
            position: absolute;
            top: \kindling\value\rem-calc(13);
            left: \kindling\value\rem-calc(-5);
        }

        .price-box {
            @include \kindling\spacing\apply('product-grid.item.price', $margin-sides: ('top'));
            line-height: 1;
        }

        .fitment-label {
            @include \kindling\spacing\apply('product-grid.item.fitment', $margin-sides: ('top'));
        }
    }

    .product-item-name {
        @include \compass\product-grid\card\product-name();
        @include \kindling\spacing\apply('product-grid.item.name', $margin-sides: ('top'));
    }

    .product-item-details-reviews {
        @include \compass\reviews\short-summary\apply();
        @include \kindling\spacing\apply('product-grid.item.reviews', $margin-sides: ('top'));
    }

    .tocompare {
        @include \compass\catalog\compare\icon-action();
    }

    .action {
        @include \compass\product-grid\card\above-link-overlay();
    }
}
