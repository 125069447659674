@mixin \project\brands\page\sticky-nav-outer-border($sides: ('all')) {
    @include \kindling\border\apply(
        $context: 'brands-page.sticky-on-page-nav.outer-border',
        $sides: $sides
    );
}

@mixin \project\brands\page\sticky-nav-arrow($icon, $width, $sides: ('all'), $icon-size: 0.7) {
    @include \compass\button\icon-only(
        $icon: $icon,
        $size: $width,
        $icon-size: $icon-size,
        $context: 'button.primary-light'
    );
    @include \project\brands\page\sticky-nav-outer-border($sides);
}

@mixin \project\brands\page\nav-mobile($width: \kindling\value\rem-calc(30)) {
    .mobile-brand-nav-container-outer {
        @include \kindling\spacing\apply(
            $spacing: 'constrained-content-container.default',
            $margin-sides: ('right'),
            $ratio: -1
        );
        position: sticky;
        top: 0;
        order: 1;

        @include \kindling\breakpoint\media-query($\project\brands\main-list\breakpoint) {
            display: none;
        }

        ~ .brand-page-list-container {
            @include \kindling\spacing\apply(
                $spacing: 'grid-gutters',
                $padding-sides: ('right')
            );
        }
    }

    .mobile-brand-nav-previous {
        @include \project\brands\page\sticky-nav-arrow($icon-chevron-up, ('small': $width), $icon-size: 0.53);
    }

    .mobile-brand-nav-next {
        @include \project\brands\page\sticky-nav-arrow($icon-chevron-down, ('small': $width), $icon-size: 0.53);
    }

    .mobile-brand-nav {
        @include \kindling\typography\unstyled-list();
        @include \project\brands\page\sticky-nav-outer-border(('right', 'left'));
        width: $width;
    }

    .mobile-brand-nav-container {
        $header-height: \kindling\value\rem-calc(87);
        @include \compass\scroll-bar\invisible('y');
        max-height: calc(100vh - (2 * #{$width}) - #{$header-height});
    }

    .mobile-brand-nav-item {
        @include \kindling\typography\font-size('brands-page.sticky-on-page-nav.link');
        @include \compass\typography\font\apply('primary', 'semi-bold');
        text-align: center;

        &.active {
            background-color: \kindling\color\get('brands-page.sticky-on-page-nav.active-link-background');
            color: \kindling\color\get('brands-page.sticky-on-page-nav.active-link');
        }
    }

    .mobile-brand-nav-link {
        display: block;
        text-decoration: none;
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
    }
}

@mixin \project\brands\page\nav-desktop($width: \kindling\value\rem-calc(30)) {
    .desktop-brand-nav-container-outer {
        @include \kindling\spacing\apply(
            $spacing: 'brands-page.desktop-nav.section-inner',
            $padding-sides: ('top', 'bottom')
        );
        display: none;
        background-color: \kindling\color\get('brands-page.desktop-nav.section-background');

        @include \kindling\breakpoint\media-query($\project\brands\main-list\breakpoint) {
            display: block;
        }
    }

    .desktop-brand-nav-container {
        @include \compass\layout\container-padded();
    }

    .desktop-brand-nav {
        @include \kindling\typography\unstyled-list();
        display: flex;

        > * {
            @include \kindling\spacing\apply('brands-page.desktop-nav.item-outer', $padding-sides: ('right'));
        }
    }

    .desktop-brand-nav-link {
        @include \kindling\border\apply(
            $context: 'brands-page.desktop-nav.link',
            $sides: ('all')
        );
        @include \kindling\typography\font-size('brands-page.desktop-nav.link');
        @include \compass\typography\font\apply('primary', 'semi-bold');
        $sizes: (
            $\project\brands\main-list\breakpoint: \kindling\value\rem-calc(30),
            'xlarge': \kindling\value\rem-calc(35)
        );
        @include \kindling\property\apply('width', $sizes);
        @include \kindling\property\apply('height', $sizes);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: \kindling\color\get('brands-page.desktop-nav.link.background');
        text-decoration: none;
        color: \kindling\color\get('brands-page.desktop-nav.link.text');

        &:hover,
        &:focus {
            background-color: \kindling\color\get('brands-page.desktop-nav.link.background-hover');
            color: \kindling\color\get('brands-page.desktop-nav.link.text-hover');
        }
    }
}

@mixin \project\brands\page\section() {
    @include \kindling\spacing\apply(
        $spacing: 'brands-page.section',
        $margin-sides: ('top', 'bottom')
    );
    @include \kindling\spacing\apply(
        $spacing: 'brands-page.section-title',
        $padding-sides: ('bottom')
    );
    @include \kindling\border\apply('brands-page.page-section', $sides: ('bottom'));
}

@mixin \project\brands\page\apply() {
    .brands-index-index {
        @include \project\brands\page\nav-mobile();
        @include \project\brands\page\nav-desktop();

        .page-main-content {
            .cms-product-list {
                margin-top: \kindling\value\rem-calc(100);

                .content-main {
                    @include \kindling\breakpoint\media-query(\kindling\breakpoint\get-previous-breakpoint($\project\brands\main-list\breakpoint) 'down') {
                        @include \kindling\spacing\apply($spacing: 'reset', $padding-sides: ('left', 'right'));
                    }
                }
            }
        }

        .page-title-wrapper {
            @include \compass\layout\container-padded();
        }

        .featured-brand-list-container {
            @include \compass\layout\container-padded();
            @include \kindling\spacing\apply(
                $spacing: 'brands-page.section',
                $margin-sides: ('top')
            );
        }

        .featured-brand-list-title {
            @include \kindling\typography\heading\h2();
        }

        .brand-page-list-content-inner {
            @include \kindling\breakpoint\media-query(\kindling\breakpoint\get-previous-breakpoint($\project\brands\main-list\breakpoint) 'down') {
                @include \compass\layout\container-padded();
                display: flex;
                flex: 1 1 auto;
                align-items: flex-start;
            }
        }

        .brand-page-list-container {
            @include \kindling\breakpoint\media-query(\kindling\breakpoint\get-previous-breakpoint($\project\brands\main-list\breakpoint) 'down') {
                flex: 1 1 auto;
            }

            @include \kindling\breakpoint\media-query($\project\brands\main-list\breakpoint) {
                @include \compass\layout\container-padded();
            }
        }

        .brand-page-list {
            @include \kindling\typography\unstyled-list();

            @include \kindling\breakpoint\media-query(\kindling\breakpoint\get-previous-breakpoint($\project\brands\main-list\breakpoint) 'down') {
                flex: 1 1 auto;
            }
        }

        .brand-page-list-item-row {
            @include \kindling\grid\row();

            @include \kindling\breakpoint\media-query(\kindling\breakpoint\get-previous-breakpoint($\project\brands\main-list\breakpoint) 'down') {
                display: block;
            }
        }

        .brand-group-title-container {
            @include \project\brands\page\section();
            width: 100%;
        }

        .brand-group-title {
            @include \kindling\typography\heading\h2();
        }

        .brand-group-list-container {
            width: 100%;
        }

        .brand-group-list {
            @include \project\catalog\category\list();
            justify-content: normal;
        }

        .brand-group-list-item {
            @include \kindling\grid\column(
                $responsive-sizes: (
                    $\project\brands\main-list\breakpoint: \kindling\grid\column-width-calc(3),
                ),
                $flex-grow: 0
            );
        }
    }

    .brands-index-view {
        .category-cms {
            @include \compass\cms\styles\apply();
            @include \compass\slider\product\apply();

            .hero-slider-container {
                @include \compass\slider\hero\apply($constrict-title-width: false);
            }

            .category-featured-tabs {
                display: none;
            }

            .category-featured-tabs-panel {
                .featured-category-tabs-actions {
                    .featured-categories-shop-all {
                        display: inline-block;
                    }
                }
            }
        }
    }
}
