@mixin \compass\contact\cms-base-styles() {
    h2 {
        @include \compass\typography\heading('h3', $include-spacing: true);
    }

    p,
    th,
    td {
        @include \compass\typography\copy\small();
    }

    table {
        @include \kindling\typography\line-height('base');
    }

    th {
        @include \kindling\spacing\apply('contact.info', $padding-sides: ('right'));
        text-align: left;
        font-weight: inherit;
    }
}

@mixin \compass\contact\page\apply() {
    .contact-index-index {
        .main-content-panel {
            @include \kindling\breakpoint\media-query('large') {
                padding-right: \kindling\grid\column-width-calc(1);
                padding-left: \kindling\grid\column-width-calc(1);
            }

            .contact-map-title {
                @include \kindling\spacing\apply('contact.title', $margin-sides: ('bottom'));
            }

            .contact-map {
                @include \kindling\spacing\apply('contact.map', $margin-sides: ('bottom'));
            }

            .contact-info-section {
                @include \compass\grid\row(\kindling\spacing\get('contact.info'));
                @include \compass\contact\cms-base-styles();
                justify-content: space-between;

                > * {
                    @include \kindling\grid\column(
                        $responsive-sizes: (
                            'small': 100%,
                            'large': auto
                        ),
                        $flex-grow: 0,
                        $flex-shrink: 0
                    );
                }
            }

            .contact-information {
                strong {
                    display: block;
                    margin-bottom: \kindling\value\rem-calc(5);
                }
            }
        }
    }
}
