$\compass\catalog\product\pdp\gallery\width: (
    'small': 100%,
    'large': \kindling\grid\column-width-calc(6),
    'xlarge': \kindling\grid\column-width-calc(7)
);
$\compass\catalog\product\pdp\info\width: (
    'small': 100%,
    'large': \kindling\grid\column-width-calc(6),
    'xlarge': \kindling\grid\column-width-calc(5)
);
