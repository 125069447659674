@mixin \compass\checkout\order\item\options() {
    .product-info-option-group {
        @include \kindling\spacing\apply($checkout-sales-order-item-info-spacing, $margin-sides: ('bottom'));
        @include \kindling\typography\font-size('typography.copy.small')
    }

    .product-info-option-label {
        @include \compass\typography\font\apply('primary', 'normal');
        @include \kindling\spacing\apply('global.xsmall', $padding-sides: ('right'));
        // width: 1px is necessary so the label ths shrink as small as possible.
        width: 1px; // sass-lint:disable-line property-units
        text-align: left;
    }

    .product-info-option-value {
        vertical-align: bottom;
    }
}

@mixin \compass\checkout\order\item\apply($include-bottom-border: false) {
    .order-items {
        @include \kindling\border\apply($color: $checkout-sales-order-item-border-color, $sides: ('top'));
        background-color: \kindling\color\get($checkout-sales-order-item-background-color);

        &:last-child {
            @if $include-bottom-border == false {
                @include \kindling\breakpoint\media-query('large') {
                    border-bottom: 0;
                }
            }
        }
    }

    .order-item-row {
        @include \kindling\spacing\apply($checkout-sales-order-item-inner-spacing, $padding-sides: ('top', 'bottom'));
        @include \kindling\grid\row($vertical-gutters: true);

        .product-photo {
            @include \kindling\grid\column(
                $responsive-sizes: $checkout-sales-order-item-image-sizes
            );
        }

        .item-product-info {
            @include \kindling\grid\column(
                $responsive-sizes: $checkout-sales-order-item-content-sizes
            );
            @include \kindling\spacing\apply('grid-gutters.large', $padding-sides: ('left'));
            display: flex;
            flex-direction: column;

            .item-name {
                @include \kindling\spacing\apply('base.medium', $margin-sides: ('bottom'));
                @include \compass\typography\heading('h5');

                a {
                    text-decoration: none;
                }
            }

            .item-options {
                @include \compass\checkout\order\item\options();
                @include \project\delivery-timelines\table\apply();
                order: 1;

            }

            .item-qty {
                order: 2;
            }

            .items-qty {
                @include \kindling\typography\unstyled-list();

                .item {
                    @include \kindling\spacing\apply($checkout-sales-order-item-info-spacing, $margin-sides: ('bottom'));
                }

                .title {
                    font-weight: \kindling\typography\font-weight\get('bold');
                }
            }

            .item-price {
                @include \kindling\spacing\apply($checkout-sales-order-item-info-spacing, $margin-sides: ('top'));
                order: 3;
            }
        }
    }
}
