@mixin \project\fitment\header-dropdown\apply() {
    .vehicle-navigation-container {
        @include \project\fitment\vehicle-navigator-form\base\apply();
        @include \project\fitment\mini-garage\accordion\apply();
        @include \compass\dropdown\shadow();
        @include \project\fitment\mini-garage\empty-message\apply();
        @include \project\fitment\mini-garage\list\apply();
        width: \kindling\value\rem-calc(280);

        .logged-in {
            .mini-garage-accordion-title {
                @include \compass\accordion\disable-button();
                @include \kindling\property\apply('padding-right', \kindling\value\get-side(\kindling\spacing\get('accordion.mini-garage.title'), 'left'));
                background-color: \kindling\color\get('menu.toolbar.header.background');
                pointer-events: auto;

                .mini-garage-accordion-title-text {
                    @include \kindling\icon\selector() {
                        color: \kindling\color\get('secondary');
                    }
                    @include \compass\typography\heading('h4');
                    color: \kindling\color\get('menu.toolbar.header.text');
                    pointer-events: none;
                }

                .mini-garage-login-link {
                    margin-top: \kindling\value\rem-calc(5);
                    color: \kindling\color\get('menu.toolbar.header.text');
                }
            }

            .mini-garage-accordion-body {
                display: block;
            }

            .vehicle-navigator-form-accordion-title-text {
                @include \kindling\typography\font-size('accordion.vehicle-navigation-form.dropdown-title');
            }

            .vehicle-navigator-form-accordion-body {
                @include \kindling\spacing\apply('accordion.vehicle-navigation-form.title', $padding-sides: ('top'));
            }

            .garage-empty-list-title {
                display: none;
            }
        }

        .logged-out {
            .vehicle-navigator-form-accordion-title {
                @include \compass\accordion\disable-button();
            }
        }
    }
}

@mixin \project\fitment\main-header\dropdown\apply() {
    .my-garage-dropdown-container {
        @include \project\fitment\header-dropdown\apply();

        @include \kindling\breakpoint\media-query($\compass\header\main\desktop-breakpoint) {
            display: none;
        }

        .dropdown-button {
            @include \compass\button\reset-browser-button();

            .icon-image {
                @include \kindling\icon\apply($icon: $icon-garage) {
                    @include \kindling\typography\font-size('my-garage.mobile-toggle');
                    @include \kindling\typography\line-height('header.icon');
                }
            }

            .text {
                @include \kindling\visually-hidden\apply();
            }
        }

        .dropdown {
            @include \compass\global\header\dropdown-content();
        }
    }
}
