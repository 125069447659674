@mixin \project\send-friend\form\apply() {
    .sendfriend-product-send {
        .main-content-panel {
            @include \kindling\breakpoint\media-query('large') {
                padding-right: \kindling\grid\column-width-calc(1);
                padding-left: \kindling\grid\column-width-calc(1);
            }
        }
    }

    .form.friend {
        .fieldset {
            @include \compass\grid\fieldset(
                $column-selector: '> .field'
            );

            .legend {
                @include \compass\base\form-legend\heading($include-margin: false);
                margin-top: \kindling\value\get-side($\compass\grid\fieldset\row-spacing, 'top');
                width: auto;
            }

            .field {
                &.text {
                    width: calc(100% - #{\kindling\value\get-side($\compass\grid\fieldset\row-spacing, 'right')});
                }
            }

            .actions-toolbar {
                @include \kindling\spacing\apply('form.default.input.margin', $margin-sides: ('top', 'bottom'));

                .secondary {
                    .action {
                        @include \compass\button\secondary('micro');
                    }
                }
            }
        }

        #recipients-options { // sass-lint:disable-line no-ids
            width: calc(100% - #{\kindling\value\get-side($\compass\grid\fieldset\row-spacing, 'right')});

            > .fields {
                &:first-child {
                    > .actions-toolbar {
                        margin: 0;
                    }
                }
            }
        }

        > .actions-toolbar {
            @include \compass\section(
                $margin-sides: ('top')
            );
            display: flex;
            justify-content: space-between;

            .primary {
                .action {
                    @include \compass\button\primary();
                }
            }

            .secondary {
                order: -1;

                .action {
                    @include \compass\button\secondary();
                }
            }
        }
    }
}
