@mixin \compass\catalog\product\details\apply() {
    .product-info-additional {
        @include \kindling\spacing\apply('pdp.product-details.padding', $padding-sides: ('top'));
        @include \kindling\spacing\apply('pdp.product-details.margin', $margin-sides: ('bottom', 'top'));
        @include \kindling\border\apply('section.default', $sides: ('top'));
        @include \kindling\grid\column((
                'small': \kindling\grid\column-width-calc(12),
                'xlarge': \kindling\grid\column-width-calc(10)
        ));
        margin-right: auto;
        margin-left: auto;

        .product-info-title {
            @include \kindling\typography\margin('typography.h2');
            text-align: center;
        }
    }

    .product-info-additional-tabs {
        @include \compass\tabs\list\apply();
    }

    .product-info-additional-tab-title {
        @include \compass\tabs\title\apply('tabs.pdp.title');
    }

    .product-info-additional-body {
        @include \compass\tabs\body\apply('tabs.pdp.body');
    }

    .product-info-additional-panel {
        @include \compass\tabs\panel\apply('tabs.pdp.panel');
        @include \kindling\spacing\apply('reset', $padding-sides: ('all'));

        .table-wrapper {
            @include \compass\table-secondary\apply();
            @include \compass\table\item-actions\apply();
        }

        .description {
            @include \kindling\spacing\apply('pdp.product-details.description.padding', $padding-sides: ('all'));
        }
    }
}
