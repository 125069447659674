@mixin \project\site-map\apply() {
    .sitemap-index-index,
    .sitemap-products-index,
    .sitemap-content-index {
        .page-title-wrapper {
            @include \compass\section(
                $border-sides: ('bottom'),
                $margin-sides: ('bottom')
            );
        }
    }

    .page-sitemap {
        .inner-wrapper {
            @include \compass\section(
                $border-sides: ('bottom'),
                $margin-sides: ('bottom')
            );

            @include \kindling\breakpoint\media-query('medium') {
                @include \kindling\grid\row();
                align-items: center;
            }
        }

        .links {
            @include \kindling\typography\unstyled-list();

            @include \kindling\breakpoint\media-query('medium') {
                @include \kindling\grid\row();
            }

            a {
                @include \compass\button\primary($size: 'small');
                @include \kindling\spacing\apply($spacing: 'site-map.button', $margin-sides: ('all'), $ratio: 0.5);
            }
        }

        .sitelist {
            @include \kindling\spacing\apply($spacing: 'site-map.default', $margin-sides: ('bottom'));
            @include \kindling\grid\row();

            li {
                width: 100%;

                @include \kindling\breakpoint\media-query('medium') {
                    @include \kindling\spacing\apply($spacing: 'site-map.default', $padding-sides: ('right'));
                    width: 50%;
                }
            }
        }

        .sitemap-pager {
            @include \compass\toolbar\apply();
        }
    }
}
