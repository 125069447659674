@mixin \customer\account\box\container() {
    @include \compass\box\container('box.account');
    box-shadow: 0 0 0 \kindling\value\rem-calc(2) \kindling\color\get('neutral-lightest');
}

@mixin \customer\account\box\body() {
    @include \compass\box\body('box.account');
}

@mixin \customer\account\box\header() {
    @include \compass\box\header('box.account');
}

@mixin \customer\account\box\footer() {
    @include \compass\box\footer('box.account');
}

@mixin \customer\account\boxes\apply() {
    .box {
        @include \customer\account\box\container();
    }

    .box-content {
        @include \customer\account\box\body();
    }

    .box-title {
        @include \customer\account\box\header();

        @include \kindling\breakpoint\media-query('large') {
            padding-bottom: \kindling\breakpoint\get-breakpoint-value(\kindling\spacing\get('box.account.section'), 'small');
        }

        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        + .box-content {
            padding-top: 0;
        }
    }

    .box-footer {
        @include \customer\account\box\footer();
    }
}

@mixin \customer\account\block\apply() {
    .block {
        &:not(:last-of-type) {
            @include \kindling\spacing\apply($spacing: 'base.large', $padding-sides: ('bottom'), $margin-sides: ('bottom'));
            @include \kindling\border\apply($color: \kindling\color\get('neutral-lighter'), $sides: 'bottom');
        }
    }

    .block-title {
        @include \compass\typography\heading('h3', $include-spacing: true);
    }
}
