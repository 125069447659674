@mixin \compass\form\input\box-shadow-border($context, $include-inset: false) {
    $border: \kindling\border\get($context);
    $border: \kindling\value\to-responsive-map($border);

    @each $breakpoint, $item in $border {
        @include \kindling\breakpoint\media-query($breakpoint) {
            $result: null;
            $border-width: null;
            $border-color: null;

            @if $item != null {
                $border-width: \kindling\map\get($item, 'width');
                $border-color: \kindling\map\get($item, 'color');
            }

            @if $border-width != null and \kindling\value\strip-unit($border-width) > 0 {
                @if $include-inset {
                    $result: 0 0 0 $border-width \kindling\color\get($border-color) inset;
                } @else {
                    $result: 0 0 0 $border-width \kindling\color\get($border-color);
                }
            }

            box-shadow: $result;
        }
    }
}

@mixin \compass\form\input\border($context: 'form.default.input', $use-inset: false) {
    &:not(:disabled) {
        @include \compass\form\input\box-shadow-border($context, $use-inset);

        &:hover,
        &:focus {
            @include \compass\form\input\box-shadow-border('form.default.active', $use-inset);
        }

        &.mage-error {
            @include \compass\form\input\box-shadow-border('form.default.error', $use-inset);

            &:hover,
            &:focus {
                @include \compass\form\input\box-shadow-border('form.default.active', $use-inset);
            }
        }
    }
}

@mixin \kindling\form\input-base\extra(
    $context: $\kindling\form\default-context,
    $border: null,
    $border-color-hover: null,
    $border-radius: null,
    $background-color: null,
    $background-color-hover: null,
    $background-color-disabled: null,
    $color: null,
    $font-size: null,
    $include-outline: null,
    $padding: null,
    $transition-properties: null,
    $width: null,
    $appearance: null,
    $input-color-disabled: null,
    $input-border-color-disabled: null,
    $input-cursor-disabled: null,
    $extras: ()
) {
    @include \compass\form\input\border();
    border: 0;

    &:disabled {
        @include \compass\form\input\box-shadow-border('form.default.disabled');
    }
}

@mixin \compass\base\input\password-field() {
    &.is-initialized {
        &:not(.is-empty) {
            [type='password'] {
                @include \compass\form\input\border('form.default.complete', $use-inset: true);
            }
        }
    }

    [type='password'] {
        @include \compass\form\input\border($use-inset: true);
    }

    .password-icon {
        @include \kindling\icon\apply($icon: $icon-lock-closed);
        @include \kindling\typography\font-size('form.default.input');
        position: absolute;
        top: \kindling\value\rem-calc(13);
        right: \kindling\value\rem-calc(15);
        pointer-events: none;
    }

    [data-role='password-strength-meter'] {
        .password-strength-meter {
            @include \kindling\typography\font-size('form.default.password');
            @include \kindling\typography\font-weight('form.default.input');
            position: relative;
            padding-top: \kindling\value\rem-calc(2);
            color: \kindling\color\get('form.default.input.text-color');

            &::before {
                position: absolute;
                top: $\compass\base\input\password-strength-meter-top;
                left: 0;
                z-index: \kindling\z-index\get('above-sibling');
                width: 0;
                height: $\compass\base\input\password-strength-meter-height;
                content: '';
            }

            &::after {
                position: absolute;
                top: $\compass\base\input\password-strength-meter-top;
                right: 0;
                z-index: \kindling\z-index\get('neutral-sibling');
                background-color: \kindling\color\get('form.default.password-meter-background');
                width: 100%;
                height: $\compass\base\input\password-strength-meter-height;
                content: '';
            }
        }

        &.password-weak {
            .password-strength-meter {
                &::before {
                    background-color: \kindling\color\get('error');
                    width: 33%;
                }
            }
        }

        &.password-medium {
            .password-strength-meter {
                &::before {
                    background-color: \kindling\color\get('warning');
                    width: 66%;
                }
            }
        }

        &.password-strong,
        &.password-very-strong {
            .password-strength-meter {
                &::before {
                    background-color: \kindling\color\get('success');
                    width: 100%;
                }
            }
        }
    }
}

@mixin \compass\base\input\mage-error($color: 'error', $background-color: 'not-set') {
    div.mage-error {
        @include \kindling\typography\font-size('form.default.error');
        @include \kindling\typography\font-style('form.default.error');
        @include \kindling\typography\font-weight('form.default.input');
        background-color: \kindling\color\get($background-color);
        color: \kindling\color\get($color);
        @content;
    }
}
