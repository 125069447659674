@mixin \compass\typography\font\apply($font-stack, $weight: 'normal', $style: normal) {
    @if $font-stack != null {
        $font-family: nth(\kindling\typography\font-stack\get($font-stack), 1);
        $style: \kindling\typography\font-style\get($style);
        $font-face-config: \kindling\map\get($\compass\typography\font-faces\config, $font-family);
        $weights: map-keys($font-face-config);

        @if index($weights, $weight) == null {
            @error '`#{$weight}` font weight not available for `#{$font-stack}` in: #{$font-face-config}';
        }

        @if $style != normal {
            @if \kindling\map\has-key($font-face-config, '#{$weight}.#{$style}') != true {
                @error '`#{$style}` font style not available for [font-stack: `#{$font-stack}`] [font-weight: `#{$weight}`] in: #{$font-face-config}';
            }
        }
    }

    font-family: \kindling\typography\font-stack\get($font-stack);
    font-weight: \kindling\typography\font-weight\get($weight);
    font-style: $style;
}

@mixin \compass\typography\font\body() {
    @include \compass\typography\font\apply($font-stack: 'primary', $weight: 'normal');
}

//@mixin \compass\typography\font\heading() {
//    @include \compass\typography\font\set($font-stack: 'secondary', $weight: 'light', $style: $style);
//}
//
//@mixin \compass\typography\font\subheading() {
//    @include \compass\typography\font\set($font-stack: 'tertiary', $weight: 'normal', $style: italic);
//}
