@mixin \compass\page-builder\slider\apply() {
    div[data-content-type='slider'] {
        display: none;

        &.slick-initialized {
            display: block;
            margin-bottom: \kindling\value\rem-calc(35) !important; // sass-lint:disable-line no-important
        }

        .slick-list,
        .slick-track,
        .slick-slide {
            min-height: inherit;

            > div {
                width: 100%;
                min-height: inherit;
                overflow: hidden;
            }
        }
    }

    a.button {
        padding: \kindling\value\rem-calc(10);
        text-decoration: none;
        color: initial;
        appearance: button;
    }
}
