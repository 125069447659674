@mixin \project\fitment\fitment-label() {
    .fitment-label {
        @include \compass\typography\font\apply('primary', 'medium');
        @include \kindling\typography\font-size('fitment.label');
        @include \kindling\typography\line-height('fitment.label');
        @include \kindling\spacing\apply('fitment.label', $padding-sides: ('all'));
        display: inline-block;

        &.universal {
            background-color: \kindling\color\get('fitment.label.universal.background');
            color: \kindling\color\get('fitment.label.universal.text-color');
        }

        &.vehicle-specific {
            background-color: \kindling\color\get('fitment.label.vehicle-specific.background');
            color: \kindling\color\get('fitment.label.vehicle-specific.text-color');
        }
    }
}
