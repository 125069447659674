@mixin \compass\page-content-container(
    $context: 'main-content-container.default',
    $padding-sides: ('top', 'bottom')
) {
    @include \kindling\spacing\apply(
        $spacing: $context,
        $padding-sides: $padding-sides
    );
}

@mixin \compass\page-content-container\reset(
    $padding-sides: ('top', 'bottom')
) {
    @include \compass\page-content-container(
        $context: 'main-content-container.reset',
        $padding-sides: $padding-sides
    );
}

@mixin \compass\page-content-container\apply() {
    body {
        &.page-layout-empty-full-width,
        &.page-layout-category-full-width {
            @include \project\message\full-width-styles();

            .content-main {
                @include \compass\page-content-container\reset();
            }
        }
    }

    .content-main {
        @include \compass\page-content-container();
    }
}
