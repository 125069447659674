@mixin \compass\catalog\gallery\video-play-button($size, $hover: true) {
    @include \compass\icon\psuedo-button(
        $icon: $icon-play,
        $size: $size,
        $icon-size-ratio: 0.6,
        $context: 'gallery.default.play-icon',
        $hover: $hover
    ) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: \kindling\z-index\get('above-next-sibling');
        @content;
    }
    @include \compass\button\reset-browser-button();
    position: relative;
}

@mixin \compass\catalog\gallery\main-video-slide() {
    .gallery-video-button {
        @include \compass\catalog\gallery\video-play-button($size: \kindling\value\rem-calc(50));
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        &.hidden {
            display: none;
        }
    }

    .gallery-video-image {
        width: 100%;
        max-width: \kindling\value\rem-calc(640);
    }

    .responsive {
        @include \kindling\embed\apply('widescreen');
        z-index: \kindling\z-index\get('above-next-sibling');
        width: 100%;
    }
}

@mixin \compass\catalog\gallery\fullscreen-video-slide() {
    .slider-item {
        position: relative;

        .responsive {
            position: relative;
            z-index: \kindling\z-index\get('above-next-sibling');
            width: 100%;
            height: 100%;

            iframe {
                width: inherit;
                height: inherit;
            }
        }

        .gallery-modal-play-button {
            @include \compass\catalog\gallery\video-play-button($size: \kindling\value\rem-calc(50));
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            &.hidden {
                display: none;

                // Image isn't a child of the button so existing image layout will work (via css grid).
                ~ .gallery-video-image {
                    display: none;
                }
            }
        }
    }
}
