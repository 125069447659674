@mixin \compass\table\item-actions\apply() {
    .action {
        &.order {
            @include \compass\button\item-action\order();
        }

        &.view {
            @include \compass\button\item-action\view();
        }
    }
}
