@mixin \catalog\product-grid\out-of-stock\apply() {
    @include \compass\typography\font\apply($\catalog\components\out-of-stock\font-stack, $weight: $\catalog\components\out-of-stock\font-weight, $style: $\catalog\components\out-of-stock\style);
    @include \kindling\typography\font-size($\catalog\components\out-of-stock\font-size);
    color: \kindling\color\get($\catalog\components\product-price\special-price\color);
}

@mixin \catalog\price\apply($price-type: 'price', $context: $\catalog\components\product-price\context, $font-weight: $\catalog\components\product-price\font-weight) {
    $price-font-stack: 'product-price.default.price';
    $price-font-size: \kindling\typography\font-size\context-keys-calc($price-type, $context, 'product-price.default');
    $color: \kindling\color\context-keys-calc($price-type, $context, 'product-price.default');
    @include \compass\typography\font\apply($price-font-stack, $weight: $font-weight);
    @include \kindling\typography\font-size($price-font-size);
    color: \kindling\color\get($color);
}

@mixin \catalog\special-price\apply($context: $\catalog\components\product-price\context) {
    @include \catalog\price\apply('special-price', $context);

    &:not(.configurable-product) {
        .price-label {
            display: none;
        }
    }
}

@mixin \catalog\same-price\apply() {
    .price-label {
        display: none !important; // sass-lint:disable-line no-important
    }
}

@mixin \catalog\old-price\apply($context: $\catalog\components\product-price\context, $text-decoration: $\catalog\components\product-price\old-price\text-decoration) {
    @include \catalog\price\apply('old-price', $context);
    text-decoration: $text-decoration;

    .price-label {
        display: none;
    }
}

@mixin \catalog\price\styles\apply(
    $context: $\catalog\components\product-price\context,
    $wrapper-selector: '.price-box',
    $normal-price-selector: '.normal-price',
    $special-price-selector: '.special-price',
    $old-price-selector: '.old-price',
    $same-price-selector: '.same-price'
) {
    #{$wrapper-selector} {
        flex: 0 0 auto;

        .price-box .price,
        > .price-container .price,
        #{$normal-price-selector} {
            @include \catalog\price\apply('price', $context);
        }

        #{$special-price-selector} {
            @include \catalog\special-price\apply($context);
        }

        #{$same-price-selector} {
            @include \catalog\same-price\apply();
        }

        #{$old-price-selector} {
            &:not(:first-child) {
                @include \catalog\old-price\apply($context);
            }

            &:first-child {
                @include \catalog\price\apply();
                text-decoration: line-through;
            }
        }

        .product-msrp {
            $font-size: \kindling\typography\font-size\context-keys-calc('msrp', $context, 'product-price.default.msrp');
            @include \kindling\typography\font-size($font-size);
            margin-top: \kindling\value\rem-calc(5);
        }
    }
}
