@mixin \compass\product-badge\container\apply() {
    .product-badge-container {
        display: flex;
    }
}

@mixin \compass\product-badge\badge\apply() {
    @include \compass\product-badge\container\apply();

    .product-badge {
        @include \kindling\spacing\apply('badge', $margin-sides: ('right'), $padding-sides: ('all'));
        @include \compass\typography\font\apply('secondary', $weight: 'bold');
        @include \kindling\typography\font-size('badge');
        @include \kindling\typography\line-height('badge');
        @include \kindling\typography\letter-spacing('badge');
        display: block;
        text-transform: uppercase;

        &:last-child {
            margin-right: 0;
        }
    }
}
