// Create unique grid area name so autoprefixer can create styles which work in IE 11
//
// The returned value will have to be stored in a local variable within
// the current mixin since this never returns the same value
@function \compass\grid\area-name($name) {
    $\compass\grid\current-id: $\compass\grid\current-id + 1 !global;
    @return unquote('#{$name}-#{$\compass\grid\current-id}');
}

@function \project\grid\column-width-calc-relative-to-grandparent(
    $columns,
    $parent-columns,
    $total-columns: $grid-columns,
    $parent-total-columns: $grid-columns
) {
    $parent-ratio: $parent-total-columns / $parent-columns;
    $column-ratio: $columns / $total-columns;

    @return percentage($column-ratio * $parent-ratio);
}
