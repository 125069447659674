@mixin \compass\page-builder\layout\apply() {
    .product-full-width-section {
        padding: 0.5em 0;

        .block.review-add {
            margin-top: 2.7rem;
        }
    }

    .page-layout-product-full-width {
        .block.related {
            margin-top: 2.7rem;
        }
    }

    .page-main-details .product-section-title {
        margin-bottom: \kindling\value\rem-calc(15);
        border-bottom: 1px solid \kindling\color\get('neutral-dark'); // sass-lint:disable-line property-units
        padding-bottom: \kindling\value\rem-calc(12);
    }

    .additional-attributes-wrapper .additional-attributes {
        border: 0;
        width: auto;

        > tbody > tr {
            > th {
                border: 0;
                padding: \kindling\value\rem-calc(6) \kindling\value\rem-calc(30) \kindling\value\rem-calc(10) 0;
            }

            > td {
                border: 0;
                padding: \kindling\value\rem-calc(6) \kindling\value\rem-calc(5) \kindling\value\rem-calc(10);
            }
        }
    }

    .cms-home.page-layout-cms-full-width .nav-sections {
        margin-bottom: 0;
    }
}
