@mixin \compass\overlay\button($z-index: null) {
    @include \compass\button\reset-browser-button();
    @include \kindling\modal\overlay();
    display: block;
    z-index: \kindling\z-index\get($z-index);
    width: 100%;
    height: 100%;
    overflow: visible;
}

@mixin \compass\overlay($z-index: null) {
    @include \kindling\modal\overlay();
    z-index: \kindling\z-index\get($z-index);
    -webkit-overflow-scrolling: touch;
}
