@mixin \compass\global\payment-icons\apply($show-secure: true, $in-footer: true) {
    .page-payment-icon-container {
        width: 100%;
    }

    .page-payment-icon-list {
        @include \kindling\typography\unstyled-list();
        @include \kindling\spacing\apply('base.small', $margin-sides: ('bottom'));
        display: flex;

        @if $in-footer {
            justify-content: center;

            @include \kindling\breakpoint\media-query($\compass\footer\breakpoint\mobile) {
                justify-content: flex-start;
            }

            @include \kindling\breakpoint\media-query($\compass\footer\breakpoint\large-desktop) {
                justify-content: flex-end;
            }

            .page-payment-icon-item {
                &:not(:first-child) {
                    @include \kindling\spacing\apply('base.xsmall', $margin-sides: ('left'));
                }
            }
        } @else {
            justify-content: start;
        }

        @if $show-secure == false {
            .icon-secure,
            .icon-privacy {
                display: none;
            }
        }
    }

    .page-payment-icon {
        @include \kindling\property\apply('max-height', \kindling\value\rem-calc(25));
    }
}
