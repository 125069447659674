@mixin \compass\sales\order\print\apply() {
    body {
        &[class*='sales-order-print'],
        &[class*='sales-guest-print'] {
            .page-header,
            .page-header-bottom,
            .header-logo-container {
                background: none;
            }

            .page-header-main {
                box-shadow: none;
            }

            .page-header-main-content-row {
                justify-content: center;
            }

            .page-title-wrapper {
                @include \kindling\spacing\apply('base.small', $margin-sides: ('bottom'));

                @include \kindling\breakpoint\media-query('large') {
                    flex-wrap: wrap;
                }
            }

            .table-wrapper {
                thead {
                    display: none;
                }
            }

            .page-footer {
                display: none;
            }
        }
    }
}
