@mixin \project\fitment\mini-garage\accordion\apply() {
    .mini-garage-accordion {
        @include \project\accordion\mini-garage\container();
    }

    .mini-garage-accordion-item {
        @include \project\accordion\mini-garage\item();
        position: relative;
    }

    .mini-garage-accordion-title {
        @include \project\accordion\mini-garage\title();
        position: relative;

        .accordion-title-overlay {
            @include \compass\accordion\overlay-button();
        }
    }

    .mini-garage-accordion-title-text {
        @include \kindling\icon\apply($icon: $icon-garage) {
            @include \kindling\spacing\apply(
                $spacing: 'fitment.mini-garage.accordion-icon',
                $margin-sides: ('right')
            );
            line-height: 60%;
            color: \kindling\color\get('fitment.mini-garage.garage-icon');
            font-size: 130%;
        }
        @include \kindling\typography\line-height('fitment.mini-garage.reset');
        display: flex;
        padding-top: \kindling\value\px-to-em(5);
    }

    .mini-garage-login-link {
        @include \compass\accordion\above-overlay-button();
        @include \compass\typography\anchor\micro();
        @include \kindling\spacing\apply(
            $spacing: 'fitment.mini-garage.accordion-link-outer',
            $margin-sides: ('left')
        );
        text-transform: none;
        color: inherit;

        &:hover,
        &:focus {
            color: inherit;
        }
    }

    .mini-garage-accordion-body {
        @include \project\accordion\mini-garage\body();
    }
}

@mixin \project\fitment\mini-garage\empty-message\apply() {
    $icon-width: \kindling\value\rem-calc(20);

    .garage-empty-message-container {
        @include \kindling\spacing\apply(
            $spacing: 'fitment.mini-garage.empty-message.item-spacing',
            $padding-sides: ('all')
        );
    }

    .garage-empty-list-title {
        @include \kindling\typography\heading\h6();
        @include \kindling\spacing\apply(
            $spacing: 'fitment.mini-garage.empty-message.title',
            $margin-sides: ('bottom')
        )
    }

    .garage-empty-list {
        @include \kindling\typography\unstyled-list();
        @include \compass\grid\row(
            $column-gutters: ('small': \kindling\spacing\get('fitment.mini-garage.empty-message.item-spacing')),
            $spacing-type: 'margin'
        );
        flex-direction: column;

        li {
            @include \compass\typography\copy\micro();
            position: relative;
            padding-left: calc(#{$icon-width} + #{\kindling\value\rem-calc(10)});
        }

        img {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: $icon-width;
        }
    }
}

@mixin \project\fitment\mini-garage\list\apply() {
    .garage-vehicles {
        @include \kindling\typography\unstyled-list();
    }

    .garage-vehicle {
        @include \kindling\spacing\apply(
            $spacing: 'fitment.mini-garage.garage-items.item-inner',
            $padding-sides: ('all')
        );
        display: flex;

        &:not(:last-child) {
            @include \kindling\border\apply(
                $context: 'light',
                $sides: ('bottom')
            );
        }
    }

    .garage-vehicle-info-icon {
        @include \kindling\icon\apply($icon: $icon-motorcycle) {
            font-size: \kindling\value\rem-calc(26);
        }
        @include \kindling\spacing\apply(
            $spacing: 'fitment.mini-garage.garage-items.icon-outer',
            $margin-sides: ('right')
        );
        @include \kindling\typography\line-height('fitment.mini-garage.reset');
        position: relative;
        flex: 0 0 auto;
        width: \kindling\value\rem-calc(28);
    }

    @each $name, $icon in $\project\fitment\fitment-icons {
        .garage-vehicle-info-icon-#{$name} {
            @include \kindling\icon\selector() {
                content: $icon;
            }
        }
    }

    .garage-vehicle-info {
        flex: 1 1 auto;
    }

    .garage-vehicle-info-label {
        @include \kindling\typography\font-size('typography.copy.small');
    }

    .garage-vehicle-info-action-items {
        @include \kindling\typography\unstyled-list();
        @include \compass\grid\row(
            $column-gutters: ('small': \kindling\spacing\get('fitment.mini-garage.garage-items.action-outer')),
            $vertical-gutters: false
        );
        @include \kindling\typography\line-height('fitment.mini-garage.reset');
    }

    .garage-vehicle-info-action {
        @include \compass\typography\anchor\micro();
    }
}
