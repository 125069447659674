@mixin \project\sizing-chart\modal\apply() {
    .catalog-product-view {
        .modal-open-button-sizing-chart {
            @include \kindling\spacing\apply('form.default.input.margin', $margin-sides: ('bottom'));
            @include \kindling\button\link-style();
            padding: 0;

            img {
                @include \kindling\spacing\apply('global.xsmall', $margin-sides: ('right'));
                width: \kindling\value\rem-calc(16);
            }
        }

        .modal-sizing-chart {
            .modal-footer {
                flex-wrap: wrap;

                .split-left {
                    @include \kindling\grid\column(('small': \kindling\grid\column-width-calc(12), 'large': \kindling\grid\column-width-calc(9)));
                }

                .split-right {
                    @include \kindling\grid\column(('small': \kindling\grid\column-width-calc(12), 'large': \kindling\grid\column-width-calc(3)));
                    @include \kindling\spacing\apply('reset', $margin-sides: ('left'));
                    @include \kindling\spacing\apply('modal.content', $padding-sides: ('left'));
                    @include \kindling\breakpoint\media-query('medium' 'down') {
                        @include \kindling\spacing\apply('modal.content', $margin-sides: ('top'));
                        @include \kindling\spacing\apply('reset', $padding-sides: ('left'));
                    }
                }

                .modal-back-button {
                    @include \compass\button\apply();
                    width: 100%;

                    @include \kindling\breakpoint\media-query('large') {
                        width: auto;
                    }
                }
            }
        }
    }
}
