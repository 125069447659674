$\kindling\border\palette: (
    'not-set': (
        'width': null,
        'style': null,
        'color': null
    ),
    'reset': (
        'width': 0,
        'style': solid,
        'color': 'transparent'
    ),
    'current-color-thick': (
        'width': \kindling\value\rem-calc(2),
        'style': solid,
        'color': 'current-color'
    ),
    'xsmall': (
        'width': 1px,
        'style': solid,
        'color': 'black'
    ),
    'large': (
        'width': \kindling\value\rem-calc(2),
        'style': solid,
        'color': 'neutral-black'
    ),
    'light': (
        'width': 1px,
        'style': solid,
        'color': 'neutral-lighter'
    ),
    'light-thin': (
        'width': 1px,
        'style': solid,
        'color': 'neutral-light'
    ),
    'light-thick': (
        'width': \kindling\value\rem-calc(2),
        'style': solid,
        'color': 'neutral-lighter'
    ),
    'primary-thin': (
        'width': 1px,
        'style': solid,
        'color': 'primary'
    ),
    'primary-thick': (
        'width': \kindling\value\rem-calc(2),
        'style': solid,
        'color': 'primary'
    ),
    'grid': (
        'width': \kindling\value\rem-calc(2),
        'style': solid,
        'color': 'neutral-lightest'
    ),
    'transparent': (
        'width': \kindling\value\rem-calc(2),
        'style': solid,
        'color': 'transparent'
    ),
    'transparent-thin': (
        'width': 1px,
        'style': solid,
        'color': 'transparent'
    ),
    'transparent-thick': (
        'width': \kindling\value\rem-calc(2),
        'style': solid,
        'color': 'transparent'
    ),
    'darkest-thin': (
        'width': 1px,
        'style': solid,
        'color': 'neutral-darkest'
    ),
    'tertiary-button-spacing': (
        'width': \kindling\value\px-to-em(24),
        'style': solid,
        'color': 'transparent'
    ),
    'error': (
        'width': \kindling\value\rem-calc(2),
        'style': solid,
        'color': 'error'
    ),
    'neutral-dark': (
        'width': 1px,
        'style': solid,
        'color': 'neutral-dark'
    ),
    'secondary-thick': (
        'width': \kindling\value\rem-calc(2),
        'style': solid,
        'color': 'secondary'
    ),
    'white-thin': (
        'width': 1px,
        'style': solid,
        'color': 'white'
    ),
    'white-extra-thick': (
        'width': \kindling\value\rem-calc(12),
        'style': solid,
        'color': 'white'
    ),
    'secondary-extra-thick': (
        'width': \kindling\value\rem-calc(10),
        'style': solid,
        'color': 'secondary'
    ),
    'secondary-super-extra-thick': (
        'width': \kindling\value\rem-calc(15),
        'style': solid,
        'color': 'secondary'
    ),
    'secondary-exxtra-thick': (
        'width': \kindling\value\rem-calc(14),
        'style': solid,
        'color': 'secondary'
    ),
    'gray': (
        'width': 2px,
        'style': solid,
        'color': 'gallery-gray'
    ),
    'large-white': (
        'width': \kindling\value\rem-calc(2),
        'style': solid,
        'color': 'neutral-white'
    )
);

$\kindling\border\contexts: (
    'default': 'large',
    'button': (
        'default': 'not-set',
        'secondary': 'primary-thick',
        'secondary-hover': 'transparent-thick',
        'tertiary': 'tertiary-button-spacing',
        'social-login': (
            'facebook': 'not-set',
            'google': 'xsmall'
        )
    ),
    'accordion': (
        'default': (
            'title': 'not-set',
            'body': 'primary-thin',
            'item': 'not-set'
        ),
        'sidebar': (
            'title': 'not-set',
            'body': 'not-set',
            'item': 'not-set'
        ),
        'sidebar-light': (
            'title': 'not-set',
            'body': 'not-set',
            'item': 'light-thick'
        ),
        'account-sidebar': (
            'title': 'not-set',
            'body': 'not-set',
            'item': 'not-set'
        ),
        'sidebar-checkout': (
            'title': 'not-set',
            'body': 'not-set',
            'item': 'light-thick'
        ),
        'footer': (
            'title': 'not-set',
            'body': 'not-set',
            'item': 'not-set'
        ),
        'table': (
            'title': 'not-set',
            'body': 'not-set',
            'item': 'not-set'
        ),
        'mini-garage': (
            'title': 'not-set',
            'body': 'light',
            'item': 'not-set'
        ),
        'compare-bar': (
            'title': 'not-set',
            'body': 'not-set',
            'item': 'not-set'
        )
    ),
    'box': (
        'default': (
            'container': 'not-set',
            'section': 'not-set'
        ),
        'account': (
            'container': 'not-set',
            'section': 'not-set'
        )
    ),
    'section': (
        'default': 'light-thick'
    ),
    'form': (
        'default': (
            'input': 'neutral-dark',
            'active': 'large',
            'complete': 'xsmall',
            'error': 'error',
            'disabled': 'not-set',
            'switch': 'xsmall'
        ),
        'search': (
            'default': 'xsmall',
            'active': 'primary-thick',
            'autocomplete': 'xsmall'
        )
    ),
    'typography': (
        'horizontal-rule': 'light-thick',
        'blockquote': (
            'icon': 'primary-thick'
        )
    ),
    'menu': (
        'default': (
            'header': (
                'default': 'light'
            ),
            'content': (
                'link-list': (
                    'default': 'light',
                    'header': 'secondary-thick'
                )
            )
        ),
        'mobile-mega-menu': (
            'header': (
                'default': 'not-set'
            ),
            'content': (
                'link-list': (
                    'default': 'light'
                )
            )
        )
    ),
    'tabs': (
        'default': (
            'title': (
                'default': 'transparent-thick',
                'active': 'secondary-thick'
            ),
            'body': 'not-set'
        ),
        'pdp': (
            'title': (
                'default': 'transparent-thick',
                'active': 'secondary-thick'
            ),
            'body': 'not-set'
        )
    ),
    'pdp': (
        'product-info': (
            'section': 'light-thick',
            'add-to-cart': 'form.default.input'
        )
    ),
    'checkout': (
        'section': 'light-thick',
        'totals': 'light',
        'success': (
            'totals': 'light-thick'
        )
    ),
    'minicart': (
        'item': 'light-thick',
        'actions': 'light-thick'
    ),
    'contact': (
        'form': 'light-thick'
    ),
    'skip-link': (
        'default': 'light'
    ),
    'table': (
        'default': (
            'thead': 'not-set',
            'tbody': 'light'
        ),
        'secondary': (
            'table': 'not-set'
        ),
        'responsive': (
            'thead': 'not-set',
            'tbody': 'light'
        ),
        'mobile': (
            'thead': 'not-set',
            'tbody': 'light'
        )
    ),
    'item-actions': (
        'default': 'tertiary-button-spacing'
    ),
    'product-grid': (
        'item': (
            'top': 'light-thick'
        )
    ),
    'header': 'light-thin',
    'slider': (
        'product': (
            'arrow': (
                'default': 'large',
                'hover': 'not-set',
                'disabled': 'current-color-thick'
            )
        ),
        'hero': (
            'arrow': (
                'default': 'not-set',
                'hover': 'not-set',
                'disabled': 'not-set'
            )
        )
    ),
    'gallery': (
        'default': (
            'arrow': (
                'default': 'not-set',
                'hover': 'not-set',
                'disabled': 'not-set'
            )
        ),
        'default-item': 'transparent-thick',
        'active-item': 'secondary-thick'
    ),
    'shop-for-bikes-modal': 'white-thin',
    'shop-for-bikes-mobile': 'primary-thick',
    'footer': (
        'top-icons': 'light-thin'
    ),
    'maintenance-page': 'secondary-extra-thick',
    'category-list': (
        'default': 'light-thin'
    ),
    'brands-page': (
        'desktop-nav': (
            'link': 'large',
        ),
        'sticky-on-page-nav': (
            'outer-border': 'large'
        ),
        'page-section': 'light-thick'
    ),
    'grid-border': (
        'default': (
            'container': 'transparent',
            'item': 'grid'
        )
    ),
    'checkout-bottom-border': (
        'default': (
            'default': 'gray'
        )
    ),
    'swatches': (
        'default': 'xsmall',
        'active': 'large-white'
    ),
    'compare-page': (
        'table': 'light-thick'
    ),
    'dropdown': (
        'action-dropdown': (
            'content': 'light'
        )
    ),
    'delivery-timeline': (
        'row-spacing': 'transparent-thick',
        'extra-spacing': 'white-extra-thick'
    )
);
