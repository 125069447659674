$\compass\magento-checkout\minicart\trigger\count\size: \kindling\value\rem-calc(18);
$\compass\magento-checkout\minicart\trigger\count\offset\top: \kindling\value\rem-calc(5);
$\compass\magento-checkout\minicart\trigger\count\offset\right: \kindling\value\rem-calc(16);
$\compass\magento-checkout\minicart\empty-tooltip\width: \kindling\value\rem-calc(220);

@mixin \compass\magento-checkout\minicart\empty\tooltip() {
    .minicart-tooltip {
        @include \compass\tooltip\content\apply(
            $side: 'bottom',
            $align: 'right'
        );
        @include \compass\animation\fade-in\hidden-state($duration: 'slow');
        right: 0;
        width: $\compass\magento-checkout\minicart\empty-tooltip\width;

        &.is-active {
            @include \compass\animation\fade-in\active-state($duration: 'slow');
        }

        .tooltip-text {
            font-size: inherit;
        }
    }
}

@mixin \compass\magento-checkout\minicart\item\image-container() {
    flex: 0 0 auto;
    width: \kindling\value\rem-calc(80);

    img {
        width: 100%;
    }
}

@mixin \compass\magento-checkout\minicart\item\info-container() {
    @include \kindling\spacing\apply('minicart.item-wrapper', $margin-sides: ('left'));
    flex: 1 1 auto;
}

@mixin \compass\magento-checkout\minicart\item() {
    .minicart-main {
        @include \compass\scroll-bar\apply();
        @include \kindling\typography\unstyled-list();
        @include \kindling\spacing\apply($spacing: 'minicart.item-wrapper', $padding-sides: ('left', 'right'));
        flex: 1 1 auto;
    }

    .minicart-item {
        @include \kindling\border\apply($context: 'minicart.item', $sides: ('top'));
        @include \kindling\spacing\apply($spacing: 'minicart.item-wrapper', $padding-sides: ('top', 'bottom'));

        &:first-child {
            border-top: 0;
        }
    }

    .minicart-item-content {
        display: flex;
        width: 100%;
        overflow: hidden;
    }

    .minicart-item-img-link {
        @include \compass\magento-checkout\minicart\item\image-container();
    }

    .minicart-item-content-details {
        @include \compass\magento-checkout\minicart\item\info-container();
    }

    .minicart-item-title {
        @include \compass\typography\font\apply('primary', 'normal');
        @include \kindling\typography\font-size('minicart.item.name');
        @include \kindling\typography\line-height('minicart.item.name');
        text-transform: none;
    }

    .minicart-item-prices {
        @include \kindling\typography\font-size('minicart.item.price');
        @include \kindling\spacing\apply('minicart.item', $padding-sides: ('top'));
        @include \kindling\typography\font-weight('minicart.price');
    }

    .minicart-attribute-row {
        @include \kindling\typography\line-height('minicart.item.attributes');
        vertical-align: baseline;
    }

    .minicart-attribute-th {
        text-align: left;
    }

    .minicart-attribute-data {
        @include \kindling\spacing\apply('minicart.item', $padding-sides: ('left'));
    }

    .minicart-item-attribute-label {
        @include \kindling\typography\font-size('minicart.item.attribute-label');
        @include \kindling\typography\font-weight('body');
    }

    .minicart-item-attribute-value {
        @include \kindling\typography\font-size('minicart.item.attribute-value');
    }

    .minicart-item-attributes {
        table {
            @include \project\delivery-timelines\table\apply();
        }
    }

    .minicart-item-actions {
        @include \kindling\spacing\apply('minicart.item', $padding-sides: ('top'));
    }

    .minicart-item-action-remove,
    .minicart-item-action-edit {
        @include \kindling\icon\apply($icon: $icon-trash) {
            @include \kindling\typography\font-size('minicart.item.action.icon');
            line-height: 1;
        }
        @include \kindling\typography\font-size('minicart.item.action.link');
        display: inline-block;
        text-decoration: none;

        &:not(:first-child) {
            @include \kindling\spacing\apply('minicart.item', $margin-sides: ('left'));
        }

        .minicart-item-action-remove-text,
        .minicart-item-action-edit-text {
            text-decoration: underline;
        }
    }

    .minicart-item-action-edit {
        &::before {
            content: $icon-edit;
        }
    }
}

@mixin \compass\magento-checkout\minicart\actions() {
    .minicart-actions {
        @include \kindling\spacing\apply($spacing: 'minicart.item-wrapper', $padding-sides: 'all');
        @include \kindling\border\apply('minicart.actions', $sides: ('bottom'));
        justify-content: space-between;
        background-color: \kindling\color\get('minicart.actions.background-color');

        .action {
            flex-grow: 1;

            &:first-of-type {
                @include \kindling\spacing\apply($spacing: 'minicart.header', $margin-sides: 'right');
            }
        }
    }

    .minicart-action-cart {
        @include \compass\button\apply($style: 'secondary', $size: 'small');
        @include \kindling\icon\apply($icon: $icon-chevron-left);
        display: block;
    }

    .minicart-action-checkout {
        @include \compass\button\apply($style: 'primary', $size: 'small');
        @include \kindling\icon\apply($icon: $icon-chevron-right, $use-before: false);
        display: block;
    }
}

@mixin \compass\magento-checkout\minicart\desktop-trigger() {
    .minicart-open-button {
        @include \compass\button\reset-browser-button();
        @include \kindling\spacing\apply('header.button.small', $padding-sides: ('top', 'bottom'));
        @include \kindling\spacing\apply('header.button.large', $padding-sides: ('left', 'right'));
        background: \kindling\color\get('minicart.trigger.background');

        &:hover,
        &:focus {
            .minicart-open-button-icon {
                color: \kindling\color\get('minicart.trigger.count.color');
            }
        }
    }

    .minicart-open-button-icon {
        @include \kindling\icon\apply($icon: $icon-cart) {
            @include \kindling\typography\font-size('minicart.trigger.icon');
            line-height: 1;
        }
        display: inline-block; // fix IE 11 bug: unexpected count indicator position
    }

    .minicart-open-button-count {
        @include \kindling\typography\font-size('minicart.trigger.count');
        vertical-align: 10%;
        line-height: 2;
        color: \kindling\color\get('minicart.trigger.count.color');
    }

    .minicart-open-button-icon {
        &::before {
            @include \kindling\typography\font-size('header.top.icon.minicart-icon');
        }
    }
}

@mixin \compass\magento-checkout\minicart\mobile-trigger() {
    .minicart-open-button {
        @include \compass\button\reset-browser-button();
        @include \kindling\spacing\apply('grid-gutters.large', $margin-sides: ('right'));
        position: relative;
        background-color: \kindling\color\get('minicart.trigger.background-mobile');

        @include \kindling\breakpoint\media-query($\compass\header\main\desktop-breakpoint) {
            display: none;
        }
    }

    .minicart-open-button-icon {
        @include \kindling\icon\apply($icon: $icon-cart) {
            @include \kindling\typography\font-size('minicart.trigger.icon');
            line-height: 1;
        }
        display: inline-block; // fix IE 11 bug: unexpected count indicator position
        color: \kindling\color\get('minicart.trigger.icon-mobile');

        @include \kindling\breakpoint\media-query($\compass\header\main\desktop-breakpoint) {
            color: \kindling\color\get('minicart.trigger.icon');
        }
    }

    .minicart-open-button-count {
        @include \kindling\typography\font-size('minicart.trigger.count');
        @include \kindling\border-radius\apply('minicart.trigger.count');
        display: block;
        position: absolute;
        top: $\compass\magento-checkout\minicart\trigger\count\offset\top;
        right: -#{$\compass\magento-checkout\minicart\trigger\count\offset\right};
        background-color: \kindling\color\get('minicart.trigger.count.background-color');
        min-width: $\compass\magento-checkout\minicart\trigger\count\size;
        text-align: left;
        line-height: $\compass\magento-checkout\minicart\trigger\count\size;
        color: \kindling\color\get('minicart.trigger.count.color-mobile');

        @include \kindling\breakpoint\media-query($\compass\header\main\desktop-breakpoint) {
            color: \kindling\color\get('minicart.trigger.count.color');
        }
    }
}

@mixin \compass\magento-checkout\minicart\header() {
    .minicart-header-item-count {
        @include \kindling\typography\font-size('minicart.header.count');
        @include \compass\typography\font\apply('primary', 'normal');
        margin-left: \kindling\value\rem-calc(5);
        vertical-align: middle;
        text-transform: lowercase;

        &::before {
            content: '-';
        }
    }

    .minicart-header {
        @include \compass\menu\header('minicart');
        display: flex;
    }

    .minicart-header-title {
        @include \compass\menu\header\text();
        @include \kindling\typography\heading\h4();
        @include \kindling\icon\apply($icon: $icon-cart) {
            color: \kindling\color\get('minicart.header.icon');
        };
    }

    .minicart-close-button {
        @include \compass\menu\header\close-button('minicart');
    }
}

@mixin \compass\magento-checkout\minicart\summary() {
    .minicart-summary {
        .subtotal {
            @include \kindling\spacing\apply($spacing: 'minicart.footer', $padding-sides: 'all');
            @include \kindling\typography\heading\h5();
            background-color: \kindling\color\get('minicart.totals.background-color');
            width: 100%;
        }

        .subtotal-table {
            width: 100%;
        }

        .est-total {
            text-align: left;
        }

        .price-column {
            text-align: right;
        }
    }
}

@mixin \compass\magento-checkout\minicart\apply() {
    @include \compass\magento-checkout\minicart\empty\tooltip();
    @include \compass\magento-checkout\minicart\item();
    @include \compass\magento-checkout\minicart\actions();
    @include \compass\magento-checkout\minicart\header();
    @include \compass\magento-checkout\minicart\summary();

    .minicart-wrapper {
        position: relative;
    }

    .minicart {
        @include \compass\pullout-menu\apply($side: 'right');
        background-color: \kindling\color\get('minicart.background-color');
    }

    .minicart-inner {
        display: flex;
        flex-direction: column;
        height: 100%;
        max-height: 100%;
    }

    .minicart-backdrop {
        @include \compass\pullout-menu\overlay();
        display: block;
    }

    .minicart-header,
    .minicart-actions,
    .minicart-summary {
        display: flex;
        flex: 0 0 auto;
    }
}
