@mixin \compass\menu\header($context: $\compass\menu\context) {
    $background-color: \kindling\color\context-keys-calc('header.background', $context, $\compass\menu\context);
    $border: \kindling\border\context-keys-calc('header.default', $context, $\compass\menu\context);

    @include \kindling\border\apply(
        $context: $border,
        $sides: ('bottom')
    );
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    background-color: \kindling\color\get($background-color);
}

@mixin \compass\menu\header\text($context: $\compass\menu\context) {
    $color: \kindling\color\context-keys-calc('header.text', $context, $\compass\menu\context);
    $spacing: \kindling\spacing\context-keys-calc('header.text', $context, $\compass\menu\context);

    @include \kindling\typography\ellipsis();
    @include \kindling\spacing\apply(
        $spacing: $spacing,
        $padding-sides: ('left', 'right')
    );
    flex-grow: 1;
    color: \kindling\color\get($color);
}

@mixin \compass\menu\header\close-button($context: $\compass\menu\context) {
    @include \compass\button\close(
        $size: $\compass\menu\close-button-size,
        $context: \kindling\context\concat-key-parts($context, 'header.close-button')
    );
    flex: 0 0 auto;
}

@mixin \compass\menu\header\apply($context: $\compass\menu\context) {
    @include \compass\menu\header($context);

    .menu-header-text {
        @include \compass\menu\header\text($context);
    }

    .menu-header-close-button {
        @include \compass\menu\header\close-button($context);
    }
}

@mixin \compass\menu\link-list\list() {
    @include \kindling\typography\unstyled-list();
}

@mixin \compass\menu\link-list\link(
    $context: $\compass\menu\context,
    $icon: $icon-next
) {
    $text-color: \kindling\color\context-keys-calc('content.link-list.text', $context, $\compass\menu\context);
    $text-color-hover: \kindling\color\context-keys-calc('content.link-list.text-hover', $context, $\compass\menu\context);
    $icon-color: \kindling\color\context-keys-calc('content.link-list.icon', $context, $\compass\menu\context);
    $icon-color-hover: \kindling\color\context-keys-calc('content.link-list.icon-hover', $context, $\compass\menu\context);
    $spacing: \kindling\spacing\context-keys-calc('items', $context, $\compass\menu\context);
    $background-color: \kindling\color\context-keys-calc('content.link-list.background', $context, $\compass\menu\context);
    $background-color-hover: \kindling\color\context-keys-calc('content.link-list.background-hover', $context, $\compass\menu\context);

    @if $icon {
        @include \kindling\icon\apply($icon: $icon, $use-before: false) {
            margin-left: \kindling\value\rem-calc(5);
            color: \kindling\color\get($icon-color);
        }
    }
    @include \kindling\typography\font-stack('secondary');
    @include \kindling\spacing\apply(
        $spacing: $spacing,
        $padding-sides: ('all')
    );
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: \kindling\color\get($background-color);
    text-decoration: none;
    color: \kindling\color\get($text-color);

    &:hover,
    &:focus {
        @include \kindling\icon\selector($use-before: false) {
            text-decoration: none;
            color: \kindling\color\get($icon-color-hover);
        }
        background-color: \kindling\color\get($background-color-hover);
        text-decoration: none;
        color: \kindling\color\get($text-color-hover);
    }
}

@mixin \compass\menu\link-list\item($context: $\compass\menu\context) {
    $border: \kindling\border\context-keys-calc('content.link-list.default', $context, $\compass\menu\context);

    @include \kindling\border\apply(
        $context: $border,
        $sides: ('bottom')
    );
}

@mixin \compass\menu\link-list\apply(
    $context: $\compass\menu\context,
    $icon: $\compass\menu\icon-default
) {
    @include \compass\menu\link-list\list();

    > * {
        @include \compass\menu\link-list\item($context);

        > a {
            @include \compass\menu\link-list\link($context, $icon);
        }
    }
}

@mixin \compass\menu\apply(
    $context: $\compass\menu\context,
    $icon: $\compass\menu\icon-default
) {
    .menu-header {
        @include \compass\menu\header\apply($context);
    }

    .menu-link-list {
        @include \compass\menu\link-list\apply($context, $icon);
    }
}
