@mixin \compass\page-banner\apply() {
    .page-banner-image {
        @include \compass\image-overlay\apply();

        .image-wrapper {
            @include \compass\image-section\background();
        }

        .page-banner-content {
            @include \compass\image-section\foreground();
            @include \kindling\spacing\apply('page-banner', $padding-sides: ('all'));
            @include \compass\layout\container-padded();
            @include \compass\typography\utility\set-section-color('page-banner.foreground');
            text-align: center;

            .breadcrumbs {
                @include \kindling\spacing\apply('base.small', $margin-sides: ('bottom'));

                a {
                    color: \kindling\color\get('page-banner.foreground');
                }
            }

            .page-title-wrapper {
                @include \kindling\spacing\apply('base.small', $padding-sides: ('bottom'));
                position: relative;

                &::before {
                    display: inline-block;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    background-color: \kindling\color\get('secondary');
                    width: \kindling\value\rem-calc(100);
                    height: \kindling\value\rem-calc(5);
                    content: '';
                }
            }
        }
    }
}
