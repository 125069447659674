@mixin \project\cms\fitment-chart-search\apply() {
    .cms-fitment-chart-search { // sass-lint:disable-block class-name-format
        .tablepress {
            margin-bottom: \kindling\value\rem-calc(12);
        }

        .dataTables_length,
        .dataTables_filter,
        .dataTables_info,
        .dataTables_paginate {
            display: flex;
            justify-content: right;
            padding-bottom: \kindling\value\rem-calc(12);
        }

        .sorting {
            cursor: pointer;

            &.sorting_desc {
                @include \kindling\icon\apply($icon: $icon-triangle-up, $use-before: false) {
                    margin-right: \kindling\value\rem-calc(5);
                }
            }

            &.sorting_asc {
                @include \kindling\icon\apply($icon: $icon-triangle-down, $use-before: false) {
                    margin-right: \kindling\value\rem-calc(5);
                }
            }
        }

        .dataTables_paginate {
            .paginate_button {
                @include \compass\button\secondary($size: 'micro');

                &.current {
                    @include \compass\button\primary-dark($size: 'micro');
                }
            }

            .previous,
            .next {
                @include \compass\button\primary($size: 'micro');

                &.disabled {
                    background-color: \kindling\color\get('button.disabled.background-color');
                    color: \kindling\color\get('button.disabled.text-color');
                }
            }

            .previous,
            .paginate_button {
                margin-right: \kindling\value\rem-calc(3);
            }

            .next {
                margin-right: 0;
            }
        }
    }
}
