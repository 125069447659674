@mixin \compass\page-builder\tabs\apply() {
    div[data-content-type='tabs'] {
        .tabs-navigation {
            display: block;
            margin: 0 0 \kindling\value\rem-calc(-1);
            padding: 0;
            font-size: 0;

            li {
                & :first-child {
                    margin-left: 0 !important; // sass-lint:disable-line no-important
                }
            }

            li.tab-header {
                display: inline-block;
                position: relative;
                z-index: 1;
                margin: 0;
                border: \kindling\value\rem-calc(1) solid \kindling\color\get('neutral');
                border-bottom: 0;
                border-bottom-left-radius: 0 !important; // sass-lint:disable-line no-important
                border-bottom-right-radius: 0 !important; // sass-lint:disable-line no-important
                background: \kindling\color\get('neutral-light');
                max-width: 100%;
                overflow-wrap: break-word;
                word-wrap: break-word;

                &:not(:first-child) {
                    margin-left: \kindling\value\rem-calc(-1);
                }

                a.tab-title {
                    display: block;
                    position: relative;
                    border-right: 0;
                    cursor: pointer !important; // sass-lint:disable-line no-important
                    padding: 1.4rem 2rem;
                    vertical-align: middle;
                    white-space: normal;
                    color: \kindling\color\get('neutral-black');
                    font-size: \kindling\value\rem-calc(14);
                    font-weight: 600;

                    span.tab-title {
                        display: block;
                    }

                    &:hover,
                    &:focus  {
                        text-decoration: none;
                    }
                }

                &:last-child {
                    border-right: \kindling\value\rem-calc(1) solid \kindling\color\get('neutral');
                }

                &.ui-state-active {
                    z-index: 19;
                    background: \kindling\color\get('white');

                    a.tab-title {
                        position: relative;
                    }
                }
            }
        }

        .tabs-content {
            position: relative;
            z-index: 9;
            border: \kindling\value\rem-calc(1) solid \kindling\color\get('neutral');
            overflow: hidden;

            [data-content-type='tab-item'] {
                min-height: inherit;

                &:not(:first-child) {
                    display: none;
                }
            }
        }

        &.tab-align-left {
            .tabs-content {
                border-top-left-radius: 0 !important; // sass-lint:disable-line no-important
            }
        }

        &.tab-align-right {
            .tabs-content {
                border-top-right-radius: 0 !important; // sass-lint:disable-line no-important
            }
        }
    }

    @include \kindling\breakpoint\media-query('medium' down) {
        [data-content-type='tab-item'] {
            background-attachment: scroll !important; // sass-lint:disable-line no-important
        }
    }
}
