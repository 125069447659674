@mixin \project\accordion\footer\container() {
    @include \compass\accordion\container('accordion.footer');

    background-color: \kindling\color\get('accordion.footer.body.background-color');
}

@mixin \project\accordion\footer\item() {
    @include \compass\accordion\item('accordion.footer');

    &:first-child {
        > button {
            @include \kindling\border\apply('reset', $sides: ('top'));
        }
    }

    &:last-child {
        > button {
            @include \kindling\border\apply('darkest-thin', $sides: ('bottom'), $additive: true);
        }

        .footer-links-content {
            @include \kindling\border\apply('darkest-thin', $sides: ('bottom'));
        }

        > button[aria-expanded='true'] {
            @include \kindling\border\apply('reset', $sides: ('bottom'), $additive: true);
        }
    }

    &.is-active {
        > button {
            &::before {
                right: 0;
            }
        }
    }
}

@mixin \project\accordion\footer\title() {
    @include \compass\accordion\title('accordion.footer');
    @include \kindling\border\apply('darkest-thin', $sides: ('top'));
    @include \kindling\spacing\apply('reset', $padding-sides: ('left', 'right'));

    &::before {
        right: 0;
        color: \kindling\color\get('accordion.footer.title.icon-color');
    }
}

@mixin \project\accordion\footer\body() {
    @include \compass\accordion\body('accordion.footer');
    @include \kindling\spacing\apply('reset', $padding-sides: ('top', 'left', 'right'));
}
