@mixin \compass\social-login\page\sociallogin-customer-connections\apply() {
    .customer-account-social-connections {
        .social-type-container {
            &:not(:first-of-type) {
                @include \compass\section(
                    $context: 'section.small',
                    $margin-sides: ('top')
                );
            }

            ~ .social-type-connect-buttons {
                @include \kindling\spacing\apply(
                    $spacing: 'section.small.outer',
                    $margin-sides: ('top')
                );
            }
        }

        .social-type-container-row {
            @include \compass\social-login\button\apply-all();
            @include \compass\grid\row();
            flex-direction: column;
            align-items: center;

            @include \kindling\breakpoint\media-query($\compass\social-login\breakpoint) {
                flex-direction: row;
            }
        }

        .social-type-image-container {
            flex: 0 1 auto;

            .social-type-image {
                width: \kindling\value\rem-calc(125);
            }
        }

        .social-type-info {
            @include \kindling\typography\unstyled-list();
            flex: 1 1 auto;
            text-align: center;

            @include \kindling\breakpoint\media-query($\compass\social-login\breakpoint) {
                text-align: left;
            }
        }

        .social-type-connect-buttons {
            @include \compass\social-login\buttons\apply();
        }

        .social-type-disconnect {
            flex: 0 0 auto;
        }
    }
}
