@mixin \compass\catalog\product\info\stock() {
    .product-stock {
        display: inline-block;

        span {
            @include \compass\typography\font\apply('primary', 'bold');

            @include \kindling\icon\apply($icon: '') {
                @include \kindling\typography\font-size('pdp.product-info.stock.icon');
                color: inherit;
            }

            &.available {
                @include \kindling\icon\selector() {
                    color: \kindling\color\get('success');
                    content: $icon-success;
                }
            }

            &.unavailable {
                @include \kindling\icon\selector() {
                    color: \kindling\color\get('warning');
                    content: $icon-error;
                }
            }
        }
    }
}

@mixin \compass\catalog\product\info\top() {
    @include \compass\catalog\product\info\stock();

    .product-top-info {
        @include \kindling\typography\unstyled-list();
        @include \kindling\spacing\apply('pdp.product-info.product-info-top', $margin-sides: ('bottom'));
        display: flex;
        flex-wrap: wrap;

        li {
            @include \kindling\typography\font-size('pdp.product-info.product-info-top');

            &:not(:first-child) {
                &::before {
                    margin: \kindling\value\rem-calc(0 6 0 8);
                    content: '|';
                }
            }

            .yotpo-sr-bottom-line-summary {
                //sass-lint:disable-block no-important
                div {
                    &:first-child {
                        @include \kindling\typography\font-size('pdp.yotpo-review.ratings');
                        margin-right: 0 !important;
                    }
                }

                .yotpo-sr-bottom-line-text {
                    font-size: \kindling\value\rem-calc(13) !important;
                }
            }
        }
    }
}

@mixin \compass\catalog\product\info\price() {
    .product-info-price {
        @include \kindling\spacing\apply('pdp.product-info.price', $margin-sides: ('top', 'bottom'));
        @include \catalog\price\styles\apply();

        .product-badge-container {
            float: left;
            margin-right: \kindling\value\rem-calc(6);
        }

        .price-box {
            line-height: 1;
        }

        .product-info-stock-sku {
            .configurable-variation-qty {
                display: none;
            }
        }
    }
}

@mixin \compass\catalog\product\info\stock-wrapper() {
    .product-info-stock-wrapper {
        @include \kindling\spacing\apply('pdp.product-info.stock-wrapper', $padding-sides: ('top'));

        .product.alert,
        .delivery-note-wrapper {
            display: inline;
        }

        .delivery-note,
        .product-alert-label {
            @include \kindling\typography\font-size('pdp.product-info.stock-wrapper.text');
            display: inline;
        }

        .shipping-policy-link,
        .product-alert-link {
            @include \kindling\typography\font-size('pdp.product-info.stock-wrapper.shipping-policy');
        }
    }
}

@mixin \compass\catalog\product\info\reviews() {
    .product-reviews-summary {
        @include \compass\reviews\short-summary\rating\apply();

        .rating-summary {
            @include \kindling\typography\font-size('reviews.pdp.icon');
        }
    }

    .reviews-actions {
        display: inline-block;

        .action {
            &.add {
                @include \compass\reviews\short-summary\action\apply();
                @include \kindling\typography\font-size('reviews.pdp.action');
            }

            &.view {
                @include \compass\typography\font\apply('primary', 'normal');
                @include \kindling\typography\font-size('reviews.pdp.action');
                text-decoration: none;
                color: \kindling\color\get('reviews.default.action');
            }
        }
    }
}

@mixin \compass\catalog\product\info\add-to-cart($full-width: true) {
    .product-add-form {
        @include \kindling\spacing\apply('pdp.product-info.add-to-cart.margin', $padding-sides: ('top'), $margin-sides: ('top'));
        @include \kindling\border\apply('pdp.product-info.section', $sides: ('top'));
    }

    .box-tocart {
        $input-spacing: \kindling\value\rem-calc(12);
        @include \kindling\spacing\apply('pdp.product-info.add-to-cart.padding', $padding-sides: ('all'));
        @include \compass\base\input\mage-error();
        background-color: \kindling\color\get('pdp.product-info.add-to-cart.background');

        .fieldset {
            @include \compass\grid\row(('small': $input-spacing), $spacing-type: 'margin');
            align-items: center;
        }

        .field {
            @include \kindling\border\apply('pdp.product-info.add-to-cart', $sides: ('all'));
            display: flex;
            align-items: center;
            background-color: \kindling\color\get('pdp.product-info.add-to-cart.quantity');
        }

        label {
            @include \compass\typography\heading('h6');
            @include \kindling\typography\font-size('pdp.product-info.add-to-cart.label');
            padding: 0 $input-spacing;
        }

        input {
            @include \kindling\form\input-text();
            @include \kindling\border\apply('form.default.input', $sides: ('left'));
            max-width: \kindling\value\rem-calc(60);
            height: \kindling\value\rem-calc(50);
            text-align: center;

            @include \kindling\breakpoint\media-query('large') {
                padding-right: \kindling\value\rem-calc(5);
            }

            &:not(:disabled) {
                box-shadow: none;

                &:hover,
                &:focus {
                    box-shadow: none;
                }

                &.mage-error {
                    box-shadow: none;

                    &:hover,
                    &:focus {
                        box-shadow: none;
                    }
                }
            }
        }

        .tocart {
            @include \compass\button\icon-button($icon: $icon-cart);
        }

        .alert {
            @include \compass\button\primary();
            margin-left: \kindling\value\rem-calc(12);
            background: \kindling\color\get('warning');
            color: \kindling\color\get('black');

            &:hover,
            &:focus {
                background: scale-color(\kindling\color\get('warning'), $lightness: -10%);
            }

            a {
                text-decoration: none;
                color: \kindling\color\get('black');

                &:hover,
                &:focus {
                    color: \kindling\color\get('black');
                }
            }
        }

        .actions {
            display: flex;
            flex: 1 1 auto;
        }

        div.mage-error {
            position: absolute;
            background: 0;
            padding: \kindling\value\rem-calc(5 0 0);
        }

        @if $full-width {
            .tocart {
                width: 100%;
            }

            .actions {
                flex: 1 1 auto;
            }
        }
    }
}

@mixin \compass\catalog\product\info\social-link\anchor($icon) {
    @include \compass\button\reset-browser-button();
    @include \compass\typography\font\apply('primary', 'semi-bold');
    @include \kindling\typography\font-size('pdp.product-info.social-link.text');
    @include \kindling\icon\apply($icon: $icon) {
        @include \kindling\typography\font-size('pdp.product-info.social-link.icon');
    }
    text-decoration: none;

    span {
        text-decoration: underline;
    }
}

@mixin \compass\catalog\product\info\social-link\dropdown() {
    @include \compass\dropdown\actions-dropdown();
}

@mixin \compass\catalog\product\info\social-link\list() {
    $social-link-list: (
        'email': $icon-email,
        'facebook': $icon-facebook,
        'twitter': $icon-twitter
    );

    .mailto-items {
        a {
            @include \kindling\icon\selector() {
                @include \kindling\icon\base();
                @include \kindling\typography\font-size('pdp.product-info.social-link.icon');
                margin-right: \kindling\value\rem-calc(5);
            }
            text-decoration: none;

            @each $social-link, $icon in $social-link-list {
                &.#{$social-link}-link {
                    @include \kindling\icon\selector() {
                        content: $icon;
                    }
                }
            }
        }
    }
}

@mixin \compass\catalog\product\info\social-links() {
    .product-social-links {
        .product-addto-links {
            @include \compass\grid\row(('small': \kindling\value\rem-calc(10)), $spacing-type: 'margin');
            align-items: center;
            justify-content: space-between;
        }

        .product-addto-link {
            &.addto-dropdown {
                @include \compass\catalog\product\info\social-link\dropdown();
                @include \compass\catalog\product\info\social-link\list();
            }
        }

        .towishlist {
            @include \compass\catalog\product\info\social-link\anchor($icon-heart-on);
        }

        .tocompare {
            @include \compass\catalog\product\info\social-link\anchor($icon-plus);
        }

        .mailto {
            @include \compass\catalog\product\info\social-link\anchor($icon-share);
        }
    }
}

@mixin \compass\catalog\product\info\bottom\additional() {
    .product-info-bottom-additional {
        @include \kindling\spacing\apply('pdp.product-info.bottom-additional.margin', $margin-sides: ('top'));
        @include \kindling\spacing\apply('pdp.product-info.bottom-additional.padding', $padding-sides: ('top'));
        @include \kindling\border\apply('pdp.product-info.section', $sides: ('top'));
        @include \compass\catalog\product\info\social-links();
    }
}

@mixin \project\catalog\product\info\trust-section() {
    .product-info-trust {
        @include \kindling\spacing\apply('pdp.product-info.trust', $padding-sides: ('top'));
        @include \compass\grid\row(('small': \kindling\value\rem-calc(10)));
        @include \compass\global\payment-icons\apply($show-secure: false, $in-footer: false);
        align-items: center;

        > * {
            flex: 0 0 auto;
        }

        .page-payment-icon-list {
            margin-bottom: 0;
        }

        .product-currency {
            flex-shrink: 1;
        }

        .product-currency-label {
            @include \kindling\typography\font-size('pdp.product-info.currency.label');

            &.ca {
                @include \kindling\icon\apply($icon: $icon-maplesleaf) {
                    @include \kindling\typography\font-size('pdp.product-info.currency.icon');
                    margin-right: \kindling\value\rem-calc(3);
                    color: \kindling\color\get('canada');
                }
            }
        }
    }
}

@mixin \project\catalog\product\info\fitment-label() {
    @include \project\fitment\fitment-label();

    .fitment-label {
        @include \kindling\spacing\apply('pdp.product-info.product-info-top', $margin-sides: ('bottom'));
    }
}

@mixin \compass\catalog\product\info\apply() {
    .product-info-main {
        @include \compass\catalog\product\info\top();
        @include \compass\catalog\product\info\price();
        @include \compass\catalog\product\info\stock-wrapper();
        @include \compass\catalog\product\info\reviews();
        @include \compass\product-badge\badge\apply();
        @include \compass\catalog\product\info\add-to-cart($full-width: false);
        @include \compass\catalog\product\info\bottom\additional();
        @include \project\catalog\product\info\trust-section();
        @include \project\catalog\product\info\fitment-label();

        .page-title {
            @include \compass\typography\heading('h3');
        }
    }
}
