@mixin \customer\account\heading\box() {
    .box {
        .box-title {
            strong {
                @include \kindling\typography\heading\h3();
            }
        }
    }
}

@mixin \customer\account\heading() {
    .block-title,
    .legend {
        @include \kindling\spacing\apply($spacing: 'base.small', $margin-sides: ('bottom'));
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        > .title,
        > span,
        > strong {
            @include \kindling\typography\heading\h2();
            @include \kindling\spacing\apply($spacing: 'global.xsmall', $padding-sides: ('top', 'bottom'), $ratio: 0.5);
        }

        .box-actions {
            @include \compass\button\grid-row();
        }

        .action {
            @include \compass\button\primary($size: 'small');
        }
    }

    .block-title {
        align-items: center;
    }
}
