@mixin \customer\account-entry\forgot\apply() {
    .customer-account-forgotpassword,
    .customer-account-createpassword {
        .block-title {
            h3 {
                @include \kindling\typography\heading\h2();
            }
        }
    }

    .block-customer-forgotpassword,
    .block-customer-resetpassword {
        @include \kindling\spacing\apply('section.default.inner', $padding-sides: ('top'));
    }
}
