@mixin \compass\button\item-action\base($use-before: true, $font-size: 'item-actions.default.text') {
    $side: if($use-before, 'left', 'right');
    $ratio: if($use-before, -1, 1);

    @include \kindling\typography\font-stack('item-actions.default.actions');
    @include \kindling\border\apply('item-actions.default', $sides: ($side));
    @include \kindling\typography\font-size($font-size);
    @include \kindling\icon\selector($use-before) {
        position: absolute;
        #{$side}: \kindling\value\px-to-em(20) * $ratio;
    }

    display: inline-block;
    position: relative;
    text-decoration: none;
    color: \kindling\color\get('item-actions.default.text-color');

    > span {
        text-decoration: underline;
    }

    &:not(:only-child) {
        @include \kindling\spacing\apply($spacing: 'item-actions.default.between', $margin-sides: ('right'));
    }

    &:last-child {
        margin-right: 0;
    }

    &:hover,
    &:focus {
        text-decoration: none;
        color: \kindling\color\get('item-actions.default.text-color-hover');
    }
}

@mixin \compass\button\item-action\apply(
    $icon,
    $use-before: true,
    $icon-font-size: 'item-actions.default.icon',
    $font-size: 'item-actions.default.text'
) {
    @include \compass\button\item-action\base($use-before: $use-before, $font-size: $font-size);
    @include \kindling\icon\apply($icon: $icon, $use-before: $use-before) {
        @include \kindling\typography\font-size($icon-font-size);
    }
}

@mixin \compass\button\item-action\order() {
    @include \compass\button\item-action\apply($icon: $icon-refresh);
}

@mixin \compass\button\item-action\view() {
    @include \compass\button\item-action\apply($icon: $icon-cart);
}
