@mixin \customer\account\address\default-label() {
    .customer-address-default-label {
        @include \kindling\typography\heading\h4();
        @include \kindling\spacing\apply($spacing: 'customer.account.address-labels', $margin-sides: ('bottom', 'left'));
        display: block;

        @include \kindling\breakpoint\media-query('large') {
            display: inline-block;
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .box-address-empty {
        @include \kindling\spacing\apply($spacing: 'grid-gutters', $margin-sides: ('right', 'left'));
    }
}
