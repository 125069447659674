@mixin \project\fitment\vehicle-navigator-form\featured\form\apply($breakpoint: 'large' 'only', $include-shadow: true) {
    @include \project\fitment\vehicle-navigator-form\base\apply();
    position: relative;
    z-index: \kindling\z-index\get('neutral-sibling');

    @if $include-shadow {
        @include \compass\dropdown\shadow();
    }

    .vehicle-navigator-form-accordion-title {
        @include \compass\accordion\disable-button();
    }

    .vehicle-navigator-form-accordion-body {
        &.is-active {
            display: block;
        }
    }

    .form {
        @include \compass\grid\row(
            $column-gutters: \kindling\spacing\get('form.default.input.margin'),
            $vertical-gutters: true,
            $spacing-type: 'margin'
        );
        flex-direction: column;

        @include \kindling\breakpoint\media-query($breakpoint) {
            flex-direction: row;
            flex-wrap: nowrap;
        }
    }

    .fields-container {
        flex: 1 1 auto;
    }

    .fields-container-row {
        @include \compass\grid\row(
            $column-gutters: \kindling\spacing\get('form.default.input.margin'),
            $vertical-gutters: true,
            $spacing-type: 'margin',
            $target: '.field'
        );
        flex-direction: column;

        @include \kindling\breakpoint\media-query($breakpoint) {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
        }
    }

    .field {
        flex: 1 1 auto;
        margin-bottom: 0;

        @include \kindling\breakpoint\media-query($breakpoint) {
            width: \kindling\grid\column-width-calc(3);
        }
    }

    .action {
        display: flex;
        flex: 0 0 auto;
    }
}

@mixin \project\fitment\vehicle-navigator-form\featured\apply() {
    .vehicle-navigation-container {
        @include \project\fitment\vehicle-navigator-form\featured\form\apply();
        @include \project\fitment\mini-garage\accordion-featured\apply();
        @include \project\fitment\mini-garage\empty-message-featured\apply();
        @include \project\fitment\mini-garage\list\apply();
    }
}
