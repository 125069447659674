@mixin \customer\account-entry\login\apply() {
    @include \compass\social-login\page\customer-account-login\apply();

    .customer-account-login {
        .content-main {
            .block-new-customer {
                @include \customer\account-entry\actions\apply();
                @include \kindling\spacing\apply('section.default.inner', $padding-sides: ('bottom', 'top'));
            }

            .actions-toolbar {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-start;

                .action.primary {
                    @include \kindling\spacing\apply($spacing: 'global.xsmall', $margin-sides: ('right'));
                }
            }

            .block-title {
                h3 {
                    @include \kindling\typography\heading\h2();
                }
            }

            fieldset.login {
                @include \compass\section(
                    $border-sides: ('bottom'),
                    $margin-sides: (),
                    $padding-sides: ('bottom', 'top')
                );

                @include \kindling\breakpoint\media-query('large') {
                    @include \kindling\border\apply('reset');
                }
            }
        }

        .social-login-container {
            @include \compass\section(
                $border-sides: ('bottom'),
                $margin-sides: (),
                $padding-sides: ('bottom', 'top')
            );
        }

        .customer-account-forgot-password-link {
            @include \kindling\typography\font-size('typography.small-copy');
            @include \kindling\spacing\apply($spacing: 'global.xsmall', $margin-sides: ('top', 'bottom'));

            .action {
                @include \kindling\spacing\apply($spacing: 'global.xsmall', $margin-sides: ('left'));
            }
        }

        .customer-create-account-value-add-list {
            @include \kindling\spacing\apply($spacing: 'base.small', $margin-sides: ('top', 'bottom'));
        }
    }
}
