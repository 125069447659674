@mixin \compass\mega-menu\desktop\top-level-item() {
    display: flex;
    align-items: center;

    .mega-menu-desktop-link-container {
        display: inherit;
        position: relative;
        align-items: inherit;
    }

    .mega-menu-desktop-level-0-link {
        @include \kindling\typography\heading\h6();
        @include \project\anchor\special-underline(
            $hover-color: 'inherit',
            $underline-size: \kindling\value\rem-calc(2),
            $underline-color-hover: 'menu.desktop-mega-menu.accent'
        );
        z-index: \kindling\z-index\get('above-sibling');
        margin: \kindling\value\rem-calc(31 10);

        @include \kindling\breakpoint\media-query('xlarge') {
            margin: \kindling\value\rem-calc(51 10);
        }

        @include \kindling\breakpoint\media-query($\compass\header\main\max-logo-breakpoint-down 'down') {
            font-size: \kindling\value\rem-calc(16);
        }
    }

    .mega-menu-desktop-level-0-link-parent {
        margin-right: \kindling\value\rem-calc(3);
    }

    .mega-menu-desktop-expand-button {
        @include \compass\button\reset-browser-button();
        position: absolute;
        top: 0;
        left: 0;
        z-index: \kindling\z-index\get('neutral-sibling');
        width: 100%;
        height: 100%;

        &:hover,
        &:focus {
            ~ .mega-menu-desktop-expand-icon {
                color: \kindling\color\get('menu.desktop-mega-menu.accent');
            }
        }
    }

    .mega-menu-desktop-expand-icon {
        @include \kindling\icon\apply($icon: $icon-triangle-down);
        display: inline-flex;
        margin-top: -0.6em;
        margin-right: \kindling\value\rem-calc(10);
        color: \kindling\color\get('menu.desktop-mega-menu.neutral');
        font-size: \kindling\value\rem-calc(10);
    }

    .mega-menu-desktop-item-active {
        .mega-menu-desktop-expand-icon {
            @include \kindling\icon\apply($icon: $icon-triangle-up);
        }
    }
}

@mixin \compass\mega-menu\desktop\prevent-item-wrap() {
    break-inside: avoid-column;
}

@mixin \compass\mega-menu\desktop\menu-base() {
    @include \compass\modal\shadow();
    @include \kindling\border\apply(
        $context: 'light'
    );
    @include \compass\scroll-bar\apply();
    top: 100%;
    background-color: \kindling\color\get('menu.desktop-mega-menu.content.background');
    max-height: calc(95vh - #{\kindling\value\rem-calc(120)});
}

@mixin \compass\mega-menu\desktop\apply() {
    .page-header-main-content-row {
        position: relative;
    }

    .mega-menu-desktop-nav-container {
        display: flex;
        height: inherit;

        @include \kindling\breakpoint\media-query($\compass\mega-menu\desktop\breakpoint-down 'down') {
            display: none;
        }
    }

    .mega-menu-desktop-nav {
        display: flex;
        align-items: center;
        height: inherit;
    }

    .mega-menu-desktop-list {
        @include \kindling\typography\unstyled-list();
        @include \compass\mega-menu\link-featured\apply('menu.desktop-mega-menu');
    }

    .mega-menu-desktop-level-0-list {
        display: flex;
        flex-wrap: wrap;
        margin: \kindling\value\rem-calc(0 32 0 10);
    }

    .mega-menu-desktop-level-0-item {
        @include \compass\mega-menu\desktop\top-level-item();
        @include \compass\mega-menu\link-featured\apply('menu.desktop-mega-menu');
    }

    .mega-menu-desktop-level-0-item-additional {
        position: relative;

        &:last-child {
            .mega-menu-desktop-list-cms {
                @include \kindling\breakpoint\media-query('large' 'only') {
                    right: 0;
                    left: auto;
                }
            }
        }
    }

    .mega-menu-desktop-list-cms {
        @include \compass\dropdown\content\apply();
        @include \compass\mega-menu\desktop\menu-base();
        left: 0;

        &.mega-menu-desktop-list-active {
            display: block;
            visibility: visible;
        }

        > :last-child {
            > [data-element='inner'] {
                &:last-child {
                    margin-bottom: 0 !important; // sass-lint:disable-line no-important
                }
            }
        }

        [data-content-type='row'] {
            padding-right: 0 !important; // sass-lint:disable-line no-important
            padding-left: 0 !important; // sass-lint:disable-line no-important
        }

        [data-content-type='text'] {
            > ul {
                @include \compass\menu\link-list\apply();
            }
        }
    }

    .mega-menu-desktop-level-1-list {
        @include \compass\mega-menu\desktop\menu-base();
        @include \kindling\property\apply('width', $\compass\mega-menu\desktop\level-1-width);
        display: none;
        position: absolute;
        left: 0;
        column-fill: balance;
        column-count: 4;
        padding: $\compass\mega-menu\desktop\level-1-spacing;
        max-width: 100%;

        &.mega-menu-desktop-list-active {
            display: block;
        }
    }

    .mega-menu-desktop-level-1-link {
        @include \kindling\typography\heading\h6();
        @include \kindling\typography\font-size('menu.mobile-mega-menu-level-2-link');
        text-decoration: none;

        &:hover,
        &:focus {
            color: \kindling\color\get('menu.desktop-mega-menu.accent');

            .mega-menu-desktop-level-1-text {
                @include \project\pseudo-underline\apply(
                    $use-before: false,
                    $bottom: 0,
                    $color: 'current-color'
                );
                position: relative;
            }
        }
    }

    .mega-menu-desktop-level-1-title {
        column-span: all;
        margin-bottom: \kindling\value\get-side($\compass\mega-menu\desktop\level-1-spacing, 'bottom');
    }

    .mega-menu-desktop-level-1-title-link {
        @include \compass\button\tertiary($size: 'large');
    }

    .mega-menu-desktop-level-1-item {
        @include \compass\mega-menu\desktop\prevent-item-wrap();
        margin-bottom: \kindling\value\rem-calc(20);

        > .mega-menu-desktop-link {
            @include \compass\mega-menu\desktop\prevent-item-wrap();
            display: inline-block; // ensure text wrapping respects padding
            position: relative;
            padding-left: $\compass\mega-menu\desktop\level-2-image-total-space;
            text-decoration: none;
        }

        .mega-menu-desktop-category-image {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: $\compass\mega-menu\desktop\level-2-image-width;
        }
    }

    .mega-menu-desktop-level-2-list {
        @include \compass\mega-menu\link-bottom-level('menu.desktop-mega-menu');
        padding-left: $\compass\mega-menu\desktop\level-2-image-total-space;
    }

    .mega-menu-desktop-level-2-more-link {
        color: \kindling\color\get('menu.desktop-mega-menu.accent');
    }
}
