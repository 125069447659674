@mixin \compass\header\promo\apply() {
    .page-header-promo {
        @include \compass\typography\utility\set-section-color(
            $color: \kindling\color\get-best-contrast('header.promo-bar.background-color', 'header.promo-bar.text-color')
        );
        @include \kindling\spacing\apply('header.promo-bar', $padding-sides: ('top', 'bottom'));
        position: relative;
        background-color: \kindling\color\get('header.promo-bar.background-color');
    }

    .page-header-promo-content {
        @include \compass\layout\container-padded();

        @include \kindling\breakpoint\media-query('small' 'only') {
            padding-right: 0;
        }
    }

    .page-header-promo-text {
        @include \kindling\property\apply('padding-right', $\compass\header\promo-bar\close-button-size, $ratio: 1.5);
        @include \kindling\typography\font-size('header.promo-bar');
        @include \kindling\typography\line-height('header.promo-bar');
        @include \kindling\typography\font-weight('medium');
        text-align: center;

        @include \kindling\breakpoint\media-query($\compass\header\main\desktop-breakpoint) {
            padding-right: 0;
        }

        a {
            text-decoration: underline;
        }
    }

    .page-header-promo-button-close {
        @include \compass\button\close($\compass\header\promo-bar\close-button-size);
        @include \kindling\property\apply('right', $\compass\header\promo-bar\close-button-position);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}
