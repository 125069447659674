@mixin \kindling\button\base\extra(
    $display: null,
    $align-items: null,
    $justify-content: null,
    $cursor: null,
    $vertical-align: null,
    $text-align: null,
    $font-stack: null,
    $font-weight: null,
    $font-style: null,
    $text-transform: null,
    $text-decoration: null,
    $appearance: null,
    $extras: ()
) {
    @include \kindling\typography\letter-spacing('button.default');

    &:focus,
    &:hover {
        text-decoration: none;
    }
}

@mixin \compass\button\grid-row($column-gutters: $\compass\button\grid-row\spacing) {
    @include \compass\grid\row(
        $column-gutters: \kindling\spacing\get($column-gutters),
        $spacing-type: 'margin'
    );
}

@mixin \compass\button\base($style: 'primary', $size: 'regular') {
    $border-hover: if(\kindling\border\exists('button.#{$style}-hover'), 'button.#{$style}-hover', null);

    @include \kindling\button\style($context: 'button.#{$style}', $border-hover: $border-hover);
    @include \kindling\button\size($context: 'button.#{$size}');
    text-transform: uppercase;
    text-decoration: $\compass\button\text-decoration;
}

@mixin \compass\button\apply($style: 'primary', $size: 'regular') {
    @if $style == 'tertiary' {
        @include \compass\button\tertiary($size: $size);
    } @else if $style == 'primary' {
        @include \compass\button\primary($size: $size);
    } @else if $style == 'primary-light' {
        @include \compass\button\primary-light($size: $size);
    } @else if $style == 'primary-dark' {
        @include \compass\button\primary-dark($size: $size);
    } @else if $style == 'secondary' {
        @include \compass\button\secondary($size: $size);
    } @else if $style == 'social-login.facebook' or $style == 'social-login.twitter' {
        @include \compass\social-login\button($type: $style, $size: $size);
    } @else {
        @error 'Unknown button style `#{$style}`';
    }
}

// @TODO Nate why contexts no work for disabled?
@mixin \kindling\button\disabled\extra(
    $color: null,
    $extras: ()
) {
    box-shadow: none;
    background-color: \kindling\color\get('button.disabled.background-color');
    color: \kindling\color\get('button.disabled.text-color');
}

@mixin \compass\button\primary($size: 'regular') {
    @include \compass\button\base($size: $size);
}

@mixin \compass\button\primary-light($size: 'regular') {
    @include \compass\button\base($style: 'primary-light', $size: $size);
}

@mixin \compass\button\primary-dark($size: 'regular') {
    @include \compass\button\base($style: 'primary-dark', $size: $size);

    &:disabled {
        background-color: \kindling\color\get('button.primary-dark.background-color');
        color: \kindling\color\get('button.disabled.background-color');
    }
}

@mixin \compass\button\secondary($size: 'regular') {
    @include \compass\button\base($style: 'secondary', $size: $size);
}

@mixin \compass\button\tertiary\light($size: 'regular') {
    @include \compass\button\tertiary($size: $size, $context: 'button.tertiary-light');
}

@mixin \compass\button\tertiary\dark($size: 'regular') {
    @include \compass\button\tertiary($size: $size, $context: 'button.tertiary-dark');
}

@mixin \compass\button\tertiary-hover($use-before: false, $text-color-hover: 'button.tertiary.text-color-hover') {
    $underline-selector: if($use-before, false, true);
    $ratio: if($use-before, -1, 1);

    @include \project\pseudo-underline\apply(
        $use-before: $underline-selector,
        $color: $text-color-hover
    );
    @include \kindling\icon\selector($use-before: $use-before) {
        transform: translateX($\compass\button\tertiary\icon-movement * $ratio);
        text-decoration: none;
    }

    text-decoration: none;
    color: \kindling\color\get($text-color-hover);
}

@mixin \compass\button\tertiary($size: 'regular', $context: 'button.tertiary', $use-before: false) {
    $icon: if($use-before, $icon-double-arrow-left, $icon-double-arrow-right);
    $side: if($use-before, 'right', 'left');
    $border-side: if($use-before, 'left', 'right');
    $underline-selector: if($use-before, false, true);

    $font-size: \kindling\typography\font-size\context-keys-calc($size, $context, 'button.tertiary');
    $border: \kindling\border\context-keys-calc('', $context, 'button.tertiary');
    $text-color: \kindling\color\context-keys-calc('text-color', $context, 'button.tertiary');
    $text-color-hover: \kindling\color\context-keys-calc('text-color-hover', $context, 'button.tertiary');

    @include \compass\button\reset-browser-button();
    @include \kindling\button\link-style('tertiary');
    @include \kindling\typography\font-stack('button.default');
    @include \kindling\typography\font-size($font-size);
    @include \kindling\border\apply($border, $sides: ($border-side));
    @include \kindling\icon\apply($icon: $icon, $use-before: $use-before) {
        @include \kindling\transition\apply(transform);
        position: absolute;
        #{$side}: calc(100% + #{$\compass\button\tertiary\icon-spacing});
        text-decoration: none;
    }

    position: relative;
    text-transform: uppercase;
    text-decoration: none;
    color: \kindling\color\get($text-color);

    &:disabled {
        @include \kindling\color\apply-background-color('button.disabled.tertiary.background-color', 'button.disabled.tertiary.text-color');
        cursor: not-allowed;
        text-decoration: none;
    }

    &:hover,
    &:focus {
        &:not(:disabled) {
            @include \compass\button\tertiary-hover($use-before: $use-before, $text-color-hover: $text-color-hover);
        }
    }
}

@mixin \compass\button\icon-button($icon, $style: 'primary', $size: 'regular', $use-before: true) {
    @include \compass\button\base($style: $style, $size: $size);
    @include \kindling\icon\apply($icon: $icon, $use-before: $use-before) {
        @include \kindling\spacing\apply($spacing: 'button-icon', $margin-sides: if($use-before == true, 'right', 'left'));
        @content;
    }
}

@mixin \compass\button\reset-browser-button($remove-outline: false) {
    @if $remove-outline != false {
        outline: none;
    }
    border: 0;
    background: \kindling\color\get('transparent');
    cursor: pointer;
    padding: 0;
    text-align: left;
}

@mixin \compass\button\icon-only-base(
    $size: $\compass\button\icon-only\button-size,
    $icon-size: $\compass\button\icon-only\icon-ratio,
    $context: $\kindling\button\default-context,
    $icon-color: 'icon-color',
    $icon-color-hover: 'icon-color-hover',
    $background-color: 'background-color',
    $background-color-hover: 'background-color-hover',
    $border: null,
    $border-hover: null
) {
    @include \compass\button\reset-browser-button();
    @include \kindling\button\style\frame(
        $context: $context,
        $background-color: $background-color,
        $background-color-hover: $background-color-hover,
        $border: $border,
        $border-hover: $border-hover,
        $text-color: $icon-color,
        $text-color-hover: $icon-color-hover
    );
    @include \kindling\property\apply('width', $size);
    @include \kindling\property\apply('height', $size);
    @include \kindling\icon\selector() {
        @each $breakpoint, $value in $size {
            @include \kindling\breakpoint\media-query($breakpoint) {
                @if type-of($icon-size) == 'map' {
                    font-size: calc(#{$value} * #{\kindling\map\get($icon-size, $breakpoint)});
                } @else {
                    font-size: calc(#{$value} * #{$icon-size});
                }
            }
        }
    }
    text-align: center;
}

@mixin \compass\button\icon-only(
    $icon: null,
    $size: $\compass\button\icon-only\button-size,
    $icon-size: $\compass\button\icon-only\icon-ratio,
    $context: $\kindling\button\default-context,
    $icon-color: 'icon-color',
    $icon-color-hover: 'icon-color-hover',
    $background-color: 'background-color',
    $background-color-hover: 'background-color-hover'
) {
    @include \compass\button\icon-only-base(
        $size: $size,
        $icon-size: $icon-size,
        $context: $context,
        $icon-color: $icon-color,
        $icon-color-hover: $icon-color-hover,
        $background-color: $background-color,
        $background-color-hover: $background-color-hover
    );
    @include \kindling\icon\apply($icon: $icon);
}

@mixin \compass\button\next-button(
    $style: 'primary',
    $size: 'regular'
) {
    @if ($style == 'tertiary') {
        @include \compass\button\tertiary($size: $size, $use-before: false);
    } @else {
        @include \compass\button\icon-button(
            $icon: $icon-next,
            $style: $style,
            $size: $size,
            $use-before: false
        );
    }
}

@mixin \compass\button\back-button(
    $style: 'primary',
    $size: 'regular'
) {
    @if ($style == 'tertiary') {
        @include \compass\button\tertiary($size: $size, $use-before: true);
    } @else {
        @include \compass\button\icon-button(
            $icon: $icon-previous,
            $style: $style,
            $size: $size,
            $use-before: true
        );
    }
}

@mixin \compass\button\close(
    $context,
    $size: $\compass\button\icon-only\button-size
) {
    @include \compass\button\icon-only(
        $icon: $icon-close,
        $size: $size,
        $context: $context
    );
}
