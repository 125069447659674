@mixin \project\horizontal-scroll-bar\apply(
    $top-scroll-bar-selector: '> :nth-child(1)',
    $scrollable-area-bar-selector: '> :nth-child(2)'
) {
    #{$top-scroll-bar-selector} {
        @include \compass\scroll-bar\apply($side: 'x');
    }

    #{$scrollable-area-bar-selector} {
        @include \compass\scroll-bar\apply($side: 'x');
    }
}
