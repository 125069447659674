@mixin \project\catalog\category\featured\apply() {
    .category-featured-tabs-container {
        @include \kindling\breakpoint\media-query('xxlarge') {
            @include \kindling\grid\max-width-container(\kindling\grid\column-width-calc(10));
        }
    }

    .category-featured-tabs {
        @include \compass\tabs\list\apply();
        margin-bottom: 0;
    }

    .category-featured-tabs-title {
        @include \compass\tabs\title\apply();
    }

    .category-featured-tabs-body {
        @include \compass\tabs\body\apply();
    }

    .category-featured-tabs-panel {
        @include \compass\tabs\panel\apply();
        @include \project\catalog\category\featured-list();
        @include \project\catalog\brand\featured-list();
    }
}
